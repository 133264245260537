import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { SectionWrapper } from '@Root/HOCs'
import { useHistory, useLocation } from 'react-router'
import { CreateInstitution } from './Create'
import { fieldTooltipManagerActions, modalActions } from '@Root/store'
import { useDispatch } from 'react-redux'
import { EditInstitution } from './Edit'
import { qualificationActions } from '@Store/admin'
import { institutionActions } from '@Store/admin'

export const InstitutionPage = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const pathname = location.pathname
  const isNew = pathname.includes('new')
  const DEFAULT_URL = '/home/admin/data/institutions'

  useEffect(() => {
    dispatch(qualificationActions.getStaticOptions())
    dispatch(institutionActions.getOptions())
    dispatch(
      fieldTooltipManagerActions.getDescriptions({
        entity: 'admin',
        entity_type: 'institutions',
      })
    )
  }, [dispatch])

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  return (
    <SectionWrapper hasBackButton backButtonText='Institutions' clickBackButtonHandler={() => history.push(DEFAULT_URL)}>
      <div className={styles.wrapper}>
        {isNew ? (
          <CreateInstitution onOpenDescriptionModal={onOpenDescriptionModal} parentURL={DEFAULT_URL} />
        ) : (
          <EditInstitution onOpenDescriptionModal={onOpenDescriptionModal} parentURL={DEFAULT_URL} />
        )}
      </div>
    </SectionWrapper>
  )
}

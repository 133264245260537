export const TOGGLE_LOADING = 'module/role/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'module/role/TOGGLE_SUCCESS'
export const GET_ROLE = 'module/role/GET_ROLE'
export const SET_ROLE = 'module/role/SET_ROLE'
export const CREATE_ROLE = 'module/role/CREATE_ROLE'
export const EDIT_ROLE = 'module/role/EDIT_ROLE'
export const DELETE_ROLE = 'module/role/DELETE_ROLE'
export const SET_OPTIONS = 'module/role/SET_OPTIONS'
export const GET_STATIC_OPTIONS = 'module/role/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'module/role/GET_DYNAMIC_OPTIONS'
export const SET_PERMISSIONS = 'module/role/SET_PERMISSIONS'

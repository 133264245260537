export const TOGGLE_LOADING = 'church/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'church/TOGGLE_SUCCESS'
export const GET_CHURCH = 'church/GET_CHURCH'
export const SET_CHURCH = 'church/SET_CHURCH'
export const EDIT_CHURCH = 'church/EDIT_CHURCH'
export const GET_STATIC_OPTIONS = 'church/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'church/GET_DYNAMIC_OPTIONS'
export const SET_OPTIONS = 'church/SET_OPTIONS'
export const DELETE_CHURCH = 'church/DELETE_CHURCH'
export const SET_PERMISSIONS = 'church/SET_PERMISSIONS'

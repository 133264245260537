import { all, call, put, takeEvery } from 'redux-saga/effects'
import { API } from '@Root/API'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage } from '@Root/helpers'
import { actions } from './note.actions'
import * as types from './note.types'

function* get({ payload }) {
	yield put(actions.toggleLoading(true))
	const { studentCourseSessionId } = payload
	try {
		const { data } = yield call(API.studies.studentCourseSession.note.get, studentCourseSessionId)
		yield put(actions.set(data.data))
		yield put(actions.setPermissions(data.permissions))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
	yield put(actions.toggleLoading(true))
	try {
		const { data } = yield call(API.studies.studentCourseSession.note.create, payload)
		yield put(actions.setCreated(data.data))
		yield put(actions.toggleSuccess(true))
		yield put(snackbarActions.setSuccessNotification({ text: 'The note has been created successfully!' }))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
		yield put(actions.toggleSuccess(false))
	}
	yield put(actions.toggleLoading(false))
	yield put(actions.toggleSuccess(false))
}

function* edit({ payload }) {
	yield put(actions.toggleLoading(true))
	const { id, data } = payload
	try {
		const response = yield call(API.studies.studentCourseSession.note.edit, id, data)
		yield put(actions.setEdited(response.data.data))
		yield put(actions.toggleSuccess(true))
		yield put(snackbarActions.setSuccessNotification({ text: 'The note has been saved successfully!' }))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleSuccess(true))
	yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
	const { id } = payload
	yield put(actions.toggleLoading(true))
	try {
		yield call(API.studies.studentCourseSession.note.remove, id)
		yield put(actions.setDeleted({ id }))
		yield put(snackbarActions.setSuccessNotification({ text: 'The note has been deleted successfully!' }))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

export function* rootSaga() {
	yield all([takeEvery(types.GET_NOTE, get), takeEvery(types.EDIT_NOTE, edit), takeEvery(types.DELETE_NOTE, remove), takeEvery(types.CREATE_NOTE, create)])
}

export const TOGGLE_LOADING = 'leaver/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'leaver/TOGGLE_SUCCESS'
export const GET_LEAVER = 'leaver/GET_COLLABORATIVE_PROVISION'
export const SET_LEAVER = 'leaver/SET_COLLABORATIVE_PROVISION'
export const CREATE_LEAVER = 'leaver/CREATE_COLLABORATIVE_PROVISION'
export const DELETE_LEAVER = 'leaver/DELETE_COLLABORATIVE_PROVISION'
export const EDIT_LEAVER = 'leaver/EDIT_COLLABORATIVE_PROVISION'
export const GET_OPTIONS = 'leaver/GET_OPTIONS'
export const SET_OPTIONS = 'leaver/SET_OPTIONS'
export const CLEAR_DATA = 'leaver/CLEAR_DATA'
export const SET_PERMISSIONS = 'leaver/SET_PERMISSIONS'

export const TOGGLE_LOADING = 'auth/TOGGLE_LOADING'
export const LOG_IN = 'auth/LOG_IN'
export const LOG_OUT = 'auth/LOG_OUT'
export const REMOVE_TOKEN = 'auth/REMOVE_TOKEN'
export const SET_TOKEN = 'auth/SET_TOKEN'
export const SET_AUTH_ERROR = 'auth/SET_AUTH_ERROR'
export const SEND_RECOVERY_EMAIL = 'auth/SEND_RECOVERY_EMAIL'
export const CHECK_RECOVERY_TOKEN = 'auth/CHECK_RECOVERY_TOKEN'
export const SET_RECOVERY_TOKEN = 'auth/SET_RECOVERY_TOKEN'
export const SEND_NEW_PASSWORD = 'auth/SEND_NEW_PASSWORD'
export const GET_USER = 'auth/GET_USER'
export const SET_USER = 'auth/SET_USER'
export const INIT_2FA_CODE = 'auth/INIT_2FA_CODE'
export const SET_2FA_CODE = 'auth/SET_2FA_CODE'
export const DISABLE_2FA = 'auth/DISABLE_2FA'

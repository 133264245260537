export const TOGGLE_LOADING = 'studentCourseSession/offVenueActivity/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'studentCourseSession/offVenueActivity/TOGGLE_SUCCESS'
export const GET_VENUE_ACTIVITY = 'studentCourseSession/offVenueActivity/GET_VENUE_ACTIVITY'
export const SET_VENUE_ACTIVITY = 'studentCourseSession/offVenueActivity/SET_VENUE_ACTIVITY'
export const CREATE_VENUE_ACTIVITY = 'studentCourseSession/offVenueActivity/CREATE_VENUE_ACTIVITY'
export const DELETE_VENUE_ACTIVITY = 'studentCourseSession/offVenueActivity/DELETE_VENUE_ACTIVITY'
export const EDIT_VENUE_ACTIVITY = 'studentCourseSession/offVenueActivity/EDIT_VENUE_ACTIVITY'
export const GET_OPTIONS = 'studentCourseSession/offVenueActivity/GET_OPTIONS'
export const SET_OPTIONS = 'studentCourseSession/offVenueActivity/SET_OPTIONS'
export const CLEAR_DATA = 'studentCourseSession/offVenueActivity/CLEAR_DATA'
export const SET_PERMISSIONS = 'studentCourseSession/offVenueActivity/SET_PERMISSIONS'
export const SET_CAN_BE_CREATED = 'studentCourseSession/offVenueActivity/SET_CAN_BE_CREATED'
export const SET_ERRORS = 'studentCourseSession/offVenueActivity/SET_ERRORS'

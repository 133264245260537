import * as types from './courseRole.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  courseRole: {
    hesa_identifier: null,
    role_type_id: null,
    proportion: null,
  },
  options: {},
  errors: [],
  permissions: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.SET_COURSE_ROLE:
      return {
        ...state,
        courseRole: payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: payload,
      }
    case types.CLEAR_DATA:
      return {
        ...state,
        courseRole: initialState.courseRole,
        errors: initialState.errors,
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    case types.SET_ERRORS:
      return {
        ...state,
        errors: payload,
      }
    default:
      return state
  }
}

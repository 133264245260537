import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { CheckboxInput } from "../CheckboxInput";
import { toggleArrayPrimitiveValue } from "@Root/helpers";

export const CheckboxInputGroup = ({ style, isInline, options: passedOptions, values: passedValues, changeHandler, isDisabled }) => {
  const options = passedOptions.map(passedOption => (typeof passedOption === "object" ? passedOption : { label: passedOption, value: passedOption }));
  const values = passedValues !== null ? passedValues : [];

  return (
    <div className={isInline ? classes.inline : null} style={style}>
      {options.map((option, i) => (
        <CheckboxInput
          style={{ height: 38 }}
          isChecked={values.includes(option.value)}
          changeHandler={() => changeHandler(toggleArrayPrimitiveValue(values, option.value), option.value)}
          label={{ text: option.label }}
          isDisabled={isDisabled}
          key={i}
        />
      ))}
    </div>
  );
};

CheckboxInputGroup.propTypes = {
  style: PropTypes.object,
  isInline: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
      })
    ])
  ),
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
  changeHandler: PropTypes.func,
  isDisabled: PropTypes.bool
};

CheckboxInputGroup.defaultProps = {
  style: {},
  isInline: false,
  options: [],
  values: null,
  changeHandler: () => {},
  isDisabled: false
};

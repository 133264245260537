import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import TEIDLogo from '../icons/TEIDLogo/TEIDLogo'
import { HeaderNavLinks } from './HeaderNavLinks'
import { HeaderUser } from './HeaderUser'
// import { GlobalSearch } from './GlobalSearch'
// import { useLocation } from 'react-router'

export const Header = ({ links, userName, userIcon, path, logOut, canTwoFA }) => {
  // const { pathname } = useLocation()
  // const isSearchPage = pathname === '/home/teid-search'

  return (
    <div className={classes.wrapper}>
      <div className={classes.left}>
        <TEIDLogo style={{ marginRight: 60 }} link={`/home`} />
        <HeaderNavLinks links={links} style={{ gridTemplateColumns: `repeat(${links.length}, max-content)`, justifyContent: 'flex-start' }} />
        {/* {!isSearchPage && <GlobalSearch />} */}
      </div>
      <HeaderUser userName={userName} userIcon={userIcon} path={path} logOut={logOut} canTwoFA={canTwoFA} />
    </div>
  )
}

Header.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  userName: PropTypes.string,
  userIcon: PropTypes.string,
  clickUserHandler: PropTypes.func,
  logOut: PropTypes.func,
}

import { createSelector } from 'reselect'
import { optionsSelectors } from '../../options'

export const admission = state => state.contactReducer.admission.data

export const isLoading = state => state.contactReducer.admission.isLoading

export const isSuccess = state => state.contactReducer.admission.isSuccess

export const permissions = state => state.contactReducer.admission.permissions

const admissionOptions = state => state.contactReducer.admission.options

export const options = createSelector([optionsSelectors.admissionBapStatuses, admissionOptions], (statuses, options) => {
  return {
    admission_bap_statuses: statuses,
    ...options,
  }
})

import * as types from './additionalInformation.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_ADDITIONAL_INFORMATION,
		payload,
	}),
	set: payload => ({
		type: types.SET_ADDITIONAL_INFORMATION,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_ADDITIONAL_INFORMATION,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

import { axios } from "@Root/API";
import objectToFormdata from "object-to-formdata";

export const postStudiesStudyProgrammeAttachment = (student_course_session_id, file, description, id) => {
  return axios.post("attache/file/student-course-session", objectToFormdata({ student_course_session_id, file, description, id }), {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

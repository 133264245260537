import * as types from './permissions.types'
import { all, put, takeEvery } from 'redux-saga/effects'
import { actions } from './permissions.actions'
import { role } from '@Root/configs'

function* get() {
	yield put(actions.toggleLoading(true))
	try {
		yield put(actions.set(role))
	} catch (error) {
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* save({ payload }) {
	try {
		yield console.log('Just simple save')
	} catch (error) {
		console.log(error)
	}
}

function getPermissionByRolesMerge({ payload }) {
	try {
		console.log('Log', payload)
	} catch (error) {
		console.log(error)
	}
}
export function* rootSaga() {
	yield all([takeEvery(types.GET_PERMISSIONS, get), takeEvery(types.SAVE_PERMISSIONS, save), takeEvery(types.ROLES_MERGE, getPermissionByRolesMerge)])
}

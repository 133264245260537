import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './studentInitiatives.types'
import { actions } from './studentInitiatives.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { contactId, engagementId, studentInitiativesId } = data
  try {
    const response = yield call(API.studies.engagement.studentInitiatives.get, engagementId, studentInitiativesId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/student-initiatives`)
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history, contactId, engagementId } = payload
  try {
    const response = yield call(API.studies.engagement.studentInitiatives.create, engagementId, data)
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/student-initiatives/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The student initiatives have been created successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, engagementId } = payload
  try {
    const response = yield call(API.studies.engagement.studentInitiatives.edit, engagementId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The student initiatives have been saved successfully!' }))
  } catch (error) {
    const errors = error.response.data.errors
    yield put(actions.set(payload))
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { engagementId, contactId, studentInitiativesId } = data
  try {
    yield call(API.studies.engagement.studentInitiatives.remove, studentInitiativesId, studentInitiativesId)
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/accreditation-aim`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The student initiatives have been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const {
      data: { student_initiative_types },
    } = yield call(API.studies.engagement.studentInitiatives.options.getStatic)
    const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    const formattedData = {
      student_initiative_types: generateObject(student_initiative_types),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('student_initiatives', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_STUDENT_INITIATIVES, get),
    takeEvery(types.CREATE_STUDENT_INITIATIVES, create),
    takeEvery(types.EDIT_STUDENT_INITIATIVES, edit),
    takeEvery(types.DELETE_STUDENT_INITIATIVES, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}

export const matchTypes = {
	'All of the rules': 'and',
	'Any of the rules': 'or',
}

export const equalities = {
	is: 'is',
	'<': 'less',
	'>': 'more',
	'<=': 'less_equal',
	'>=': 'more_equal',
	'is not': 'is_not',
	contains: 'contains',
	'is empty': 'is_empty',
	'is not empty': 'is_not_empty',
}

const trashedMandatoryRule = {
	id: 'Trashed',
	name: 'Trashed',
	description: 'Trashed',
	checkInPermissions: true,
	permission_id: 'trashed',
	matchType: 'All of the rules',
	rows: [
		{
			field: 'trashed',
			equality: 'is',
			filter: 'Yes',
		},
	],
}
export const trashInformationMandatoryRules = [
	{
		id: 'not_trashed',
		name: 'Not Trashed',
		description: 'Not Trashed',
		matchType: 'All of the rules',
		rows: [
			{
				field: 'trashed',
				equality: 'is',
				filter: 'No',
			},
		],
	},
	trashedMandatoryRule,
]

export const admissionsMandatoryRules = [
	{
		id: 'not_actioned',
		name: 'Not Actioned',
		description: 'Not Actioned',
		matchType: 'All of the rules',
		rows: [
			{
				field: 'actioned',
				equality: 'is',
				filter: 'unactioned',
			},
		],
	},
	{
		id: 'actioned',
		name: 'Actioned',
		description: 'Actioned',
		matchType: 'All of the rules',
		rows: [
			{
				field: 'actioned',
				equality: 'is',
				filter: 'actioned',
			},
		],
	},
]

export const suspendedMandatoryRules = [
	{
		id: 'current_students',
		name: 'Current students',
		description: 'Current students',
		matchType: 'All of the rules',
		rows: [
			{
				field: 'suspended',
				equality: 'is',
				filter: 'null',
			},
			{
				field: 'trashed',
				equality: 'is',
				filter: 'No',
			},
		],
	},
	{
		id: 'all',
		name: 'All Students',
		description: 'All',
		matchType: 'All of the rules',
		rows: [
			{
				field: 'trashed',
				equality: 'is',
				filter: 'No',
			},
		],
	},
]

export const suspendedRule = {
	id: 'suspended',
	name: 'Suspended',
	description: 'Suspended',
	matchType: 'All of the rules',
	rows: [
		{
			field: 'suspended',
			equality: 'contains',
			filter: '01,02,03',
		},
		{
			field: 'trashed',
			equality: 'is',
			filter: 'No',
		},
	],
}
export const suspendedSecretRule = {
	field: 'suspended',
	equality: 'is',
	filter: 'null',
	isDisabled: true,
}

export const defaultAllRule = {
	id: 'all',
	name: 'All',
	description: 'All',
	matchType: 'All of the rules',
	rows: [
		{
			field: 'trashed',
			equality: 'is',
			filter: 'No',
		},
	],
}

export const deletedRule = {
	id: 'archived',
	name: 'Deleted',
	description: 'archived',
	matchType: 'archived',
	rows: [],
}

export const dataPruningMandatoryRules = [trashedMandatoryRule, deletedRule]

import React, { useState, useEffect, useRef, useCallback } from 'react'
import styles from './style.module.scss'
import { useDispatch } from 'react-redux'
import { snackbarActions } from '@Root/store'
import { API } from '@Root/API'
import { all, errorMessage } from '@Root/helpers'
import { SectionWrapper, FormWrapper } from '@Root/HOCs'
import { Spinner, InputLabel, TextInput, Editor } from '@Root/components'
import { useHistory } from 'react-router'
import { useToggle } from '@Root/hooks'

export const EmailsTemplate = ({ conf }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [initialData, setInitialData] = useState({})
  const [variables, setVariables] = useState([])
  const [subject, setSubject] = useState('')
  const [body, setBody] = useState('<p></p>')
  const [isShown, setIsShown] = useState(false)
  const [isEditable, toggleEditable] = useToggle()
  const [isSpinning, setIsSpinning] = useState(false)
  const [permissions, setPermissions] = useState({})
  const editorRef = useRef()
  const { onRead, onEdit, createTemplate, getTemplate, pathId } = conf

  const onError = error => {
    dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }

  const readData = useCallback(async () => {
    try {
      const { data } = await getTemplate()
      setInitialData({
        subject: data.data.subject,
        body: data.data.template_email,
      })
      setVariables(
        Object.keys(data.data.variables_for_template).map(key => ({
          name: key,
          value: data.data.variables_for_template[key],
        }))
      )
      setSubject(data.data.subject)
      setBody(data.data.template_email)
      setPermissions(data.permissions)
    } catch (error) {
      onError(error)
      history.push('/page-not-found')
    }
  }, [history, onError])

  const resetData = () => {
    setSubject(initialData.subject)
    setBody(initialData.body)
    editorRef.current.setEditorState(initialData.body)
  }

  const updateTemplate = async () => {
    try {
      setIsSpinning(true)
      await createTemplate(subject, body)
      toggleEditable()
    } catch (error) {
      onError(error)
    }
    setIsSpinning(false)
  }

  useEffect(() => {
    ;(async () => {
      await readData()
      setIsShown(true)
    })()
  }, [])

  return (
    <div className={styles.wrapper} style={{ marginTop: 235 }}>
      {isShown ? (
        <SectionWrapper>
          <FormWrapper
            buttons={isEditable ? ['cancel', 'save'] : []}
            buttonsAreDisabled={isSpinning}
            isSpinning={isSpinning}
            clickCancelButtonHandler={() =>
              all(
                () => resetData(),
                () => toggleEditable()
              )
            }
            clickSaveButtonHandler={updateTemplate}
            isEditable={isEditable}
            canEdit={permissions?.edit}
            readTitle={onRead}
            editTitle={onEdit}
            clickToggleEditModeButtonHandler={toggleEditable}
            idForm={pathId ? pathId : null}
            isSuccess={isSpinning && !isEditable}
          >
            <div className={styles.content}>
              <div className={styles.variables}>
                {variables.map((variable, i) => (
                  <div className={styles.variable} key={i}>
                    <div className={styles.variableName}>{variable.name}</div>
                    <div className={styles.variableValue}>{variable.value} </div>
                  </div>
                ))}
              </div>
              <div className={styles.template}>
                <InputLabel text='Subject' />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ marginBottom: 20, width: 756 }}
                  value={subject}
                  changeHandler={value => setSubject(value)}
                  isDisabled={!isEditable}
                />
                <InputLabel text='Body' />
                <Editor className={styles.editor} ref={editorRef} value={body} changeHandler={value => setBody(value)} isDisabled={!isEditable} />
              </div>
            </div>
          </FormWrapper>
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: 'absolute' }} />
      )}
    </div>
  )
}

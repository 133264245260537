import * as types from './studentInitiatives.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	set: payload => ({
		type: types.SET_STUDENT_INITIATIVES,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_STUDENT_INITIATIVES,
		payload,
	}),
	get: payload => ({
		type: types.GET_STUDENT_INITIATIVES,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_STUDENT_INITIATIVES,
		payload,
	}),
	delete: payload => ({
		type: types.DELETE_STUDENT_INITIATIVES,
		payload,
	}),
	getOptions: () => ({
		type: types.GET_OPTIONS,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	clearData: () => ({
		type: types.CLEAR_DATA,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

import moment from 'moment'
import { DATE } from '@Root/configs/constants'

export const datePeriods = {
	All: ['1990-01-01', '2030-01-01'],
	Today: [moment().format(DATE.format), moment().format(DATE.format)],
	Yesterday: [
		moment()
			.subtract(1, 'days')
			.format(DATE.format),
		moment()
			.subtract(1, 'days')
			.format(DATE.format),
	],
	'This week': [
		moment()
			.startOf('isoWeek')
			.format(DATE.format),
		moment()
			.endOf('isoWeek')
			.format(DATE.format),
	],
	'Last week': [
		moment()
			.subtract(1, 'weeks')
			.startOf('isoWeek')
			.format(DATE.format),
		moment()
			.subtract(1, 'weeks')
			.endOf('isoWeek')
			.format(DATE.format),
	],
	'This month': [
		moment()
			.startOf('month')
			.format(DATE.format),
		moment()
			.endOf('month')
			.format(DATE.format),
	],
	'Last month': [
		moment()
			.subtract(1, 'months')
			.startOf('month')
			.format(DATE.format),
		moment()
			.subtract(1, 'months')
			.endOf('month')
			.format(DATE.format),
	],
	'This year': [
		moment()
			.startOf('year')
			.format(DATE.format),
		moment()
			.endOf('year')
			.format(DATE.format),
	],
	'Last year': [
		moment()
			.subtract(1, 'year')
			.startOf('year')
			.format(DATE.format),
		moment()
			.subtract(1, 'year')
			.endOf('year')
			.format(DATE.format),
	],
}

export const trackTokenInLocalStorage = (isRemember = 'false') => {
	;((nm, tm) => {
		const lStorage = localStorage
		const sStorage = sessionStorage
		const tabId =
			sStorage.getItem(tm) ||
			(newId => {
				sStorage.setItem(tm, newId)
				return newId
			})((Math.random() * 1e8).toFixed())
		const update = setTabValue => {
			let currentValue = JSON.parse(lStorage.getItem(nm) || '{}')
			const arrValue = Object.values(currentValue)
			if (
				!JSON.parse(isRemember) &&
				setTabValue &&
				typeof currentValue[tabId] === 'undefined' &&
				!arrValue.reduce((sum, current) => {
					return sum + current
				}, 0)
			) {
				lStorage.clear()
				currentValue = {}
			}
			currentValue[tabId] = setTabValue
			lStorage.setItem(nm, JSON.stringify(currentValue))
		}
		update(1)
		window.onbeforeunload = () => {
			update(0)
		}
	})('tabs', 'tabid')
}

import * as types from './subject.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_SUBJECT,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_SUBJECT,
		payload,
	}),
	set: payload => ({
		type: types.SET_SUBJECT,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_SUBJECT,
		payload,
	}),
	remove: payload => ({
		type: types.DELETE_SUBJECT,
		payload,
	}),
	getStaticOptions: () => ({
		type: types.GET_STATIC_OPTIONS,
	}),
	getDynamicOptions: () => ({
		type: types.GET_DYNAMIC_OPTIONS,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
	setErrors: payload => ({
		type: types.SET_ERRORS,
		payload,
	}),
}

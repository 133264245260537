import { engagementActions, feeActions, optionsActions, store } from '@Root/store'
import { admissionActions } from '@Store/contact'
import { hesaActions, personContactActions } from '@Store/contact/person'
import { organisationContactActions } from '@Store/contact/organisation'
import { fundingAndMonitoringActions } from '@Store/studies/studentCourseSession/fundingAndMonitoring'
import {
  accreditationAimActions,
  collaborationProvisionActions,
  entryProfileActions,
  entryQualificationAwardedActions,
  entryQualificationSubjectActions,
  leaverActions,
  qualificationAwardAccreditationActions,
  qualificationAwardActions,
  studentInitiativesActions,
} from '@Store/studies'

const searchOptionInLocalStorage = (optionsName, data) => {
  const methodsForGettingOptions = {
    student_application: admissionActions.getOptions,
    admission_bap_statuses: optionsActions?.getAdmissionBapStatuses,
    person_relationships: () => personContactActions?.getRelationShips({ isNew: false }),
    organisation_relationships: organisationContactActions?.getRelationShips,
    finance_funding: optionsActions.getFinanceFundingStatic,
    finance_tuition: optionsActions.getFinanceTuitionStatic,
    countries: optionsActions?.getCountries,
    TCB_response: optionsActions?.getTCBResponse,
    UOB_response: optionsActions?.getUOBResponse,
    postgrad_statuses: optionsActions?.getStatuses,
    hesa_details_options: hesaActions?.getStaticOptions,
    fee: feeActions.getOptions,
    funding_and_monitoring: fundingAndMonitoringActions.getOptions,
    engagements: engagementActions.getOptions,
    collaborative_provision: collaborationProvisionActions.getOptions,
    engagement_entry_profile: entryProfileActions.getOptions,
    entry_qualification_award: entryQualificationAwardedActions.getOptions,
    entry_qualification_subject: entryQualificationSubjectActions.getOptions,
    accreditation_aim: accreditationAimActions.getOptions,
    student_initiatives: studentInitiativesActions.getOptions,
    engagement_leaver: leaverActions.getOptions,
    qualification_award: qualificationAwardActions.getOptions,
    qualification_award_accreditation: qualificationAwardAccreditationActions.getOptions,
  }
  const methodsForSettingOptions = {
    student_application: admissionActions.setOptions,
    admission_bap_statuses: optionsActions?.setAdmissionBapStatuses,
    person_relationships: personContactActions?.setRelationShips,
    organisation_relationships: organisationContactActions?.setRelationShips,
    finance_funding: payload => optionsActions.setFinanceFunding({ name: 'static', data: payload }),
    finance_tuition: payload => optionsActions.setFinanceTuition({ name: 'static', data: payload }),
    countries: optionsActions?.setCountries,
    TCB_response: optionsActions?.setTCBResponse,
    UOB_response: optionsActions?.setUOBResponse,
    postgrad_statuses: optionsActions?.setStatuses,
    hesa_details_options: payload => hesaActions?.setOptions({ name: 'static', data: payload }),
    fee: feeActions.setOptions,
    funding_and_monitoring: fundingAndMonitoringActions.setOptions,
    engagements: engagementActions.setOptions,
    collaborative_provision: collaborationProvisionActions.setOptions,
    engagement_entry_profile: entryProfileActions.setOptions,
    entry_qualification_award: entryQualificationAwardedActions.setOptions,
    entry_qualification_subject: entryQualificationSubjectActions.setOptions,
    accreditation_aim: accreditationAimActions.setOptions,
    student_initiatives: studentInitiativesActions.setOptions,
    engagement_leaver: leaverActions.setOptions,
    qualification_award: qualificationAwardActions.setOptions,
    qualification_award_accreditation: qualificationAwardAccreditationActions.setOptions,
  }

  const parsedData = JSON.parse(data)
  optionsName.forEach(name => {
    const isAvailableInData = parsedData[name]
    if (!isAvailableInData) {
      store.dispatch(methodsForGettingOptions[name]?.())
    } else {
      const parsedData = JSON.parse(isAvailableInData)
      store.dispatch(methodsForSettingOptions[name]?.(parsedData))
    }
  })
}

export const getDataOptionsFromLocalStorage = optionsName => {
  const data = localStorage.getItem('dataOptions')
  if (data) {
    searchOptionInLocalStorage(optionsName, data)
  } else {
    const stringifyData = JSON.stringify({})
    localStorage.setItem('dataOptions', stringifyData)
    searchOptionInLocalStorage(optionsName, stringifyData)
  }
}

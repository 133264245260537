import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Error } from '@Root/HOCs'

export const PageNavLinks = ({ root, links, style, subLinks, currentLink, subStyles }) => {
	return !subLinks ? (
		<div style={{ ...style, width: '100%', background: '#f5f5f5' }}>
			{links.length > 0 ? (
				<div className='PageNavLinks' style={{ width: style.width }}>
					{links.map((link, i) => (
						<NavLink to={`${root}/${link.path}`} activeClassName='active' key={i}>
							{link.name}
						</NavLink>
					))}
				</div>
			) : (
				<Error>No data available</Error>
			)}
		</div>
	) : (
		<div style={{ ...style, width: '100%', background: '#f5f5f5', padding: '0 8px' }}>
			{links.length > 0 ? (
				<div className='PageNavLinks' style={{ marginBottom: currentLink?.subLinks.length > 0 ? 10 : 30 }}>
					{links.map(({ title, path }, i) => (
						<NavLink to={`${root}/${path}`} activeClassName='active' key={i}>
							{title}
						</NavLink>
					))}
				</div>
			) : (
				<Error>No data available</Error>
			)}
			{currentLink?.subLinks.length > 0 && (
				<div className='PageNavSubLinks' style={subStyles}>
					{currentLink?.subLinks.map(({ name, path }, i) => {
						return (
							<NavLink to={`${root}/${currentLink.path}/${path}`} activeClassName='active' key={i}>
								{name}
							</NavLink>
						)
					})}
				</div>
			)}
		</div>
	)
}

PageNavLinks.propTypes = {
	root: PropTypes.string.isRequired,
	links: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			path: PropTypes.string,
		})
	),
	subLinks: PropTypes.bool,
	style: PropTypes.object,
}

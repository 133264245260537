import * as types from './institution.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  institution: null,
  permissions: {},
  options: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      }
    case types.SET_INSTITUTION:
      return {
        ...state,
        institution: action.payload,
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: action.payload,
      }
    default:
      return state
  }
}

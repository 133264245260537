import React, { useEffect } from 'react'
import { StudentInitiativesForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { studentInitiativesActions, studentInitiativesSelectors } from '@Store/studies'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const EditStudentInitiativesPage = ({ onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: engagementId, contactId, studentInitiativesId } = useParams()
  const studentInitiatives = useSelector(studentInitiativesSelectors.studentInitiatives)
  const isSuccess = useSelector(studentInitiativesSelectors.isSuccess)
  const isLoading = useSelector(studentInitiativesSelectors.isLoading)
  const options = useSelector(studentInitiativesSelectors.options)
  const permissions = useSelector(studentInitiativesSelectors.permissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagement_student_initiatives

  useEffect(() => {
    dispatch(studentInitiativesActions.get({ data: { engagementId, contactId, studentInitiativesId }, history }))
    return () => {
      dispatch(studentInitiativesActions.clearData())
    }
  }, [dispatch])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This accreditation aim will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(studentInitiativesActions.delete({ data: { engagementId, contactId, studentInitiativesId }, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = data => {
    dispatch(studentInitiativesActions.edit({ data, engagementId }))
  }

  return (
    <div>
      <StudentInitiativesForm
        onSave={onSave}
        onDelete={onDelete}
        canEdit={permissions.form?.edit}
        canDelete={permissions.form?.delete}
        initialValue={studentInitiatives}
        isLoading={isLoading}
        isSuccess={isSuccess}
        options={options}
        descriptions={descriptions}
        onOpenDescriptionModal={onOpenDescriptionModal}
      />
    </div>
  )
}

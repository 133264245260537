import React from 'react'
import { CurriculumAccreditationForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { curriculumAccreditationSelectors, curriculumAccreditationActions } from '@Store/studies'

export const CreateCurriculumAccreditation = ({ onOpenDescriptionModal, parentURL }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { studyProgrammeId } = useParams()
  const curriculumAccreditation = useSelector(curriculumAccreditationSelectors.curriculumAccreditation)
  const options = useSelector(curriculumAccreditationSelectors.options)
  const isLoading = useSelector(curriculumAccreditationSelectors.isLoading)

  const onCreate = data => {
    dispatch(curriculumAccreditationActions.create({ data, history, studyProgrammeId }))
  }

  const onCancel = () => {
    history.length > 1 ? history.goBack() : history.push(parentURL)
  }

  return (
    <CurriculumAccreditationForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onCreate}
      onCancel={onCancel}
      canEdit={true}
      isNew
      initialValue={curriculumAccreditation}
      options={options}
      isLoading={isLoading}
    />
  )
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './subject.types'
import { actions } from './subject.actions'
import { API } from '@Root/API'
import { errorMessage } from '@Root/helpers'
import { moduleActions, snackbarActions } from '@Root/store'

function* get({ payload }) {
	yield put(actions.toggleLoading(true))
	const { subjectId, history } = payload
	try {
		const { data } = yield call(API.studies.module.subject.get, subjectId)
		yield put(actions.set(data.data))
		yield put(actions.setPermissions({ ...data.permissions, edit: true }))
	} catch (error) {
		history.push('/home/studies/engagements')
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
	yield put(actions.toggleLoading(true))
	const { history, data } = payload
	try {
		const response = yield call(API.module.subject.create, data)
		history.push(`/home/studies/modules/${response.data.data.module_id}/module-subject`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The module subject has been created successfully!' }))
	} catch (error) {
		// yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
		const entities = Object.entries(error.response.data.errors)
		const errors = entities.reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1][0] }), {})
		yield put(actions.setErrors(errors))
	}
	yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
	yield put(actions.toggleLoading(true))
	try {
		const response = yield call(API.module.subject.edit, payload)
		const data = response.data.data
		yield put(actions.set(data))
		yield put(actions.toggleSuccess(true))
		yield put(snackbarActions.setSuccessNotification({ text: 'The module subject has been saved successfully!' }))
		yield put(moduleActions.get({ moduleId: Number(data.module_id) }))
	} catch (error) {
		// yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
		const entities = Object.entries(error.response.data.errors)
		const errors = entities.reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1][0] }), {})
		yield put(actions.setErrors(errors))
	}
	yield put(actions.toggleSuccess(false))
	yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
	yield put(actions.toggleLoading(true))
	const { history, subjectId, moduleId } = payload
	try {
		yield call(API.module.subject.remove, subjectId)
		history.push(`/home/studies/modules/${moduleId}`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The module subject has been deleted successfully!' }))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

function* getStaticOptions() {
	yield put(actions.toggleLoading(true))
	try {
		const {
			data: { subject_identifiers },
		} = yield call(API.module.subject.options.getStatic)
		yield put(
			actions.setOptions({
				name: 'static',
				data: {
					subject_identifiers: subject_identifiers.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
				},
			})
		)
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
	yield put(actions.toggleLoading(false))
}

export function* rootSaga() {
	yield all([
		takeEvery(types.GET_SUBJECT, get),
		takeEvery(types.CREATE_SUBJECT, create),
		takeEvery(types.EDIT_SUBJECT, edit),
		takeEvery(types.DELETE_SUBJECT, remove),
		takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
	])
}

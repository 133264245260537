import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { SubstractIcon } from '@Root/assets'

export const InputLabel = ({ style, text, hasAsterisk, clickHandler, onOpenDescription, description, hideHeight }) => {
	return (
		text && (
			<div className={`${classes.InputLabel} ${hideHeight ? '' : classes.height}`} style={style} onClick={clickHandler}>
				{text}
				{hasAsterisk ? '*' : ''}
				{description && (
					<span>
						<img src={SubstractIcon} className={classes.mark} onClick={() => onOpenDescription(description, text)} />
					</span>
				)}
			</div>
		)
	)
}

InputLabel.propTypes = {
	style: PropTypes.object,
	text: PropTypes.string,
	hasAsterisk: PropTypes.bool,
	clickHandler: PropTypes.func,
}

InputLabel.defaultProps = {
	style: {},
	text: 'Label',
	description: '',
	hasAsterisk: false,
	clickHandler: () => {},
	onOpenDescription: () => {},
}

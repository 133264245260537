import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './courseInitiative.types'
import { actions } from './courseInitiative.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history } = payload
	const { studyProgrammeId, courseInitiativeId } = data
	try {
		const response = yield call(API.studies.programme.courseInitiative.get, studyProgrammeId, courseInitiativeId)
		yield put(actions.set(response.data.data))
		yield put(actions.setPermissions(response.data.permissions))
	} catch (error) {
		history.push(`/home/studies/programmes/${studyProgrammeId}/course_initiative`)
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history, studyProgrammeId } = payload
	try {
		const response = yield call(API.studies.programme.courseInitiative.create, studyProgrammeId, data)
		history.push(`/home/studies/programmes/${studyProgrammeId}/course_initiatives/${response.data.data.id}`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The Course Initiative has been created successfully!' }))
	} catch (error) {
		const errors = error.response.data.errors
		if (errors['studyProgrammeId']) {
			history.push('/home/studies/programmes')
		} else {
			yield put(actions.set(data))
			yield put(actions.setErrors(errors))
		}
	}
	yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
	yield put(actions.toggleLoading(true))
	const { courseInitiativeId, studyProgrammeId, data } = payload
	try {
		const response = yield call(API.studies.programme.courseInitiative.edit, studyProgrammeId, courseInitiativeId, data)
		yield put(actions.set(response.data.data))
		yield put(actions.toggleSuccess(true))
		yield put(snackbarActions.setSuccessNotification({ text: 'The Course Initiative has been saved successfully!' }))
	} catch (error) {
		const errors = error.response.data.errors
		yield put(actions.set(payload))
		yield put(actions.setErrors(errors))
	}
	yield put(actions.toggleSuccess(false))
	yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history } = payload
	const { studyProgrammeId, courseInitiativeId } = data
	try {
		yield call(API.studies.programme.courseInitiative.remove, studyProgrammeId, courseInitiativeId)
		history.push(`/home/studies/programmes/${studyProgrammeId}/course_initiatives`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The Course Initiative has been deleted successfully!' }))
	} catch (error) {
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* getOptions() {
	try {
		const { data } = yield call(API.studies.programme.courseInitiative.options.getStatic)
		const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
		const formattedData = {
			initiative_identifier: generateObject(data),
		}
		yield put(actions.setOptions(formattedData))
		saveDataOptionsToLocalStorage('courseInitiatives', formattedData)
	} catch (error) {
		console.log(error)
	}
}

export function* rootSaga() {
	yield all([
		takeEvery(types.GET_COURSE_INITIATIVE, get),
		takeEvery(types.CREATE_COURSE_INITIATIVE, create),
		takeEvery(types.EDIT_COURSE_INITIATIVE, edit),
		takeEvery(types.DELETE_COURSE_INITIATIVE, remove),
		takeEvery(types.GET_OPTIONS, getOptions),
	])
}

import { combineReducers } from 'redux'
import { authReducer } from './auth'
import { modalReducer } from './modal'
import { snackbarReducer } from './snackbar'
import { attachmentsReducer } from './attachments'
import { optionsReducer } from './options'
import { consentsReducer } from './consents'
import { studiesReducer } from './studies'
import { contactReducer } from './contact'
import { fieldTooltipManagerReducer } from './fieldTooltipManager'
import { adminReducer } from './admin'
import { admissionReducer } from './admission'
import { tableReducer } from './table'
import { permissionsReducer } from './permissions'
import { globalSearchReducer } from './globalSearch'

export const rootReducer = combineReducers({
  authReducer,
  attachmentsReducer,
  modalReducer,
  snackbarReducer,
  optionsReducer,
  consentsReducer,
  tableReducer,
  contactReducer,
  studiesReducer,
  fieldTooltipManagerReducer,
  adminReducer,
  admissionReducer,
  permissionsReducer,
  globalSearchReducer,
})

import React from 'react'
import { QualificationForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { qualificationActions, qualificationSelectors } from '@Store/admin'
import { useHistory } from 'react-router'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router-dom'

export const CreateQualification = ({ onOpenDescriptionModal, parentURL }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const qualification = useSelector(qualificationSelectors.qualification)
	const options = useSelector(qualificationSelectors.options).static
	const isLoading = useSelector(qualificationSelectors.isLoading)
	const descriptions = useSelector(fieldTooltipManagerSelectors.adminDescriptions).qualifications
	const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)

	const onSubmit = data => {
		dispatch(qualificationActions.create({ data, history }))
	}

	const onCancel = () => {
		history.goBack()
	}

	if (!dataCreationPermissions.qualifications) return <Redirect to={parentURL} />
	return (
		<QualificationForm
			canEdit
			isNew
			options={options}
			initialValue={qualification}
			onSave={onSubmit}
			onOpenDescriptionModal={onOpenDescriptionModal}
			descriptions={descriptions}
			isLoading={isLoading}
			onCancel={onCancel}
		/>
	)
}

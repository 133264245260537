import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { CreateEngagement } from './Create'
import { EditEngagement } from './Edit'
import styles from './style.module.scss'
import { modalActions } from '@Root/store'
import { useDispatch } from 'react-redux'

export const EngagementDetailsPage = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const isNew = location.pathname.includes('new')
  const { contactId } = useParams()

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('NotificationModal', {
          text: description,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
          title,
        })
      )
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  return (
    <SectionWrapper hasBackButton backButtonText={'Engagements'} clickBackButtonHandler={() => history.push(`/home/studies/engagements`)} activeName>
      <div className={styles.sections}>
        {isNew ? (
          <CreateEngagement contactId={contactId} onOpenDescriptionModal={onOpenDescriptionModal} />
        ) : (
          <EditEngagement onOpenDescriptionModal={onOpenDescriptionModal} />
        )}
      </div>
    </SectionWrapper>
  )
}

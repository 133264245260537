export const TOGGLE_LOADING = 'module/subject/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'module/subject/TOGGLE_SUCCESS'
export const GET_SUBJECT = 'module/subject/GET_SUBJECT'
export const SET_SUBJECT = 'module/subject/SET_SUBJECT'
export const CREATE_SUBJECT = 'module/subject/CREATE_SUBJECT'
export const EDIT_SUBJECT = 'module/subject/EDIT_SUBJECT'
export const DELETE_SUBJECT = 'module/subject/DELETE_SUBJECT'
export const SET_OPTIONS = 'module/subject/SET_OPTIONS'
export const GET_STATIC_OPTIONS = 'module/subject/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'module/subject/GET_DYNAMIC_OPTIONS'
export const SET_PERMISSIONS = 'module/subject/SET_PERMISSIONS'
export const SET_ERRORS = 'module/subject/SET_ERRORS'

import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './tableManager.types'
import { API } from '@Root/API'
import { actions } from './tableManager.actions'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage } from '@Root/helpers'
import { modalActions } from '@Store/modal'
import { store } from '@Root/store'

function* get({ payload }) {
	const { entity, entityType } = payload
	try {
		const response = yield call(API.permissions.tableManager.get, entity, entityType)
		const data = response.data
		yield put(actions.set(data.data))
		yield put(actions.setPermissions(data.permissions))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

function* getAdditionalFields() {
	try {
		const response = yield call(API.permissions.tableManager.additionalFields.get)
		const data = response.data
		yield put(actions.set(data.data))
		yield put(actions.setPermissions(data.permissions))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

function* edit({ payload }) {
	const onCloseModal = () => {
		store.dispatch(modalActions.hideModal())
	}
	const { entity, entityType, data } = payload
	const formattedData = data.filter(item => item.value).map(item => item.label)
	try {
		yield call(API.permissions.tableManager.edit, entity, entityType, { columns: formattedData })
		yield put(snackbarActions.setSuccessNotification({ text: 'Changes have been saved successfully!' }))
	} catch (error) {
		yield put(
			modalActions.showModal('NotificationModal', {
				text: 'At least one data should be active',
				clickRejectButtonHandler: onCloseModal,
				clickResolveButtonHandler: onCloseModal,
			})
		)
	}
}

function* editAdditionalFields({ payload }) {
	try {
		const { data } = yield call(API.permissions.tableManager.additionalFields.edit, { fields: payload })
		yield put(actions.set(data.data))
		yield put(snackbarActions.setSuccessNotification({ text: 'Changes have been saved successfully!' }))
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

export function* rootSaga() {
	yield all([
		takeEvery(types.GET_TABLE_PERMISSIONS, get),
		takeEvery(types.GET_ADDITIONAL_FIELDS_TABLE_PERMISSIONS, getAdditionalFields),
		takeEvery(types.EDIT_TABLE_PERMISSIONS, edit),
		takeEvery(types.EDIT_ADDITIONAL_FIELDS_TABLE_PERMISSIONS, editAdditionalFields),
	])
}

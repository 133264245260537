import React, { useEffect, useState, useRef } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { useError, useToggle } from '@Root/hooks'
import { useSelector } from 'react-redux'

const titles = { onRead: 'Reference Period', onEdit: 'Edit Reference Period', onCreate: 'Create Reference Period' }
export const ReferencePeriodForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  onOpenDescriptionModal,
  errorsFields,
}) => {
  const [data, setData] = useState({})
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).person.hesa_details.student_course_sessions
  const { errors, setError, onClear: onClearError } = useError()
  const memoData = useRef({})

  useEffect(() => {
    if (initialValue) setData(initialValue)
    if (!memoData.current?.year) memoData.current = initialValue
  }, [initialValue])

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      onClearError()
      memoData.current = initialValue
    }
  }, [isSuccess])

  useEffect(() => {
    if (errorsFields['year']) {
      const error = errorsFields['year']
      setError('year', error[0])
    }

    if (errorsFields['rp_student_load']) {
      const error = errorsFields['rp_student_load']
      setError('rp_student_load', error[0])
    }
  }, [errorsFields])

  const validateForm = () => {
    const { reference_period_id, year, rp_student_load } = data
    if (!reference_period_id) {
      setError('reference_period_id', 'Required')
      return false
    }
    if (!year) {
      setError('year', 'Required')
      return false
    }
    if (!rp_student_load) {
      setError('rp_student_load', 'Required')
      return false
    }
    return true
  }

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    onSave(data)
  }

  const onCancelEdit = () => {
    if (!isNew) {
      toggleEditable()
      onClearError()
      onCancel(memoData.current)
    } else {
      onCancel()
    }
  }

  const { onEdit, onRead, onCreate } = titles
  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this Reference Period' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onChangeMode}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={data?.id ? `reference_period_${data?.id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Reference period'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.reference_period_id}
                onChange={onChangeValue('reference_period_id')}
                placeholder='Reference period'
                isDisabled={!isEditable}
                options={options.reference_periods}
                description={descriptions?.reference_period_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                error={errors.reference_period_id}
                field={'select'}
              />
              <Field
                label={'Year'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.year}
                onChange={onChangeValue('year')}
                placeholder='Year'
                isDisabled={!isEditable}
                description={descriptions?.year}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                maxLength={50}
                error={errors.year}
              />
              <Field
                label={'RP Student load'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.rp_student_load}
                onChange={onChangeValue('rp_student_load')}
                placeholder='RP Student load'
                isDisabled={!isEditable}
                description={descriptions?.rp_student_load}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                restriction='betweenOneAndHundred'
                maxLength={4}
                error={errors.rp_student_load}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, Select } from "@Root/components";

export class StudiesStudentModuleFeeForm extends Component {
  constructor(props) {
    super(props);
    const { initialIsEditable, initialData } = props;
    this.state = {
      isEditable: initialIsEditable,
      error: null,
      isSaving: false,
      student_course_session_id: initialData.student_course_session_id,
      fee_amount: initialData.fee_amount,
      fee_collector_id: initialData.fee_collector_id
    };
  }
  formIsValid = () => {
    const { fee_amount } = this.state;

    if (!fee_amount) {
      this.showError("fee_amount", "Required");
      return false;
    }
    return true;
  };

  showError = (input, message) => {
    this.setState({ error: { input, message } }, () => {
      this.setState({ error: null });
    });
  };

  errorMessage = input => {
    const { error } = this.state;
    return error && error.input === input ? error.message : null;
  };

  setInitialState = () => {
    const { initialData } = this.props;
    this.setState({
      isEditable: false,
      error: null,
      student_course_session_id: initialData.student_course_session_id,
      fee_amount: initialData.fee_amount,
      fee_collector_id: initialData.fee_collector_id
    });
  };

  handleClickCancelButton = () => {
    this.setInitialState();
  };

  handleClickDeleteButton = () => {
    this.props.deleteHandler(
      () => {
        this.setState({ isSaving: true });
      },
      () => {
        this.setState({ isSaving: false });
      }
    );
  };

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return;
    const { state } = this;
    const moduleDelId = this.props.location.pathname.split("/")[6];
    this.setState({ isSaving: true });
    try {
      await this.props.saveHandler({
        module_delivery_id: moduleDelId,
        student_course_session_id: state.student_course_session_id,
        fee_amount: state.fee_amount,
        fee_collector_id: state.fee_collector_id
      });
      !this.isUnmounted && this.setState({ isEditable: false });
    } catch (error) {
      console.log(error);
      alert("Unable to save the data");
    }
    !this.isUnmounted && this.setState({ isSaving: false });
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const { isEditable, isSaving, student_course_session_id, fee_amount, fee_collector_id } = this.state;

    const { permissions, title, buttons, options } = this.props;
    const { handleClickCancelButton, handleClickDeleteButton, handleClickSaveButton, errorMessage } = this;

    const { onRead: titleOnRead = "", onEdit: titleOnEdit = "" } = title;

    return (
      <div className={classes.StudiesStudentModuleFeeForm}>
        <FormWrapper
          buttons={isEditable ? buttons : []}
          buttonsNames={[{ button: "delete", name: "Delete this module cost center" }]}
          buttonsAreDisabled={isSaving}
          isSpinning={isSaving}
          clickCancelButtonHandler={handleClickCancelButton}
          clickDeleteButtonHandler={handleClickDeleteButton}
          clickSaveButtonHandler={handleClickSaveButton}
        >
          <div className={classes.titleWrapper}>
            <SectionTitle title={isEditable ? titleOnEdit : titleOnRead} />
            {permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => this.setState({ isEditable: true })} />}
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Student course session" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.student_course_sessions}
                  value={student_course_session_id}
                  changeHandler={value => this.setState({ student_course_session_id: value })}
                  isDisabled={!isEditable}
                  // error={errorMessage("student_course_session_id")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Fee amount" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={fee_amount}
                  changeHandler={fee_amount => this.setState({ fee_amount })}
                  placeholder=""
                  isDisabled={!isEditable}
                  restriction="digits"
                  error={errorMessage("fee_amount")}
                />
              </div>
            </div>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Fee collector" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.fee_collectors}
                  value={fee_collector_id}
                  changeHandler={value => this.setState({ fee_collector_id: value })}
                  isDisabled={!isEditable}
                  // error={errorMessage("fee_collector_id")}
                />
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

StudiesStudentModuleFeeForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  initialData: PropTypes.shape({
    student_course_session_id: PropTypes.number,
    fee_amount: PropTypes.number,
    fee_collector_id: PropTypes.number
  }),
  initialIsEditable: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

StudiesStudentModuleFeeForm.defaultProps = {
  permissions: [],
  title: {
    onRead: "",
    onEdit: ""
  },
  initialData: {
    student_course_session_id: null,
    fee_amount: null,
    fee_collector_id: null
  },
  initialIsEditable: false,
  options: null,
  buttons: ["cancel", "delete", "save"],
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {}
};

export const popupHeight = (initialRowsQuantity, maxRowsQuantity, rowHeight) => {
  let quantity;
  if (maxRowsQuantity) {
    quantity = initialRowsQuantity <= maxRowsQuantity ? initialRowsQuantity : maxRowsQuantity;
  } else {
    quantity = initialRowsQuantity;
  }
  const calculatedHeight = quantity * rowHeight + quantity - 1;
  return `${calculatedHeight > 0 ? calculatedHeight : 0}px`;
};

export const tableHeight = (rowsQuantity, hasFilters = false) => {
  return (rowsQuantity + 1 + hasFilters) * 46 + 38;
};

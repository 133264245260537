import * as types from './fee.types'

const initialPermissions = {
	fields: { main_source_of_fee: { view: true, edit: true }, gross_fee: { view: true, edit: true }, deductions: { view: true, edit: true } },
}
const initialState = {
	fee: {},
	options: [],
	isLoading: false,
	isSuccess: false,
	isNew: false,
	permissions: initialPermissions,
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: action.payload,
			}
		case types.SET_FEE:
			return {
				...state,
				fee: action.payload,
			}
		case types.TOGGLE_NEW_FEE:
			return {
				...state,
				isNew: action.payload,
			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: action.payload,
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			}
		case types.CLEAR_PERMISSIONS:
			return {
				...state,
				permissions: initialPermissions,
			}
		default:
			return state
	}
}

export const TOGGLE_LOADING = 'studentCourseSession/moduleInstances/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'studentCourseSession/moduleInstances/TOGGLE_SUCCESS'
export const GET_MODULE_INSTANCES = 'studentCourseSession/moduleInstances/GET_MODULE_INSTANCES'
export const SET_MODULE_INSTANCES = 'studentCourseSession/moduleInstances/SET_MODULE_INSTANCES'
export const CREATE_MODULE_INSTANCES = 'studentCourseSession/moduleInstances/CREATE_MODULE_INSTANCES'
export const DELETE_MODULE_INSTANCES = 'studentCourseSession/moduleInstances/DELETE_MODULE_INSTANCES'
export const EDIT_MODULE_INSTANCES = 'studentCourseSession/moduleInstances/EDIT_MODULE_INSTANCES'
export const GET_OPTIONS = 'studentCourseSession/moduleInstances/GET_OPTIONS'
export const SET_OPTIONS = 'studentCourseSession/moduleInstances/SET_OPTIONS'
export const CLEAR_DATA = 'studentCourseSession/moduleInstances/CLEAR_DATA'
export const SET_PERMISSIONS = 'studentCourseSession/moduleInstances/SET_PERMISSIONS'
export const SET_CAN_BE_CREATED = 'studentCourseSession/moduleInstances/SET_CAN_BE_CREATED'
export const SET_ERRORS = 'studentCourseSession/moduleInstances/SET_ERRORS'

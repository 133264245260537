import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { CreatePostgrad } from './Create'
import { EditPostgrad } from './Edit'
import { useDispatch } from 'react-redux'
import { studentCourseSessionActions } from '@Store/studies/studentCourseSession/index'

export const PostgradPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const pathname = location.pathname
  const isNew = pathname.includes('new')
  const { studyProgrammeId, contactId } = useParams()
  const isDuplicate = pathname.includes('duplicate')
  const DEFAULT_URL = `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/funding_monitoring`

  useEffect(() => {
    dispatch(studentCourseSessionActions.get({ studyProgrammeId, isCopy: isDuplicate }))
    return () => {
      dispatch(studentCourseSessionActions.clearData({}))
    }
  }, [])

  return (
    <SectionWrapper hasBackButton backButtonText={'Funding and Monitoring'} clickBackButtonHandler={() => history.push(DEFAULT_URL)} activeName>
      <div className={styles.sections}>{isNew ? <CreatePostgrad isNew={isNew} parentURL={DEFAULT_URL} /> : <EditPostgrad />}</div>
    </SectionWrapper>
  )
}

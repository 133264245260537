import React, { Component } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import { delay } from 'redux-saga'
import xIcon from '../../../assets/icons/x.png'
import { popupHeight, all } from '@Root/helpers'
import { CustomScrollbar, InputError } from '@Root/HOCs'

import { TextInput } from '../TextInput'
import triangle from '../../../assets/icons/triangle.png'
import { NO_DATA_AVAILABLE } from '@Root/configs'

export class Input extends Component {
	state = {
		popupIsShown: false,
		filter: '',
		error: null,
	}

	handleClickOutside = () => {
		this.setState({ popupIsShown: false })
	}

	showError = async error => {
		this.setState({ error })
		await delay(3000)
		this.setState({ error: null })
	}

	componentDidUpdate(prevProps) {
		const { error } = this.props
		if (error !== prevProps.error) {
			error && this.showError(error)
		}
	}

	render() {
		const { popupIsShown, filter, error } = this.state
		const {
			inputClassNames,
			inputStyle,
			options: passedOptions,
			value,
			inputPlaceholder,
			searchPlaceholder,
			changeHandler,
			isDisabled,
			maxVisibleOptionsQuantity,
		} = this.props

		const options = passedOptions.map(passedOption => (typeof passedOption === 'object' ? passedOption : { label: passedOption, value: passedOption }))
		const selectedOption = options.find(option => option.value === value)
		const label = selectedOption ? selectedOption.label : null
		const filteredOptions = options.filter(option => option.label.toLowerCase().includes(filter.toLowerCase()))
		const isNotDataAvailable = value === NO_DATA_AVAILABLE.label

		return (
			<div className={classes.DataList}>
				<InputError error={error}>
					<div
						className={`${classes.input} ${isDisabled ? classes.disabled : ''} 
                        ${inputClassNames.reduce((acc, className) => acc + ` ${classes[className]}`, '')}`}
						style={inputStyle}
						onClick={() =>
							all(
								() => !isDisabled && this.setState({ popupIsShown: !popupIsShown }),
								() => this.setState({ error: null })
							)
						}
					>
						{!isNotDataAvailable ? (
							<>{label ? <div className={classes.value}>{label}</div> : <div className={`${classes.value} ${classes.empty}`}>{inputPlaceholder}</div>}</>
						) : (
							<div className={classes.value}>{NO_DATA_AVAILABLE.value}</div>
						)}
						{!isDisabled && !isNotDataAvailable && (
							<>
								{value && (
									<div
										className={classes.xIcon}
										onClick={event =>
											all(
												() => event.stopPropagation(),
												() => changeHandler(null)
											)
										}
									>
										<img src={xIcon} alt='' />
									</div>
								)}
								<div className={classes.icon}>
									<img style={popupIsShown ? { transform: 'rotate(180deg)' } : null} src={triangle} alt='' />
								</div>
							</>
						)}
					</div>
				</InputError>
				{popupIsShown && (
					<div className={classes.popup}>
						<div className={classes.search}>
							<TextInput classNames={['transparent']} value={filter} changeHandler={filter => this.setState({ filter })} placeholder={searchPlaceholder} />
						</div>
						<div style={{ height: popupHeight(filteredOptions.length, maxVisibleOptionsQuantity, 38) }}>
							<CustomScrollbar verticalOnly>
								{filteredOptions.map((option, i) => (
									<div
										className={classes.option}
										onClick={() =>
											all(
												() => changeHandler(option.value),
												() => this.setState({ popupIsShown: false })
											)
										}
										key={i}
									>
										{option.label}
									</div>
								))}
							</CustomScrollbar>
						</div>
					</div>
				)}
			</div>
		)
	}
}

Input.propTypes = {
	inputClassNames: PropTypes.arrayOf(PropTypes.oneOf(['borderless', 'transparent'])),
	inputStyle: PropTypes.object,
	options: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.shape({
				label: PropTypes.string,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			}),
		])
	),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	changeHandler: PropTypes.func,
	inputPlaceholder: PropTypes.string,
	searchPlaceholder: PropTypes.string,
	isDisabled: PropTypes.bool,
	maxVisibleOptionsQuantity: PropTypes.number,
	error: PropTypes.string,
}

Input.defaultProps = {
	inputClassNames: [],
	inputStyle: {},
	options: [],
	value: null,
	changeHandler: () => {},
	inputPlaceholder: '',
	searchPlaceholder: 'Start typing',
	isDisabled: false,
	maxVisibleOptionsQuantity: 5,
	error: null,
}

export const DataList = onClickOutside(Input)

import React, { useEffect } from 'react'
import { ActionsDropdown, StudyRecordProgrammeForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import {
  attachmentsActions,
  engagementSelectors,
  feeSelectors,
  fundingAndMonitoringSelectors,
  fieldTooltipManagerSelectors,
  studentCourseSessionActions,
  studentCourseSessionSelectors,
  programmeSelectors,
  studentCourseSessionPostgradSelectors,
} from '@Root/store'
import { venueActivitySelectors } from '@Store/studies/studentCourseSession/venueActivity'
import { referencePeriodSelectors } from '@Store/studies/studentCourseSession/referencePeriod'
import { studyLocationSelectors } from '@Store/studies/studentCourseSession/studyLocation'
import { fundingBodySelectors } from '@Store/studies/studentCourseSession/fundingBody'
import { sessionStatusSelectors } from '@Store/studies/studentCourseSession/sessionStatus'
import { studentFinancialSupportSelectors } from '@Store/studies/studentCourseSession/studentFinancialSupport/index'
import { moduleInstancesSelectors } from '@Store/studies'
import { supervisorAllocationSelectors } from '@Store/studies'
import { useHistory, useParams } from 'react-router'
import { permissionsSelectors } from '@Store/permissions'
import { modalActions } from '@Root/store'

export const EditStudentCourseSession = ({ onOpenDescriptionModal, isDuplicate }) => {
  const { contactId, studyProgrammeId, contactTab } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions)['records-of-studies']
  const options = useSelector(studentCourseSessionSelectors.formattedOptions)
  const programmesList = useSelector(programmeSelectors.programmesListOptions)
  const errors = useSelector(studentCourseSessionSelectors.errorsFields)
  const postgrad = useSelector(studentCourseSessionPostgradSelectors.postgrad)
  const dynamicByStudentOptions = useSelector(studentCourseSessionSelectors.dynamicByStudentOptions)
  const newPostgradIsAllowed = useSelector(studentCourseSessionPostgradSelectors.canBeAttached)
  const fundingAndMonitoring = useSelector(fundingAndMonitoringSelectors.fundingAndMonitoring)
  const fee = useSelector(feeSelectors.fee)
  const programmeRecord = useSelector(studentCourseSessionSelectors.programmeRecord)
  const isSuccess = useSelector(studentCourseSessionSelectors.isSuccess)
  const isLoading = useSelector(studentCourseSessionSelectors.isLoading)
  const permissions = useSelector(studentCourseSessionSelectors.permissions)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const additionalPermissions = useSelector(permissionsSelectors.additionalPermissions)
  const canBeCreatVenueActivity = useSelector(venueActivitySelectors.canBeCreatedVenueActivity)
  const canBeCreatReferencePeriod = useSelector(referencePeriodSelectors.canBeCreatReferencePeriod)
  const canBeCreatStudyLocation = useSelector(studyLocationSelectors.canBeCreatedStudyLocation)
  const canBeCreatStudentFinancialSupport = useSelector(studentFinancialSupportSelectors.canBeCreatedStudentFinancialSupport)
  const canBeCreatSessionStatus = useSelector(sessionStatusSelectors.canBeCreatedSessionStatus)
  const canBeCreatFundingBody = useSelector(fundingBodySelectors.canBeCreatFundingBody)
  const canBeCreatModuleInstances = useSelector(moduleInstancesSelectors.canBeCreatedModuleInstances)
  const canBeCreatSupervisorAllocation = useSelector(supervisorAllocationSelectors.canBeCreatedSupervisorAllocation)

  useEffect(() => {
    if (isDuplicate) {
      dispatch(studentCourseSessionActions.set([]))
      dispatch(attachmentsActions.setAttachments([]))
    }
  }, [dispatch, isDuplicate])

  useEffect(() => {
    dispatch(studentCourseSessionActions.get({ studyProgrammeId, isCopy: isDuplicate }))
    return () => {
      dispatch(studentCourseSessionActions.clearData())
      dispatch(studentCourseSessionActions.setErrorsFields([]))
    }
  }, [dispatch, isDuplicate])

  const actions = [
    ...(!isDuplicate && (additionalPermissions.student_course_session?.duplicate || permissions?.form?.duplicate)
      ? [
          {
            name: 'Duplicate Student Course Session',
            path: `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/off_venue_activity/duplicate`,
          },
        ]
      : []),
    ...(newPostgradIsAllowed && !postgrad && !isDuplicate && contactTab !== 'post_grad' && dataCreationPermissions.student_course_session_sub
      ? [
          {
            name: 'Add Postgrad Details',
            path: `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/post_grad/new`,
          },
        ]
      : []),
    // ...(!fee && contactTab !== 'fees' && !isDuplicate && dataCreationPermissions.student_course_session_sub
    // 	? [
    // 			{
    // 				name: 'Add Fees Details',
    // 				path: `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/fees/new`,
    // 			},
    // 	  ]
    // 	: []),

    ...(!fundingAndMonitoring && !isDuplicate && dataCreationPermissions.student_course_session_sub
      ? [
          {
            name: 'Add Funding and Monitoring Details',
            path: `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/funding-and-monitoring/new`,
          },
        ]
      : []),
    ...(canBeCreatVenueActivity && !isDuplicate
      ? [
          {
            name: 'Add Off venue activity',
            path: `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/off_venue_activity/new`,
          },
        ]
      : []),
    ...(canBeCreatReferencePeriod && !isDuplicate
      ? [
          {
            name: 'Add Reference period student load',
            path: `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/reference_period_student_load/new`,
          },
        ]
      : []),
    ...(canBeCreatStudyLocation && !isDuplicate
      ? [
          {
            name: 'Add Study location',
            path: `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/study_location/new`,
          },
        ]
      : []),
    ...(canBeCreatStudentFinancialSupport && !isDuplicate
      ? [
          {
            name: 'Add Student financial support',
            path: `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/student_financial_support/new`,
          },
        ]
      : []),
    ...(canBeCreatSessionStatus && !isDuplicate
      ? [
          {
            name: 'Add Session status',
            path: `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/session_status/new`,
          },
        ]
      : []),
    ...(canBeCreatFundingBody && !isDuplicate
      ? [
          {
            name: 'Add Funding body',
            path: `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/funding_body/new`,
          },
        ]
      : []),
    ...(canBeCreatModuleInstances && !isDuplicate
      ? [
          {
            name: 'Add Module Instance',
            path: `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/module_instances/new`,
          },
        ]
      : []),
    ...(canBeCreatSupervisorAllocation && !isDuplicate
      ? [
          {
            name: 'Add Supervisor Allocation',
            path: `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/supervisor_allocation/new`,
          },
        ]
      : []),
  ]
  const onSave = data => {
    if (!isDuplicate) {
      dispatch(studentCourseSessionActions.edit({ data }))
      return
    }
    dispatch(
      studentCourseSessionActions.copy({
        data: {
          course_student_session: data,
          post_grad: postgrad,
          funding_and_monitoring: fundingAndMonitoring,
          fees: fee,
          notes: null,
          attachments: null,
        },
        history,
      })
    )
  }

  const onCancel = memoData => {
    if (isDuplicate) history.goBack()
    dispatch(studentCourseSessionActions.set(memoData))
    dispatch(studentCourseSessionActions.setErrorsFields([]))
  }

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: `This student course session will be removed?`,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        dispatch(studentCourseSessionActions.delete({ data: studyProgrammeId, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  return (
    <div>
      {!!actions.length && <ActionsDropdown actions={actions} style={{ position: 'absolute', right: 10, top: -60 }} />}
      <StudyRecordProgrammeForm
        descriptions={descriptions}
        options={{ ...options, programmes: programmesList || [], ...dynamicByStudentOptions }}
        onOpenDescriptionModal={onOpenDescriptionModal}
        permissions={permissions}
        initialErrors={errors}
        initialValue={programmeRecord}
        onSave={onSave}
        onCancel={onCancel}
        isSuccess={isSuccess}
        isLoading={isLoading}
        title={{ onEdit: isDuplicate ? 'Duplicate the Student Course Session' : 'Edit this Student Course Session' }}
        isNew={isDuplicate}
        isDuplicate={isDuplicate}
        onDelete={onDelete}
      />
    </div>
  )
}

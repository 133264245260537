import React from 'react'
import styles from './style.module.scss'
import DocumentTitle from 'react-document-title'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { authSelectors } from '@Root/store'

export const ErrorPage = () => {
  const history = useHistory()
  const error = useSelector(authSelectors.error)

  return (
    <DocumentTitle title='Page not found'>
      <div className={styles.page}>
        <div className={styles.content}>
          <div className={styles.title}> {error?.status ? error.status : '404'}</div>
          <div className={styles.titleBold}>Ooops!!</div>
          <div className={styles.text}>{error?.message ? error.message : "THAT PAGE DOESN'T EXIST OR IS UNAVAILABLE."}</div>
          <div className={styles.wrapper}>
            <button onClick={() => history.push('/')}>Go Back to Home</button>
          </div>
        </div>
      </div>
    </DocumentTitle>
  )
}

import * as types from './moodle.types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	moodle: null,
	error: null,
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: action.payload,
			}
		case types.SET_MOODLE:
			return {
				...state,
				moodle: action.payload,
			}
		case types.SET_ERROR:
			return {
				...state,
				error: action.payload,
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			}
		default:
			return state
	}
}

import React from 'react'
import styles from './style.module.scss'
import { TextInput } from '@Root/components'
import { CrossIcon } from '@Root/assets'

export const SearchField = ({ onChange, value }) => {
	return (
		<div className={styles.search}>
			<div className={styles.wrapper}>
				<TextInput changeHandler={onChange} value={value} placeholder={'Search'} />
				<img src={CrossIcon} className={styles.reset} onClick={() => onChange('')} alt={'cross'} />
			</div>
		</div>
	)
}

export const TOGGLE_LOADING = 'fieldTooltipManager/TOGGLE_LOADING'
export const SET_PERMISSIONS = 'fieldTooltipManager/SET_PERMISSIONS'
export const TOGGLE_SUCCESS = 'fieldTooltipManager/TOGGLE_SUCCESS'
export const GET_MESSAGES = 'fieldTooltipManager/GET_MESSAGES'
export const SET_MESSAGES = 'fieldTooltipManager/SET_MESSAGES'
export const EDIT_MESSAGES = 'fieldTooltipManager/EDIT_MESSAGES'
export const GET_CONTACT_DESCRIPTIONS = 'fieldTooltipManager/GET_CONTACT_DESCRIPTIONS'
export const SET_CONTACT_DESCRIPTIONS = 'fieldTooltipManager/SET_CONTACT_DESCRIPTIONS'
export const GET_DESCRIPTIONS = 'fieldTooltipManager/GET_DESCRIPTIONS'
export const SET_DESCRIPTIONS = 'fieldTooltipManager/SET_DESCRIPTIONS'
export const GET_HESA_DESCRIPTIONS = 'fieldTooltipManager/GET_HESA_DESCRIPTIONS'
export const GET_ROLLOVER_DESCRIPTION = 'fieldTooltipManager/GET_ROLLOVER_DESCRIPTION'
export const SET_ROLLOVER_DESCRIPTION = 'fieldTooltipManager/SET_ROLLOVER_DESCRIPTION'

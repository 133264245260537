import React, { useEffect, useState } from 'react'
import { NotesForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { admissionNoteActions, admissionNoteSelectors } from '@Store/admission'
import { Redirect } from 'react-router'

import moment from 'moment'
import { modalActions } from '@Store/modal'

const PARENT_URL = '/home/contacts/all-contacts'

export const ApplicationsNotesForm = ({ applicant_id }) => {
  const dispatch = useDispatch()
  const loadingConf = useSelector(admissionNoteSelectors.loadingConf)
  const isSuccess = useSelector(admissionNoteSelectors.isSuccess)
  const notes = useSelector(admissionNoteSelectors.notes)
  const notesLength = useSelector(admissionNoteSelectors.notesLength)
  const permissions = useSelector(admissionNoteSelectors.permissions)

  const [openIdHistoryOfChanges, setOpenIdHistoryOfChanges] = useState(null)
  const page = useSelector(admissionNoteSelectors.currentNotesPage)
  const isDragDisabled = useSelector(admissionNoteSelectors.isOpenHistory)
  const [pageHistory, setPageHistory] = useState(1)
  const history = useSelector(admissionNoteSelectors.history)
  const historyLength = useSelector(admissionNoteSelectors.historyLength)

  useEffect(() => {
    dispatch(admissionNoteActions.get({ applicant_id }))

    return () => {
      dispatch(admissionNoteActions.set([]))
    }
  }, [dispatch, applicant_id])

  useEffect(() => {
    if (history.length > 0) {
      dispatch(
        modalActions.showModal('HistoryModal', {
          history,
          historyLength,
          moment,
          historyPaginate,
          clearHistory,
        })
      )
    }
  }, [history])

  const onCreate = payload => {
    dispatch(admissionNoteActions.create({ ...payload, applicant_id }))
  }

  const OnSave = (id, payload) => {
    dispatch(admissionNoteActions.edit({ id, data: payload }))
  }

  const onDelete = (id, pageReply, callBack) => {
    dispatch(admissionNoteActions.delete({ id, pageReply, callBack }))
  }

  const onReply = payload => {
    dispatch(admissionNoteActions.createReply({ ...payload, applicant_id }))
  }

  const onSort = newData => {
    dispatch(admissionNoteActions.sortNode({ applicant_id, data: newData }))
  }

  const onOpenHistoryOfChanges = id => {
    dispatch(admissionNoteActions.getHistory({ id }))
    setOpenIdHistoryOfChanges(id)
  }

  const handelPaginationNoteAction = () => {
    dispatch(admissionNoteActions.paginateNote(page + 1))
  }

  const historyPaginate = () => {
    setPageHistory(pageHistory + 1)
    dispatch(admissionNoteActions.paginateHistory(pageHistory + 1))
  }

  const clearHistory = () => {
    dispatch(admissionNoteActions.clearHistory())
  }

  const paginateReply = (pageReply, parentId, resolve, reject) => {
    dispatch(admissionNoteActions.paginateReply({ page: pageReply + 1, parentId, resolve, reject }))
  }

  //if (!contactPermissions.tabs?.note) return <Redirect to={PARENT_URL} />
  return (
    <NotesForm
      onEdit={OnSave}
      onCreate={onCreate}
      loadingConf={loadingConf}
      isSuccess={isSuccess}
      initialValue={notes}
      onDelete={onDelete}
      canManipulate
      canCreate={permissions?.create}
      onReply={onReply}
      notesLength={notesLength}
      onSort={onSort}
      handelPaginationNoteAction={handelPaginationNoteAction}
      onOpenHistoryOfChanges={onOpenHistoryOfChanges}
      openIdHistoryOfChanges={openIdHistoryOfChanges}
      page={page}
      isDragDisabled={isDragDisabled}
      paginateReply={paginateReply}
    />
  )
}

import * as types from './qualification.types'

const iniitailQualificationValue = {
  qualification_title: null,
  qualification_category_id: null,
  qualification_id: null,
  awarding_body_roles: [
    {
      awarding_body_identifier: null,
    },
  ],
  qualification_subjects: [
    {
      qual_subject_id: null,
      qualification_proportion: null,
      itt_specialism: null,
    },
  ],
}
const initialState = {
  isLoading: false,
  isSuccess: false,
  qualification: iniitailQualificationValue,
  options: {
    static: {},
    dynamic: {},
  },
  permissions: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      }
    case types.SET_QUALIFICATION:
      return {
        ...state,
        qualification: action.payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.name]: action.payload.data,
        },
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      }
    case types.CLEAR_DATA:
      return {
        ...state,
        qualification: iniitailQualificationValue,
      }
    default:
      return state
  }
}

import React, { useEffect } from 'react'
import styles from './style.module.scss'
import DocumentTitle from 'react-document-title'
import { useDispatch, useSelector } from 'react-redux'
import { PasswordChangingForm } from '@Root/components'
import { authActions, authSelectors } from '@Store/auth'
import { RedirectAuthUser } from '@Root/processes'
import { useHistory, useLocation } from 'react-router'

export const PasswordChangingPage = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()
	const error = useSelector(authSelectors.error)
	const recoveryToken = useSelector(authSelectors.recoveryToken)

	useEffect(() => {
		const token = location.hash.slice(1)
		dispatch(authActions.checkRecoveryToken(token, history))
	}, [dispatch, history, location.hash])

	const sendNewPassword = password => {
		dispatch(authActions.sendNewPassword(password, history))
	}

	return (
		<DocumentTitle title='Password changing'>
			<RedirectAuthUser>
				<div className={styles.form}>{recoveryToken && <PasswordChangingForm error={error} history={history} newPasswordHandler={sendNewPassword} />}</div>
			</RedirectAuthUser>
		</DocumentTitle>
	)
}

import React, { useEffect } from 'react'
import { engagementActions, modalActions, engagementSelectors, fieldTooltipManagerSelectors } from '@Root/store'
import { ActionsDropdown, EngagementDetailsForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { DetailInformation } from './DetailInformation'
import { eventService } from '@Helpers/events'
import { AddToStudentCourseSession } from '@Root/processes'
import { permissionsSelectors } from '@Store/permissions'

export const EditEngagement = ({ onOpenDescriptionModal }) => {
  const engagement = useSelector(engagementSelectors.engagement)
  const isLoading = useSelector(engagementSelectors.isLoading)
  const errors = useSelector(engagementSelectors.errors)
  const isSuccess = useSelector(engagementSelectors.isSuccess)
  const options = useSelector(engagementSelectors.options)
  const permissions = useSelector(engagementSelectors.permissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagements
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: engagementId, tab: otherTabId, contactId } = useParams()

  useEffect(() => {
    if (engagementId && engagementId !== 'new' && !otherTabId) {
      dispatch(engagementActions.get({ data: engagementId, history }))
      return () => {
        dispatch(engagementActions.set({}))
      }
    }
  }, [dispatch, engagementId, history, otherTabId])

  const {
    can_be_created_student_registration,
    can_be_created_leaver,
    can_be_created_student_initiatives,
    can_be_created_student_accreditation_aim,
    can_be_created_qualification_awarded,
    can_be_created_collaborative_provision,
    can_be_created_student_course_session,
    contact_id,
  } = engagement

  const onAddStudentToCourseSession = () => {
    eventService.trigger('addStudentToCourseSession')
  }

  const actions = () => {
    return [
      ...(can_be_created_collaborative_provision && dataCreationPermissions.engagements_sub
        ? [
            {
              name: 'Add Collaborative provision',
              path: `/home/studies/engagements/${contactId}/${engagementId}/collaborative-provision/new`,
            },
          ]
        : []),
      ...(can_be_created_student_registration && dataCreationPermissions.engagements_sub
        ? [
            {
              name: 'Add Entry Profile',
              path: `/home/studies/engagements/${contactId}/${engagementId}/entry-profile/new`,
            },
          ]
        : []),
      ...(can_be_created_leaver && dataCreationPermissions.engagements_sub
        ? [
            {
              name: 'Add Leaver',
              path: `/home/studies/engagements/${contactId}/${engagementId}/leaver/new`,
            },
          ]
        : []),
      ...(can_be_created_qualification_awarded && dataCreationPermissions.engagements_sub
        ? [
            {
              name: 'Add Qualification awarded',
              path: `/home/studies/engagements/${contactId}/${engagementId}/qualification-award/new`,
            },
          ]
        : []),
      ...(can_be_created_student_accreditation_aim && dataCreationPermissions.engagements_sub
        ? [
            {
              name: 'Add Student accreditation aim',
              path: `/home/studies/engagements/${contactId}/${engagementId}/student-accreditation-aim/new`,
            },
          ]
        : []),
      ...(can_be_created_student_course_session && dataCreationPermissions.engagements_sub
        ? [
            {
              name: 'Add Student Course session',
              handler: onAddStudentToCourseSession,
            },
          ]
        : []),
      ...(can_be_created_student_initiatives && dataCreationPermissions.engagements_sub
        ? [
            {
              name: 'Add Student initiatives',
              path: `/home/studies/engagements/${contactId}/${engagementId}/student-initiatives/new`,
            },
          ]
        : []),
    ]
  }

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This engagement will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(engagementActions.delete({ data: engagementId, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = data => {
    dispatch(engagementActions.edit(data))
  }

  const onChooseAction = actionName => {
    actions()
      .find(action => action.name === actionName)
      .handler()
  }

  const onCancel = memoData => {
    dispatch(engagementActions.set(memoData))
    dispatch(engagementActions.setErrors({}))
  }

  return (
    <AddToStudentCourseSession contactId={contact_id}>
      <div>
        {!!actions().length && <ActionsDropdown actions={actions()} clickHandler={onChooseAction} style={{ position: 'absolute', right: 10, top: -60 }} />}
        <EngagementDetailsForm
          onClickDeleteButton={onDelete}
          onSave={onSave}
          onCancel={onCancel}
          initialValue={engagement}
          errorsFields={errors}
          isSuccess={isSuccess}
          options={options}
          isLoading={isLoading}
          permissions={permissions}
          descriptions={descriptions}
          onOpenDescriptionModal={onOpenDescriptionModal}
        />
        {permissions?.tabs && <DetailInformation contactId={contactId} engagementId={engagementId} permissions={permissions.tabs} />}
      </div>
    </AddToStudentCourseSession>
  )
}

import React, { useEffect } from 'react'
import { StudentFinancialSupportForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { studentFinancialSupportSelectors, studentFinancialSupportActions } from '@Store/studies'

export const EditStudentFinancialSupport = ({ onOpenDescriptionModal, programmeRecord }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { studyProgrammeId, contactId, studentFinancialSupportId } = useParams()
	const studentFinancialSupport = useSelector(studentFinancialSupportSelectors.studentFinancialSupport)
	const isSuccess = useSelector(studentFinancialSupportSelectors.isSuccess)
	const isLoading = useSelector(studentFinancialSupportSelectors.isLoading)
	const options = useSelector(studentFinancialSupportSelectors.options)
	const permissions = useSelector(studentFinancialSupportSelectors.permissions)

	useEffect(() => {
		if (!programmeRecord.student_course_session_id) return
		dispatch(
			studentFinancialSupportActions.get({
				data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, studentFinancialSupportId },
				history,
			})
		)
		return () => {
			dispatch(studentFinancialSupportActions.clearData())
		}
	}, [programmeRecord])

	const onDelete = () => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('ConfirmationModal', {
					text: 'This Student Financial Support will be removed?',
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
				})
			)
		})
			.then(async () => {
				dispatch(studentFinancialSupportActions.delete({ data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, studentFinancialSupportId}, history }))
			})
			.finally(() => {
				dispatch(modalActions.hideModal())
			})
	}

	const onSave = data => {
		dispatch(studentFinancialSupportActions.edit({ data, studentCourseSessionId: programmeRecord.student_course_session_id, studentFinancialSupportId }))
	}

	return (
		<StudentFinancialSupportForm
			onOpenDescriptionModal={onOpenDescriptionModal}
			onSave={onSave}
			onDelete={onDelete}
			canEdit={permissions.form?.edit}
			canDelete={permissions.form?.delete}
			initialValue={studentFinancialSupport}
			isLoading={isLoading}
			isSuccess={isSuccess}
			options={options}
		/>
	)
}

import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { Redirect, useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { getDataOptionsFromLocalStorage } from '@Root/helpers'
import { CreateEntryQualificationAward } from './Create'
import { EditEntryQualificationAward } from './Edit'
import { useSelector } from 'react-redux'
import { entryProfileSelectors } from '@Store/studies'
import { useDispatch } from 'react-redux'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager'
import { entryQualificationAwardedActions } from '@Store/studies'

export const EntryQualificationAward = ({ onOpenDescriptionModal }) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const pathname = location.pathname
  const entryProfile = useSelector(entryProfileSelectors.entryProfile)
  const entryProfilePermissions = useSelector(entryProfileSelectors.permissions)
  const canBeCreated = entryProfile.can_be_created_entry_qualification_award
  const isNew = pathname.includes('new')
  const { id: engagementId, contactId, entryProfileId } = useParams()
  const entryQualificationAwardId = pathname.split('/')[9]

  const PARENT_URL = `/home/studies/engagements/${contactId}/${engagementId}/entry-profile/${entryProfileId}/entry-qualification-award`

  useEffect(() => {
    getDataOptionsFromLocalStorage(['entry_qualification_award'])
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'entry_qualification_award' }))
  }, [])

  const goBack = () => {
    dispatch(entryQualificationAwardedActions.clearData())
    history.push(PARENT_URL)
  }

  if (!entryQualificationAwardId) history.push(PARENT_URL)

  if (!canBeCreated && entryQualificationAwardId === 'new' && entryProfile.id) return <Redirect to={PARENT_URL} />

  if (entryProfilePermissions.tabs && !entryProfilePermissions.tabs.entry_qualification_award) return <Redirect to={PARENT_URL} />
  return (
    <SectionWrapper hasBackButton backButtonText={'Entry Profile'} clickBackButtonHandler={goBack} activeName>
      <div className={styles.sections}>
        {isNew ? (
          <CreateEntryQualificationAward goBack={goBack} parentURL={PARENT_URL} onOpenDescriptionModal={onOpenDescriptionModal} />
        ) : (
          <EditEntryQualificationAward onOpenDescriptionModal={onOpenDescriptionModal} />
        )}
      </div>
    </SectionWrapper>
  )
}

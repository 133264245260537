import React from 'react'
import styles from './style.module.scss'
import { createDataConfig } from '@Root/configs'
import { VerticalTabs, TableBlock } from '@Root/components'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import { API } from '@Root/API'

export const DetailInformation = ({ engagementId, contactId, entryProfileId, entryQualificationAwardId, permissions }) => {
	const defaultURL = `/home/studies/engagements/${contactId}/${engagementId}/entry-profile/${entryProfileId}/entry-qualification-award/${entryQualificationAwardId}`
	const history = useHistory()

	const EntryQualificationSubject = () => {
		const columns = () => [
			{
				name: 'Subject identifier',
				field: 'subject_identifier',
				searchInputType: 'textInput',
				cellType: 'link',
				cellLinkField: 'subject_identifier_link',
			},
		]
		return {
			...createDataConfig('Entry Qualification Subject', [], columns(), 'getModules', 'entryQualificationSubject', false, false, false, false, false, false),
			fetchDataHandler: params =>
				API.studies.engagement.entryProfile.entryQualificationAwarded.entryQualificationSubject.getAll(entryQualificationAwardId, params),
			clickLinkHandlers: {
				'Subject identifier': row => history.push(`${defaultURL}/entry-qualification-subject/${row.id}`),
			},
		}
	}

	const tables = [
		...(permissions.entry_qualification_subject
			? [{ name: 'Entry qualification subject', label: 'entry-qualification-subject', tableInformation: EntryQualificationSubject() }]
			: []),
	]
	return (
		<div className={styles.wrapper}>
			<VerticalTabs tabs={tables} isLink defaultURL={defaultURL} />
			<Switch>
				{tables.map(({ name, tableInformation, label }) => (
					<Route exact={true} key={name} path={`${defaultURL}/${label}`} render={() => <TableBlock title={name} config={tableInformation} />} />
				))}
				<Redirect to={`${defaultURL}/${tables[0]?.label || ''}`} />
			</Switch>
		</div>
	)
}

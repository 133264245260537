import React, { useState, useEffect } from 'react'
import './style.scss'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import DocumentTitle from 'react-document-title'
import { LoginForm, SecurityForm, Preloader, ActiveForm2fa } from '@Root/components'
import { authActions, authSelectors } from '@Root/store'
import { RedirectAuthUser } from '@Root/processes'

export const LoginPage = () => {
  const [isKeptLoggedIn, setIsKeptLoggedIn] = useState(false)
  const isLoading = useSelector(authSelectors.isLoading)
  const error = useSelector(authSelectors.error)
  const isTwoFactoreAuth = useSelector(authSelectors.isGoogle2fa_active)
  const isForce_activated = useSelector(authSelectors.isForce_activated)
  const qr_code = useSelector(authSelectors.qr_code)
  const google2fa_secret = useSelector(authSelectors.google2fa_secret)
  const history = useHistory()
  const dispatch = useDispatch()
  const [login, setLogin] = useState(null)
  const [password, setPassword] = useState(null)

  useEffect(() => {
    localStorage.isKeptLoggedIn && updateKeepLoggedInCheckbox()
  }, [])

  const keepLoggedIn = payload => {
    setIsKeptLoggedIn(payload)
    localStorage.isKeptLoggedIn = JSON.stringify(payload)
  }

  const updateKeepLoggedInCheckbox = () => {
    setIsKeptLoggedIn(JSON.parse(localStorage.isKeptLoggedIn))
  }

  const submitForm = (login, password) => {
    setLogin(login)
    setPassword(password)
    dispatch(authActions.logIn(login, password, history))
  }
  const handleSubmitSecurityForm = code => dispatch(authActions.logIn(login, password, history, code))

  const handleCancel = () => {
    setLogin(null)
    setPassword(null)
    dispatch(authActions.setTwoFactoreAuth({ google2fa_active: false, is_force_activated: false, qr_code: null, google2fa_secret: null }))
  }

  return (
    <DocumentTitle title='Login'>
      <RedirectAuthUser>
        <div className='LoginPage'>
          {isTwoFactoreAuth || isForce_activated ? (
            isTwoFactoreAuth ? (
              <SecurityForm handleSubmit={handleSubmitSecurityForm} error={error} handleCancel={handleCancel} />
            ) : (
              <div className={'modal'}>
                <ActiveForm2fa isLogin parseQr_code={qr_code} loginGoogle2fa_secret={google2fa_secret} handleSubmitSecurityForm={handleSubmitSecurityForm} />
              </div>
            )
          ) : (
            <LoginForm
              isKeptLoggedIn={isKeptLoggedIn}
              error={error}
              keepLoggedInHandler={keepLoggedIn}
              passwordRecoveryHandler={() => history.push('/password-recovery')}
              logInHandler={submitForm}
            />
          )}
        </div>
        {isLoading && (
          <div className='spinnerWrapper fixedSpinner'>
            <Preloader />
          </div>
        )}
      </RedirectAuthUser>
    </DocumentTitle>
  )
}

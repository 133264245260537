import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { FormWrapper } from '@Root/HOCs'
import { Table, SectionTitle } from '@Root/components'
import { API } from '@Root/API'
import { Redirect } from 'react-router'
import { useSelector } from 'react-redux'
import { contactSelectors } from '@Root/store'

const PARENT_URL =  '/home/contacts/all-contacts'
export const ContactsApplicationsForm = ({ contactId }) => {
	const fetchTableDataHandler = params => API.contact.person.student.application.get(contactId, params)
	const contactPermissions = useSelector(contactSelectors.permissions)
	const table = {
		name: 'contactsStudentApplications',
		columns: [
			{
				name: 'ID',
				field: 'id',
				cellType: 'link',
				searchInputType: null,
				cellLinkField: 'id_link',
			},
			{
				name: 'Date',
				field: 'date',
				cellType: 'text',
				searchInputType: 'datePicker',
			},
			{
				name: 'Academic Year',
				field: 'academic_years',
				cellType: 'text',
				searchInputType: 'select',
				options: ['2019/2020'],
			},
			{
				name: 'Ordinand',
				field: 'ordinand',
				cellType: 'text',
				searchInputType: 'select',
				options: ['Y', 'N'],
			},
			{
				name: 'FT / PT',
				field: 'ft_pt',
				cellType: 'text',
				searchInputType: 'select',
				options: ['FT', 'PT'],
			},
			{
				name: 'Res / Con',
				field: 'res_con',
				cellType: 'text',
				searchInputType: 'select',
				options: ['Res', 'Con'],
			},
			{
				name: 'Programme',
				field: 'programme',
				searchInputType: 'textInput',
				cellType: 'text',
			},
		],
		fetchDataHandler: params => fetchTableDataHandler(params),
		fetchColumnOptions: () => API.getColumnOptions('contactsStudentApplications'),
		fetchSaveColumnOptions: options => API.saveColumnOptions('contactsStudentApplications', options),
		clickLinkHandlers: {},
	}

	if(!contactPermissions.tabs?.student_application) return <Redirect to={PARENT_URL} />
	return (
		<FormWrapper>
			<div className={classes.titleWrapper}>
				<SectionTitle title='Applications' />
			</div>
			<Table
				style={{ marginTop: 20 }}
				datePeriodDropdownStyle={{ top: -70 }}
				name={table.name}
				columns={table.columns}
				fetchDataHandler={table.fetchDataHandler}
				fetchColumnOptions={table.fetchColumnOptions}
				fetchSaveColumnOptions={table.fetchSaveColumnOptions}
				clickLinkHandlers={table.clickLinkHandlers}
			/>
		</FormWrapper>
	)
}

ContactsApplicationsForm.propTypes = {
	history: PropTypes.object.isRequired,
	fetchTableDataHandler: PropTypes.func,
}

ContactsApplicationsForm.defaultProps = {
	fetchTableDataHandler: () => {},
}

import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './entryQualificationAwarded.types'
import { actions } from './entryQualificationAwarded.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { entryQualificationSubjectRootSaga } from '@Store/studies'
import { parseErrorToArray } from '@Helpers/parseErrorToArray'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { contactId, engagementId, entryProfileId, entryQualificationAwardId } = data
  try {
    const response = yield call(API.studies.engagement.entryProfile.entryQualificationAwarded.get, entryQualificationAwardId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/entry-profile/${entryProfileId}`)
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  yield put(actions.setErrors([]))
  const { data, history, entryProfileId, contactId, engagementId } = payload
  try {
    const response = yield call(API.studies.engagement.entryProfile.entryQualificationAwarded.create, entryProfileId, data)
    yield put(actions.set(response.data.data))
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/entry-profile/${entryProfileId}/entry-qualification-award/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The entry qualification award has been created successfully!' }))
  } catch (error) {
    yield put(actions.set(data))
    const errors = parseErrorToArray(error.response.data.errors)
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, entryProfileId, entryQualificationAwardId } = payload
  try {
    const response = yield call(API.studies.engagement.entryProfile.entryQualificationAwarded.edit, entryProfileId, entryQualificationAwardId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The entry qualification award has been saved successfully!' }))
  } catch (error) {
    yield put(actions.set(data))
    const errors = parseErrorToArray(error.response.data.errors)
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { entryProfileId, entryQualificationAwardId, contactId, engagementId } = data
  try {
    yield call(API.studies.engagement.entryProfile.entryQualificationAwarded.remove, entryProfileId, entryQualificationAwardId)
    yield put(actions.clearData())
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/entry-profile/${entryProfileId}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The entry qualification award has been deleted successfully!' }))
  } catch (error) {
    const errors = parseErrorToArray(error.response.data.errors)
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const {
      data: { qualification_results, qualification_types },
    } = yield call(API.studies.engagement.entryProfile.entryQualificationAwarded.options.getStatic)

    const generateObject = arr =>
      arr.map(obj => {
        if (obj.label === null) {
          return { value: obj.id, label: `${obj.code}` }
        } else {
          return { value: obj.id, label: `${obj.code} - ${obj.label}` }
        }
      })
    const formattedData = {
      qualification_results: generateObject(qualification_results),
      qualification_types: generateObject(qualification_types),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('entry_qualification_award', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_ENTRY_QUALIFICATION_AWARDED, get),
    takeEvery(types.CREATE_ENTRY_QUALIFICATION_AWARDED, create),
    takeEvery(types.EDIT_ENTRY_QUALIFICATION_AWARDED, edit),
    takeEvery(types.DELETE_ENTRY_QUALIFICATION_AWARDED, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
    entryQualificationSubjectRootSaga(),
  ])
}

import * as types from './contact.types'

const initialPermissions = {
	fields: {
		photo: { view: true, edit: true },
		first_name: { view: true, edit: true },
		surname: { view: true, edit: true },
		middle_names: { view: true, edit: true },
		previous_name: { view: true, edit: true },
		previous_surname: { view: true, edit: true },
		known_first_name: { view: true, edit: true },
		known_surname: { view: true, edit: true },
		gender_id: { view: true, edit: true },
		title: { view: true, edit: true },
		spouse_name: { view: true, edit: true },
		spouse_surname: { view: true, edit: true },
		is_resident_in_carter: { view: true, edit: true },
		relationship_ids: { view: true, edit: true },
		student_id: { view: true, edit: false },
		trashed: { view: true, edit: true },
		date_of_birthday: { view: true, edit: true },
		age: { view: true, edit: true },
		tel_primary: { view: true, edit: true },
		tel_secondary: { view: true, edit: true },
		tel_mobile: { view: true, edit: true },
		email: { view: true, edit: true },
		second_email: { view: true, edit: true },
		is_not_emailed: { view: true, edit: true },
		emergency_contact_details: { view: true, edit: true },
		organisation_ids: { view: true, edit: true },
		dietary_requirements: { view: true, edit: true },
		moodle_id: { view: true, edit: true },
		comment: { view: true, edit: true },
		address: {
			address_line_1: { view: true, edit: true },
			address_line_2: { view: true, edit: true },
			address_line_3: { view: true, edit: true },
			address_type: { view: true, edit: true },
			city: { view: true, edit: true },
			country_id: { view: true, edit: true },
			county: { view: true, edit: true },
			current_address: { view: true, edit: true },
			other_address_type: { view: true, edit: true },
			postcode: { view: true, edit: true },
		},
		year_joined_federation: { view: true, edit: true },
		year_left_federations: { view: true, edit: true },
		pruning_review_date: { view: true, edit: true },
		financial_arrangements: { view: true, edit: true },
		university_card_barcode: { view: true, edit: true },
		publicly_fundable: { view: true, edit: true },
		university_card_date: { view: true, edit: true },
		include_in_hesa: { view: true, edit: true },
		dbs: {
			valid_dbs: { view: true, edit: true },
			issued_by: { view: true, edit: true },
			date_expires: { view: true, edit: true },
		},
		contact_name: { view: true, edit: true },
		contacts_ids: { view: true, edit: true },
		fax: { view: true, edit: true },
		type: { view: true, edit: true },
		relationship_id: { view: true, edit: true },
		name: { view: true, edit: true },
		institution_ids: { view: true, edit: true },
		title_id: { view: true, edit: true },
	},
	form: { delete: true, edit: true },
}
const initialState = {
	isLoading: false,
	isSaving: false,
	isSuccess: false,
	contact: null,
	options: {
		static: {},
		dynamic: {},
	},
	information: {
		type: '',
		relationships: {},
		has_course_session: false,
	},
	error: {
		errorName: '',
		errorMessage: '',
	},
	moodleLinks: null,
	person: {
		photo: '',
		surname: '',
		name: '',
		middle_names: '',
		email: '',
		relationship_ids: null,
		date_of_birthday: '',
		gender_id: null,
		title_id: null,
		is_resident_in_carter: null,
		is_not_emailed: false,
		tel_primary: '',
		tel_secondary: '',
		tel_mobile: '',
		spouse_name: '',
		spouse_surname: '',
		year_joined_federation: '',
		year_left_federation: '',
		updated_at: '',
		comment: '',
		organisation_ids: null,
		moodle_id: null,
		trashed: false,
		pruning_review_date: '',
		financial_arrangements: '',
		university_card_barcode: '',
		publicly_fundable: 0,
		age: null,
		emergency_contact_details: null,
		university_card_date: null,
		previous_name: null,
		previous_surname: null,
		know_first_name: null,
		know_surname: null,
		dbsses: null,
		addresses: null,
		include_in_hesa: false,
		institution_ids: [],
	},
	organisation: {
		photo: null,
		name: null,
		contactName: null,
		email: null,
		second_email: null,
		relationshipId: null,
		type: null,
		telPrimary: null,
		telSecondary: null,
		telMobile: null,
		fax: null,
		comment: null,
		contacts_ids: null,
		trashed: null,
		addresses: null,
		openedItemId: null,
		relationship_id: null,
		institution_ids: [],
	},
	permissions: initialPermissions,
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.TOGGLE_SAVING:
			return {
				...state,
				isSaving: payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: payload,
			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: { ...state.options, [payload.name]: payload.data },
			}
		case types.SET_CONTACT_TYPE:
			return {
				...state,
				contactType: payload,
			}
		case types.SET_ERROR:
			return {
				...state,
				error: payload,
			}
		case types.SET_MOODLE_LINKS:
			return {
				...state,
				moodleLinks: payload,
			}
		case types.SET_PERSON:
			return {
				...state,
				person: payload,
			}
		case types.SET_ORGANISATION:
			return {
				...state,
				organisation: payload,
			}
		case types.SET_INFORMATION:
			return {
				...state,
				information: payload,
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: payload,
			}
		case types.CLEAR_PERMISSIONS:
			return {
				...state,
				permissions: initialPermissions,
			}
		default:
			return state
	}
}

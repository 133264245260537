import React, { Component } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { TextInput, Select } from '@Root/components'
import { equalities } from '@Root/configs'

export class RuleRow extends Component {
  state = {
    error: null,
  }

  handleButtonClick = e => {
    e.preventDefault()
    const { isNew, clickAddRowHandler = () => {}, clickDeleteRowHandler = () => {} } = this.props
    if (isNew) {
      if (!this.rowIsValid()) return
      clickAddRowHandler()
    } else {
      clickDeleteRowHandler()
    }
  }

  rowIsValid = () => {
    const { field, equality, filter } = this.props.row
    if (!field) {
      this.showError('field')
      return false
    } else if (!equality) {
      this.showError('equality')
      return false
    } else if (!filter && equality !== 'is empty' && equality !== 'is not empty') {
      this.showError('filter')
      return false
    } else {
      return true
    }
  }

  showError = input => {
    this.setState({ error: input }, () => {
      this.setState({ error: null })
    })
  }

  render() {
    const { isNew, columns, row, changeRuleValuesHandler = () => {}, index = null, options } = this.props
    const { error } = this.state
    const { handleButtonClick } = this
    const optionsValue = columns.filter(el => {
      if (el.value === row.field) {
        return el
      }
    })
    return (
      <form onSubmit={handleButtonClick}>
        <div className={classes.RuleRow}>
          <Select
            inputClassNames={['borderless']}
            options={columns}
            value={row.field}
            changeHandler={value => changeRuleValuesHandler(index, { field: value })}
            isDisabled={false}
            inputStyle={{ width: '130px' }}
            error={error === 'field' ? 'Required' : null}
          />
          <Select
            inputClassNames={['borderless']}
            options={Object.keys(equalities)}
            value={row.equality}
            changeHandler={value => changeRuleValuesHandler(index, { equality: value })}
            isDisabled={false}
            inputStyle={{ width: '130px' }}
            optionsStyle={{ width: '90px' }}
            error={error === 'equality' ? 'Required' : null}
          />

          {options[row.field] || optionsValue[0]?.options ? (
            <Select
              inputClassNames={['borderless']}
              options={options[row.field] ? options[row.field] : optionsValue[0].options}
              value={row.filter}
              changeHandler={value => changeRuleValuesHandler(index, { filter: value })}
              isDisabled={false}
              inputStyle={{ width: '130px' }}
              optionsStyle={{ width: '90px' }}
              error={error === 'filter' ? 'Required' : null}
            />
          ) : (
            <TextInput
              classNames={['borderless']}
              style={{ width: 130 }}
              value={row.filter}
              changeHandler={value => changeRuleValuesHandler(index, { filter: value })}
              isDisabled={false}
              error={error === 'filter' ? 'Required' : null}
              pattern={row.rule_suggest || null}
            />
          )}

          <button type='submit'>{isNew ? 'Add' : 'Delete'}</button>
        </div>
      </form>
    )
  }
}

RuleRow.propTypes = {
  isNew: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  row: PropTypes.shape({
    field: PropTypes.string,
    equality: PropTypes.string,
    filter: PropTypes.string,
    rule_suggest: PropTypes.string,
  }).isRequired,
  index: PropTypes.number,
  clickAddRowHandler: PropTypes.func,
  clickDeleteRowHandler: PropTypes.func,

  changeRuleValuesHandler: PropTypes.func,
}

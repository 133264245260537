import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { getFromLocalStorage, getFromSessionStorage } from '@Root/helpers'

export const RedirectAuthUser = ({ children }) => {
	const [isAuth, setAuth] = useState(false)

	useEffect(() => {
		const token = getFromLocalStorage('token', true) || getFromSessionStorage('token', true)
		setAuth(Boolean(token))
	}, [])

	if (isAuth) return <Redirect to={'/home'} />
	return <>{children}</>
}

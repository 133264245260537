export const TOGGLE_LOADING = 'accreditationAim/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'accreditationAim/TOGGLE_SUCCESS'
export const GET_ACCREDITATION_AIM = 'accreditationAim/GET_ACCREDITATION_AIM'
export const SET_ACCREDITATION_AIM = 'accreditationAim/SET_ACCREDITATION_AIM'
export const CREATE_ACCREDITATION_AIM = 'accreditationAim/CREATE_ACCREDITATION_AIM'
export const DELETE_ACCREDITATION_AIM = 'accreditationAim/DELETE_ACCREDITATION_AIM'
export const EDIT_ACCREDITATION_AIM = 'accreditationAim/EDIT_ACCREDITATION_AIM'
export const GET_OPTIONS = 'accreditationAim/GET_OPTIONS'
export const SET_OPTIONS = 'accreditationAim/SET_OPTIONS'
export const CLEAR_DATA = 'accreditationAim/CLEAR_DATA'
export const SET_PERMISSIONS = 'accreditationAim/SET_PERMISSIONS'

export const TOGGLE_LOADING = 'admin/role/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'admin/role/TOGGLE_SUCCESS'
export const GET_ROLE = 'admin/role/GET_ROLE'
export const GET_ROLES = 'admin/role/GET_ROLES'
export const SET_ROLES = 'admin/role/SET_ROLES'
export const SET_ROLE = 'admin/role/SET_ROLE'
export const EDIT_ROLE = 'admin/role/EDIT_ROLE'
export const CREATE_ROLE = 'admin/role/CREATE_ROLE'
export const DELETE_ROLE = 'admin/role/DELETE_ROLE'
export const GET_STATIC_OPTIONS = 'admin/role/GET_STATIC_OPTIONS'
export const SET_OPTIONS = 'admin/role/SET_OPTIONS'
export const GET_INITIAL = 'admin/role/GET_INITIAL'
export const CHANGE_EXCEPTIONS = 'admin/role/CHANGE_EXCEPTIONS'
export const SET_SINGLE_TAB = 'admin/role/SET_SINGLE_TAB'
export const SET_PERMISSIONS = 'admin/role/SET_PERMISSIONS'
export const SET_INITIAL_ROLE = 'admin/role/SET_INITIAL_ROLE'

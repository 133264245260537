import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import {
  ConfirmationModal,
  InputModal,
  UploadingModal,
  ImportModal,
  NotificationModal,
  ProcessModal,
  ConfigureExportModal,
  HistoryList,
  ModalIdle,
  ModalProcessedRollover,
  Rollover,
} from '@Root/components'
import { connect } from 'react-redux'

const Wrapper = ({ component, props }) => {
  const components = {
    ConfirmationModal: <ConfirmationModal {...props} />,
    InputModal: <InputModal {...props} />,
    UploadingModal: <UploadingModal {...props} />,
    ImportModal: <ImportModal {...props} />,
    NotificationModal: <NotificationModal {...props} />,
    ProcessModal: <ProcessModal {...props} />,
    ConfigureExportModal: <ConfigureExportModal {...props} />,
    HistoryModal: <HistoryList {...props} />,
    ModalIdle: <ModalIdle {...props} />,
    ModalProcessedRollover: <ModalProcessedRollover {...props} />,
    Rollover: <Rollover {...props} />,
  }
  return component && <div className={classes.wrapper}>{component in components ? components[component] : null}</div>
}

Wrapper.propTypes = {
  component: PropTypes.oneOf([
    'ConfirmationModal',
    'InputModal',
    'UploadingModal',
    'ImportModal',
    'NotificationModal',
    'ConfigureExportModal',
    'HistoryModal',
    'ModalIdle',
    'ModalStudentProcessed',
  ]),
  props: PropTypes.object,
}

Wrapper.defaultProps = {
  component: null,
  props: {},
}
const mapStateToProps = ({ modalReducer }) => ({ component: modalReducer.component, props: modalReducer.props })

export const ModalWrapper = connect(mapStateToProps, null)(Wrapper)

import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { CreateStudentFinancialSupport } from './Create'
import { EditStudentFinancialSupport } from './Edit'
import { useDispatch, useSelector } from 'react-redux'
import { studentFinancialSupportActions } from '@Store/studies'
import { modalActions } from '@Root/store'
import { studentCourseSessionSelectors, studentCourseSessionActions } from '@Store/studies/studentCourseSession/index'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager/index'

export const StudentFinancialSupportPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const programmeRecord = useSelector(studentCourseSessionSelectors.programmeRecord)
  const pathname = location.pathname
  const isNew = pathname.includes('new')
  const isDuplicate = pathname.includes('duplicate')
  const { studyProgrammeId, contactId } = useParams()

  const DEFAULT_URL = `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/student_financial_support`

  useEffect(() => {
    dispatch(studentCourseSessionActions.get({ studyProgrammeId, isCopy: isDuplicate }))
    dispatch(studentFinancialSupportActions.getOptions())
    dispatch(fieldTooltipManagerActions.getHesaDescriptions())
    return () => {
      dispatch(studentCourseSessionActions.clearData({}))
    }
  }, [])

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  const handleBack = () => {
    history.push(DEFAULT_URL)
  }
  return (
    <SectionWrapper hasBackButton backButtonText={'Student Financial Support Details'} clickBackButtonHandler={handleBack} activeName>
      <div className={styles.sections}>
        {isNew ? (
          <CreateStudentFinancialSupport onOpenDescriptionModal={onOpenDescriptionModal} programmeRecord={programmeRecord} handleBack={handleBack} />
        ) : (
          <EditStudentFinancialSupport onOpenDescriptionModal={onOpenDescriptionModal} programmeRecord={programmeRecord} />
        )}
      </div>
    </SectionWrapper>
  )
}

import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './courseRole.types'
import { actions } from './courseRole.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { studyProgrammeId, courseRoleId } = data
  try {
    const response = yield call(API.studies.programme.courseRole.get, studyProgrammeId, courseRoleId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(`/home/studies/programmes/${studyProgrammeId}/course_role`)
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  yield put(actions.setErrors([]))
  const { data, history, studyProgrammeId } = payload
  try {
    const response = yield call(API.studies.programme.courseRole.create, studyProgrammeId, data)
    history.push(`/home/studies/programmes/${studyProgrammeId}/course_role/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The Course Role has been created successfully!' }))
  } catch (error) {
    const errors = error.response.data.messages
    yield put(actions.set(data))
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  yield put(actions.setErrors([]))
  const { courseRoleId, studyProgrammeId, data } = payload
  try {
    const response = yield call(API.studies.programme.courseRole.edit, studyProgrammeId, courseRoleId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The Course Role has been saved successfully!' }))
  } catch (error) {
    const errors = error.response.data.messages
    yield put(actions.set(data))
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { studyProgrammeId, courseRoleId } = data
  try {
    yield call(API.studies.programme.courseRole.remove, studyProgrammeId, courseRoleId)
    history.push(`/home/studies/programmes/${studyProgrammeId}/course_role`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The Course Role has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const { data } = yield call(API.studies.programme.courseRole.options.getStatic)
    const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    const formattedData = {
      hesa_identifiers: generateObject(data.hesa_identifiers),
      role_types: generateObject(data.role_types),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('courseRole', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_COURSE_ROLE, get),
    takeEvery(types.CREATE_COURSE_ROLE, create),
    takeEvery(types.EDIT_COURSE_ROLE, edit),
    takeEvery(types.DELETE_COURSE_ROLE, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}

import classes from './style.module.scss'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { API } from '@Root/API'
import { useHistory } from 'react-router'
import { authActions } from '@Store/auth'

export const ActiveForm2fa = ({ isLogin, parseQr_code, loginGoogle2fa_secret, handleSubmitSecurityForm }) => {
  const [code, setCode] = useState('')
  const [googleSecret2fa, setGoogleSecret2fa] = useState('')
  const [qr_code, setQr_code] = useState({ __html: '' })
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (isLogin) {
      setQr_code({
        __html: `<` + getQr_code(parseQr_code),
      })
      setGoogleSecret2fa(loginGoogle2fa_secret)
    } else {
      ;(async function() {
        const { data } = await API.google2fa.getQR()
        const { qr_code, google2fa_secret } = data.data

        setQr_code({
          __html: `<` + getQr_code(qr_code),
        })
        setGoogleSecret2fa(google2fa_secret)
      })()
    }
  }, [])

  const getQr_code = qr_code =>
    qr_code
      .split('<')
      .slice(2)
      .join('<')

  const handleSubmit = e => {
    e.preventDefault()
    if (isLogin) {
      handleSubmitSecurityForm(code)
    } else {
      dispatch(authActions.initTwoFactoreAuth({ code, history }))
    }
  }
  return (
    <>
      <h2 className={classes.Tittle}>Google 2FA Authentication</h2>
      <p className={classes.Info}>Scan the QR Code in Google Authentication and enter the code to activate 2FA</p>
      <div className={classes.QRCode} dangerouslySetInnerHTML={qr_code} />
      <p className={classes.SecretCode}>
        <span className={classes.SecretCodeTitle}>Secret Key:</span> {googleSecret2fa}
      </p>
      <form className={classes.SecurityForm} onSubmit={handleSubmit}>
        <input
          type='text'
          className={classes.Input}
          placeholder={'Google Authentication'}
          minLength='5'
          value={code}
          onChange={e => setCode(e.target.value)}
          required
        />
        <button type='submit' className={classes.SubmitBtn}>
          Send
        </button>
      </form>
    </>
  )
}

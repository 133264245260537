import * as types from './snackbar.types'

export const setSnackbar = data => {
	return {
		type: types.SET_SNACKBAR,
		data,
	}
}

export const setSuccessNotification = data => {
	return {
		type: types.SET_SUCCESS_NOTIFICATION,
		data,
	}
}

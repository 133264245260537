import { useEffect } from 'react'
import { Redirect, useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import styles from './style.module.scss'
import { CreateEntryProfile } from './Create'
import { EditEntryProfile } from './Edit'
import { useSelector } from 'react-redux'
import { engagementSelectors } from '@Store/studies'
import { useDispatch } from 'react-redux'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager'

export const EntryProfilePage = ({ onOpenDescriptionModal }) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const engagement = useSelector(engagementSelectors.engagement)
  const engagementPermissions = useSelector(engagementSelectors.permissions)
  const canBeCreated = engagement.can_be_created_student_registration
  const pathname = location.pathname
  const isNew = location.pathname.includes('new')
  const { id: engagementId, contactId } = useParams()
  const entryProfileId = pathname.split('/')[7]

  useEffect(() => {
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'engagement_entry_profile' }))
  }, [])

  const PARENT_URL = `/home/studies/engagements/${contactId}/${engagementId}/entry-profile`

  if (!entryProfileId) history.push(PARENT_URL)

  if (!canBeCreated && entryProfileId === 'new' && engagement.id) return <Redirect to={PARENT_URL} />

  if (engagementPermissions.tabs && !engagementPermissions.tabs.student_registration) return <Redirect to={PARENT_URL} />

  return (
    <SectionWrapper hasBackButton backButtonText={'Engagement'} clickBackButtonHandler={() => history.push(PARENT_URL)} activeName>
      <div className={styles.sections}>
        {isNew ? (
          <CreateEntryProfile studentId={contactId} onOpenDescriptionModal={onOpenDescriptionModal} parentURL={PARENT_URL} />
        ) : (
          <EditEntryProfile parentURL={PARENT_URL} onOpenDescriptionModal={onOpenDescriptionModal} />
        )}
      </div>
    </SectionWrapper>
  )
}

import * as types from './costCenter.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_COST_CENTER,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_COST_CENTER,
		payload,
	}),
	set: payload => ({
		type: types.SET_COST_CENTER,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_COST_CENTER,
		payload,
	}),
	remove: payload => ({
		type: types.DELETE_COST_CENTER,
		payload,
	}),
	getStaticOptions: () => ({
		type: types.GET_STATIC_OPTIONS,
	}),
	getDynamicOptions: () => ({
		type: types.GET_DYNAMIC_OPTIONS,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
	clearForm: () => ({
		type: types.CLEAR_FORM,
	}),
}

import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { CreateCourseInitiative } from './Create'
import { EditCourseInitiative } from './Edit'
import { useDispatch } from 'react-redux'
import { modalActions } from '@Root/store'
import { courseInitiativeActions } from '@Store/studies'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager/index'

export const CourseInitiativePage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const pathname = location.pathname
  const isNew = pathname.includes('new')
  const { studyProgrammeId } = useParams()
  const DEFAULT_URL = `/home/studies/programmes/${studyProgrammeId}/course_initiative`

  useEffect(() => {
    dispatch(courseInitiativeActions.getOptions())
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'programmes_course_initiative' }))
  }, [])

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }
  return (
    <SectionWrapper hasBackButton backButtonText={'Course Initiative Details'} clickBackButtonHandler={() => history.push(DEFAULT_URL)}>
      <div className={styles.sections}>
        {isNew ? (
          <CreateCourseInitiative onOpenDescriptionModal={onOpenDescriptionModal} parentURL={DEFAULT_URL} />
        ) : (
          <EditCourseInitiative onOpenDescriptionModal={onOpenDescriptionModal} />
        )}
      </div>
    </SectionWrapper>
  )
}

export const TOGGLE_LOADING = 'entryQualificationSubject/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'entryQualificationSubject/TOGGLE_SUCCESS'
export const GET_ENTRY_QUALIFICATION_SUBJECT = 'entryQualificationSubject/GET_ENTRY_QUALIFICATION_SUBJECT'
export const SET_ENTRY_QUALIFICATION_SUBJECT = 'entryQualificationSubject/SET_ENTRY_QUALIFICATION_SUBJECT'
export const CREATE_ENTRY_QUALIFICATION_SUBJECT = 'entryQualificationSubject/CREATE_ENTRY_QUALIFICATION_SUBJECT'
export const DELETE_ENTRY_QUALIFICATION_SUBJECT = 'entryQualificationSubject/DELETE_ENTRY_QUALIFICATION_SUBJECT'
export const EDIT_ENTRY_QUALIFICATION_SUBJECT = 'entryQualificationSubject/EDIT_ENTRY_QUALIFICATION_SUBJECT'
export const GET_OPTIONS = 'entryQualificationSubject/GET_OPTIONS'
export const SET_OPTIONS = 'entryQualificationSubject/SET_OPTIONS'
export const CLEAR_DATA = 'entryQualificationSubject/CLEAR_DATA'
export const SET_PERMISSIONS = 'entryQualificationSubject/SET_PERMISSIONS'

import { combineReducers } from 'redux'
import { reducer } from './student.reducer'
import { hesaReducer } from './hesa'
import * as studentSelectors from './student.selectors'

export { actions as studentActions } from './student.actions'
export { rootSaga as studentRootSaga } from './student.saga'
export { studentSelectors }
export * from './hesa'

export const studentReducer = combineReducers({ reducer, hesa: hesaReducer })

export const options = {
	'all-contacts': [
		{ label: 'All Contacts - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'All Contacts - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'All Contacts Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	students: [
		{ label: 'Student records - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Student record - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Student Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	academics: [
		{ label: 'Academics - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Academics - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Academics Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	staff: [
		{ label: 'Staff records - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Staff - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Staff Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	clergy: [
		{ label: 'Clergy - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Clergy - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Clergy Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	associations: [
		{ label: 'Associations - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Associations - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Associations Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	churches: [
		{ label: 'Churches - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Churches - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Churches Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	dioceses: [
		{ label: 'Dioceses - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Dioceses - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Dioceses Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	'training-facilities': [
		{ label: 'Training Facilities - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Training Facilities - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Training Facilities Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	other: [
		{ label: 'Other - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Other - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Other Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	'admissions-details': [
		{ label: 'Admissions - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Admissions - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Admissions Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	applications: [
		{ label: 'Applications - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Applications - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Applications Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	engagements: [
		{ label: 'Engagements - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Engagements - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Engagements Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	'records-of-studies': [
		{ label: 'Student Course Session - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Student Course Session - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Student Course Session Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	'module-instances': [
		{ label: 'Modules-instances - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Modules-instances - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Modules-instances Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	programmes: [
		{ label: 'Programmes - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Programmes - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Programmes Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	modules: [
		{ label: 'Module - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Module - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Module Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	'postgrad-student-details': [
		{ label: 'Postgrad Student Details - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Postgrad Student Details - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Postgrad Student Details Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
	'non-active': [
		{ label: 'Non Active - CSV', value: 'csv', checkField: 'hasCSVExport' },
		{ label: 'Non Active - XML', value: 'xml', checkField: 'hasXMLExport' },
		{ label: 'Non Active Moodle Export - CSV', value: 'moodle_csv', checkField: 'hasMoodleExport' },
	],
}

import React, { useEffect } from 'react'
import { StudiesModuleSubjectForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import {
	fieldTooltipManagerActions,
	fieldTooltipManagerSelectors,
	modalActions,
	moduleActions,
	moduleSelectors,
	subjectActions,
	subjectSelectors,
} from '@Root/store'
import { useHistory, useLocation, useParams } from 'react-router'

export const EditSubject = ({ defaultURL }) => {
	const history = useHistory()
	const location = useLocation()
	const { moduleId, moduleSubId: subjectId } = useParams()
	const maxProportion = useSelector(moduleSelectors.maxProportion)
	const minProportion = useSelector(moduleSelectors.minProportion)
	const staticOptions = useSelector(subjectSelectors.options).static
	const subject = useSelector(subjectSelectors.subject)
	const isLoading = useSelector(subjectSelectors.isLoading)
	const isSuccess = useSelector(subjectSelectors.isSuccess)
	const permissions = useSelector(subjectSelectors.permissions)
	const errors = useSelector(subjectSelectors.errors)
	const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions)['module-subject']
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(subjectActions.getStaticOptions())
	}, [dispatch])

	useEffect(() => {
		dispatch(moduleActions.get({ moduleId }))

		return () => {
			dispatch(subjectActions.setErrors({}))
		}
	}, [moduleId, dispatch])

	useEffect(() => {
		dispatch(subjectActions.get({ subjectId, history }))
	}, [dispatch, history, subjectId])

	const onEdit = payload => {
		dispatch(subjectActions.edit({ id: subjectId, ...payload, educational_module_id: moduleId }))
	}

	const onDelete = () => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('ConfirmationModal', {
					text: 'This subject module will be removed?',
					rejectButtonText: 'Cancel',
					resolveButtonText: 'Proceed',
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
				})
			)
		})
			.then(async () => {
				dispatch(subjectActions.remove({ subjectId, history, moduleId }))
			})
			.finally(() => dispatch(modalActions.hideModal()))
	}

	useEffect(() => {
		dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'module-subject' }))
	}, [dispatch])

	const onOpenDescriptionModal = (description, title) => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('NotificationModal', {
					text: description,
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
					title,
				})
			)
		})
			.then(() => {
				dispatch(subjectActions.remove({ subjectId, moduleId }))
			})
			.finally(() => dispatch(modalActions.hideModal()))
	}

	return (
		<StudiesModuleSubjectForm
			permissions={permissions}
			options={staticOptions}
			initialValue={subject}
			onDelete={onDelete}
			onSave={onEdit}
			location={location}
			maxProportion={maxProportion}
			openDescriptionModal={onOpenDescriptionModal}
			descriptions={descriptions}
			parentURL={defaultURL}
			isLoading={isLoading}
			isSuccess={isSuccess}
			minProportion={minProportion}
			initialErrors={errors}
		/>
	)
}

import { axios } from '@Root/API'

export const validateSID = (studentId, applicationId) => {
	return axios.get('validate-sid-application-form', {
		params: {
			sid: studentId,
			id: applicationId,
		},
	})
}

import * as userSelectors from './user.selectors'
import { combineReducers } from 'redux'
import { permissionsReducer } from './permissions'
import { reducer } from './user.reducer'

export { actions as userActions } from './user.actions'
export { rootSaga as userRootSaga } from './user.saga'
export { userSelectors }
export * from './permissions'

export const userReducer = combineReducers({ reducer, permissions: permissionsReducer })

export const isLoading = state => state.studiesReducer.programme.curriculumAccreditation.isLoading

export const isSuccess = state => state.studiesReducer.programme.curriculumAccreditation.isSuccess

export const curriculumAccreditation = state => state.studiesReducer.programme.curriculumAccreditation.curriculumAccreditation

export const errors = state => state.studiesReducer.programme.curriculumAccreditation.errors

export const options = state => state.studiesReducer.programme.curriculumAccreditation.options

export const permissions = state => state.studiesReducer.programme.curriculumAccreditation.permissions

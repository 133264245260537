import * as types from './studentTypes.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_STUDENT_TYPES,
		payload,
	}),
	set: payload => ({
		type: types.SET_STUDENT_TYPES,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_STUDENT_TYPE,
		payload,
	}),
	remove: payload => ({
		type: types.DELETE_STUDENT_TYPE,
		payload,
	}),
	setRemoved: payload => ({
		type: types.SET_REMOVED_STUDENT_TYPE,
		payload,
	}),
	setCreated: payload => ({
		type: types.SET_CREATED_STUDENT_TYPE,
		payload,
	}),
}

import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { switchEmptyStringsToNullsInObject } from '@Root/helpers'
import { FormWrapper } from '@Root/HOCs'
import { Select, InputLabel } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions } from '@Root/store'
import { clericActions, clericSelectors, personSelectors } from '@Store/contact/person'
import { Redirect, useHistory } from 'react-router'
import { permissionsActions } from '@Store/permissions'
import { contactSelectors } from '@Root/store'

const PARENT_URL = '/home/contacts/all-contacts'
const relationShipDeleteLabel = 'Clergy'
const titles = { onRead: 'Cleric Details', onEdit: 'Edit Cleric Details' }
export const ClericForm = ({ contactId }) => {
  const history = useHistory()
  const [isEditable, setIsEditable] = useState(false)
  const [data, setData] = useState({})
  const relationships = useSelector(personSelectors.relationships)
  const permissions = useSelector(clericSelectors.permissions)
  const isPermissionsLoaded = useSelector(clericSelectors.isPermissionsLoaded)
  const cleric = useSelector(clericSelectors.cleric)
  const isSuccess = useSelector(clericSelectors.isSuccess)
  const isLoading = useSelector(clericSelectors.isLoading)
  const staticOptions = useSelector(clericSelectors.options).static
  const dynamicOptions = useSelector(clericSelectors.options).dynamic
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).person.clergy
  const contactPermissions = useSelector(contactSelectors.permissions)
  const dispatch = useDispatch()

  useEffect(() => {
    const hasOptions = Object.keys(staticOptions).length > 0
    if (!hasOptions) dispatch(clericActions.getStaticOptions())
  }, [dispatch, staticOptions])

  useEffect(() => {
    dispatch(clericActions.getStaticOptions())
    dispatch(clericActions.getDynamicOptions())
    dispatch(fieldTooltipManagerActions.getContact({ entity: 'contacts', entity_type: 'person', entity_sub_type: 'clergy' }))
  }, [dispatch])

  useEffect(() => {
    if (contactId) {
      dispatch(clericActions.get({ contactId }))
      dispatch(permissionsActions.getMain({ entity: 'cleric', contactId: Number(contactId) }))
    }
  }, [contactId, dispatch])

  useEffect(() => {
    if (cleric) setData(cleric)
  }, [cleric])

  useEffect(() => {
    if (isSuccess) setIsEditable(false)
  }, [isSuccess])

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const onCancel = () => {
    setData(cleric)
    setIsEditable(false)
  }

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: `The "${relationShipDeleteLabel}" relationship will be removed?`,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        const relationshipId = relationships.find(item => item.label === relationShipDeleteLabel).value
        dispatch(clericActions.remove({ contactId, relationshipId, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = async () => {
    const modifiedData = switchEmptyStringsToNullsInObject(data)
    dispatch(clericActions.edit({ ...modifiedData, id: contactId }))
  }

  const toggleEditMode = () => {
    setIsEditable(prevState => !prevState)
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  if (!contactPermissions.tabs?.cleric) return <Redirect to={PARENT_URL} />
  const { fields, form } = permissions
  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', ...(form?.delete ? ['delete'] : []), 'save'] : []}
      buttonsNames={[{ button: 'delete', name: 'Delete this cleric' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading || !isPermissionsLoaded}
      clickCancelButtonHandler={onCancel}
      clickDeleteButtonHandler={onDelete}
      clickSaveButtonHandler={onSave}
      canEdit={form?.edit}
      clickToggleEditModeButtonHandler={toggleEditMode}
      isEditable={isEditable}
      readTitle={titles.onRead}
      editTitle={titles.onEdit}
      idForm={data?.id ? `cleric_details_${data?.id}` : null}
      isSuccess={isSuccess}
    >
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text='Type' description={descriptions.type} onOpenDescription={onOpenDescriptionModal} />
          <Select
            inputClassNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 150, width: 260 }}
            options={staticOptions.cleric_types}
            value={data.type}
            changeHandler={value => handleChangeInput('type', value)}
            isDisabled={!isEditable || !fields.type?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Diocese' description={descriptions.diocese_id} onOpenDescription={onOpenDescriptionModal} />
          <div style={{ position: 'absolute', left: 150 }}>
            <Select
              inputClassNames={!isEditable ? ['borderless'] : []}
              options={dynamicOptions.diocese}
              inputStyle={{ width: 260 }}
              value={data.diocese_id}
              changeHandler={value => handleChangeInput('diocese_id', value)}
              isDisabled={!isEditable || !fields.diocese_id?.edit}
            />
          </div>
        </div>
      </div>
    </FormWrapper>
  )
}

ClericForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    type: PropTypes.string,
    diocese_id: PropTypes.number,
    institutions_ids: PropTypes.arrayOf(PropTypes.number),
  }),
  clergyTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func,
}

ClericForm.defaultProps = {
  permissions: [],
  initialData: {
    type: null,
    diocese_id: null,
    institutions_ids: [],
  },
  titleOptions: [],
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {},
}

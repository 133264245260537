import React, { Component } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { FormWrapper } from '@Root/HOCs'
import { EditButton, SectionTitle, InputLabel, TextInput, Select } from '@Root/components'

export class StudiesModuleDeliveryLocationForm extends Component {
	constructor(props) {
		super(props)
		const { initialIsEditable, initialData } = props
		this.state = {
			isEditable: initialIsEditable,
			error: null,
			isSaving: false,
			venue_id: initialData.venue_id,
			location_proportion: initialData.location_proportion,
		}
	}
	formIsValid = () => {
		const { location_proportion } = this.state
		if (!location_proportion) {
			this.showError('location_proportion', 'Required')
			return false
		}
		return true
	}

	showError = (input, message) => {
		this.setState({ error: { input, message } }, () => {
			this.setState({ error: null })
		})
	}

	errorMessage = input => {
		const { error } = this.state
		return error && error.input === input ? error.message : null
	}

	setInitialState = () => {
		const { initialData } = this.props
		this.setState({
			isEditable: false,
			error: null,
			venue_id: initialData.venue_id,
			location_proportion: initialData.location_proportion,
		})
	}

	handleClickCancelButton = () => {
		this.setInitialState()
	}

	handleClickDeleteButton = () => {
		this.props.deleteHandler(
			() => {
				this.setState({ isSaving: true })
			},
			() => {
				this.setState({ isSaving: false })
			}
		)
	}

	handleClickSaveButton = async () => {
		if (!this.formIsValid()) return
		const { state } = this
		const moduleDelId = this.props.location.pathname.split('/')[6]
		this.setState({ isSaving: true })
		try {
			await this.props.saveHandler({
				module_delivery_id: moduleDelId,
				venue_id: state.venue_id,
				location_proportion: state.location_proportion,
			})
			!this.isUnmounted && this.setState({ isEditable: false })
		} catch (error) {
			console.log(error)
			alert('Unable to save the data')
		}
		!this.isUnmounted && this.setState({ isSaving: false })
	}

	onToggleEditable = () => {
		this.setState(prevState => ({ isEditable: !prevState.isEditable }))
	}

	onOpenDescriptionModal = (description, title) => {
		const { openDescriptionModal } = this.props
		openDescriptionModal(description, title)
	}

	componentWillUnmount() {
		this.isUnmounted = true
	}

	render() {
		const { isEditable, isSaving, venue_id, location_proportion } = this.state

		const { permissions, title, buttons, options, descriptions } = this.props
		const { handleClickCancelButton, handleClickDeleteButton, handleClickSaveButton, errorMessage, onToggleEditable, onOpenDescriptionModal } = this

		const { onRead: titleOnRead = '', onEdit: titleOnEdit = '' } = title

		return (
			<div className={classes.StudiesModuleDeliveryLocationForm}>
				<FormWrapper
					buttons={isEditable ? buttons : []}
					buttonsNames={[{ button: 'delete', name: 'Delete this module cost center' }]}
					buttonsAreDisabled={isSaving}
					isSpinning={isSaving}
					clickCancelButtonHandler={handleClickCancelButton}
					clickDeleteButtonHandler={handleClickDeleteButton}
					clickSaveButtonHandler={handleClickSaveButton}
					canEdit={permissions.includes('edit')}
					isEditable={isEditable}
					editTitle={titleOnEdit}
					readTitle={titleOnRead}
					clickToggleEditModeButtonHandler={onToggleEditable}
				>
					<div className={classes.contentWrapper}>
						<div className={classes.columnWrapper}>
							<div className={classes.inputWrapper}>
								<InputLabel text='Venue identifier' description={descriptions.venue_id} onOpenDescription={onOpenDescriptionModal} />
								<Select
									inputClassNames={!isEditable ? ['borderless'] : []}
									inputStyle={{ width: 260 }}
									optionsStyle={{ width: 260 }}
									options={options.venues}
									value={venue_id}
									changeHandler={value => this.setState({ venue_id: value })}
									isDisabled={!isEditable}
								/>
							</div>
						</div>
						<div className={classes.columnWrapper}>
							<div className={classes.inputWrapper}>
								<InputLabel
									text='Module delivery location proportion'
									hasAsterisk={isEditable}
									description={descriptions.module_delivery_location_id}
									onOpenDescription={onOpenDescriptionModal}
								/>
								<TextInput
									classNames={!isEditable ? ['borderless'] : []}
									style={{ width: 260 }}
									value={location_proportion}
									changeHandler={location_proportion => this.setState({ location_proportion })}
									placeholder=''
									isDisabled={!isEditable}
									restriction='digits'
									error={errorMessage('location_proportion')}
								/>
							</div>
						</div>
					</div>
				</FormWrapper>
			</div>
		)
	}
}

StudiesModuleDeliveryLocationForm.propTypes = {
	permissions: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.shape({
		onRead: PropTypes.string,
		onEdit: PropTypes.string,
	}),
	initialData: PropTypes.shape({
		venue_id: PropTypes.number,
		location_proportion: PropTypes.number,
	}),
	initialIsEditable: PropTypes.bool,
	buttons: PropTypes.arrayOf(PropTypes.oneOf(['cancel', 'delete', 'save'])),
	cancelHandler: PropTypes.func,
	deleteHandler: PropTypes.func,
	saveHandler: PropTypes.func,
}

StudiesModuleDeliveryLocationForm.defaultProps = {
	permissions: [],
	title: {
		onRead: '',
		onEdit: '',
	},
	initialData: {
		venue_id: null,
		location_proportion: null,
	},
	initialIsEditable: false,
	options: null,
	buttons: ['cancel', 'delete', 'save'],
	cancelHandler: () => {},
	deleteHandler: () => {},
	saveHandler: () => {},
	openDescriptionModal: () => {},
}

export const TOGGLE_LOADING = 'fundingAndMonitoring/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'fundingAndMonitoring/TOGGLE_SUCCESS'
export const CREATE_FUNDING_AND_MONITORING = 'fundingAndMonitoring/CREATE_FUNDING_AND_MONITORING'
export const SET_FUNDING_AND_MONITORING = 'fundingAndMonitoring/SET_FUNDING_AND_MONITORING'
export const EDIT_FUNDING_AND_MONITORING = 'fundingAndMonitoring/EDIT_FUNDING_AND_MONITORING'
export const DELETE_FUNDING_AND_MONITORING = 'fundingAndMonitoring/DELETE_FUNDING_AND_MONITORING'
export const GET_OPTIONS = 'fundingAndMonitoring/GET_OPTIONS'
export const SET_OPTIONS = 'fundingAndMonitoring/SET_OPTIONS'
export const SET_PERMISSIONS = 'fundingAndMonitoring/SET_PERMISSIONS'
export const CLEAR_PERMISSIONS = 'fundingAndMonitoring/CLEAR_PERMISSIONS'

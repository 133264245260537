import * as types from './entryProfile.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	set: payload => ({
		type: types.SET_ENTRY_PROFILE,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_ENTRY_PROFILE,
		payload,
	}),
	get: payload => ({
		type: types.GET_ENTRY_PROFILE,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_ENTRY_PROFILE,
		payload,
	}),
	delete: payload => ({
		type: types.DELETE_ENTRY_PROFILE,
		payload,
	}),
	getOptions: () => ({
		type: types.GET_OPTIONS,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	clearData: payload => ({
		type: types.CLEAR_DATA,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

export const isLoading = state => state.admissionReducer.attachment.isLoading

export const isSuccess = state => state.admissionReducer.attachment.isSuccess

export const attachments = state => state.admissionReducer.attachment.attachments

export const totalAttachments = state => state.admissionReducer.attachment.totalAttachments

export const options = state => state.admissionReducer.attachment.options

export const history = state => state.admissionReducer.attachment.history

export const totalHistory = state => state.admissionReducer.attachment.totalHistory

export const permissions = state => state.admissionReducer.attachment.permissions

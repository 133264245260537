export const isLoading = state => state.studiesReducer.studentCourseSession.fundingBody.isLoading

export const isSuccess = state => state.studiesReducer.studentCourseSession.fundingBody.isSuccess

export const fundingBody = state => state.studiesReducer.studentCourseSession.fundingBody.fundingBody

export const errors = state => state.studiesReducer.studentCourseSession.fundingBody.errors

export const options = state => state.studiesReducer.studentCourseSession.fundingBody.options

export const permissions = state => state.studiesReducer.studentCourseSession.fundingBody.permissions

export const canBeCreatFundingBody = state => state.studiesReducer.studentCourseSession.fundingBody.canBeCreatFundingBody
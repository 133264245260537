import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './entryQualificationSubject.types'
import { actions } from './entryQualificationSubject.actions'
import { errorMessage, saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { contactId, engagementId, entryProfileId, entryQualificationId, entryQualificationSubjectId } = data
  try {
    const response = yield call(API.studies.engagement.entryProfile.entryQualificationAwarded.entryQualificationSubject.get, entryQualificationSubjectId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(
      `/home/studies/engagements/${contactId}/${engagementId}/entry-profile/${entryProfileId}/entry-qualification-award/${entryQualificationId}/entry-qualification-subject`
    )
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history, entryProfileId, contactId, engagementId, entryQualificationId } = payload
  try {
    const response = yield call(API.studies.engagement.entryProfile.entryQualificationAwarded.entryQualificationSubject.create, entryQualificationId, data)
    history.push(
      `/home/studies/engagements/${contactId}/${engagementId}/entry-profile/${entryProfileId}/entry-qualification-award/${entryQualificationId}/entry-qualification-subject/${response.data.data.id}`
    )
    yield put(snackbarActions.setSuccessNotification({ text: 'The entry qualification subject has been created successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, entryQualificationId, entryQualificationSubjectId } = payload
  try {
    const response = yield call(
      API.studies.engagement.entryProfile.entryQualificationAwarded.entryQualificationSubject.edit,
      entryQualificationId,
      entryQualificationSubjectId,
      data
    )
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The entry qualification subject has been saved successfully!' }))
  } catch (error) {
    const errors = error.response.data.errors
    yield put(actions.set(payload))
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { entryProfileId, entryQualificationId, entryQualificationSubjectId, engagementId, contactId } = data
  try {
    yield call(
      API.studies.engagement.entryProfile.entryQualificationAwarded.entryQualificationSubject.remove,
      entryQualificationId,
      entryQualificationSubjectId
    )
    history.push(
      `/home/studies/engagements/${contactId}/${engagementId}/entry-profile/${entryProfileId}/entry-qualification-award/${entryQualificationId}/entry-qualification-subject`
    )
    yield put(snackbarActions.setSuccessNotification({ text: 'The entry qualification subject has been deleted successfully!' }))
  } catch (error) {
    console.log('error', error.response)
    yield put(snackbarActions.setSnackbar({ text: error.response.data.errors, isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const {
      data: { subject_identificators },
    } = yield call(API.studies.engagement.entryProfile.entryQualificationAwarded.entryQualificationSubject.options.getStatic)
    const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    const formattedData = {
      subject_identificators: generateObject(subject_identificators),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('entry_qualification_subject', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_ENTRY_QUALIFICATION_SUBJECT, get),
    takeEvery(types.CREATE_ENTRY_QUALIFICATION_SUBJECT, create),
    takeEvery(types.EDIT_ENTRY_QUALIFICATION_SUBJECT, edit),
    takeEvery(types.DELETE_ENTRY_QUALIFICATION_SUBJECT, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}

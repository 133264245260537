import React, { useEffect } from 'react'
import { fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions, moduleActions, moduleSelectors } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { StudiesModuleForm } from '@Root/components'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router-dom'

export const CreateModule = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const options = useSelector(moduleSelectors.options).static
  const isLoading = useSelector(moduleSelectors.isLoading)
  const module = useSelector(moduleSelectors.module)
  const permissions = useSelector(moduleSelectors.permissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).modules
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)

  const onSubmit = payload => {
    dispatch(moduleActions.create({ data: payload, history }))
  }

  const onCancel = () => {
    history.length > 1 ? history.goBack() : history.push('/home/studies/modules')
  }

  useEffect(() => {
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'modules' }))
    dispatch(moduleActions.getStaticOptions())
  }, [dispatch])

  const onOpenDescriptionModal = description => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('NotificationModal', {
          text: description,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  if (!dataCreationPermissions.modules) return <Redirect to={'/home/studies/modules'} />
  return (
    <StudiesModuleForm
      generateId
      canEdit
      isNew
      options={options}
      onSave={onSubmit}
      onCancel={onCancel}
      isLoading={isLoading}
      onOpenDescriptionModal={onOpenDescriptionModal}
      descriptions={descriptions}
      initialValue={module}
      permissions={permissions}
    />
  )
}

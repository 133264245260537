import React from 'react'
import classes from './style.module.scss'
import { ArrowIcon } from '@Root/assets'
import { CancelButton, CheckboxInput, InputLabel, SaveButton } from '@Root/components'

export class AdditionalAccordionManager extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			opened: false,
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.isSuccess && !prevProps.isSuccess) {
			this.setState({ opened: false })
		}
	}

	onChange = entity => () => {
		const { data, onDataChange, permissionType } = this.props
		const modifiedData = data.map(item =>
			item.entity === entity
				? {
						...item,
						permissions: item.permissions.includes(permissionType.label)
							? item.permissions.filter(permission => permission !== permissionType.label)
							: [...item.permissions, permissionType.label],
				  }
				: item
		)
		onDataChange(modifiedData)
	}

	onTickAll = () => {
		const { data, onDataChange, permissionType } = this.props
		const modifiedData = data.map(item => ({
			...item,
			permissions: item.permissions.includes(permissionType.label) ? item.permissions : [...item.permissions, permissionType.label],
		}))
		onDataChange(modifiedData)
	}

	onUnTickAll = () => {
		const { data, onDataChange, permissionType } = this.props
		const modifiedData = data.map(item => ({
			...item,
			permissions: item.permissions.includes(permissionType.label)
				? item.permissions.filter(permission => permission !== permissionType.label)
				: item.permissions,
		}))
		onDataChange(modifiedData)
	}
	render() {
		const {
			state: { opened },
			props: { isEditable, permissionType, data = [] },
			onUnTickAll,
			onTickAll,
		} = this

		return (
			<div className={`${classes.accordion_item}, ${opened && `${classes.accordion_item__opened}`}`}>
				<div
					className={`${classes.accordion_item__line}`}
					onClick={() => {
						this.setState({ opened: !opened })
					}}
				>
					<h3 className={classes.accordion_item__title}>{permissionType.title}</h3>
					<img className={classes.accordion_item__icon} src={ArrowIcon} alt={'arrow'} />
				</div>
				<div className={classes.accordion_item__inner}>
					<div className={classes.grid}>
						{data.length > 0 &&
							data?.map(({ entity, label, permissions }) => {
								const isChecked = permissions.includes(permissionType.label)
								return (
									<div className={classes.item} key={label}>
										<InputLabel text={label} />
										<CheckboxInput isChecked={isChecked} changeHandler={this.onChange(entity)} isDisabled={!isEditable} />
									</div>
								)
							})}
					</div>
					<div className={classes.actions}>
						<CancelButton onClick={onUnTickAll}>Untick all</CancelButton>
						<SaveButton onClick={onTickAll}>Tick all</SaveButton>
					</div>
				</div>
			</div>
		)
	}
}

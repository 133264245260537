import * as types from './hesa.types'

export const actions = {
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_HESA,
		payload,
	}),
	set: payload => ({
		type: types.SET_HESA,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_HESA,
		payload,
	}),
	editCourseSession: payload => ({
		type: types.EDIT_COURSE_SESSION,
		payload,
	}),
	editModuleInstance: payload => ({
		type: types.EDIT_MODULE_INSTANCE,
		payload,
	}),
	getSnapshots: payload => ({
		type: types.GET_SNAPSHOTS,
		payload,
	}),
	setSnapshots: payload => ({
		type: types.SET_SNAPSHOTS,
		payload,
	}),
	getSnapshot: payload => ({
		type: types.GET_SNAPSHOT,
		payload,
	}),
	getStaticOptions: () => ({
		type: types.GET_STATIC_OPTIONS,
	}),
	getDynamicOptions: payload => ({
		type: types.GET_DYNAMIC_OPTIONS,
		payload,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

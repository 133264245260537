export const genders = state => state.optionsReducer.genders

export const countries = state => state.optionsReducer.countries

export const institutions = state => state.optionsReducer.institutions

export const disabilities = state => state.optionsReducer.disabilities

export const SLCLoan = state => state.optionsReducer.SLCLoan

export const admissionBapStatuses = state => state.optionsReducer.admissionBapStatuses

export const financeFundingOptions = state => state.optionsReducer.financeFunding

export const financeTuitionFees = state => state.optionsReducer.financeTuitionFees

export const dataForCreateContact = state => state.optionsReducer.dataForCreateContact

export const suspensions = state => state.optionsReducer.suspensions

export const rollover = state => state.optionsReducer.rollover

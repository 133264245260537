import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { Redirect, useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { CreateCollaborativeProvision } from './Create'
import { EditCollaborativeProvision } from './Edit'
import { getDataOptionsFromLocalStorage } from '@Root/helpers'
import { useSelector } from 'react-redux'
import { engagementSelectors } from '@Store/studies'
import { useDispatch } from 'react-redux'
import { modalActions } from '@Store/modal'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager'

export const CollaborativeProvisionPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const engagement = useSelector(engagementSelectors.engagement)
  const engagementPermissions = useSelector(engagementSelectors.permissions)
  const canBeCreated = engagement.can_be_created_collaborative_provision
  const pathname = location.pathname
  const isNew = pathname.includes('new')
  const { id: engagementId, contactId } = useParams()
  const collaborativeId = pathname.split('/')[7]

  const DEFAULT_URL = `/home/studies/engagements/${contactId}/${engagementId}/collaborative-provision`

  useEffect(() => {
    getDataOptionsFromLocalStorage(['collaborative_provision'])
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'engagement_collaborative_provision' }))
  }, [])

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('NotificationModal', {
          text: description,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
          title,
        })
      )
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  if (!collaborativeId) history.push(DEFAULT_URL)

  if (!canBeCreated && collaborativeId === 'new' && engagement.id) return <Redirect to={DEFAULT_URL} />

  if (engagementPermissions.tabs && !engagementPermissions.tabs.collaborative_provision) return <Redirect to={DEFAULT_URL} />

  return (
    <SectionWrapper hasBackButton backButtonText={'Engagement Details'} clickBackButtonHandler={() => history.push(DEFAULT_URL)} activeName>
      <div className={styles.sections}>
        {isNew ? (
          <CreateCollaborativeProvision parentURL={DEFAULT_URL} onOpenDescriptionModal={onOpenDescriptionModal} />
        ) : (
          <EditCollaborativeProvision parentURL={DEFAULT_URL} onOpenDescriptionModal={onOpenDescriptionModal} />
        )}
      </div>
    </SectionWrapper>
  )
}

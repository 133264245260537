import * as types from './modules.types'

const initialModuleValue = {
  module_identifier: '',
  credit_value_of_module: '',
  credit_transfer_scheme_id: null,
  language_identifier_id: null,
  full_time_equivalence: '',
  level_of_credit_points_id: null,
  module_title: '',
  programs_ids: [],
}

const initialPermissions = {
  fields: {
    credit_transfer_scheme_id: { view: true, edit: true },
    credit_value_of_module: { view: true, edit: true },
    full_time_equivalence: { view: true, edit: true },
    language_identifier_id: { view: true, edit: true },
    level_of_credit_points_id: { view: true, edit: true },
    module_identifier: { view: true, edit: true },
    module_title: { view: true, edit: true },
    programs_ids: { view: true, edit: true },
  },
}
const initialState = {
  isLoading: false,
  isSuccess: false,
  module: initialModuleValue,
  options: {
    static: {},
    dynamic: {},
  },
  minProportion: 5,
  permissions: initialPermissions,
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: payload,
      }
    case types.SET_MODULE:
      return {
        ...state,
        module: payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          [payload.name]: {
            ...state.options[payload.name],
            ...payload.data,
          },
        },
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    case types.CLEAR_PERMISSIONS:
      return {
        ...state,
        permissions: initialPermissions,
      }
    default:
      return state
  }
}

export const TOGGLE_LOADING = 'studentCourseSession/studyLocation/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'studentCourseSession/studyLocation/TOGGLE_SUCCESS'
export const GET_STUDY_LOCATION = 'studentCourseSession/studyLocation/GET_STUDY_LOCATION'
export const SET_STUDY_LOCATION = 'studentCourseSession/studyLocation/SET_STUDY_LOCATION'
export const CREATE_STUDY_LOCATION = 'studentCourseSession/studyLocation/CREATE_STUDY_LOCATION'
export const DELETE_STUDY_LOCATION = 'studentCourseSession/studyLocation/DELETE_STUDY_LOCATION'
export const EDIT_STUDY_LOCATION = 'studentCourseSession/studyLocation/EDIT_STUDY_LOCATION'
export const GET_OPTIONS = 'studentCourseSession/studyLocation/GET_OPTIONS'
export const SET_OPTIONS = 'studentCourseSession/studyLocation/SET_OPTIONS'
export const CLEAR_DATA = 'studentCourseSession/studyLocation/CLEAR_DATA'
export const SET_PERMISSIONS = 'studentCourseSession/studyLocation/SET_PERMISSIONS'
export const SET_CAN_BE_CREATED = 'studentCourseSession/studyLocation/SET_CAN_BE_CREATED'
export const SET_ERRORS = 'studentCourseSession/studyLocation/SET_ERRORS'

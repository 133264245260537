import React, { useEffect, useState } from 'react'
import { FormWrapper } from '@Root/HOCs'
import { CheckboxInput, InputLabel } from '@Root/components'
import PropTypes from 'prop-types'
import styles from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { consentsActions, consentsSelectors, modalActions } from '@Root/store'
import { permissionsActions, permissionsSelectors } from '@Store/permissions'

const titles = { onRead: 'Consents Details', onEdit: 'Edit Consents Details' }
export const ContactConsentsForm = ({ contactId }) => {
  const [isEditable, setIsEditable] = useState(false)
  const [data, setData] = useState(null)
  const consent = useSelector(consentsSelectors.consent)
  const isLoading = useSelector(consentsSelectors.isLoading)
  const isSuccess = useSelector(consentsSelectors.isSuccess)
  const permissions = useSelector(consentsSelectors.permissions)
  const isPermissionsLoaded = useSelector(consentsSelectors.isPermissionsLoaded)
  const mainPermissions = useSelector(permissionsSelectors.mainPermissions)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(permissionsActions.getMain({ entity: 'consent', contactId: Number(contactId) }))
  }, [dispatch, contactId])

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const handleClickCancelButton = () => {
    setData(consent)
    setIsEditable(false)
  }

  const handleClickSaveButton = () => {
    dispatch(consentsActions.updateConsents({ ...data, contactId }))
  }

  const toggleEditMode = () => {
    setIsEditable(prevState => !prevState)
  }

  useEffect(() => {
    setData(consent)
  }, [consent])

  useEffect(() => {
    if (isSuccess) setIsEditable(false)
  }, [isSuccess])

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  const { fields, form } = permissions
  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', 'save'] : []}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading && !isPermissionsLoaded}
      clickCancelButtonHandler={handleClickCancelButton}
      clickSaveButtonHandler={handleClickSaveButton}
      canEdit={form?.edit}
      clickToggleEditModeButtonHandler={toggleEditMode}
      isEditable={isEditable}
      readTitle={titles.onRead}
      editTitle={titles.onEdit}
      idForm={contactId ? `consents_details_${contactId}` : null}
      isSuccess={isSuccess}
    >
      <div className={styles.rowsWrapper}>
        <div className={styles.rowWrapper}>
          <InputLabel text='Marketing Email' />
          <CheckboxInput
            isDisabled={!isEditable || !fields.marketing_email?.edit}
            isChecked={data?.marketing_email}
            changeHandler={value => handleChangeInput('marketing_email', value)}
          />
        </div>{' '}
        <div className={styles.rowWrapper}>
          <InputLabel text='Marketing Post' />
          <CheckboxInput
            isDisabled={!isEditable || !fields.marketing_post?.edit}
            isChecked={data?.marketing_post}
            changeHandler={value => handleChangeInput('marketing_post', value)}
          />
        </div>
      </div>
    </FormWrapper>
  )
}

ContactConsentsForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  contactId: PropTypes.number,
}

ContactConsentsForm.defaultProps = {
  permissions: [],
  contact_id: null,
}

import React, { useEffect, useState, useRef } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { useError, useToggle } from '@Root/hooks'
import { useSelector } from 'react-redux'
import { nanoid } from '@Root/../node_modules/nanoid/index'

const titles = { onRead: 'Supervisor Allocation', onEdit: 'Edit Supervisor Allocation', onCreate: 'Create  Supervisor Allocation' }
export const SupervisorAllocationForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  onOpenDescriptionModal,
  errorsFields,
}) => {
  const [data, setData] = useState({})
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).person.hesa_details.student_course_sessions
  const [customCode, setCustomCode] = useState({ label: '', value: '' })
  const [hesaIdOptions, setHesaIdOptions] = useState([])
  const { errors, setError, onClear: onClearError } = useError()
  const memoData = useRef({})

  useEffect(() => {
    initData(initialValue)
    if (!memoData.current?.proportion) memoData.current = initialValue
  }, [initialValue, options])

  const initData = initData => {
    if (options?.hesa_identifiers) {
      if (options.hesa_identifiers.find(el => el.value === initData.hesa_identifier) || initData.hesa_identifier === null) {
        setHesaIdOptions([...options.hesa_identifiers])
        setData(initData)
      } else {
        const id = nanoid()
        setHesaIdOptions([...options.hesa_identifiers, { label: initData.hesa_identifier, value: id }])
        setData({ ...initData, hesa_identifier: id })
      }
    }
  }

  useEffect(() => {
    if (errorsFields['proportion']) {
      const error = errorsFields['proportion']
      setError('proportion', error[0])
    }
    if (errorsFields['supervisor_identifier']) {
      const error = errorsFields['supervisor_identifier']
      setError('supervisor_identifier', error[0])
    }
  }, [errorsFields])

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      onClearError()
      memoData.current = initialValue
    }
  }, [isSuccess])

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const validateForm = () => {
    const { proportion, supervisor_identifier } = data
    if (!proportion) {
      setError('proportion', 'Required')
      return false
    }
    if (!supervisor_identifier) {
      setError('supervisor_identifier', 'Required')
      return false
    }

    return true
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    if (options.hesa_identifiers.find(el => el.value === data.hesa_identifier) || data.hesa_identifier === null) {
      onSave(data)
    } else {
      onSave({ ...data, hesa_identifier: hesaIdOptions.find(el => el.value === data.hesa_identifier).label })
      setCustomCode({ label: '', value: '' })
    }
  }

  const onCancelEdit = () => {
    if (!isNew) {
      toggleEditable()
      onClearError()
      initData(memoData.current)
      onCancel(memoData.current)
    } else {
      onCancel()
    }
  }

  const onChangeCustomCode = field => value => {
    setCustomCode({ [field]: value, value: nanoid() })
  }

  const onAddCustomCode = () => {
    setData(prevState => ({ ...prevState, hesa_identifier: customCode.value }))
    setHesaIdOptions([...hesaIdOptions, customCode])
    setCustomCode({ label: '', value: '' })
  }

  const { onEdit, onRead, onCreate } = titles
  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this Supervisor Allocation' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onChangeMode}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={data?.id ? `supervisor_allocation_${data?.id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Supervisor allocation identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.supervisor_identifier}
                onChange={onChangeValue('supervisor_identifier')}
                placeholder='Supervisor allocation identifier'
                isDisabled={!isEditable}
                description={descriptions?.supervisor_identifier}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                maxLength={50}
                isRequired={isEditable}
                error={errors.supervisor_identifier}
              />
              <Field
                label={'HESA identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.hesa_identifier}
                onChange={onChangeValue('hesa_identifier')}
                placeholder='HESA identifier'
                isDisabled={!isEditable}
                options={hesaIdOptions}
                description={descriptions?.supervisor_hesa_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                optionsStyle={{ overflow: 'inherit' }}
                customComponents={
                  <Field
                    style={{ width: 230, border: 'none', height: 36 }}
                    value={customCode.label}
                    onChange={onChangeCustomCode('label')}
                    placeholder='Set custom code'
                    isDisabled={!isEditable}
                    field={'textField'}
                    maxLength={8}
                    autoFocus
                  />
                }
                callBack={onAddCustomCode}
              />
              <Field
                label={'REF2021 unit of assessment'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.ref2021_unit_of_assessment_id}
                onChange={onChangeValue('ref2021_unit_of_assessment_id')}
                placeholder={'REF2021 unit of assessment'}
                isDisabled={!isEditable}
                options={options.ref2021_unit_assessments}
                description={descriptions?.ref2021_unit_of_assessment_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
              />
              <Field
                label={'Supervisor allocation proportion'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.proportion}
                onChange={onChangeValue('proportion')}
                placeholder='Supervisor allocation proportion'
                isDisabled={!isEditable}
                description={descriptions?.proportion}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                maxLength={4}
                isRequired={isEditable}
                error={errors.proportion}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

export const TOGGLE_LOADING = 'module/costCenter/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'module/costCenter/TOGGLE_SUCCESS'
export const GET_COST_CENTER = 'module/costCenter/GET_COST_CENTER'
export const SET_COST_CENTER = 'module/costCenter/SET_COST_CENTER'
export const CREATE_COST_CENTER = 'module/costCenter/CREATE_COST_CENTER'
export const EDIT_COST_CENTER = 'module/costCenter/EDIT_COST_CENTER'
export const DELETE_COST_CENTER = 'module/costCenter/DELETE_COST_CENTER'
export const SET_OPTIONS = 'module/costCenter/SET_OPTIONS'
export const GET_STATIC_OPTIONS = 'module/costCenter/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'module/costCenter/GET_DYNAMIC_OPTIONS'
export const SET_PERMISSIONS = 'module/costCenter/SET_PERMISSIONS'
export const CLEAR_FORM = 'modules/costCenter/CLEAR_FORM'

import React from 'react'
import { ModuleInstanceForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { moduleInstancesSelectors, moduleInstancesActions } from '@Store/studies'
import { hesaSelectors } from '@Store/contact/person'

export const CreateModuleInstance = ({ programmeRecord, onOpenDescriptionModal, handleBack }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { contactId, studyProgrammeId } = useParams()
  const moduleInstances = useSelector(moduleInstancesSelectors.moduleInstances)
  const options = useSelector(moduleInstancesSelectors.options)
  const errors = useSelector(moduleInstancesSelectors.errors)
  const isLoading = useSelector(moduleInstancesSelectors.isLoading)
  const dynamicOptions = useSelector(hesaSelectors.options).dynamic
  const onCreate = data => {
    dispatch(moduleInstancesActions.create({ data, history, studentCourseSessionId: programmeRecord.student_course_session_id, studyProgrammeId, contactId }))
  }

  const onCancel = () => {
    handleBack()
  }

  return (
    <ModuleInstanceForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onCreate}
      onCancel={onCancel}
      canEdit={true}
      isNew
      initialValue={moduleInstances}
      options={options}
      isLoading={isLoading}
      dynamicOptions={dynamicOptions}
      errorsFields={errors}
    />
  )
}

import { combineReducers } from 'redux'
import { reducer } from './programme.reducer'

import { courseInitiativeReducer } from './courseInitiative'
import { courseReferenceReducer } from './courseReference'
import { courseRoleReducer } from './courseRole'
import { curriculumAccreditationReducer } from './curriculumAccreditation'

import * as programmeSelectors from './programme.selectors'
export { actions as programmeActions } from './programme.actions'
export { rootSaga as programmeRootSaga } from './programme.saga'
export { programmeSelectors }

export * from './courseInitiative'
export * from './courseReference'
export * from './courseRole'
export * from './curriculumAccreditation'

export const programmeReducer = combineReducers({
	reducer,
	courseInitiative: courseInitiativeReducer,
	courseReference: courseReferenceReducer,
	courseRole: courseRoleReducer,
	curriculumAccreditation: curriculumAccreditationReducer,
})

import React, { useEffect } from 'react'
import { StudyLocationForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { studyLocationSelectors, studyLocationActions } from '@Store/studies'
import { hesaSelectors } from '@Store/contact/person'

export const EditStudyLocation = ({ onOpenDescriptionModal, programmeRecord }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { studyProgrammeId, contactId, studyLocationId } = useParams()
  const studyLocation = useSelector(studyLocationSelectors.studyLocation)
  const isSuccess = useSelector(studyLocationSelectors.isSuccess)
  const isLoading = useSelector(studyLocationSelectors.isLoading)
  const options = useSelector(studyLocationSelectors.options)
  const errors = useSelector(studyLocationSelectors.errors)
  const permissions = useSelector(studyLocationSelectors.permissions)
  const dynamicOptions = useSelector(hesaSelectors.options).dynamic

  useEffect(() => {
    if (!programmeRecord.student_course_session_id) return
    dispatch(
      studyLocationActions.get({
        data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, studyLocationId },
        history,
      })
    )
  }, [programmeRecord])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This Study Location will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(
          studyLocationActions.delete({
            data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, studyLocationId },
            history,
          })
        )
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = data => {
    dispatch(studyLocationActions.edit({ data, studentCourseSessionId: programmeRecord.student_course_session_id, studyLocationId }))
  }

  const onCancel = memoData => {
    dispatch(studyLocationActions.set(memoData))
    dispatch(studyLocationActions.setErrors({}))
  }

  return (
    <StudyLocationForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onSave}
      onDelete={onDelete}
      onCancel={onCancel}
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      errorsFields={errors}
      initialValue={studyLocation}
      isLoading={isLoading}
      isSuccess={isSuccess}
      options={options}
      dynamicOptions={dynamicOptions}
    />
  )
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './instance.types'
import { actions } from './instance.actions'
import { API } from '@Root/API'
import { errorMessage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* getStaticOptions() {
	try {
		const {
			data: { modules_counts, modules_outcomes, modules_results, inactive_module_flags, accreditation_of_priors },
		} = yield call(API.studies.module.instance.options.getStatic)
		const mapToValueAndLabel = arr => {
			return arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
		}
		const formattedData = {
			module_counts: mapToValueAndLabel(modules_counts),
			module_outcomes: mapToValueAndLabel(modules_outcomes),
			module_results: mapToValueAndLabel(modules_results),
			inactive_module_flags: mapToValueAndLabel(inactive_module_flags),
			accreditation_of_priors: mapToValueAndLabel(accreditation_of_priors),
		}
		yield put(
			actions.setOptions({
				name: 'static',
				data: formattedData,
			})
		)
	} catch (error) {
		yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
	}
}

export function* rootSaga() {
	yield all([takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions)])
}

import { useEffect, useState } from 'react'
import classes from './style.module.scss'
import closeIcon from '../../../assets/icons/close.png'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { CustomScrollbar } from '@Root/HOCs'
import { Preloader } from '@Root/components'

export const ModalProcessedRollover = ({ text, headerText, clickRejectButtonHandler, clickResolveButtonHandler, handler, param, saveData = {} }) => {
  const [list, setList] = useState([])

  useEffect(() => {
    ; (async function () {
      try {
        const { data } = await handler(param)
        //this logic only for Rollover Continuing Students
        if (data.data.length > 0) {
          setList(data.data)
        } else if (Object.keys(saveData).length > 0) {
          clickResolveButtonHandler(saveData)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <h3 className={classes.headerText}>{headerText}</h3>
        <div className={classes.icon} onClick={clickRejectButtonHandler}>
          <img src={closeIcon} alt='' />
        </div>
      </div>
      <div className={classes.line} />
      {list.length === 0 ? (
        <div className={classes.contentList}>
          <Preloader />
        </div>
      ) : (
        <>
          <div className={classes.contentText}>{text}</div>
          <div className={classes.contentList}>
            <ul className={classes.listOfStudent}>
              <CustomScrollbar verticalOnly>
                {list.map(el => (
                  <li>
                    {' '}
                    <Link to={el.number_link} className={classes.itemStudent} key={el.name} onClick={clickRejectButtonHandler}>
                      <p>ID: {el.id}</p>
                      <p>Name: {el.name}</p>
                    </Link>{' '}
                  </li>
                ))}
              </CustomScrollbar>
            </ul>

          </div>

          <div className={classes.line} />
          <div className={classes.buttons}>
            <button className={classes.buttonLeft} onClick={clickRejectButtonHandler}>
              No
            </button>
            <button className={classes.buttonRight} onClick={() => clickResolveButtonHandler(saveData)}>
              Yes
            </button>
          </div>
        </>
      )}
    </div>
  )
}

ModalProcessedRollover.propTypes = {
  headerText: PropTypes.string,
  handler: PropTypes.func,
  text: PropTypes.string,
  clickRejectButtonHandler: PropTypes.func,
  clickResolveButtonHandler: PropTypes.func,
}

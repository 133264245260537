import * as types from './courseRole.types'

export const actions = {
  toggleLoading: payload => ({
    type: types.TOGGLE_LOADING,
    payload,
  }),
  toggleSuccess: payload => ({
    type: types.TOGGLE_SUCCESS,
    payload,
  }),
  set: payload => ({
    type: types.SET_COURSE_ROLE,
    payload,
  }),
  create: payload => ({
    type: types.CREATE_COURSE_ROLE,
    payload,
  }),
  get: payload => ({
    type: types.GET_COURSE_ROLE,
    payload,
  }),
  edit: payload => ({
    type: types.EDIT_COURSE_ROLE,
    payload,
  }),
  delete: payload => ({
    type: types.DELETE_COURSE_ROLE,
    payload,
  }),
  getOptions: () => ({
    type: types.GET_OPTIONS,
  }),
  setOptions: payload => ({
    type: types.SET_OPTIONS,
    payload,
  }),
  clearData: () => ({
    type: types.CLEAR_DATA,
  }),
  setPermissions: payload => ({
    type: types.SET_PERMISSIONS,
    payload,
  }),
  setErrors: payload => ({
    type: types.SET_ERRORS,
    payload,
  }),
}

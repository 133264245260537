import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import { switchEmptyStringsToNullsInObject } from '@Root/helpers'
import { FormWrapper } from '@Root/HOCs'
import { Select, InputLabel, TextInput } from '@Root/components'
import { EmbeddedForm } from './EmbeddedForm'
import { useToggle } from '@Root/hooks'
import { nanoid } from '@Root/../node_modules/nanoid/index'

const titles = { onRead: 'Qualification', onEdit: 'Edit Qualification', onCreate: 'Create Qualification' }
export const QualificationForm = ({
  canEdit,
  isNew,
  options,
  initialValue,
  onDelete,
  onSave,
  onOpenDescriptionModal,
  descriptions,
  isLoading,
  canDelete,
  isSuccess,
}) => {
  const [isEditable, toggleEditable] = useToggle(isNew)
  const [data, setData] = useState({})
  const [error, setError] = useState(null)
  const [customCode, setCustomCode] = useState([])
  const [awardingBodyOptions, setAwardingBodyOptions] = useState([])

  //init data operation
  useEffect(() => {
    if (initialValue && initialValue.awarding_body_roles.length !== customCode.length) {
      let arr = []
      for (let index = 0; index < initialValue.awarding_body_roles.length; index++) {
        arr.push({ label: '', value: '' })
      }
      setCustomCode([...arr])
    }

    if (initialValue && options?.awarding_body_role?.awarding_body) {
      const awardingBodyOptionsCopy = JSON.parse(JSON.stringify(options?.awarding_body_role?.awarding_body))
      const initialValueCopy = JSON.parse(JSON.stringify(initialValue))

      initialValueCopy.awarding_body_roles = initialValueCopy.awarding_body_roles.map(el => {
        if (awardingBodyOptionsCopy.find(option => option.value === el.awarding_body_identifier)) {
          return el
        } else {
          const id = nanoid()
          awardingBodyOptionsCopy.push({ value: id, label: el.awarding_body_identifier })
          return { ...el, awarding_body_identifier: id }
        }
      })

      setData(initialValueCopy)
      setAwardingBodyOptions(awardingBodyOptionsCopy)
    }
  }, [initialValue, options?.awarding_body_role])

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess])

  const awardingBodyRoleConfig = {
    path: 'awarding_body_roles',
    inputs: [
      {
        label: 'Awarding Body Identifier',
        field: 'awarding_body_identifier',
        type: 'select',
        options: awardingBodyOptions,
        validations: ['required'],
      },
    ],
    emptyRow: {
      awarding_body_identifier: null,
    },
  }

  const qualificationSubjectConfig = {
    path: 'qualification_subjects',
    inputs: [
      {
        label: 'Qualification subject',
        field: 'qual_subject_id',
        type: 'select',
        options: options?.qualification_subject?.qualification_subject,
        validations: ['required'],
      },
      {
        label: 'Qualification Proportion',
        field: 'qualification_proportion',
        type: 'textInput',
        validations: ['required'],
      },
      {
        label: 'Qualification ITT specialism',
        field: 'itt_specialism',
        type: 'textInput',
        //validations: ['required'],
      },
    ],
    emptyRow: {
      qual_subject_id: null,
      qualification_proportion: null,
      itt_specialism: null,
    },
  }

  const handleAddRow = ({ path, emptyRow }) => {
    const dataCopy = JSON.parse(JSON.stringify(data))
    dataCopy[path].push(emptyRow)
    setData(dataCopy)
    if (path === 'awarding_body_roles') setCustomCode([...customCode, { label: '', value: nanoid() }])
  }

  const handleChangeRowInput = ({ path }, rowIndex, field, value) => {
    const dataCopy = JSON.parse(JSON.stringify(data))
    dataCopy[path][rowIndex][field] = value
    setData(dataCopy)
  }

  const handleDeleteRow = ({ path }, rowIndex) => {
    const dataCopy = JSON.parse(JSON.stringify(data))
    let customCodeDataCopy = JSON.parse(JSON.stringify(customCode))
    dataCopy[path] = dataCopy[path].filter((row, i) => i !== rowIndex)
    setData(dataCopy)
    if (path === 'awarding_body_roles') {
      customCodeDataCopy = customCodeDataCopy.filter((row, i) => i !== rowIndex)
      setCustomCode([...customCodeDataCopy])
    }
  }

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const showError = (path, index, field, message) => setError({ path, index, field, message })

  const embeddedFormIsValid = (path, form) => {
    for (let index = 0; index < form.length; index++) {
      const row = form[index]
      for (const field in row) {
        if (field === 'itt_specialism') return true
        if (!row[field]) {
          showError(path, index, field, 'Required')
          return false
        }
      }
    }
    return true
  }

  const formIsValid = () => {
    const { qualification_category_id, qualification_title, qualification_id, awarding_body_roles, qualification_subjects } = data

    if (!qualification_title) {
      showError(null, null, 'qualification_title', 'Required')
      return false
    }
    if (!qualification_category_id) {
      showError(null, null, 'qualification_category_id', 'Required')
      return false
    }
    if (!qualification_id) {
      showError(null, null, 'qualification_id', 'Required')
      return false
    }
    if (!embeddedFormIsValid('awarding_body_roles', awarding_body_roles)) return false
    if (!embeddedFormIsValid('qualification_subjects', qualification_subjects)) return false
    return true
  }

  const rootErrorMessage = field => (error && !error.path && error.field === field ? error.message : null)

  const embeddedFormError = path => {
    return error && error.path === path ? { index: error.index, field: error.field, message: error.message } : null
  }

  const handleClickCancelButton = () => {
    setData(initialValue)
    setError(null)
    toggleEditable()
  }

  const handleClickDeleteButton = () => {
    onDelete()
  }

  const handleClickSaveButton = () => {
    if (!formIsValid()) return
    const modifiedData = switchEmptyStringsToNullsInObject(data)
    onSave(switchSubmitCustomData(modifiedData))
  }

  const switchSubmitCustomData = data => {
    const switchData = JSON.parse(JSON.stringify(data))
    switchData.awarding_body_roles = switchData.awarding_body_roles.map(el => {
      const awardingBodyOriginOptions = options?.awarding_body_role?.awarding_body
      const response = awardingBodyOriginOptions.find(option => option.value === el.awarding_body_identifier)
      if (response) {
        return el
      } else {
        return { ...el, awarding_body_identifier: awardingBodyOptions.find(option => option.value === el.awarding_body_identifier).label }
      }
    })
    return switchData
  }

  const onChangeCustomCode = (rowIndex, field, value) => {
    const dataCopy = JSON.parse(JSON.stringify(customCode))
    dataCopy[rowIndex][field] = value
    dataCopy[rowIndex].value = nanoid()
    setCustomCode(dataCopy)
  }

  const onAddCustomCode = (i, field) => {
    setAwardingBodyOptions([...awardingBodyOptions, customCode[i]])
    handleChangeRowInput(awardingBodyRoleConfig, i, field, customCode[i].value)
    onChangeCustomCode(i, 'label', '')
  }

  const { awarding_body_roles, qualification_subjects } = data

  const { onRead, onCreate, onEdit } = titles
  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', ...(!isNew ? (canDelete ? ['delete'] : []) : []), 'save'] : []}
      buttonsNames={[{ button: 'delete', name: 'Delete this Qualification' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
      canEdit={canEdit}
      isEditable={isEditable}
      readTitle={onRead}
      editTitle={onEdit}
      createTitle={onCreate}
      clickToggleEditModeButtonHandler={toggleEditable}
      isCreate={isNew}
      idForm={data?.id ? `qualification_${data?.id}` : null}
      isSuccess={isSuccess}
    >
      <div className={classes.content}>
        <div className={classes.rows}>
          <div className={classes.row}>
            <InputLabel
              text='Qualification Title'
              hasAsterisk={isEditable}
              description={descriptions.qualification_title}
              onOpenDescription={onOpenDescriptionModal}
            />
            <TextInput
              classNames={!isEditable ? ['borderless'] : []}
              style={{ position: 'absolute', left: 200, width: 340 }}
              value={data.qualification_title}
              changeHandler={value => handleChangeInput('qualification_title', value)}
              isDisabled={!isEditable}
              error={rootErrorMessage('qualification_title')}
            />
            <InputLabel
              style={{ position: 'absolute', left: 560 }}
              text='Qualification Category'
              hasAsterisk={isEditable}
              description={descriptions.qualification_category}
              onOpenDescription={onOpenDescriptionModal}
            />
            <Select
              style={{ position: 'absolute', left: 760 }}
              inputClassNames={!isEditable ? ['borderless'] : []}
              inputStyle={{ width: 340 }}
              options={options.qualification_category}
              value={data.qualification_category_id}
              changeHandler={value => handleChangeInput('qualification_category_id', value)}
              isDisabled={!isEditable}
              error={rootErrorMessage('qualification_category_id')}
            />
          </div>
          <div className={classes.row}>
            <InputLabel
              text='Qualification identifier'
              hasAsterisk={isEditable}
              description={descriptions.qualification_id}
              onOpenDescription={onOpenDescriptionModal}
            />
            <TextInput
              classNames={!isEditable ? ['borderless'] : []}
              style={{ position: 'absolute', left: 200, width: 340 }}
              value={data.qualification_id}
              changeHandler={value => handleChangeInput('qualification_id', value)}
              isDisabled={!isEditable}
              error={rootErrorMessage('qualification_id')}
              maxLength={50}
            />
          </div>
        </div>
        <div className={classes.embeddedForms}>
          <EmbeddedForm
            isEditable={isEditable}
            title='Qualification Subject'
            data={qualification_subjects}
            inputs={qualificationSubjectConfig.inputs}
            addIsAllowed={qualification_subjects?.length < 5}
            addHandler={() => handleAddRow(qualificationSubjectConfig)}
            changeHandler={(rowIndex, field, value) => handleChangeRowInput(qualificationSubjectConfig, rowIndex, field, value)}
            deleteIsAllowed={qualification_subjects?.length > 1}
            deleteHandler={rowIndex => handleDeleteRow(qualificationSubjectConfig, rowIndex)}
            error={embeddedFormError('qualification_subjects')}
            onOpenDescriptionModal={onOpenDescriptionModal}
            descriptions={descriptions}
          />

          <EmbeddedForm
            isEditable={isEditable}
            title='Awarding Body Role'
            data={awarding_body_roles}
            inputs={awardingBodyRoleConfig.inputs}
            addIsAllowed
            addHandler={() => handleAddRow(awardingBodyRoleConfig)}
            changeHandler={(rowIndex, field, value) => handleChangeRowInput(awardingBodyRoleConfig, rowIndex, field, value)}
            deleteIsAllowed
            deleteHandler={rowIndex => handleDeleteRow(awardingBodyRoleConfig, rowIndex)}
            error={embeddedFormError('awarding_body_roles')}
            onOpenDescriptionModal={onOpenDescriptionModal}
            descriptions={descriptions}
            freeInput={true}
            onChangeCustomCode={(rowIndex, field, value) => onChangeCustomCode(rowIndex, field, value)}
            customCode={customCode}
            callBack={onAddCustomCode}
            optionsStyle={{ overflow: 'inherit' }}
          />
        </div>
      </div>
    </FormWrapper>
  )
}

import React, { useEffect } from 'react'
import { FundingAndMonitoringForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { fundingAndMonitoringSelectors, fundingAndMonitoringActions } from '@Store/studies'

export const EditFundingMonitoring = ({ onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { studyProgrammeId, contactId, fundingMonitoringId } = useParams()
  const fundingAndMonitoring = useSelector(fundingAndMonitoringSelectors.fundingAndMonitoring)
  const isSuccess = useSelector(fundingAndMonitoringSelectors.isSuccess)
  const isLoading = useSelector(fundingAndMonitoringSelectors.isLoading)
  const options = useSelector(fundingAndMonitoringSelectors.options)
  const permissions = useSelector(fundingAndMonitoringSelectors.permissions)

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This funding and monitoring will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        dispatch(fundingAndMonitoringActions.delete({ studyProgrammeId, contactId, history, monitoringId: fundingMonitoringId }))
      })
      .finally(() => dispatch(modalActions.hideModal()))
  }

  const onSave = data => {
    dispatch(fundingAndMonitoringActions.edit({ data }))
  }

  return (
    <FundingAndMonitoringForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onSave}
      onDelete={onDelete}
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      initialValue={fundingAndMonitoring}
      isLoading={isLoading}
      isSuccess={isSuccess}
      options={options}
    />
  )
}

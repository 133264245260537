import React, { useEffect, useState, useRef } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { useToggle } from '@Root/hooks'
import { useSelector } from 'react-redux'
import { useError } from '@Root/hooks'
import { validateData } from '@Root/helpers'

const titles = { onRead: 'Module Instance', onEdit: 'Edit Module Instance', onCreate: 'Create Module Instance' }
export const ModuleInstanceForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  onOpenDescriptionModal,
  dynamicOptions,
  errorsFields,
}) => {
  const [data, setData] = useState({})
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).person.hesa_details.modules_instances
  const { errors, setError, onClear: onClearError } = useError()
  const memoData = useRef({})

  useEffect(() => {
    if (initialValue) setData(initialValue)
    if (!memoData.current?.educational_module_id) memoData.current = initialValue
  }, [initialValue])

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      onClearError()
      memoData.current = initialValue
    }
  }, [isSuccess])

  useEffect(() => {
    if (errorsFields['module_instance_identifier']) {
      const error = errorsFields['module_instance_identifier']
      setError('module_instance_identifier', error[0])
    }
  }, [errorsFields])

  const onChangeValue = field => value => {
    let copyValue = value
    if ('language_percentage' === field) {
      copyValue = value > 100 ? 100 : value
    }
    setData(prevState => ({ ...prevState, [field]: copyValue }))
  }

  const onCancelEditMode = () => {
    onChangeMode()
    setData(memoData.current)
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    onSave(data)
  }

  const validateForm = () => {
    const { educational_module_id, module_instance_identifier, module_start_date, module_end_date } = data

    if (!educational_module_id) {
      setError('educational_module_id', 'Required')
      return false
    }
    if (!module_instance_identifier) {
      setError('module_instance_identifier', 'Required')
      return false
    }
    if (!module_start_date) {
      setError('module_start_date', 'Required')
      return false
    }
    if (validateData([module_start_date, module_end_date])) {
      return false
    }
    onClearError()
    return true
  }

  const onCancelEdit = () => {
    if (!isNew) {
      onCancelEditMode()
      onClearError()
      onCancel(memoData.current)
    } else {
      onCancel()
    }
  }

  const { onEdit, onRead, onCreate } = titles
  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'delete this Module Instance' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onChangeMode}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={data?.id ? `module_instance_${data?.id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Module instance identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.module_instance_identifier}
                onChange={onChangeValue('module_instance_identifier')}
                placeholder='Module instance identifier'
                isDisabled={!isEditable}
                description={descriptions?.module_instance_identifier}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                maxLength={50}
                isRequired={isEditable}
                error={errors.module_instance_identifier}
              />
              <Field
                label={'Accreditation of prior experiential learning'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.accreditation_of_prior_id}
                onChange={onChangeValue('accreditation_of_prior_id')}
                placeholder='Accreditation of prior experiential learning'
                isDisabled={!isEditable}
                options={options.accreditation_prior_experiential_learning}
                description={descriptions?.accreditation_of_prior_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
              />
              <Field
                label={'Continuing module'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.continuing_module_id}
                onChange={onChangeValue('continuing_module_id')}
                placeholder={'Continuing module'}
                isDisabled={!isEditable}
                options={options.continuing_modules}
                description={descriptions?.continuing_module_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
              />
              <Field
                label={'Inactive module flag'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.inactive_module_flag_id}
                onChange={onChangeValue('inactive_module_flag_id')}
                placeholder={'Inactive module flag'}
                isDisabled={!isEditable}
                options={options.inactive_module_flags}
                description={descriptions?.inactive_module_flag_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
              />
              <Field
                label={'Language percentage'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.language_percentage}
                onChange={onChangeValue('language_percentage')}
                placeholder='Language percentage'
                isDisabled={!isEditable}
                description={descriptions?.language_percentage}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                maxLength={4}
                restriction='betweenOneAndHundred'
              />
              <Field
                label={'Module instance fee amount'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.fee_amount}
                onChange={onChangeValue('fee_amount')}
                placeholder='Module instance fee amount'
                isDisabled={!isEditable}
                description={descriptions?.fee_amount}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                maxLength={6}
                restriction='digits'
              />
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Module count'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.module_count_id}
                onChange={onChangeValue('module_count_id')}
                placeholder={'Module count'}
                isDisabled={!isEditable}
                options={options.module_counts}
                description={descriptions?.module_count_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
              />
              <Field
                label={'Module identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.educational_module_id}
                onChange={onChangeValue('educational_module_id')}
                placeholder='Module identifier'
                isDisabled={!isEditable}
                options={dynamicOptions.modules}
                description={descriptions?.educational_module_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isRequired={isEditable}
                error={errors.educational_module_id}
              />
              <Field
                label={'Module instance start date'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.module_start_date}
                onChange={onChangeValue('module_start_date')}
                placeholder='Module instance start date'
                isDisabled={!isEditable}
                description={descriptions?.module_start_date}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'datePicker'}
                isRequired={isEditable}
                error={errors.module_start_date}
              />
              <Field
                label={'Module instance end date'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.module_end_date}
                onChange={onChangeValue('module_end_date')}
                placeholder='Module instance end date'
                isDisabled={!isEditable}
                description={descriptions?.module_end_date}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'datePicker'}
              />
              <Field
                label={'Module outcome'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.module_outcome_id}
                onChange={onChangeValue('module_outcome_id')}
                placeholder={'module_outcomes'}
                isDisabled={!isEditable}
                options={options.module_outcomes}
                description={descriptions?.module_outcome_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
              />
              <Field
                label={'Module result'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.module_result_id}
                onChange={onChangeValue('module_result_id')}
                placeholder={'Module result'}
                isDisabled={!isEditable}
                options={options.module_results}
                description={descriptions?.module_result_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
              />

              <Field
                label={'Include in hesa'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.include_in_hesa}
                onChange={onChangeValue('include_in_hesa')}
                placeholder={'Include in hesa'}
                isDisabled={!isEditable}
                options={options.include_in_hesa}
                description={descriptions?.include_in_hesa}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

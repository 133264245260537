import { createSelector } from 'reselect'

export const isLoading = state => state.contactReducer.person.association.isLoading

export const isSuccess = state => state.contactReducer.person.association.isSuccess

export const association = state => state.contactReducer.person.association.association

export const options = state => state.contactReducer.person.association.options

export const permissions = state => state.contactReducer.person.association.permissions

export const isPermissionsLoaded = createSelector(permissions, permissions => (permissions.fields ? Object.keys(permissions.fields).length > 0 : false))

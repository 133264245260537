import React, { Component } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import { delay } from 'redux-saga'
import { popupHeight, all } from '@Root/helpers'
import { CustomScrollbar, InputError } from '@Root/HOCs'
import { TextInput } from '../TextInput'
import triangle from '../../../assets/icons/triangle.png'
import xIcon from '../../../assets/icons/x.png'
import { Spinner } from '@Root/components'
import { NO_DATA_AVAILABLE } from '@Root/configs'

class List extends Component {
  state = {
    popupIsShown: false,
    label: '',
    filter: '',
    options: [],
    initialOptionIsFetching: false,
    optionsAreFetching: false,
    error: null,
  }

  handleClickOutside = () => {
    this.setState({ popupIsShown: false })
  }

  showError = async error => {
    clearTimeout(this.timeout)
    this.setState({ error })

    // if (!this.props.errorIsInfinite) {
    // 	this.timeout = setTimeout(() => this.setState({ error: null }), 3000)
    // }
  }

  fetchOptions = () => {
    this.timeout = setTimeout(async () => {
      this.setState({ optionsAreFetching: true })
      try {
        const options = (await this.props.fetchOptionsHandler(this.state.filter)) || []
        !this.isUnmounted && this.state.filter && this.setState({ options })
      } catch (error) {
        this.props.errorHandler(error)
      }
      !this.isUnmounted && this.setState({ optionsAreFetching: false })
    }, 1000)
  }

  fetchLabel = async value => {
    const { fetchLabelHandler } = this.props
    this.setState({ initialOptionIsFetching: true })
    try {
      const label = await fetchLabelHandler(value)
      !this.isUnmounted && this.setState({ label })
    } catch (error) {
      this.props.errorHandler(error)
    }
    !this.isUnmounted && this.setState({ initialOptionIsFetching: false })
  }

  async componentDidMount() {
    const { value } = this.props

    if (value) {
      await this.fetchLabel(value)
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { error, value } = this.props

    const { filter } = this.state
    if (error !== prevProps.error) {
      error && this.showError(error)
    }
    if (filter !== prevState.filter) {
      clearTimeout(this.timeout)
      filter ? this.fetchOptions() : this.setState({ options: [], optionsAreFetching: false })
    }
    if ((prevProps.value === null || prevProps.value === '') && !!this.props.value) {
      await this.fetchLabel(value)
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true
    clearTimeout(this.timeout)
  }

  render() {
    const { popupIsShown, label, filter, options, initialOptionIsFetching, optionsAreFetching, error } = this.state
    const { value, searchPlaceholder, changeHandler, inputClassNames, inputStyle, isDisabled, maxVisibleOptionsQuantity, listStyle } = this.props
    const isNotDataAvailable = value === NO_DATA_AVAILABLE.label

    return (
      <div className={classes.DataListAsync}>
        <InputError error={error}>
          <div
            className={`${classes.input} ${isDisabled ? classes.disabled : ''} 
                        ${inputClassNames.reduce((acc, className) => acc + ` ${classes[className]}`, '')}`}
            style={inputStyle}
            onClick={() =>
              all(
                () => !isDisabled && this.setState({ popupIsShown: !popupIsShown, filter: '' }),
                () => this.setState({ error: null }),
                this.fetchOptions()
              )
            }
          >
            {!isNotDataAvailable ? (
              <>{value ? <div className={classes.value}>{label}</div> : <div className={`${classes.value} ${classes.empty}`} />}</>
            ) : (
              <div className={classes.value}>{NO_DATA_AVAILABLE.value}</div>
            )}
            {initialOptionIsFetching && (
              <div className={classes.spinner}>
                <Spinner size='extra-small' color='dark' />
              </div>
            )}
            {!isDisabled && !isNotDataAvailable && (
              <>
                {!initialOptionIsFetching && value && (
                  <div
                    className={classes.xIcon}
                    onClick={event =>
                      all(
                        () => event.stopPropagation(),
                        () => changeHandler(null)
                      )
                    }
                  >
                    <img src={xIcon} alt='' />
                  </div>
                )}
                <div className={classes.icon}>
                  <img style={popupIsShown ? { transform: 'rotate(180deg)' } : null} src={triangle} alt='' />
                </div>
              </>
            )}
          </div>
        </InputError>
        {popupIsShown && (
          <div className={classes.popup} style={listStyle}>
            <div className={classes.search}>
              <TextInput classNames={['transparent']} value={filter} changeHandler={filter => this.setState({ filter })} placeholder={searchPlaceholder} />
              {optionsAreFetching && (
                <div className={classes.spinner}>
                  <Spinner size='extra-small' color='dark' />
                </div>
              )}
            </div>
            <div style={{ height: popupHeight(options.length, maxVisibleOptionsQuantity, 38) }}>
              <CustomScrollbar verticalOnly>
                {options.map((option, i) => (
                  <div
                    className={classes.option}
                    onClick={() =>
                      all(
                        () => changeHandler(option.value),
                        () => this.setState({ label: option.label }),
                        () => this.setState({ popupIsShown: false })
                      )
                    }
                    key={i}
                  >
                    {option.label}
                  </div>
                ))}
              </CustomScrollbar>
            </div>
          </div>
        )}
      </div>
    )
  }
}

List.propTypes = {
  inputClassNames: PropTypes.arrayOf(PropTypes.oneOf(['borderless', 'transparent'])),
  inputStyle: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
  fetchLabelHandler: PropTypes.func,
  fetchOptionsHandler: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  changeHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  maxVisibleOptionsQuantity: PropTypes.number,
  error: PropTypes.string,
  errorHandler: PropTypes.func,
}

List.defaultProps = {
  inputClassNames: [],
  inputStyle: {},
  listStyle: {},
  value: null,
  fetchLabelHandler: () => {},
  fetchOptionsHandler: () => {},
  searchPlaceholder: 'Type to search',
  changeHandler: () => {},
  isDisabled: false,
  maxVisibleOptionsQuantity: 5,
  error: null,
  errorHandler: error => {
    console.log(error)
  },
}

export const DataListAsync = onClickOutside(List)

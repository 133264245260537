import React, { useState, useEffect, useRef } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'

const titles = { onRead: 'Venue', onEdit: 'Edit Venue', onCreate: 'Create Venue' }
export const VenueForm = ({
  canEdit,
  isNew = false,
  initialValue,
  onDelete,
  onSave,
  onOpenDescriptionModal,
  descriptions,
  isLoading,
  onCancel,
  isSuccess,
  canDelete,
  initialErrors,
}) => {
  const [isEditable, toggleEditable] = useToggle(isNew)
  const { errors, setError, onClear: onClearError } = useError()
  const [data, setData] = useState({})
  const memoData = useRef({})

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      memoData.current = initialValue
    }
  }, [isSuccess, toggleEditable])

  useEffect(() => {
    if (initialValue) setData(initialValue)
    if (!memoData.current?.venue_identifier) memoData.current = initialValue
  }, [initialValue])

  useEffect(() => {
    if (initialErrors?.venue_identifier) {
      setError('venue_identifier', initialErrors?.venue_identifier[0])
    }
  }, [initialErrors])

  const onChangeValue = field => value => {
    setData({ ...data, [field]: value })
  }

  const formIsValid = () => {
    const { venue_name, venue_identifier, postcode, venue_ukprn } = data
    if (!venue_name) {
      setError('venue_name', 'Required')
      return false
    }
    if (!venue_identifier) {
      setError('venue_identifier', 'Required')
      return false
    }
    if (!postcode) {
      setError('postcode', 'Required')
      return false
    }
    if (!venue_ukprn) {
      setError('venue_ukprn', 'Required')
      return false
    }
    onClearError()
    return true
  }

  const onCancelEditForm = () => {
    if (!isNew) {
      setData(memoData.current)
      toggleEditable()
      onClearError()
      onCancel(memoData.current)
    } else {
      onCancel()
    }
  }

  const onSubmit = async () => {
    if (!formIsValid()) return
    onSave(data)
  }

  const { venue_name, postcode, venue_identifier, venue_own_identifier, venue_ukprn, venue_id } = data
  const { onRead, onCreate, onEdit } = titles
  return (
    <div className={classes.wrapper}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(!isNew ? (canDelete ? ['delete'] : []) : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this Venue' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEditForm}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmit}
        canEdit={canEdit}
        isEditable={isEditable}
        readTitle={onRead}
        editTitle={onEdit}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={toggleEditable}
        isCreate={isNew}
        idForm={venue_id ? `venue_${venue_id}` : null}
        isSuccess={isSuccess}
      >
        <div className={classes.grid}>
          <div className={classes.column}>
            <Field
              label={'Venue Name'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={venue_name}
              onChange={onChangeValue('venue_name')}
              placeholder='Venue Name'
              isDisabled={!isEditable}
              description={descriptions.venue_name}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
              error={errors.venue_name}
              isRequired={isEditable}
            />
            <Field
              label={'Postcode'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={postcode}
              onChange={onChangeValue('postcode')}
              placeholder='Postcode'
              isDisabled={!isEditable}
              description={descriptions.postcode}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
              error={errors.postcode}
              isRequired={isEditable}
              maxLength={12}
            />
            <Field
              label={'Venue identifier'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={venue_identifier}
              onChange={onChangeValue('venue_identifier')}
              placeholder='Venue identifier'
              isDisabled={!isEditable}
              description={descriptions.venue_identifier}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
              error={errors.venue_identifier}
              isRequired={isEditable}
              maxLength={50}
            />
          </div>
          <div className={classes.column}>
            <Field
              label={'Own Venue identifier'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={venue_own_identifier}
              onChange={onChangeValue('venue_own_identifier')}
              placeholder='Own Venue identifier'
              isDisabled={!isEditable}
              description={descriptions.venue_own_identifier}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
              error={errors.venue_own_identifier}
              maxLength={50}
            />
            <Field
              label={'Venue UKPRN'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={venue_ukprn}
              onChange={onChangeValue('venue_ukprn')}
              placeholder='Venue UKPRN'
              isDisabled={!isEditable}
              description={descriptions.venue_ukprn}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
              error={errors.venue_ukprn}
              isRequired={isEditable}
              maxLength={8}
            />
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

VenueForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  isNew: PropTypes.bool,
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string,
  }),
  initialData: PropTypes.shape({
    venue_name: PropTypes.string,
    venue_identifier: PropTypes.number,
    venue_own_identifier: PropTypes.number,
    postcode: PropTypes.string,
    venue_ukprn: PropTypes.string,
  }),
  buttons: PropTypes.arrayOf(PropTypes.oneOf(['cancel', 'delete', 'save'])),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
}

VenueForm.defaultProps = {
  permissions: [],
  isNew: false,
  title: {
    onRead: '',
    onEdit: '',
  },
  initialData: {
    venue_identifier: null,
    postcode: null,
    venue_name: null,
    venue_identifier: null,
    venue_own_identifier: null,
    venue_ukprn: null,
  },
  buttons: [],
  deleteHandler: () => {},
  saveHandler: () => {},
}

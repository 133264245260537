import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { EntryProfileForm } from '@Root/components'
import { entryProfileActions, entryProfileSelectors } from '@Store/studies'
import { Redirect } from 'react-router-dom'
import { permissionsSelectors } from '@Store/permissions'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const CreateEntryProfile = ({ parentURL, onOpenDescriptionModal }) => {
  const entryProfile = useSelector(entryProfileSelectors.entryProfile)
  const options = useSelector(entryProfileSelectors.options)
  const isLoading = useSelector(entryProfileSelectors.isLoading)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const { id: engagementId, contactId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagement_entry_profile

  const onCreate = data => {
    const payload = { ...data, engagementId }
    dispatch(entryProfileActions.create({ data: payload, history, contactId }))
  }

  const onCancel = () => {
    history.goBack()
  }

  if (!dataCreationPermissions.engagements_sub) return <Redirect to={parentURL} />
  return (
    <div>
      <EntryProfileForm
        onSave={onCreate}
        onCancel={onCancel}
        canEdit={true}
        isNew
        initialValue={entryProfile}
        options={options}
        isLoading={isLoading}
        descriptions={descriptions}
        onOpenDescriptionModal={onOpenDescriptionModal}
      />
    </div>
  )
}

import React from "react";
import styles from "./style.module.scss";
import { WhitePlusIcon } from "@Root/assets/icons";
import PropTypes from "prop-types";

export const RoundPlusButton = ({ style, text, clickHandler }) => {
  return (
    <div className={styles.RoundPlusButton} style={style} onClick={clickHandler}>
      <div className={styles.circle}>
        <img src={WhitePlusIcon} alt="" />
      </div>
      {text && <div className={styles.text}>{text}</div>}
    </div>
  );
};

RoundPlusButton.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string,
  clickHandler: PropTypes.func
};

RoundPlusButton.defaultProps = {
  style: {},
  text: null,
  clickHandler: () => {}
};

import React, { useMemo } from 'react'
import styles from './style.module.scss'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getCurrentLink } from '@Root/helpers'
import DocumentTitle from 'react-document-title'
import { PageTitle, PageNavLinks } from '@Root/components'
import { StudiesTable } from './Table'

import { StudiesNewProgramme, StudiesProgramme } from './programme'
import { StudentCourseSessionDetails } from './studentCourseSession'
import { SubjectPage, ModulePage, CostCenterPage, RolePage } from './module'
import { studiesLinks } from '@Root/configs'
import { EngagementDetails } from './EngagementDetails'
import { useLocation } from 'react-router'
import { generateStyleConfig } from './lib'
import { useSelector } from 'react-redux'
import { permissionsSelectors } from '@Store/permissions'

export const StudiesPage = () => {
  const location = useLocation()
  const currentLink = getCurrentLink(studiesLinks, location)
  const tabsPermissions = useSelector(permissionsSelectors.tabsPermissions)
  const isUserOrModule = location.pathname.split('/')[4]
  //Note: This config exists for overwriting default css styles
  const styleConfig = generateStyleConfig(Boolean(isUserOrModule))

  const filteredPageLinks = useMemo(() => studiesLinks.filter(link => tabsPermissions?.studies?.tabs?.[link.label]), [tabsPermissions])
  const PageInMemo = useMemo(() => () => <StudiesTable styleConfig={styleConfig} />, [styleConfig])
  return (
    <DocumentTitle title={`${currentLink.name} - Studies`}>
      <div className={styles.wrapper}>
        <PageTitle title={currentLink.name} style={styleConfig.pageTitle} />
        <PageNavLinks style={styleConfig.navLinksStyles} root={`/home/studies`} links={filteredPageLinks} />
        <Switch>
          {filteredPageLinks.map((link, i) => (
            <Route exact path={`/home/studies/${link.path}`} component={PageInMemo} key={i} />
          ))}
          <Route exact path='/home/studies/programmes/new' component={StudiesNewProgramme} />
          <Route exact path='/home/studies/modules/new' component={ModulePage} />
          <Route exact path='/home/studies/modules/:moduleId/:moduleType?' component={ModulePage} />
          <Route exact path='/home/studies/modules/:moduleId/module-subject/new' component={SubjectPage} />
          <Route exact path='/home/studies/modules/:moduleId/module-subject/:moduleSubId' component={SubjectPage} />
          <Route exact path='/home/studies/modules/:moduleId/module-delivery/:moduleDelId/module-cost-center/new' component={CostCenterPage} />
          <Route exact path='/home/studies/modules/:moduleId/module-delivery/:moduleDelId/module-cost-center/:moduleCostCentId' component={CostCenterPage} />
          <Route exact path='/home/studies/modules/:moduleId/module-delivery/:moduleDelId/module-delivery-role/new' component={RolePage} />
          <Route exact path='/home/studies/modules/:moduleId/module-delivery/:moduleDelId/module-delivery-role/:moduleRoleId' component={RolePage} />
          <Route exact path='/home/studies/programmes/study/:contactId/:studyProgrammeId/:contactTab?' component={StudentCourseSessionDetails} />
          <Route exact path='/home/studies/programmes/study/:contactId/:studyProgrammeId/:contactTab?/new' component={StudentCourseSessionDetails} />
          <Route exact path='/home/studies/programmes/study/:contactId/:studyProgrammeId/:contactTab?/duplicate' component={StudentCourseSessionDetails} />
          <Route exact path='/home/studies/programmes/study/:contactId/:studyProgrammeId/:contactTab?/:id' component={StudentCourseSessionDetails} />
          <Route exact path='/home/studies/programmes/:programmeId/:contactTab?' render={() => <StudiesProgramme styleConfig={styleConfig} />} />
          <Route exact path='/home/studies/programmes/:programmeId/:contactTab?/new' render={() => <StudiesProgramme styleConfig={styleConfig} />} />
          <Route exact path='/home/studies/programmes/:programmeId/:contactTab?/:id' render={() => <StudiesProgramme styleConfig={styleConfig} />} />
          <Route
            exact
            path='/home/studies/engagements/:contactId/:id/:tab?/:tabId?/:subTab?/:subTabId?/:subTabSub?/:subTabSubId?'
            component={EngagementDetails}
          />
          <Route exact path='/home/studies/engagements/:contactId/new' component={EngagementDetails} />
          <Redirect to='/home/studies/engagements' />
        </Switch>
      </div>
    </DocumentTitle>
  )
}

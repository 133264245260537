import * as types from './rule.types'

const initialState = {
	rules: [],
	isSuccess: false,
	isLoading: false,
}
export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: action.payload,
			}
		case types.SET_RULE:
			return {
				...state,
				rules: action.payload,
			}
		default:
			return state
	}
}

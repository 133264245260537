import { useEffect } from 'react'
import { CustomScrollbar } from '@Root/HOCs/CustomScrollbar/index'
import * as classes from './style.module.scss'
import { CrossIcon } from '@Root/assets/icons'
import { modalActions } from '@Store/modal'
import { useDispatch } from 'react-redux'

export const HistoryList = ({ history, moment, clearHistory, historyPaginate, historyLength }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    document.body.style.overflowY = 'hidden'
    return () => {
      document.body.style.overflowY = 'scroll'
    }
  }, [])

  const onClose = () => {
    dispatch(modalActions.hideModal())
    clearHistory()
  }
  return (
    <>
      <div className={classes.wrapper} onClick={onClose}></div>
      <div className={classes.historyOfChangesModal}>
        <div className={classes.historyOfChangesHeder}>
          <h3 className={classes.tittle}>History of changes</h3>
          <button type='button' className={classes.closeHistoryOfChangesBtn} onClick={onClose}>
            <img src={CrossIcon} alt='Close History of changes' />
          </button>
        </div>

        <CustomScrollbar>
          <ul className={classes.historyOfChangesList}>
            {history.map((el, indx) => (
              <li className={classes.historyOfChangesItem} key={indx}>
                {el?.file_url && (
                  <div className={classes.previewPhoto} style={{ backgroundImage: ` url(${el.file_url})` }}>
                    <p className={classes.fileName}> {el.file_name}</p>
                  </div>
                )}

                <div>
                  <div className={classes.replyHeder}>
                    <h4 className={classes.name}>name: {el.author_name}</h4>
                    <p>
                      <span>{el.action}:</span>
                      {el.created_at}
                    </p>
                  </div>
                  <p className={classes.wrap_message}>
                    {indx === 0 ? `original message:` : 'edit message:'}
                    <span className={classes.massage}>{el.note_text ? el.note_text : el.description}</span>
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </CustomScrollbar>
        {history.length >= 5 && historyLength !== history.length && (
          <button type='button' className={classes.loadMoreBtn} onClick={historyPaginate}>
            Load more
          </button>
        )}
      </div>
    </>
  )
}

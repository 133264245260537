export const addToColection = (notes, response) =>
  response.parent_id === null
    ? [{ ...response, replies: [] }, ...notes]
    : notes.map(note => (note.id === response.parent_id ? { ...note, replies: [...note.replies, { ...response }] } : note))

export const updateColection = (notes, response) =>
  notes.map(note =>
    note.id === response.id || note.id === response.parent_id
      ? response.parent_id
        ? { ...note, replies: note.replies.map(reply => (reply.id === response.id ? { ...reply, ...response } : reply)) }
        : { ...note, ...response, replies: note.replies }
      : note
  )

export const deleteItemInColection = (notes, response, parent_id) =>
  notes.reduce((acc, note) => {
    if (!Array.isArray(response)) {
      if (note.id === response.id) return acc
    } else {
      if (note.id === parent_id) {
        return [...acc, { ...note, replies: response }]
      }
    }
    return [...acc, note]
  }, [])

import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { FormWrapper } from '@Root/HOCs'
import { withRouter } from 'react-router-dom'
import { FileInput, CheckboxInput, MultiDataListAsync, TextArea, Select, TextInput, InputLabel, MultiSelect } from '@Root/components'
import { SubAddressesFormForFields } from '../ContactsPersonForm/SubAddressesFormForFields'
import { generateId, emailIsValid } from '@Root/helpers'

const firstAddress = [
  {
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    city: '',
    postcode: '',
    country_id: '',
    county: '',
    address_type: '',
    other_address_type: '',
    current_address: false,
    id: generateId(),
  },
]
export class Component extends React.Component {
  typeOptions = [
    {
      value: 'learning_venue',
      label: 'Learning Venue',
    },
    {
      value: 'member_institution',
      label: 'Member Institution',
    },
    {
      value: 'both',
      label: 'Both',
    },
  ]
  appealOptions = ['Academic programme', 'Faculty', 'Location', 'Other', 'The community', 'Theological position', 'Vision', 'Worship style']
  mocsOptions = ['Email', 'Phone', 'Post', 'Social media']

  constructor(props) {
    super(props)
    const { initialIsEditable, initialData } = props
    const currentAddressId = initialData?.addresses?.find(address => address.current_address)?.id
    const reservedAddressId = initialData?.addresses ? initialData.addresses[0]?.id : firstAddress[0]?.id
    this.state = {
      isEditable: initialIsEditable,
      error: null,
      isSaving: false,
      photoFile: null,

      photo: initialData?.photo,
      name: initialData?.name,
      contactName: initialData?.contact_name,
      email: initialData?.email,
      second_email: initialData?.second_email,
      relationshipId: initialData?.relationship_id,
      type: initialData?.type,
      telPrimary: initialData?.tel_primary,
      telSecondary: initialData?.tel_secondary,
      telMobile: initialData?.tel_mobile,
      fax: initialData?.fax,
      comment: initialData?.comment,
      contacts_ids: initialData?.contacts_ids,
      trashed: initialData?.trashed,
      institution_ids: initialData?.institution_ids,
      addresses: initialData?.addresses || firstAddress,
      openedItemId: currentAddressId || reservedAddressId,

      poc: '',
      appeal: [],
      mocs: [],
    }
  }

  formIsValid = () => {
    const { name, contactName, email, relationshipId, institution_ids } = this.state
    if (!name) {
      this.showError('name', 'Required')
      return false
    } else if (!contactName) {
      this.showError('contact_name', 'Required')
      return false
    } else if (!email) {
      this.showError('email', 'Required')
      return false
    } else if (!emailIsValid(email)) {
      this.showError('email', 'Invalid email')
      return false
    } else if (!relationshipId) {
      this.showError('relationship_id', 'Required')
      return false
    } else if (!institution_ids) {
      this.showError('institution_ids', 'Required')
      return false
    } else {
      this.setState({ error: null })
      return true
    }
  }

  showError = (input, message) => {
    this.setState({ error: { input, message } })
  }

  errorMessage = input => {
    const { error } = this.state
    return error && error.input === input ? error.message : null
  }

  setInitialState = () => {
    const { initialData } = this.props
    const currentAddressId = initialData.addresses?.find(address => address.current_address)?.id
    const reservedAddressId = initialData.addresses ? initialData.addresses[0]?.id : firstAddress[0]?.id
    this.setState({
      isEditable: false,
      error: null,
      photoFile: null,

      photo: initialData.photo,
      name: initialData.name,
      contactName: initialData.contact_name,
      email: initialData.email,
      second_email: initialData.second_email,
      relationshipId: initialData.relationship_id, // integer
      type: initialData.type,
      telPrimary: initialData.tel_primary,
      telSecondary: initialData.tel_secondary,
      telMobile: initialData.tel_mobile,
      fax: initialData.fax,
      comment: initialData.comment,
      contacts_ids: initialData.contacts_ids,
      trashed: initialData.trashed,
      institution_ids: initialData.institution_ids,
      addresses: initialData.addresses || firstAddress,
      openedItemId: currentAddressId || reservedAddressId,

      poc: '',
      appeal: [],
      mocs: [],
    })
  }

  fetchContactLabel = async value => {
    const { data } = await this.props.fetchContactHandler({ search: `id:${value}`, limit: '1' })
    return data?.data[0]?.name
  }

  fetchContactOptions = async value => {
    const { data } = await this.props.fetchContactHandler({ search: `name:${value};trashed:No`, searchJoin: 'and', limit: '100' })
    return data.data.map(option => ({ value: option.id, label: option.name }))
  }

  handleClickCancelButton = () => {
    const { isNew, onCancel } = this.props
    if (!isNew) {
      this.setInitialState()
      return
    }
    onCancel()
  }

  changeOpenTab = id => {
    this.setState(prevState => ({ ...prevState, openedItemId: id }))
  }

  handleClickDeleteButton = () => {
    this.props.deleteHandler(
      () => {
        this.setState({ isSaving: true })
      },
      () => {
        this.setState({ isSaving: false })
      }
    )
  }

  handlerChangeAddress = (id, field, value) => {
    this.setState(prevState => ({ ...prevState, addresses: [{ ...prevState.addresses[0], [field]: value }] }))
  }

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return
    const { state } = this
    const { initialData, saveHandler } = this.props
    const photoWasDeleted = initialData.photo !== null && this.state.photo === null
    saveHandler(
      {
        photo: state.photoFile || '',
        name: state.name,
        contact_name: state.contactName,
        email: state.email,
        second_email: state.second_email || '',
        relationship_id: state.relationshipId,
        type: state.type || '',
        tel_primary: state.telPrimary || '',
        tel_secondary: state.telSecondary || '',
        tel_mobile: state.telMobile || '',
        fax: state.fax || '',
        comment: state.comment || '',
        contacts_ids: state.contacts_ids || '',
        institution_ids: state.institution_ids || '',
        trashed: state.trashed,
        addresses: this.state.addresses,
      },
      photoWasDeleted
    )
  }

  componentDidUpdate(prevProps) {
    const {
      initialData: { id, relationship_id },
    } = this.props
    if (!prevProps.initialData.id && id) this.setInitialState()
    if (prevProps.initialData.relationship_id !== relationship_id) {
      this.setState({ relationshipId: relationship_id })
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {
      error: { errorName, errorMessage },
      isSuccess,
    } = nextProps
    if (errorName && errorName && errorName !== this.state.error?.input) {
      this.showError(errorName, errorMessage)
    }
    if (isSuccess) this.setState({ isEditable: false })
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }

  showNotificationModal = async (text, fieldName, value = null) => {
    const { hideModal, showModal } = this.props
    await new Promise((resolve, reject) => {
      showModal('ConfirmationModal', {
        text: text,
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve,
      })
    }).then(
      () => {
        hideModal()
        if (value !== null) {
          this.setState({ [fieldName]: value })
        }
      },
      () => {
        hideModal()
        if (value !== null) {
          this.setState({ [fieldName]: !value })
        }
      }
    )
  }

  onOpenDescriptionModal = (description, title) => {
    const { openDescription } = this.props
    openDescription(description, title)
  }

  render() {
    const {
      isEditable,
      photoFile,
      photo,
      name,
      contactName,
      email,
      second_email,
      relationshipId,
      type,
      telPrimary,
      telSecondary,
      telMobile,
      fax,
      comment,
      contacts_ids,
      trashed,
      openedItemId,
      addresses,
      institution_ids,
    } = this.state
    const {
      title,
      options,
      organisationRelationshipsDataList,
      descriptionsTrainingFacilities,
      buttons,
      isNew,
      isSaving,
      descriptionsOrganisation,
      canEdit,
      permissions,
      isSuccess,
      is_trashed,
    } = this.props

    const {
      typeOptions,
      fetchContactLabel,
      fetchContactOptions,
      handleClickCancelButton,
      handleClickDeleteButton,
      handleClickSaveButton,
      errorMessage,
      onOpenDescriptionModal,
    } = this
    const { contactId } = this.props.match.params

    const { onRead: titleOnRead = '', onEdit: titleOnEdit = '', onCreate: titleOnCreate = '' } = title
    return (
      <div className={classes.ContactsOrganisationForm}>
        <FormWrapper
          buttons={isEditable ? buttons : []}
          buttonsNames={[{ button: 'delete', name: 'Delete this contact' }]}
          buttonsAreDisabled={isSaving}
          isSpinning={isSaving}
          clickCancelButtonHandler={handleClickCancelButton}
          clickDeleteButtonHandler={handleClickDeleteButton}
          clickSaveButtonHandler={handleClickSaveButton}
          isEditable={isEditable}
          canEdit={canEdit}
          editTitle={titleOnEdit}
          readTitle={titleOnRead}
          createTitle={titleOnCreate}
          clickToggleEditModeButtonHandler={() => this.setState({ isEditable: true })}
          idForm={contactId ? `organisation_form_${contactId}` : null}
          isSuccess={isSuccess}
        >
          <div className={classes.contentWrapper}>
            <FileInput
              inputClassNames={!isEditable ? ['borderless'] : []}
              value={photo}
              file={photoFile}
              changeHandler={(photo, photoFile) => this.setState({ photo, photoFile })}
              removeHandler={() => this.setState({ photo: null, photoFile: null })}
              isDisabled={!isEditable || !permissions.photo?.edit}
              restrictions={['image']}
              error={errorMessage('photo')}
            />
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text='Name' hasAsterisk={isEditable} description={descriptionsOrganisation.name} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={name}
                  changeHandler={name => this.setState({ name })}
                  isDisabled={!isEditable || !permissions.name?.edit}
                  error={errorMessage('name')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel
                  text='Contact Name'
                  hasAsterisk={isEditable}
                  description={descriptionsOrganisation.contact_name}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={contactName}
                  changeHandler={contactName => this.setState({ contactName })}
                  isDisabled={!isEditable || !permissions.contact_name?.edit}
                  error={errorMessage('contact_name')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Email' hasAsterisk={isEditable} description={descriptionsOrganisation.email} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={email}
                  changeHandler={email => this.setState({ email })}
                  isDisabled={!isEditable || !permissions.email?.edit}
                  error={errorMessage('email')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Second Email' description={descriptionsOrganisation.second_email} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={second_email}
                  changeHandler={second_email => this.setState({ second_email })}
                  error={errorMessage('second_email')}
                  isDisabled={!isEditable || !permissions.second_email?.edit}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel
                  text='Relationship'
                  hasAsterisk={isEditable}
                  description={descriptionsOrganisation.relationship_ids}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={organisationRelationshipsDataList}
                  value={relationshipId}
                  changeHandler={relationshipId =>
                    this.setState({ relationshipId }, () => {
                      const { relationshipId } = this.state
                      this.setState({ type: relationshipId === 10 ? 'no' : null })
                    })
                  }
                  isDisabled={!isEditable || !permissions.relationship_id?.edit}
                  error={errorMessage('relationship_id')}
                />
              </div>
              {!isNew && is_trashed && (
                <div className={classes.inputWrapper}>
                  <InputLabel text='Trashed' description={descriptionsOrganisation.trashed} onOpenDescription={onOpenDescriptionModal} />
                  <CheckboxInput
                    style={{ height: 38 }}
                    isChecked={trashed}
                    changeHandler={trashed => this.setState({ trashed })}
                    isDisabled={!isEditable || !permissions.trashed?.edit}
                  />
                </div>
              )}
              <div className={classes.inputWrapper}>
                <InputLabel text='Type' description={descriptionsOrganisation.type} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={typeOptions}
                  value={type}
                  changeHandler={value => this.setState({ type: value })}
                  isDisabled={!isEditable || !permissions.type?.edit}
                  error={errorMessage('type')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Tel - Primary' description={descriptionsOrganisation.tel_primary} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={telPrimary}
                  changeHandler={telPrimary => this.setState({ telPrimary })}
                  restriction='digits'
                  isDisabled={!isEditable || !permissions.tel_primary?.edit}
                  error={errorMessage('tel_primary')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Tel - Secondary' description={descriptionsOrganisation.tel_secondary} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={telSecondary}
                  changeHandler={telSecondary => this.setState({ telSecondary })}
                  restriction='digits'
                  isDisabled={!isEditable || !permissions.tel_secondary?.edit}
                  error={errorMessage('tel_secondary')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Tel - Mobile' description={descriptionsOrganisation.tel_mobile} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={telMobile}
                  changeHandler={telMobile => this.setState({ telMobile })}
                  restriction='digits'
                  isDisabled={!isEditable || !permissions.tel_mobile?.edit}
                  error={errorMessage('tel_mobile')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Fax' description={descriptionsOrganisation.fax} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={fax}
                  changeHandler={fax => this.setState({ fax })}
                  isDisabled={!isEditable || !permissions.fax?.edit}
                  error={errorMessage('fax')}
                />
              </div>
              <div className={classes.noteWrapper}>
                <InputLabel text='Comment' description={descriptionsOrganisation.comment} onOpenDescription={onOpenDescriptionModal} />
                <TextArea
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={comment ? comment : ''}
                  changeHandler={comment => this.setState({ comment })}
                  isDisabled={!isEditable || !permissions.comment?.edit}
                  error={errorMessage('comment')}
                />
              </div>
              <div className={classes.contactsWrapper}>
                <InputLabel text='Contacts' description={descriptionsOrganisation.contacts} onOpenDescription={onOpenDescriptionModal} />
                <MultiDataListAsync
                  isOrganisations
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260, minHeight: '38px' }}
                  values={contacts_ids}
                  fetchLabelHandler={value => fetchContactLabel(value)}
                  fetchOptionsHandler={value => fetchContactOptions(value)}
                  changeHandler={contacts_ids => this.setState({ contacts_ids })}
                  isDisabled={!isEditable || !permissions.contacts_ids?.edit}
                />
              </div>
            </div>
            <div className={classes.columnWrapper} style={{ width: '400px' }}>
              <SubAddressesFormForFields
                countryOptions={options.countries}
                contactAddressesType={options.contact_address_type}
                changeHandler={this.handlerChangeAddress}
                openedId={openedItemId}
                changeOpenTab={this.changeOpenTab}
                errorMessage={errorMessage}
                isEditable={isEditable}
                permissions={permissions.address}
                addresses={addresses}
                onCreateNewAddressField={this.onCreateNewAddressField}
                onRemoveAddressField={this.onRemoveAddressField}
                error={errorMessage('addresses')}
                descriptions={descriptionsOrganisation.address}
                onOpenDescription={onOpenDescriptionModal}
              />
              <div className={classes.inputWrapper}>
                <InputLabel
                  text='Institution'
                  hasAsterisk={isEditable}
                  description={descriptionsTrainingFacilities.institution_ids}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <MultiSelect
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options.institutions}
                  values={institution_ids}
                  changeHandler={institution_ids => this.setState({ institution_ids })}
                  isDisabled={!isEditable || !permissions.institution_ids?.edit}
                  error={errorMessage('institution_ids')}
                />
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    )
  }
}

export const ContactsOrganisationForm = withRouter(Component)

ContactsOrganisationForm.propTypes = {
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string,
  }),
  contactIsSaving: PropTypes.bool,
  initialData: PropTypes.shape({
    photo: PropTypes.string,
    name: PropTypes.string,
    contact_name: PropTypes.string,
    email: PropTypes.string,
    relationship_id: PropTypes.number,
    type: PropTypes.oneOf(['learning_venue', 'member_institution', 'both']),
    tel_primary: PropTypes.string,
    tel_secondary: PropTypes.string,
    tel_mobile: PropTypes.string,
    fax: PropTypes.string,
    comment: PropTypes.string,
    contacts_ids: PropTypes.arrayOf(PropTypes.number),
    trashed: PropTypes.bool,
  }),
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialIsEditable: PropTypes.bool,
  countriesDataList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  organisationRelationshipsDataList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  buttons: PropTypes.arrayOf(PropTypes.oneOf(['cancel', 'delete', 'save'])),
  fetchContactHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func,
}

ContactsOrganisationForm.defaultProps = {
  title: {
    onRead: '',
    onEdit: '',
  },
  initialData: {
    photo: '',
    name: '',
    contact_name: '',
    email: '',
    relationship_id: null,
    type: null,
    address: '',
    tel_primary: '',
    tel_secondary: '',
    tel_mobile: '',
    fax: '',
    comment: '',
    contacts_ids: null,
    trashed: false,
  },
  permissions: [],
  initialIsEditable: false,
  countriesDataList: [],
  organisationRelationshipsDataList: [],
  buttons: ['cancel', 'delete', 'save'],
  fetchContactHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {},
  openDescription: () => {},
}

import * as types from './rule.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	createRule: payload => ({
		type: types.CREATE_RULE,
		payload,
	}),
	editRule: payload => ({
		type: types.EDIT_RULE,
		payload,
	}),
	deleteRule: payload => ({
		type: types.DELETE_RULE,
		payload,
	}),
	setRule: payload => ({
		type: types.SET_RULE,
		payload,
	}),
}

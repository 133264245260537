import { replaceNullValueInObj } from './replaceNullValueInObj'
import { validateCustomIdToEmpty } from './validateCustomIdToEmpty'

export const validationStudentData = payload => {
	const data = replaceNullValueInObj(payload)
	return (array, fieldName) => {
		const newArray = validateCustomIdToEmpty(array)
		newArray.forEach(address => replaceNullValueInObj(address))
		return { ...data, [fieldName]: newArray }
	}
}

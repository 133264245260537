import * as types from './sessionStatus.types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	sessionStatus: {
		status_valid_from: null,
		status_change_id: null,
	},
	options: {},
	canBeCreatedSessionStatus: false,
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.SET_SESSIONSTATUS:
			return {
				...state,
				sessionStatus: payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: payload,
			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: payload,
			}
		case types.CLEAR_DATA:
			return {
				...state,
				sessionStatus: initialState.sessionStatus,
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: payload,
			}
		case types.SET_CAN_BE_CREATED:
			return {
				...state,
				canBeCreatedSessionStatus: payload,
			}
		default:
			return state
	}
}

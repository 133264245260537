import { combineReducers } from 'redux'
import { academicReducer } from './academic'
import { reducer } from './person.reducer'
import { clericReducer } from './cleric'
import { staffReducer } from './staff'
import { studentReducer } from './student'
import { associationReducer } from './association'
import * as personSelectors from './person.selectors'

export { actions as personContactActions } from './person.actions'
export { rootSaga as personContactRootSaga } from './person.saga'
export { personSelectors }

export * from './association'
export * from './academic'
export * from './cleric'
export * from './staff'
export * from './student'

export const personReducer = combineReducers({
	reducer,
	academic: academicReducer,
	cleric: clericReducer,
	staff: staffReducer,
	student: studentReducer,
	association: associationReducer,
})

import React, { useEffect } from 'react'
import { CollaborativeProvisionForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { collaborationProvisionActions, collaborationProvisionSelectors } from '@Store/studies'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const EditCollaborativeProvision = ({ parentURL, onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: engagementId, contactId, collaborationId: collaborativeProvisionId } = useParams()
  const collaborativeProvision = useSelector(collaborationProvisionSelectors.collaborativeProvision)
  const permissions = useSelector(collaborationProvisionSelectors.permissions)
  const isSuccess = useSelector(collaborationProvisionSelectors.isSuccess)
  const isLoading = useSelector(collaborationProvisionSelectors.isLoading)
  const options = useSelector(collaborationProvisionSelectors.options)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagement_collaborative_provision

  useEffect(() => {
    dispatch(collaborationProvisionActions.get({ data: { collaborativeProvisionId, engagementId, contactId }, history }))
    return () => {
      dispatch(collaborationProvisionActions.clearData())
    }
  }, [])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This collaborative provision will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(collaborationProvisionActions.delete({ data: { engagementId, contactId }, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = data => {
    const payload = { ...data, student_id: Number(contactId), number: engagementId }
    dispatch(collaborationProvisionActions.edit({ data: payload, engagementId, contactId }))
  }

  return (
    <div>
      <CollaborativeProvisionForm
        onSave={onSave}
        onDelete={onDelete}
        canEdit={permissions.form?.edit}
        canDelete={permissions.form?.delete}
        initialValue={collaborativeProvision}
        isLoading={isLoading}
        isSuccess={isSuccess}
        options={options}
        descriptions={descriptions}
        onOpenDescriptionModal={onOpenDescriptionModal}
      />
    </div>
  )
}

export const TOGGLE_LOADING = 'studentInitiatives/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'studentInitiatives/TOGGLE_SUCCESS'
export const GET_STUDENT_INITIATIVES = 'studentInitiatives/GET_STUDENT_INITIATIVES'
export const SET_STUDENT_INITIATIVES = 'studentInitiatives/SET_STUDENT_INITIATIVES'
export const CREATE_STUDENT_INITIATIVES = 'studentInitiatives/CREATE_STUDENT_INITIATIVES'
export const DELETE_STUDENT_INITIATIVES = 'studentInitiatives/DELETE_STUDENT_INITIATIVES'
export const EDIT_STUDENT_INITIATIVES = 'studentInitiatives/EDIT_STUDENT_INITIATIVES'
export const GET_OPTIONS = 'studentInitiatives/GET_OPTIONS'
export const SET_OPTIONS = 'studentInitiatives/SET_OPTIONS'
export const CLEAR_DATA = 'studentInitiatives/CLEAR_DATA'
export const SET_PERMISSIONS = 'studentInitiatives/SET_PERMISSIONS'

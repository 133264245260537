export const isLoading = state => state.studiesReducer.engagement.leaver.isLoading

export const isSuccess = state => state.studiesReducer.engagement.leaver.isSuccess

export const leaver = state => state.studiesReducer.engagement.leaver.leaver

export const errors = state => state.studiesReducer.engagement.leaver.errors

export const options = state => state.studiesReducer.engagement.leaver.options

export const permissions = state => state.studiesReducer.engagement.leaver.permissions

import { axios } from "@Root/API";
import objectToFormdata from "object-to-formdata";

export const putStudiesStudyProgrammeAttachment = (student_course_session_id, file, description, attachment_id) => {
  return axios.post(`attache/file/${attachment_id}/student-course-session`, objectToFormdata({ student_course_session_id, file, description }), {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

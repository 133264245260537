import * as types from './role.types'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions } from './role.actions'
import { API } from '@Root/API'
import { errorMessage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'
import { permissionsActions } from '@Store/permissions/index'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id } = payload
  try {
    const { data } = yield call(API.admin.role.get, id)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getInitial() {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.admin.role.getInitial)
    yield put(actions.set(data.data))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getAll({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id } = payload
  try {
    const { data } = yield call(API.admin.role.get, id)
    yield put(actions.setAll([data.data]))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const {
    data: { id, ...restData },
    history,
  } = payload
  try {
    const response = yield call(API.admin.role.create, restData)
    const data = response.data
    yield put(actions.set(data.data))
    yield put(actions.setPermissions(data.permissions))
    history.push(`/home/admin/manage-system-team/user-roles/${data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The role has been created successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.admin.role.edit, payload)
    // console.log('data', data)
    yield put(actions.set(data.data))
    yield put(permissionsActions.getRule())
    yield put(permissionsActions.getDataCreation())
    yield put(permissionsActions.getTabs())
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The role has been saved successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id, history } = payload
  try {
    yield call(API.admin.role.remove, id)
    history.push('/home/admin/other/user-roles')
    yield put(snackbarActions.setSuccessNotification({ text: 'The role has been deleted successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getStaticOptions() {
  try {
    const data = yield all([yield call(API.admin.role.options.getStatic), yield call(API.admin.user.options.getStatuses)])
    yield put(
      actions.setOptions({
        name: 'static',
        data: {
          roles: data[0].data.data.map(obj => ({ value: obj.id, label: obj.name })),
          statuses: data[1].data.map(obj => ({ value: obj.id, label: obj.label })),
        },
      })
    )
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_ROLE, get),
    takeEvery(types.GET_ROLES, getAll),
    takeEvery(types.CREATE_ROLE, create),
    takeEvery(types.EDIT_ROLE, edit),
    takeEvery(types.DELETE_ROLE, remove),
    takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
    takeEvery(types.GET_INITIAL, getInitial),
  ])
}

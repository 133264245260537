import * as types from './supervisorAllocation.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  supervisorAllocation: {
    supervisor_identifier: null,
    hesa_identifier: null,
    ref2021_unit_of_assessment_id: null,
    proportion: null,
  },
  options: {},
  canBeCreatedSupervisorAllocation: false,
  permissions: {},
  errors: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.SET_SUPERVISOR_ALLOCATION:
      return {
        ...state,
        supervisorAllocation: payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: payload,
      }
    case types.CLEAR_DATA:
      return {
        ...state,
        supervisorAllocation: initialState.supervisorAllocation,
        errors: initialState.errors,
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    case types.SET_CAN_BE_CREATED:
      return {
        ...state,
        canBeCreatedSupervisorAllocation: payload,
      }
    case types.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    default:
      return state
  }
}

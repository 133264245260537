import React, { PureComponent } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { delay } from 'redux-saga'
import { all } from '@Root/helpers'
import onClickOutside from 'react-onclickoutside'
import xIcon from '../../../assets/icons/x.png'
import { ArrowRightIcon } from 'assets'

import { CustomScrollbar, InputError } from '@Root/HOCs'
import { popupHeight } from '@Root/helpers'
import { ArrowIcon } from '@Root/assets'
import { NO_DATA_AVAILABLE } from '@Root/configs'
import { TextInput } from '../TextInput/index'

class List extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      optionsAreShown: false,
      error: null,
      slicesQuantity: 1,
      maxSlicesQuantity: this.calculateMaxSlicesQuantity(),
      searchValue: '',
    }
  }

  handleScroll = ({ scrollHeight, clientHeight, scrollTop }) => {
    const { slicesQuantity, maxSlicesQuantity } = this.state
    if (scrollTop > scrollHeight - clientHeight - 150) {
      if (slicesQuantity < maxSlicesQuantity) {
        this.setState(({ slicesQuantity }) => ({
          slicesQuantity: slicesQuantity + 1,
        }))
      }
    }
  }

  handleOptionClick = option => {
    this.props.changeHandler(option)
    this.setState({ optionsAreShown: false, slicesQuantity: 1, searchValue: '' })
  }

  handleClickOutside = () => {
    this.setState({ optionsAreShown: false, slicesQuantity: 1, searchValue: '' })
  }

  showError = async error => {
    this.setState({ error })
  }

  calculateMaxSlicesQuantity = () => {
    const { options = [], optionsSliceQuantity } = this.props
    return Math.ceil((options?.length || 0) / optionsSliceQuantity)
  }

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      this.props.error ? this.showError(this.props.error) : this.showError(null)
    }
    if (this.props.options.length !== prevProps.options.length) {
      this.setState({ maxSlicesQuantity: this.calculateMaxSlicesQuantity() })
    }
  }

  onChangeSearch = field => value => {
    this.setState({ searchValue: value })
  }

  handelSearch = initialOptions => {
    const { searchValue } = this.state
    const normalizeFilter = searchValue.toLowerCase()
    if (normalizeFilter === '') {
      return initialOptions
    } else {
      return initialOptions.filter(option => option.label.toLowerCase().includes(normalizeFilter))
    }
  }

  render() {
    const { optionsAreShown, error, slicesQuantity, searchValue } = this.state
    const {
      options: passedOptions,
      value,
      isDisabled,
      style,
      inputClassNames,
      inputStyle,
      optionsStyle,
      placeholder,
      maxVisibleOptionsQuantity,
      changeHandler,
      optionsSliceQuantity,
      hideCross,
      customComponents,
      callBack,
      search = false,
    } = this.props
    const { handleScroll, handleOptionClick, onChangeSearch, handelSearch, ref } = this

    const initialOptions = passedOptions
      .map(passedOption => (typeof passedOption === 'object' ? passedOption : { label: passedOption, value: passedOption }))
      .filter(option => option !== null)
    const selectedOption = initialOptions.find(option => option.value === value)
    const selectedLabel = selectedOption ? selectedOption.label : null
    const options = handelSearch(initialOptions).slice(0, optionsSliceQuantity * slicesQuantity)
    const isNotDataAvailable = value === NO_DATA_AVAILABLE.label

    return (
      <div className={classes.Select} style={style}>
        <InputError error={error}>
          <div
            className={`${classes.input} ${isDisabled ? classes.disabled : null}
                        ${inputClassNames.reduce((acc, className) => acc + ` ${classes[className]}`, '')}`}
            style={inputStyle}
            onClick={() =>
              all(
                () => !isDisabled && this.setState({ optionsAreShown: search ? true : !optionsAreShown }),
                () => this.setState({ error: null })
              )
            }
          >
            {!isNotDataAvailable ? (
              <>
                {optionsAreShown && search ? (
                  <TextInput style={{ width: 260, border: 'none', height: 36 }} value={searchValue} changeHandler={onChangeSearch('search')} autoFocus />
                ) : optionsAreShown && customComponents ? (
                  <div className={classes.wrap}>
                    {customComponents}
                    <button
                      type='button'
                      className={classes.send}
                      onClick={() => {
                        this.setState({ optionsAreShown: false, slicesQuantity: 1 })
                        callBack()
                      }}
                    >
                      Add
                    </button>
                  </div>
                ) : value || value === false ? (
                  <div className={classes.value}>{selectedLabel}</div>
                ) : (
                  <div className={`${classes.value} ${classes.empty}`}>{placeholder}</div>
                )}
              </>
            ) : (
              <div className={classes.value}>{NO_DATA_AVAILABLE.value}</div>
            )}
            {!isDisabled && !isNotDataAvailable && (
              <div className={classes.wrapperIcon}>
                {value !== null && !hideCross && (
                  <div
                    className={classes.xIcon}
                    onClick={event =>
                      all(
                        () => event.stopPropagation(),
                        () => changeHandler(null)
                      )
                    }
                  >
                    <img src={xIcon} alt='' />
                  </div>
                )}
                <div className={classes.icon}>
                  <img
                    style={optionsAreShown ? { transform: 'rotate(180deg)' } : null}
                    src={ArrowIcon}
                    alt=''
                    onClick={event =>
                      all(
                        () => event.stopPropagation(),
                        () => !isDisabled && this.setState({ optionsAreShown: !optionsAreShown })
                      )
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </InputError>

        {optionsAreShown && (
          <>
            <div
              className={classes.options}
              style={{ ...optionsStyle, height: !!options.length ? popupHeight(options.length, maxVisibleOptionsQuantity, 38) : 38 }}
            >
              <CustomScrollbar verticalOnly scrollHandler={scrollOptions => handleScroll(scrollOptions)}>
                {options.length ? (
                  options.map((option, i) => (
                    <div className={classes.option} key={i} style={{ ...(option.value === value ? { background: '#EA8226' } : {}) }}>
                      <CustomScrollbar horizontalOnly>
                        <div
                          className={classes.text}
                          onClick={() => handleOptionClick(option.value)}
                          style={{ ...(option.value === value ? { color: '#ffffff' } : {}) }}
                        >
                          {option.label}
                        </div>
                      </CustomScrollbar>
                    </div>
                  ))
                ) : (
                  <div className={classes.error}>No options</div>
                )}
              </CustomScrollbar>
            </div>
          </>
        )}
      </div>
    )
  }
}

List.propTypes = {
  style: PropTypes.object,
  inputClassNames: PropTypes.arrayOf(PropTypes.oneOf(['borderless', 'transparent'])),
  inputStyle: PropTypes.object,
  optionsStyle: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      }),
    ])
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  changeHandler: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  maxVisibleOptionsQuantity: PropTypes.number,
  optionsSliceQuantity: PropTypes.number,
  error: PropTypes.string,
}

List.defaultProps = {
  style: {},
  inputClassNames: [],
  inputStyle: {},
  optionsStyle: {},
  options: [],
  value: null,
  changeHandler: () => {},
  isDisabled: false,
  placeholder: '',
  maxVisibleOptionsQuantity: 5,
  optionsSliceQuantity: 50,
  error: null,
}

export const Select = onClickOutside(List)

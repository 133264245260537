import * as types from './globalSearch.types'

export const actions = {
  toggleLoading: payload => ({
    type: types.TOGGLE_LOADING,
    payload,
  }),
  toggleLoadingPagination: payload => ({
    type: types.TOGGLE_LOADING_PAGINATION,
    payload,
  }),
  getSearchData: payload => ({
    type: types.GET_SEARCH_DATA,
    payload,
  }),
  setSearchData: payload => ({
    type: types.SET_SEARCH_DATA,
    payload,
  }),
  getSearchDataPagination: payload => ({
    type: types.GET_SEARCH_DATA_PAGINATION,
    payload,
  }),
  setSearchDataPagination: payload => ({
    type: types.SET_SEARCH_DATA_PAGINATION,
    payload,
  }),
  getOptions: payload => ({
    type: types.GET_OPTIONS,
    payload,
  }),
  setOptions: payload => ({
    type: types.SET_OPTIONS,
    payload,
  }),
}

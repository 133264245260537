export const isLoading = state => state.studiesReducer.studentCourseSession.sessionStatus.isLoading

export const isSuccess = state => state.studiesReducer.studentCourseSession.sessionStatus.isSuccess

export const sessionStatus = state => state.studiesReducer.studentCourseSession.sessionStatus.sessionStatus

export const errors = state => state.studiesReducer.studentCourseSession.sessionStatus.errors

export const options = state => state.studiesReducer.studentCourseSession.sessionStatus.options

export const permissions = state => state.studiesReducer.studentCourseSession.sessionStatus.permissions

export const canBeCreatedSessionStatus = state => state.studiesReducer.studentCourseSession.sessionStatus.canBeCreatedSessionStatus
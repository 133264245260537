import * as types from './venueActivity.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  venueActivity: {
    venue_activity_identifier: null,
    duration_amount: null,
    duration_type_id: null,
    activity_end_date: null,
    activity_start_date: null,
    activity_type_id: null,
    activity_country_id: null,
    host_identifier: null,
    host_type_id: null,
    mobility_scheme_id: null,
    module_instance_id: null,
  },
  options: {},
  canBeCreatedVenueActivity: false,
  permissions: {},
  errors: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.SET_VENUE_ACTIVITY:
      return {
        ...state,
        venueActivity: payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: payload,
      }
    case types.CLEAR_DATA:
      return {
        ...state,
        venueActivity: initialState.venueActivity,
        errors: {},
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    case types.SET_CAN_BE_CREATED:
      return {
        ...state,
        canBeCreatedVenueActivity: payload,
      }
    case types.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    default:
      return state
  }
}

import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { ArrowLeftIcon } from "@Root/assets/icons";
import { ArrowRightIcon } from "@Root/assets/icons";

export const ArrowButton = ({ direction, clickHandler, style }) => {
  let icon;
  direction === "left" && (icon = ArrowLeftIcon);
  direction === "right" && (icon = ArrowRightIcon);

  return (
    <div className={classes.ArrowButton} style={style} onClick={clickHandler}>
      <img src={icon} alt="" />
    </div>
  );
};

ArrowButton.propTypes = {
  direction: PropTypes.string.isRequired,
  clickHandler: PropTypes.func,
  style: PropTypes.object
};

import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { TickImage } from '@Root/assets/icons'
import { snackbarActions } from '@Root/store'
import { connect } from 'react-redux'
import { useNotificationAnimation } from '@Root/hooks'

export const Notification = ({ successNotification, changeHandler }) => {
	const { bottom, opacity, wait } = useNotificationAnimation(changeHandler, successNotification)

	return successNotification?.text ? (
		<div className={classes.wrapper} style={{ bottom, opacity, transition: `all ${wait}ms ease-out` }}>
			<img src={TickImage} alt='' className={classes.image} />
			{successNotification?.text}
		</div>
	) : (
		<></>
	)
}

Notification.propTypes = {
	text: PropTypes.string,
	changeHandler: PropTypes.func,
	isError: PropTypes.bool,
}

Notification.defaultProps = {
	text: null,
	changeHandler: () => {},
	isError: false,
}

const mapStateToProps = ({ snackbarReducer }) => ({
	successNotification: snackbarReducer.successNotification,
})
const mapDispatchToProps = dispatch => {
	return {
		changeHandler: data => dispatch(snackbarActions.setSuccessNotification(data)),
	}
}

export const SuccessNotification = connect(
	mapStateToProps,
	mapDispatchToProps
)(Notification)

import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { cn } from "@Root/helpers";

export const Warning = ({ className, style, children }) => {
  return (
    <div className={cn(classes.warning, className)} style={style}>
      <div className={classes.icon}>i</div>
      <div className={classes.content}> {children}</div>
    </div>
  );
};

Warning.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node
};

Warning.defaultProps = {
  className: "",
  style: {},
  children: null
};

import * as types from './qualification.types'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions } from './qualification.actions'
import { API } from '@Root/API'
import { snackbarActions } from '@Root/store'
import { errorMessage } from '@Root/helpers'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id } = payload
  try {
    const { data } = yield call(API.admin.qualification.get, id)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  try {
    const response = yield call(API.admin.qualification.create, data)
    history.push(`/home/admin/data/qualifications/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The qualification has been created successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.admin.qualification.edit, payload)
    yield put(actions.set(data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The qualification has been saved successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id, history } = payload
  try {
    yield call(API.admin.qualification.remove, id)
    history.push('/home/admin/data/qualifications')
    yield put(snackbarActions.setSuccessNotification({ text: 'The qualification has been deleted successfully!' }))
  } catch (error) {}
  yield put(actions.toggleLoading(false))
}

function* getStaticOptions() {
  try {
    const { data } = yield call(API.admin.qualification.options.getStatic)
    const { qualifications, awarding_body_role, qualification_subject, z_qualification_subject } = data.data
    const mapToValueAndLabel = arr => {
      return arr.map(obj => ({ value: obj.id, label: obj.code ? `${obj.code} - ${obj?.label}` : `${obj?.label}` }))
    }
    yield put(
      actions.setOptions({
        name: 'static',
        data: {
          qualification_category: mapToValueAndLabel(qualifications.qualification_categories),
          z_level_of_study_six_way_split: mapToValueAndLabel(qualifications.z_level_of_study_six_way_split),
          z_level_of_study_ten_way_split: mapToValueAndLabel(qualifications.z_level_of_study_ten_way_split),
          awarding_body_role: {
            awarding_body: mapToValueAndLabel(awarding_body_role.awarding_body_role_ids),
            relationship: mapToValueAndLabel(awarding_body_role.relationships),
          },
          qualification_subject: {
            qualification_subject: mapToValueAndLabel(qualification_subject.qualification_subject_types),
            qualification_subject_cah: mapToValueAndLabel(qualification_subject.qualification_subject_cah_one),
          },
          z_qualification_subject: {
            z_qual_subject: mapToValueAndLabel(z_qualification_subject.qualification_subject_types),
          },
        },
      })
    )
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_QUALIFICATION, get),
    takeEvery(types.CREATE_QUALIFICATION, create),
    takeEvery(types.EDIT_QUALIFICATION, edit),
    takeEvery(types.DELETE_QUALIFICATION, remove),
    takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
  ])
}

import React, { useEffect, useState } from 'react'
import classes from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'

const titles = { onEdit: 'Edit Module', onRead: 'Module', onCreate: 'Create Module' }
export const StudiesModuleForm = ({
  options,
  isLoading,
  descriptions,
  initialValue,
  isNew,
  onSave,
  generateId,
  onDelete,
  onOpenDescriptionModal,
  onCancel,
  isSuccess,
  permissions,
}) => {
  const [isEditable, toggleEditable] = useToggle(isNew)
  const [data, setData] = useState({})
  const { errors, setError, onClear: onClearError } = useError()

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [toggleEditable, isSuccess])

  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (generateId && isNew) {
      setData(prevState => ({ ...prevState, module_identifier: String(Math.floor(Math.random() * 100000000000000) + 1) }))
    }
  }, [generateId, isNew])

  const validateForm = () => {
    const { module_identifier, module_title, full_time_equivalence, credit_transfer_scheme_id } = data
    if (!module_identifier) {
      setError('module_identifier', 'Required')
      return false
    }
    if (!module_title) {
      setError('module_title', 'Required')
      return false
    }
    if (!full_time_equivalence) {
      setError('full_time_equivalence', 'Required')
      return false
    }
    if (!credit_transfer_scheme_id) {
      setError('credit_transfer_scheme_id', 'Required')
      return false
    }
    return true
  }

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    onSave(data)
  }

  const onToggleEditable = () => {
    toggleEditable()
  }

  const onCancelEditForm = () => {
    onClearError()
    if (!isNew) {
      setData(initialValue)
      onToggleEditable()
      return
    }
    onCancel()
  }

  const { form, fields } = permissions
  const {
    module_identifier,
    module_title,
    credit_transfer_scheme_id,
    level_of_credit_points_id,
    full_time_equivalence,
    language_identifier_id,
    credit_value_of_module,
    programs_ids,
    module_id,
  } = data

  const { onRead, onEdit, onCreate } = titles

  return (
    <div className={classes.StudiesModuleForm}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(form?.delete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this module' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEditForm}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        canEdit={form?.edit}
        isEditable={isEditable}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onToggleEditable}
        isCreate={isNew}
        idForm={module_id ? `module_${module_id}` : null}
        isSuccess={isSuccess}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.columnWrapper}>
            <Field
              label={'Module ID'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={module_identifier}
              onChange={onChangeValue('student_initiative_id')}
              placeholder='Module ID'
              isDisabled={true}
              maxLength={50}
              isRequired={isEditable}
              error={errors.student_initiative_id}
              description={descriptions.module_identifier}
              onOpenDescriptionModal={onOpenDescriptionModal}
              isView={fields?.module_identifier?.view}
            />
            <Field
              label={'Module title'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={module_title}
              onChange={onChangeValue('module_title')}
              placeholder='Module title'
              isDisabled={!isEditable || !fields?.module_title?.edit}
              isView={fields?.module_title?.view}
              maxLength={255}
              isRequired={isEditable}
              error={errors.module_title}
              description={descriptions.module_title}
              onOpenDescriptionModal={onOpenDescriptionModal}
            />
            <Field
              label={'Credit value of module'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={credit_value_of_module}
              onChange={onChangeValue('credit_value_of_module')}
              placeholder='Credit value of module'
              isDisabled={!isEditable || !fields?.credit_value_of_module?.edit}
              isView={fields?.credit_value_of_module?.view}
              maxLength={3}
              description={descriptions.credit_value_of_module}
              onOpenDescriptionModal={onOpenDescriptionModal}
            />
            <Field
              label={'Credit transfer scheme'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              optionsStyle={{ width: 260 }}
              value={credit_transfer_scheme_id}
              onChange={onChangeValue('credit_transfer_scheme_id')}
              placeholder='Credit transfer scheme'
              isDisabled={!isEditable || !fields?.credit_transfer_scheme_id?.edit}
              isView={fields?.credit_transfer_scheme_id?.view}
              maxLength={2}
              isRequired={isEditable}
              options={options.credit_transfer_scheme}
              error={errors.credit_transfer_scheme_id}
              description={descriptions.credit_transfer_scheme_id}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'select'}
            />
          </div>
          <div className={classes.columnWrapper}>
            <Field
              label={'Level of credit points'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              optionsStyle={{ width: 260 }}
              value={level_of_credit_points_id}
              onChange={onChangeValue('level_of_credit_points_id')}
              placeholder='Level of credit points'
              isDisabled={!isEditable || !fields?.level_of_credit_points_id?.edit}
              isView={fields?.level_of_credit_points_id?.view}
              maxLength={2}
              options={options.level_of_credit_points}
              description={descriptions.level_of_credit_points_id}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'select'}
            />
            <Field
              label={'Full time equivalence'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={full_time_equivalence}
              onChange={onChangeValue('full_time_equivalence')}
              placeholder='Full time equivalence'
              isDisabled={!isEditable || !fields?.full_time_equivalence?.edit}
              isView={fields?.full_time_equivalence?.view}
              maxLength={4}
              isRequired={isEditable}
              error={errors.full_time_equivalence}
              description={descriptions.full_time_equivalence}
              onOpenDescriptionModal={onOpenDescriptionModal}
            />
            <Field
              label={'Language identifier'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              optionsStyle={{ width: 260 }}
              value={language_identifier_id}
              onChange={onChangeValue('language_identifier_id')}
              placeholder='Language identifier'
              isDisabled={!isEditable || !fields?.language_identifier_id?.edit}
              isView={fields?.language_identifier_id?.view}
              maxLength={2}
              options={options.language_identifiers}
              description={descriptions.language_identifier_id}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'select'}
            />
            <Field
              label={'Programme'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              optionsStyle={{ width: 260 }}
              value={programs_ids}
              onChange={onChangeValue('programs_ids')}
              placeholder={'Programme'}
              isDisabled={!isEditable || !fields?.programs_ids?.edit}
              options={options.programmers_courses}
              description={descriptions?.programs_ids}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'multiSelect'}
            />
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

import { useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { EditButton, Preloader, SectionTitle } from '@Root/components'

import { useEditedMode } from '@Root/hooks'

export const FormWrapper = ({
  children,
  buttons,
  buttonsNames,
  buttonsAreDisabled,
  isSpinning,
  clickCancelButtonHandler,
  clickDeleteButtonHandler,
  clickSaveButtonHandler,
  clickBackButtonHandler,
  canEdit,
  isCreate,
  createTitle,
  isEditable,
  editTitle,
  readTitle,
  clickToggleEditModeButtonHandler,
  fixedSpinner,
  idForm,
  isSuccess,
}) => {
  const { startEditMode, exitEditMode } = useEditedMode({
    isEditable,
    clickToggleEditModeButtonHandler,
    clickCancelButtonHandler,
    clickBackButtonHandler,
    idForm,
  })

  useEffect(() => {
    if (isSuccess) exitEditMode()
  }, [isSuccess])

  useEffect(() => {
    return () => {
      if (idForm) exitEditMode()
    }
  }, [idForm])

  const buttonName = button => {
    const buttonName = buttonsNames.find(buttonName => buttonName.button === button)
    return buttonName ? buttonName.name : button
  }

  return (
    <div className={classes.FormWrapper}>
      <div className={classes.main}>
        {(createTitle || editTitle || readTitle) && (
          <div className={classes.titleWrapper}>
            <SectionTitle title={isCreate ? createTitle : isEditable ? editTitle : readTitle} />
            <div className={classes.wrapper}>{canEdit && !isEditable && <EditButton clickHandler={startEditMode} />}</div>
          </div>
        )}
        {children}
        {isSpinning && (
          <div className={`${classes.spinnerWrapper} ${fixedSpinner ? classes.fixedSpinner : ''}`}>
            <Preloader />
          </div>
        )}
      </div>
      {!!buttons && (
        <div className={classes.footer}>
          {buttons.includes('save') && (
            <button className={classes.save} onClick={clickSaveButtonHandler} disabled={buttonsAreDisabled}>
              {buttonName('save')}
            </button>
          )}
          {buttons.includes('delete') && (
            <button className={classes.delete} onClick={clickDeleteButtonHandler} disabled={buttonsAreDisabled}>
              {buttonName('delete')}
            </button>
          )}
          {buttons.includes('cancel') && (
            <button className={classes.cancel} onClick={isCreate ? clickCancelButtonHandler : () => exitEditMode('cancel')} disabled={buttonsAreDisabled}>
              {buttonName('cancel')}
            </button>
          )}{' '}
          {buttons.includes('back') && (
            <button className={classes.cancel} onClick={isCreate ? clickBackButtonHandler : () => exitEditMode('back')} disabled={buttonsAreDisabled}>
              {buttonName('back')}
            </button>
          )}
        </div>
      )}
    </div>
  )
}

FormWrapper.propTypes = {
  children: PropTypes.node,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(['cancel', 'delete', 'save'])),
  buttonsNames: PropTypes.arrayOf(PropTypes.shape({ button: PropTypes.oneOf(['cancel', 'delete', 'save']), name: PropTypes.string })),
  isSpinning: PropTypes.bool,
  buttonsAreDisabled: PropTypes.bool,
  clickCancelButtonHandler: PropTypes.func,
  clickDeleteButtonHandler: PropTypes.func,
  clickSaveButtonHandler: PropTypes.func,
}

FormWrapper.defaultProps = {
  children: null,
  buttons: null,
  buttonsNames: [],
  isSpinning: false,
  buttonsAreDisabled: false,
  canEdit: false,
  isEditable: false,
  editTitle: '',
  readTitle: '',
  clickToggleEditModeButtonHandler: () => {},
  clickCancelButtonHandler: () => {},
  clickDeleteButtonHandler: () => {},
  clickSaveButtonHandler: () => {},
}

import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { CheckboxInput, InputLabel, Preloader, SaveButton } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { tableManagerActions, tableManagerSelectors } from '@Store/admin'

export const TableColumnManager = () => {
  const [data, setData] = useState([])
  const permissions = useSelector(tableManagerSelectors.tablePermissions)
  const userPermissions = useSelector(tableManagerSelectors.permissions)
  const location = useLocation()
  const arrayPathname = location.pathname.split('/')
  const entityType = arrayPathname[arrayPathname.length - 1]
  const entity = arrayPathname[arrayPathname.length - 2]
  const subEntity = arrayPathname[arrayPathname.length - 3]
  const dispatch = useDispatch()

  useEffect(() => {
    if (subEntity) {
      if (entityType === 'additional-fields') {
        dispatch(tableManagerActions.getAdditionalFields())
      } else {
        if (entityType === 'session-year') {
          dispatch(tableManagerActions.get({ entity: subEntity, entityType: 'session_year' }))
        } else dispatch(tableManagerActions.get({ entity: subEntity, entityType: entityType }))
      }
    } else {
      dispatch(tableManagerActions.get({ entity: entity, entityType: entityType }))
    }
  }, [])

  const onSubmit = () => {
    if (entityType === 'additional-fields') return dispatch(tableManagerActions.editAdditionalFields(data))
    if (entityType === 'session-year') return dispatch(tableManagerActions.edit({ data: [...data], entity, entityType: 'session_year' }))
    dispatch(tableManagerActions.edit({ data: [...data], entity, entityType }))
  }

  const onChangeById = id => value => {
    setData(prevState => prevState.map(item => (item.id === id ? { ...item, value } : item)))
  }

  const onChangeByName = name => value => {
    setData(prevState => prevState.map(item => (item.name === name ? { ...item, value } : item)))
  }

  useEffect(() => {
    if (permissions) setData(permissions)
  }, [permissions])

  useEffect(() => {
    return () => {
      dispatch(tableManagerActions.set(null))
    }
  }, [])

  const onSelectAll = e => {
    setData(
      data.map(item => {
        return { ...item, value: true }
      })
    )
  }

  if (!permissions) return <Preloader />

  return permissions.length > 0 ? (
    <div className={styles.items}>
      <div className={styles.wrapper}>
        {data.map(({ id, value, name }) => {
          return (
            <div className={styles.inputWrapper} key={id}>
              <CheckboxInput
                style={{ height: 38 }}
                isChecked={value}
                changeHandler={id ? onChangeById(id) : onChangeByName(name)}
                isDisabled={!userPermissions.form?.edit}
              />
              <InputLabel text={name} />
            </div>
          )
        })}
      </div>
      <div className={styles.actions}>
        <SaveButton onClick={onSelectAll}>Select All</SaveButton>
        <SaveButton onClick={onSubmit}>Save Changes</SaveButton>
      </div>
    </div>
  ) : (
    <div className={styles.no_data}>There is no data to manage</div>
  )
}

import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './curriculumAccreditation.types'
import { actions } from './curriculumAccreditation.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history } = payload
	const { studyProgrammeId, curriculumAccreditationId } = data
	try {
		const response = yield call(API.studies.programme.curriculumAccreditation.get, studyProgrammeId, curriculumAccreditationId)
		yield put(actions.set(response.data.data))
		yield put(actions.setPermissions(response.data.permissions))
	} catch (error) {
		history.push(`/home/studies/programmes/${studyProgrammeId}/curriculum_accreditation`)
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history, studyProgrammeId } = payload
	try {
		const response = yield call(API.studies.programme.curriculumAccreditation.create, studyProgrammeId, data)
		history.push(`/home/studies/programmes/${studyProgrammeId}/curriculum_accreditation/${response.data.data.id}`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The Curriculum Accreditation has been created successfully!' }))
	} catch (error) {
		const errors = error.response.data.errors
		if (errors['studyProgrammeId']) {
			history.push('/home/studies/programmes')
		} else {
			yield put(actions.set(data))
			yield put(actions.setErrors(errors))
		}
	}
	yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
	yield put(actions.toggleLoading(true))
	const { curriculumAccreditationId, studyProgrammeId, data } = payload
	try {
		const response = yield call(API.studies.programme.curriculumAccreditation.edit, studyProgrammeId, curriculumAccreditationId, data)
		yield put(actions.set(response.data.data))
		yield put(actions.toggleSuccess(true))
		yield put(snackbarActions.setSuccessNotification({ text: 'The Curriculum Accreditation has been saved successfully!' }))
	} catch (error) {
		const errors = error.response.data.errors
		yield put(actions.set(payload))
		yield put(actions.setErrors(errors))
	}
	yield put(actions.toggleSuccess(false))
	yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history } = payload
	const { studyProgrammeId, curriculumAccreditationId } = data
	try {
		yield call(API.studies.programme.curriculumAccreditation.remove, studyProgrammeId, curriculumAccreditationId)
		history.push(`/home/studies/programmes/${studyProgrammeId}/curriculum_accreditation`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The Curriculum Accreditation has been deleted successfully!' }))
	} catch (error) {
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* getOptions() {
	try {
		const { data } = yield call(API.studies.programme.curriculumAccreditation.options.getStatic)
		const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
		const formattedData = {
			accreditation_identifier: generateObject(data),
		}
		yield put(actions.setOptions(formattedData))
		saveDataOptionsToLocalStorage('curriculumAccreditation', formattedData)
	} catch (error) {
		console.log(error)
	}
}

export function* rootSaga() {
	yield all([
		takeEvery(types.GET_CURRICULUM_ACCREDITATION, get),
		takeEvery(types.CREATE_CURRICULUM_ACCREDITATION, create),
		takeEvery(types.EDIT_CURRICULUM_ACCREDITATION, edit),
		takeEvery(types.DELETE_CURRICULUM_ACCREDITATION, remove),
		takeEvery(types.GET_OPTIONS, getOptions),
	])
}

export const TOGGLE_LOADING = 'entryQualificationAwarded/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'entryQualificationAwarded/TOGGLE_SUCCESS'
export const GET_ENTRY_QUALIFICATION_AWARDED = 'entryQualificationAwarded/GET_ENTRY_QUALIFICATION_AWARDED'
export const SET_ENTRY_QUALIFICATION_AWARDED = 'entryQualificationAwarded/SET_ENTRY_QUALIFICATION_AWARDED'
export const CREATE_ENTRY_QUALIFICATION_AWARDED = 'entryQualificationAwarded/CREATE_ENTRY_QUALIFICATION_AWARDED'
export const DELETE_ENTRY_QUALIFICATION_AWARDED = 'entryQualificationAwarded/DELETE_ENTRY_QUALIFICATION_AWARDED'
export const EDIT_ENTRY_QUALIFICATION_AWARDED = 'entryQualificationAwarded/EDIT_ENTRY_QUALIFICATION_AWARDED'
export const GET_OPTIONS = 'entryQualificationAwarded/GET_OPTIONS'
export const SET_OPTIONS = 'entryQualificationAwarded/SET_OPTIONS'
export const CLEAR_DATA = 'entryQualificationAwarded/CLEAR_DATA'
export const SET_ERRORS = 'entryQualificationAwarded/SET_ERRORS'
export const SET_PERMISSIONS = 'entryQualificationAwarded/SET_PERMISSIONS'

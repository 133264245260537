import React, { useEffect } from 'react'
import { LeaverForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { leaverActions, leaverSelectors } from '@Store/studies'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const EditLeaver = ({ onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: engagementId, contactId, tab: leaverId } = useParams()
  const leaver = useSelector(leaverSelectors.leaver)
  const isSuccess = useSelector(leaverSelectors.isSuccess)
  const isLoading = useSelector(leaverSelectors.isLoading)
  const options = useSelector(leaverSelectors.options)
  const permissions = useSelector(leaverSelectors.permissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagement_leaver

  useEffect(() => {
    dispatch(leaverActions.get({ data: { engagementId, contactId, leaverId }, history }))
    return () => {
      dispatch(leaverActions.clearData())
    }
  }, [])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This leaver will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(leaverActions.delete({ data: { engagementId, contactId }, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = data => {
    dispatch(leaverActions.edit({ data, engagementId }))
  }

  return (
    <LeaverForm
      onSave={onSave}
      onDelete={onDelete}
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      initialValue={leaver}
      isLoading={isLoading}
      isSuccess={isSuccess}
      options={options}
      descriptions={descriptions}
      onOpenDescriptionModal={onOpenDescriptionModal}
    />
  )
}

import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './referencePeriod.types'
import { actions } from './referencePeriod.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { studyProgrammeId, studentCourseSessionId, contactId, referencePeriodId } = data
  try {
    const response = yield call(API.studies.studentCourseSession.referencePeriod.get, studentCourseSessionId, referencePeriodId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/reference_period_student_load`)
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.setErrors({}))
  yield put(actions.toggleLoading(true))
  const { data, history, studentCourseSessionId, studyProgrammeId, contactId } = payload
  try {
    const response = yield call(API.studies.studentCourseSession.referencePeriod.create, studentCourseSessionId, data)
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/reference_period_student_load/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The Reference Period has been created successfully!' }))
  } catch (error) {
    const { messages } = error.response.data
    yield put(actions.set(data))
    yield put(actions.setErrors(messages))
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.setErrors({}))
  yield put(actions.toggleLoading(true))
  const { studentCourseSessionId, referencePeriodId, data } = payload
  try {
    const response = yield call(API.studies.studentCourseSession.referencePeriod.edit, studentCourseSessionId, referencePeriodId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The Reference Period has been saved successfully!' }))
  } catch (error) {
    const { messages } = error.response.data
    yield put(actions.set(data))
    yield put(actions.setErrors(messages))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { contactId, studyProgrammeId, studentCourseSessionId, referencePeriodId } = data
  try {
    yield call(API.studies.studentCourseSession.referencePeriod.remove, studentCourseSessionId, referencePeriodId)
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/reference_period_student_load`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The Reference Period has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const { data } = yield call(API.studies.studentCourseSession.referencePeriod.options.getStatic)
    const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    const formattedData = {
      reference_periods: generateObject(data.reference_periods),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('referencePeriod', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_REFERENCEPERIOD, get),
    takeEvery(types.CREATE_REFERENCEPERIOD, create),
    takeEvery(types.EDIT_REFERENCEPERIOD, edit),
    takeEvery(types.DELETE_REFERENCEPERIOD, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}

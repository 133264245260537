import * as types from './admission.types'

const initialPermissions = {
  fields: {
    DDO_Email: { view: true, edit: true },
    DDO_name: { view: true, edit: true },
    application_date: { view: true, edit: true },
    application_status: { view: true, edit: true },
    bap_date: { view: true, edit: true },
    bap_status: { view: true, edit: true },
    enquiry_date: { view: true, edit: true },
    interview_date: { view: true, edit: true },
    open_day_date: { view: true, edit: true },
    ref_received_date: { view: true, edit: true },
    ref_sent_date: { view: true, edit: true },
    sponsor_name: { view: true, edit: true },
    visa_status: { view: true, edit: true },
  },
  form: {
    delete: true,
    edit: true,
    create: true,
  },
}
const initialState = {
  data: [],
  options: [],
  isLoading: false,
  isSuccess: false,
  permissions: initialPermissions,
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      }
    case types.SET_ADMISSION:
      return {
        ...state,
        data: action.payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: action.payload,
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: { ...state.permissions, ...action.payload },
      }
    case types.CLEAR_PERMISSIONS:
      return {
        ...state,
        permissions: initialPermissions,
      }
    default:
      return state
  }
}

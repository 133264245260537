import React from "react";
import styles from "./style.module.scss";
import PropTypes from "prop-types";

export const RadioInputGroup = ({ style, values = [], currentValue, changeHandler = () => {}, isInline, labelStyle, firstWordIsBold, isDisabled = false }) => {
  return (
    <div className={`${styles.RadioInputGroup} ${isInline ? styles.inline : ""}`} style={style}>
      {values.map(value => (
        <div className={`${styles.wrapper} ${isDisabled ? styles.disabled : ""}`} onClick={() => changeHandler(value)} key={value}>
          <div className={styles.input}>{value === currentValue && <div className={styles.checkmark} />}</div>
          <div className={styles.label} style={labelStyle}>
            {!firstWordIsBold ? (
              value
            ) : (
              <>
                <b>{value.split(" ")[0]}</b>&nbsp;
                {value
                  .split(" ")
                  .slice(1)
                  .join(" ")}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

RadioInputGroup.propTypes = {
  style: PropTypes.object,
  values: PropTypes.array,
  currentValue: PropTypes.string,
  changeHandler: PropTypes.func,
  isInline: PropTypes.bool,
  labelStyle: PropTypes.object,
  firstWordIsBold: PropTypes.bool,
  isDisabled: PropTypes.bool
};

import * as types from './role.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_ROLE,
		payload,
	}),
	set: payload => ({
		type: types.SET_ROLE,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_ROLE,
		payload,
	}),
	remove: payload => ({
		type: types.DELETE_ROLE,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_ROLE,
		payload,
	}),
	getStaticOptions: () => ({
		type: types.GET_STATIC_OPTIONS,
	}),
	setOptions: payload => ({
		type: types.GET_STATIC_OPTIONS,
		payload,
	}),
	getAll: payload => ({
		type: types.GET_ROLES,
		payload,
	}),
	getInitial: payload => ({
		type: types.GET_INITIAL,
		payload,
	}),
	setAll: payload => ({
		type: types.SET_ROLES,
		payload,
	}),
	changeExceptions: payload => ({
		type: types.CHANGE_EXCEPTIONS,
		payload,
	}),
	setSingleTab: payload => ({
		type: types.SET_SINGLE_TAB,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
	setInitialRole: () => ({
		type: types.SET_INITIAL_ROLE,
	}),
}

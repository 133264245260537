export const isLoading = state => state.studiesReducer.studentCourseSession.postgrad.isLoading

export const isSuccess = state => state.studiesReducer.studentCourseSession.postgrad.isSuccess

export const postgrad = state => state.studiesReducer.studentCourseSession.postgrad.postgrad

export const canBeAttached = state => state.studiesReducer.studentCourseSession.postgrad.canBeAttached

export const options = state => state.studiesReducer.studentCourseSession.postgrad.options.static

export const permissions = state => state.studiesReducer.studentCourseSession.postgrad.permissions

import React, { Component, createRef } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { delay } from 'redux-saga'
import { all, regularExpressions } from '@Root/helpers'
import { NO_DATA_AVAILABLE } from '@Root/configs'

export class TextArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      height: 89,
    }
    this.componentRef = createRef()
    this.inputRef = createRef()
  }

  showError = async error => {
    this.setState({ error })
  }

  handleChange = e => {
    const { restriction, changeHandler, autoHeight } = this.props
    const { height } = this.state
    const value = e.target.value
    const scrollHeight = e.target.scrollHeight
    if (restriction) {
      const valueIsAllowed = (value, regularExpression) => {
        return value.match(regularExpression)
      }
      ;(value === '' || valueIsAllowed(value, regularExpressions[restriction])) && changeHandler(value)
    } else {
      changeHandler(value)
    }
    if (autoHeight) this.componentRef.current.style.height = value ? (scrollHeight >= height ? `${scrollHeight}px` : `${height}px`) : `${height}px`
  }

  setInitialInputHeight = () => {
    const element = this.inputRef.current
    const elementDiv = this.componentRef.current
    const { autoHeight } = this.props
    if (autoHeight) {
      elementDiv.style.height = element.scrollHeight + 'px'
    }
  }
  componentDidMount() {
    this.setInitialInputHeight()
  }

  componentDidUpdate(prevProps) {
    const { error, value } = this.props
    if (error !== prevProps.error) {
      error ? this.showError(this.props.error) : this.setState({ error: null })
    }

    if (value && !prevProps.value) {
      this.setInitialInputHeight()
    }
  }

  render() {
    const { error } = this.state
    const { value, isDisabled, placeholder, classNames, style, maxLength, autoHeight } = this.props
    const { handleChange } = this
    const modifiedValue = value ? value : ''
    const isNotDataAvailable = isDisabled && value === NO_DATA_AVAILABLE.label

    return (
      <div
        className={`${classes.wrapper} ${classNames ? classNames.reduce((acc, className) => acc + ` ${classes[className]}`, '') : ''}`}
        style={{ ...style, borderColor: error ? '#D0021B' : null }}
        ref={this.componentRef}
      >
        <textarea
          value={!isNotDataAvailable ? modifiedValue : NO_DATA_AVAILABLE.value}
          onChange={e => all(() => handleChange(e), modifiedValue < maxLength ? () => this.setState({ error: null }) : null)}
          disabled={isDisabled}
          placeholder={placeholder}
          maxLength={maxLength}
          className={autoHeight ? classes.overflow : ''}
          ref={this.inputRef}
        />
        {error && <div className={classes.error}>{error}*</div>}
      </div>
    )
  }
}

TextArea.propTypes = {
  value: PropTypes.string,
  changeHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.oneOf(['borderless', 'transparent'])),
  style: PropTypes.object,
  restriction: PropTypes.oneOf(['letters', 'lettersAndWhiteSpaces', 'digits', 'email']),
  autoHeight: PropTypes.bool,
}

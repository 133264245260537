export const isLoading = state => state.studiesReducer.programme.courseInitiative.isLoading

export const isSuccess = state => state.studiesReducer.programme.courseInitiative.isSuccess

export const courseInitiative = state => state.studiesReducer.programme.courseInitiative.courseInitiative

export const errors = state => state.studiesReducer.programme.courseInitiative.errors

export const options = state => state.studiesReducer.programme.courseInitiative.options

export const permissions = state => state.studiesReducer.programme.courseInitiative.permissions

import React from 'react'
import style from './style.module.scss'
import { CheckboxInput, DataList, DatePicker, InputLabel, RoundPlusButton, Select, TextInput } from '@Root/components'
import CrossIcon from '@Root/assets/icons/x.svg'
import { ExclamationIcon } from '@Root/assets'

export const DBSFormForFields = ({
	isEditable,
	data,
	changeHandler,
	activeDBSTabId,
	onChangeActiveTab,
	onCreateNewTab,
	onRemoveTab,
	onOpenDescription,
	descriptions,
	permissions,
}) => {
	const generateConfig = () => {
		return {
			config: {
				inputs: [
					{
						name: 'Issued by',
						field: 'issued_by',
						type: 'textInput',
						validations: [],
					},
					{
						name: 'Has valid DBS?',
						field: 'valid_dbs',
						type: 'checkbox',
						validations: [],
					},
					{
						name: 'Date Expires',
						field: 'date_expires',
						type: 'datepicker',
						validations: [],
					},
				],
			},
		}
	}

	const { inputs } = generateConfig().config
	return (
		<div className={style.wrapper}>
			{data.map(({ id, ...rest }, index) => {
				return (
					<div className={style.accordion_item} key={id}>
						<div className={style.title} onClick={() => onChangeActiveTab(id)}>
							<InputLabel text='Add DBS' onOpenDescription={onOpenDescription} description={descriptions.title} />
							<CheckboxInput
								style={{ height: 38, width: 'max-content' }}
								isChecked={rest['title']}
								changeHandler={value => changeHandler(id, 'title', value)}
								isDisabled={!isEditable}
							/>
						</div>
						{index === 0 && isEditable ? (
							<RoundPlusButton style={{ position: 'absolute', right: '-40px', top: '5px' }} clickHandler={onCreateNewTab} />
						) : (
							isEditable && (
								<img src={CrossIcon} style={{ position: 'absolute', right: '-20px', top: '15px', cursor: 'pointer' }} onClick={() => onRemoveTab(id)} />
							)
						)}
						{id === activeDBSTabId && rest['title'] && (
							<div className={style.fields}>
								{inputs.map(({ field, options, type, name }) => {
									const description = descriptions[field]
									return (
										<div className={style.field} key={name}>
											<span className={style.label}>
												{name} {description && <img className={style.icon} src={ExclamationIcon} alt='' onClick={() => onOpenDescription(description)} />}
											</span>{' '}
											{type === 'textInput' ? (
												<TextInput
													classNames={!isEditable ? ['borderless'] : []}
													style={{ height: '38px' }}
													changeHandler={value => changeHandler(id, field, value)}
													value={rest[field]}
													isDisabled={!isEditable || !permissions[field]?.edit}
												/>
											) : type === 'select' ? (
												<Select
													inputClassNames={!isEditable ? ['borderless'] : []}
													options={options}
													changeHandler={value => changeHandler(id, field, value)}
													value={rest[field]}
													isDisabled={!isEditable || !permissions[field]?.edit}
												/>
											) : type === 'checkbox' ? (
												<CheckboxInput
													style={{ height: 38, width: 'max-content' }}
													isChecked={rest[field]}
													changeHandler={value => changeHandler(id, field, value)}
													isDisabled={!isEditable || !permissions[field]?.edit}
												/>
											) : type === 'dataList' ? (
												<DataList
													inputClassNames={!isEditable ? ['borderless'] : []}
													inputStyle={{ width: '100%' }}
													options={options}
													value={rest[field]}
													searchPlaceholder='Start typing country'
													changeHandler={value => changeHandler(id, field, value)}
													maxVisibleOptionsQuantity={5}
													isDisabled={!isEditable || !permissions[field]?.edit}
												/>
											) : (
												type === 'datepicker' && (
													<DatePicker
														classNames={!isEditable ? ['borderless'] : []}
														style={{ width: '100%' }}
														value={rest[field]}
														changeHandler={value => changeHandler(id, field, value)}
														isDisabled={!isEditable || !permissions[field]?.edit}
													/>
												)
											)}
										</div>
									)
								})}
							</div>
						)}
					</div>
				)
			})}
		</div>
	)
}

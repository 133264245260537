import * as types from './studentCourseSession.types'

const initialState = {
  programmeRecord: {
    imported: false,
    program_id: null,
    academic_year: null,
    context_id: null,
    ordinand_id: null,
    learning_centre_id: null,
    auditing: null,
    credits: null,
    eligible_for_support: null,
    SLC_Loaт: null,
    HESA_Qual_code_id: null,
    HESA_Qual_award_id: null,
    course_session_start_date: null,
    course_session_end_date: null,
    HESA_reason_course_session: {
      id: null,
      reason_note: '',
    },
    status_id: null,
    program_years_id: null,
    accommodation_id: null,
    sponsor_id: null,
    institution_id: null,
    diocese_id: null,
    pastoral_tutor: null,
    financial_support_id: null,
    student_registration_id: null,
    phd_submission_date: null,
    start_date: null,
    leaving_date: null,
    finishing_date: null,
    ID: null,
    trashed: null,
    statusold: null,
    sselig: null,
    future_prog: null,
    fee_status_id: null,
    session_year_id: null,
    engagement_id: null,
    student_course_session_identifier_id: null,
    employer_fee_id: null,
    fee_method_id: null,
    invoice_hesa_identifier_id: null,
    invoice_fee_amount: null,
    intercalation_id: null,
    intended_thesis_title: null,
    pgr_language_identifier_id: null,
    pgr_language_percentage: null,
    placement_id: null,
    preparatory_student_course_session_flag_id: null,
    student_course_session_end_date: null,
    student_course_session_mode_id: null,
    study_abroad_id: null,
    include_in_hesa: null,
  },
  errorsFields: [],
  isLoading: false,
  isSuccess: false,
  options: {
    static: {},
    dynamic: {},
    dynamicByStudent: {},
  },
  permissions: {
    fields: {
      HESA_reason_course_session: { view: true, edit: true },
      SLC_Loan_id: { view: true, edit: true },
      academic_year: { view: true, edit: true },
      accommodation_id: { view: true, edit: true },
      auditing: { view: true, edit: true },
      course_identifier: { view: true, edit: false },
      credits: { view: true, edit: true },
      diocese_id: { view: true, edit: true },
      eligible_for_support: { view: true, edit: true },
      employer_fee_id: { view: true, edit: true },
      engagement_id: { view: true, edit: true },
      expected_end_date: { view: true, edit: true },
      fee_method_id: { view: true, edit: true },
      fee_status_id: { view: true, edit: true },
      financial_support_id: { view: true, edit: true },
      finishing_date: { view: true, edit: true },
      intercalation_id: { view: true, edit: true },
      invoice_fee_amount: { view: true, edit: true },
      invoice_hesa_identifier_id: { view: true, edit: true },
      learning_centre_id: { view: true, edit: true },
      leaving_date: { view: true, edit: true },
      ordinand_id: { view: true, edit: true },
      pastoral_tutor: { view: true, edit: true },
      pgr_language_identifier_id: { view: true, edit: true },
      pgr_language_percentage: { view: true, edit: true },
      placement_id: { view: true, edit: true },
      predicted_student_load: { view: true, edit: true },
      preparatory_student_course_session_flag_id: { view: true, edit: true },
      program_id: { view: true, edit: true },
      program_years_id: { view: true, edit: true },
      include_in_hesa: { view: true, edit: true },
      session_year_id: { view: true, edit: true },
      sponsor_id: { view: true, edit: true },
      start_date: { view: true, edit: true },
      status_id: { view: true, edit: true },
      student_course_session_end_date: { view: true, edit: true },
      student_course_session_fee_amount: { view: true, edit: true },
      student_course_session_identifier_id: { view: true, edit: true },
      student_course_session_mode_id: { view: true, edit: true },
      student_course_session_start_date: { view: true, edit: true },
      study_abroad_id: { view: true, edit: true },
      study_years: { view: true, edit: true },
      context_id: { view: true, edit: true },
      course_session_start_date: { view: true, edit: true },
      phd_submission_date: { view: true, edit: true },
      intended_thesis_title: { view: true, edit: true },
    },
  },
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: payload,
      }
    case types.SET_STUDENT_COURSE_SESSION:
      return {
        ...state,
        programmeRecord: payload,
      }
    case types.SET_ERRORS_FIELDS:
      return {
        ...state,
        errorsFields: payload,
      }
    case types.CLEAR_DATA:
      return {
        ...state,
        programmeRecord: initialState.programmeRecord,
        errorsFields: [],
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          [payload.name]: payload.data,
        },
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    default:
      return state
  }
}

import * as types from './venue.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  venue: { venue_name: null, postcode: null, venue_identifier: null, venue_own_identifier: null, venue_ukprn: null },
  options: {
    static: {},
    dynamic: {},
  },
  errors: {},
  permissions: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      }
    case types.SET_VENUE:
      return {
        ...state,
        venue: action.payload,
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      }
    case types.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    case types.CLEAR_DATA:
      return {
        ...state,
        venue: initialState.venue,
        errors: initialState.errors,
      }
    default:
      return state
  }
}

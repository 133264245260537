import React from 'react'
import { StudentFinancialSupportForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { studentFinancialSupportSelectors, studentFinancialSupportActions } from '@Store/studies'

export const CreateStudentFinancialSupport = ({ programmeRecord, onOpenDescriptionModal, handleBack }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { contactId, studyProgrammeId } = useParams()
  const studentFinancialSupport = useSelector(studentFinancialSupportSelectors.studentFinancialSupport)
  const options = useSelector(studentFinancialSupportSelectors.options)
  const isLoading = useSelector(studentFinancialSupportSelectors.isLoading)

  const onCreate = data => {
    dispatch(
      studentFinancialSupportActions.create({
        data,
        history,
        studentCourseSessionId: programmeRecord.student_course_session_id,
        studyProgrammeId,
        contactId,
      })
    )
  }

  const onCancel = () => {
    handleBack()
  }

  return (
    <StudentFinancialSupportForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onCreate}
      onCancel={onCancel}
      canEdit={true}
      isNew
      initialValue={studentFinancialSupport}
      options={options}
      isLoading={isLoading}
    />
  )
}

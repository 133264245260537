export const isLoading = state => state.studiesReducer.programme.courseReference.isLoading

export const isSuccess = state => state.studiesReducer.programme.courseReference.isSuccess

export const courseReference = state => state.studiesReducer.programme.courseReference.courseReference

export const errors = state => state.studiesReducer.programme.courseReference.errors

export const options = state => state.studiesReducer.programme.courseReference.options

export const permissions = state => state.studiesReducer.programme.courseReference.permissions

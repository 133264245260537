import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'
import { API } from '@Root/API'

const titles = { onRead: 'Snapshot', onEdit: 'Edit Snapshot', onCreate: 'Create Snapshot' }
export const SnapshotForm = ({ initialValue, onDelete, onOpenDescription, descriptions, isNew, isLoading, onSave, canDelete }) => {
  const [isEditable, toggleEditable] = useToggle(isNew)
  const [data, setData] = useState({})
  const { errors, setError } = useError()

  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  const onChange = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const validateForm = () => {
    const { contact_ids } = data
    if (!contact_ids) {
      setError('contact_ids', 'Required')
      return false
    }
    return true
  }

  const fetchContactOptions = async value => {
    const { data } = await API.admin.snapshot.searchStudents({ name: value, limit: '100' })
    return data.data.map(option => ({ value: option.id, label: option.name }))
  }

  const onSubmit = async () => {
    const { contact_ids } = data
    if (!validateForm()) return
    await onSave({ contact_ids })
  }

  const onCancel = () => {
    toggleEditable()
  }

  const { contact_ids, created_at, contact_name, id } = data
  const { onRead, onCreate, onEdit } = titles
  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', ...(!isNew ? (canDelete ? ['delete'] : []) : ['save'])] : []}
      buttonsNames={[{ button: 'delete', name: 'Delete this Snapshot' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading}
      clickCancelButtonHandler={onCancel}
      clickDeleteButtonHandler={() => onDelete(id)}
      clickSaveButtonHandler={onSubmit}
      clickToggleEditModeButtonHandler={toggleEditable}
      isEditable={isEditable}
      editTitle={onEdit}
      readTitle={onRead}
      createTitle={onCreate}
      isCreate={isNew}
      canEdit={true}
      idForm={id ? `snapshot_${id}` : null}
    >
      {!isNew ? (
        <div className={styles.grid}>
          <div className={styles.column}>
            <Field
              label={'Created at'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={created_at}
              description={descriptions.created_at}
              placeholder='Created at'
              isDisabled
              onChange={onChange('created_at')}
              field={'textField'}
              onOpenDescriptionModal={onOpenDescription}
            />{' '}
          </div>
          <div className={styles.column}>
            <Field
              label={'Contact Name'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={contact_name}
              placeholder='Contact Name'
              isDisabled
              onChange={onChange('contact_name')}
              field={'textField'}
              description={descriptions.contact_id}
              onOpenDescriptionModal={onOpenDescription}
            />
          </div>
        </div>
      ) : (
        <div className={styles.grid}>
          <div className={styles.column}>
            <Field
              label={'Contacts'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              listStyle={{ width: 260 }}
              value={contact_ids}
              placeholder='Contacts'
              isDisabled={!isEditable}
              onChange={onChange('contact_ids')}
              field={'multiDataListAsync'}
              description={descriptions.contact_id}
              error={errors.contact_ids}
              fetchOptionsHandler={value => fetchContactOptions(value)}
              onOpenDescriptionModal={onOpenDescription}
            />
          </div>
        </div>
      )}
    </FormWrapper>
  )
}

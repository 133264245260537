export const errorMessage = error => {
  try {
    const errors = error.response.data.errors;
    const errorMessage = errors[Object.keys(errors)[0]][0];
    if (errorMessage) return errorMessage;
  } catch (err) {}
  try {
    const errorMessage = error.response.data.message;
    if (errorMessage) return errorMessage;
  } catch (err) {}
  return error.message;
};

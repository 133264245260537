export const isLoading = state => state.adminReducer.fieldConstructor.isLoading

export const isSuccess = state => state.adminReducer.fieldConstructor.isSuccess

export const fields = state => state.adminReducer.fieldConstructor.fields

export const field = state => state.adminReducer.fieldConstructor.field

export const contactFields = state => state.adminReducer.fieldConstructor.contactFields

export const paginationInformation = state => state.adminReducer.fieldConstructor.paginationInformation

export const errors = state => state.adminReducer.fieldConstructor.errors

export const permissions = state => state.adminReducer.fieldConstructor.permissions

import { API } from '@Root/API'

export const adminEmail = {
  getTemplate: () => API.admin.emailsTemplate.admin.get(),
  createTemplate: (subject, body) =>
    API.admin.emailsTemplate.admin.create({
      subject,
      template_email: body,
    }),
  onRead: 'Email for TEID admin',
  onEdit: 'Edit Email for TEID admin',
  pathId: 'admin_email',
}

export const applicantEmail = {
  getTemplate: () => API.admin.emailsTemplate.applicant.get(),
  createTemplate: (subject, body) =>
    API.admin.emailsTemplate.applicant.create({
      subject,
      template_email: body,
    }),
  onRead: 'Email for TEID applicant',
  onEdit: 'Edit Email for TEID applicant',
  pathId: 'applicant_email',
}

export const applicantEmailSuccessfulSubmission = {
  getTemplate: () => API.admin.emailsTemplate.applicant.successfulSubmission.get(),
  createTemplate: (subject, body) =>
    API.admin.emailsTemplate.applicant.successfulSubmission.create({
      subject,
      template_email: body,
    }),
  onRead: 'Email for applicant after finish form',
  onEdit: 'Edit Email for applicant after finish form',
  pathId: 'applicant_email_successful_submission',
}

export const newCreatedUserEmail = {
  getTemplate: () => API.admin.emailsTemplate.createNewUser.get(),
  createTemplate: (subject, body) =>
    API.admin.emailsTemplate.createNewUser.create({
      subject,
      template_email: body,
    }),
  onRead: 'Email for new created user',
  onEdit: 'Edit Email for new created user',
  pathId: 'new_created_user_email',
}

export const emailForUsersAboutUpdatesEmail = {
  getTemplate: () => API.admin.emailsTemplate.emailForUsersAboutUpdates.get(),
  createTemplate: (subject, body) =>
    API.admin.emailsTemplate.emailForUsersAboutUpdates.create({
      subject,
      template_email: body,
    }),
  onRead: 'Email for users about updates',
  onEdit: 'Edit Email for users about updates',
  pathId: 'email_users_about_updates_email',
}

export const resetPasswordEmail = {
  getTemplate: () => API.admin.emailsTemplate.resetPassword.get(),
  createTemplate: (subject, body) =>
    API.admin.emailsTemplate.resetPassword.create({
      subject,
      template_email: body,
    }),
  onRead: 'Reset password email',
  onEdit: 'Edit Reset password email',
  pathId: 'reset_password_email',
}

import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { CreateFundingBody } from './Create'
import { EditFundingBody } from './Edit'
import { useDispatch, useSelector } from 'react-redux'
import { studentCourseSessionSelectors, studentCourseSessionActions } from '@Store/studies/studentCourseSession/index'
import { modalActions } from '@Root/store'
import { fundingBodyActions } from '@Store/studies'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager/index'

export const FundingBodyPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const programmeRecord = useSelector(studentCourseSessionSelectors.programmeRecord)
  const pathname = location.pathname
  const isNew = pathname.includes('new')
  const { studyProgrammeId, contactId } = useParams()
  const isDuplicate = pathname.includes('duplicate')
  const DEFAULT_URL = `/home/studies/programmes/study/${contactId}/${studyProgrammeId}/funding_body`

  useEffect(() => {
    dispatch(studentCourseSessionActions.get({ studyProgrammeId, isCopy: isDuplicate }))
    dispatch(fundingBodyActions.getOptions())
    dispatch(fieldTooltipManagerActions.getHesaDescriptions())
    return () => {
      dispatch(studentCourseSessionActions.clearData({}))
    }
  }, [])

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  return (
    <SectionWrapper hasBackButton backButtonText={'Funding Body'} clickBackButtonHandler={() => history.push(DEFAULT_URL)} activeName>
      <div className={styles.sections}>
        {isNew ? (
          <CreateFundingBody onOpenDescriptionModal={onOpenDescriptionModal} programmeRecord={programmeRecord} />
        ) : (
          <EditFundingBody onOpenDescriptionModal={onOpenDescriptionModal} programmeRecord={programmeRecord} />
        )}
      </div>
    </SectionWrapper>
  )
}

export const TOGGLE_LOADING = 'cleric/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'cleric/TOGGLE_SUCCESS'
export const GET_CLERIC = 'cleric/GET_CLERIC'
export const SET_CLERIC = 'cleric/SET_CLERIC'
export const EDIT_CLERIC = 'cleric/EDIT_CLERIC'
export const DELETE_CLERIC = 'cleric/DELETE_CLERIC'
export const GET_STATIC_OPTIONS = 'cleric/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'cleric/GET_DYNAMIC_OPTIONS'
export const SET_OPTIONS = 'cleric/SET_OPTIONS'
export const SET_PERMISSIONS = 'cleric/SET_PERMISSIONS'

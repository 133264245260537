import * as types from './snackbar.types'
import updateState from '../../helpers/updateState'

const initialState = {
	snackbar: null,
	successNotification: null,
}

const setSnackbar = (state, { data }) => {
	return updateState(state, {
		snackbar: data,
	})
}

const setSuccessNotification = (state, { data }) => {
	return updateState(state, {
		successNotification: data,
	})
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_SNACKBAR:
			return setSnackbar(state, action)
		case types.SET_SUCCESS_NOTIFICATION:
			return setSuccessNotification(state, action)
		default:
			return state
	}
}

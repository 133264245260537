import React from 'react'
import { SectionWrapper } from '@Root/HOCs'
import { useHistory, useLocation } from 'react-router'
import styles from './style.module.scss'
import { CreateModule } from './Create'
import { EditModule } from './Edit'

export const ModulePage = () => {
	const history = useHistory()
	const location = useLocation()
	const pathname = location.pathname
	const isNew = pathname.includes('new')
	return (
		<SectionWrapper hasBackButton backButtonText={'Back to Modules'} clickBackButtonHandler={() => history.push(`/home/studies/modules`)}>
			<div className={styles.section}>{isNew ? <CreateModule /> : <EditModule />}</div>
		</SectionWrapper>
	)
}

import * as types from './studentCourseSession.types'

export const actions = {
  toggleLoading: payload => ({
    type: types.TOGGLE_LOADING,
    payload,
  }),
  toggleSuccess: payload => ({
    type: types.TOGGLE_SUCCESS,
    payload,
  }),
  get: payload => ({
    type: types.GET_STUDENT_COURSE_SESSION,
    payload,
  }),
  delete: payload => ({
    type: types.DELETE_STUDENT_COURSE_SESSION,
    payload,
  }),
  edit: payload => ({
    type: types.EDIT_STUDENT_COURSE_SESSION,
    payload,
  }),
  set: payload => ({
    type: types.SET_STUDENT_COURSE_SESSION,
    payload,
  }),
  create: payload => ({
    type: types.CREATE_STUDENT_COURSE_SESSION,
    payload,
  }),
  copy: payload => ({
    type: types.COPY_STUDENT_COURSE_SESSION,
    payload,
  }),
  setErrorsFields: payload => ({
    type: types.SET_ERRORS_FIELDS,
    payload,
  }),
  clearData: payload => ({
    type: types.CLEAR_DATA,
    payload,
  }),
  getStaticOptions: payload => ({
    type: types.GET_STATIC_OPTIONS,
    payload,
  }),
  getDynamicOptions: payload => ({
    type: types.GET_DYNAMIC_OPTIONS,
    payload,
  }),
  getDynamicOptionsByStudent: payload => ({
    type: types.GET_DYNAMIC_OPTIONS_BY_STUDENT,
    payload,
  }),

  setOptions: payload => ({
    type: types.SET_OPTIONS,
    payload,
  }),
  setPermissions: payload => ({
    type: types.SET_PERMISSIONS,
    payload,
  }),
}

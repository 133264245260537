import React, { useState, useEffect, useRef } from 'react'
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions, programmeActions, programmeSelectors, fieldTooltipManagerActions, fieldTooltipManagerSelectors } from '@Root/store'
import { SectionWrapper } from '@Root/HOCs'
import { Spinner, ActionsDropdown, StudiesProgrammeForm } from '@Root/components'
import { useHistory, useParams } from 'react-router'
import { permissionsSelectors } from '@Store/permissions'
import { DetailInformation } from './DetailInformation'

export const StudiesProgrammePage = ({ styleConfig }) => {
  const history = useHistory()
  const [isShowDefault, setShowDefault] = useState(false)
  const programme = useSelector(programmeSelectors.programme)
  const isLoading = useSelector(programmeSelectors.isLoading)
  const options = useSelector(programmeSelectors.options)
  const permissions = useSelector(programmeSelectors.permissions)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).programmes
  const elementRef = useRef(null)
  const { programmeId } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    let timeout
    if (isShowDefault) {
      timeout = setTimeout(() => {
        setShowDefault(false)
      }, 0)
    }
    return () => clearTimeout(timeout)
  }, [isShowDefault])

  const onScrollToStudentsList = () => {
    if (elementRef.current) {
      setShowDefault(true)
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  const putProgramme = payload => {
    dispatch(programmeActions.edit({ data: payload, programmeId }))
  }

  const deleteProgramme = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This programme will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        dispatch(programmeActions.delete({ programmeId, history }))
      })
      .finally(() => dispatch(modalActions.hideModal()))
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('NotificationModal', {
          text: description,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
          title,
        })
      )
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  useEffect(() => {
    dispatch(programmeActions.get({ programmeId, history }))
    dispatch(programmeActions.getStaticOptions())
    dispatch(programmeActions.getDynamicOptions())
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'programmes' }))

    return () => {
      dispatch(programmeActions.clearPermissions())
    }
  }, [dispatch, history, programmeId])

  const actions = [
    ...(dataCreationPermissions.programmes
      ? [
          {
            name: 'Create a Programme',
            path: `/home/studies/programmes/new`,
          },
        ]
      : []),
    ...(programme.can_be_created_course_initiative
      ? [
          {
            name: 'Create a Course Initiative',
            path: `/home/studies/programmes/${programmeId}/course_initiatives/new`,
          },
        ]
      : []),
    ...(programme.can_be_created_course_reference
      ? [
          {
            name: 'Create a Course Reference',
            path: `/home/studies/programmes/${programmeId}/course_reference/new`,
          },
        ]
      : []),
    ...(programme.can_be_created_course_role
      ? [
          {
            name: 'Create a Course Role',
            path: `/home/studies/programmes/${programmeId}/course_role/new`,
          },
        ]
      : []),
    ...(programme.can_be_created_curriculum_accreditation
      ? [
          {
            name: 'Create a Curriculum Accreditation',
            path: `/home/studies/programmes/${programmeId}/curriculum_accreditation/new`,
          },
        ]
      : []),
  ]
  return (
    <div className={classes.wrapper}>
      {!!actions.length && (
        <ActionsDropdown
          actions={actions}
          clickHandler={actionName => actions.find(action => action.name === actionName).handler()}
          style={{ position: 'absolute', right: 10, top: -60 }}
        />
      )}
      <div className={classes.sectionsWrapper}>
        {!isLoading && Object.keys(options.static).length && Object.keys(options.dynamic).length ? (
          <SectionWrapper
            hasBackButton
            backButtonText='Programmes'
            clickBackButtonHandler={() => history.push('/home/studies/programmes')}
            hasAdditionalAction
            additionalActionText={'Students on this Programme'}
            clickAdditionTextHandler={onScrollToStudentsList}
          >
            <div>
              <StudiesProgrammeForm
                title={{ onEdit: 'Edit the programme' }}
                options={{ ...options.static, ...options.dynamic }}
                initialData={programme}
                permissions={permissions}
                deleteHandler={(onStartCallback, onFinishCallback) => deleteProgramme(onStartCallback, onFinishCallback)}
                saveHandler={(payload, cb) => putProgramme(payload, cb)}
                openDescriptionModal={onOpenDescriptionModal}
                descriptions={descriptions}
              />
              <div className={classes.tableWrapper} ref={elementRef}>
                <DetailInformation styleConfig={styleConfig} isShowDefault={isShowDefault} />
              </div>
            </div>
          </SectionWrapper>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

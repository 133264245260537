import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { entryQualificationSubjectActions, entryQualificationSubjectSelectors } from '@Store/studies'
import { EntryQualificationSubjectForm } from '@Root/components'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router-dom'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const CreateEntryQualificationSubject = ({ parentURL, onOpenDescriptionModal }) => {
  const entryQualificationSubject = useSelector(entryQualificationSubjectSelectors.entryQualificationSubject)
  const options = useSelector(entryQualificationSubjectSelectors.options)
  const isLoading = useSelector(entryQualificationSubjectSelectors.isLoading)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: engagementId, contactId, entryProfileId, entryQualificationId } = useParams()
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).qualification_subject

  const onCreate = data => {
    dispatch(entryQualificationSubjectActions.create({ data, history, contactId, engagementId, entryProfileId, entryQualificationId }))
  }

  const onCancel = () => {
    history.length > 1 ? history.goBack() : history.push(parentURL)
  }

  if (!dataCreationPermissions.engagements_sub) return <Redirect to={parentURL} />
  return (
    <EntryQualificationSubjectForm
      onSave={onCreate}
      onCancel={onCancel}
      canEdit={true}
      isNew
      initialValue={entryQualificationSubject}
      options={options}
      isLoading={isLoading}
      descriptions={descriptions}
      onOpenDescriptionModal={onOpenDescriptionModal}
    />
  )
}

import React, { useMemo } from 'react'
import styles from './style.module.scss'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getCurrentLinkName } from '@Root/helpers'
import DocumentTitle from 'react-document-title'
import { PageTitle, PageNavLinks } from '@Root/components'
import { GDPRTable } from './Table'
import { gdprLinks } from '@Root/configs'
import { useLocation } from 'react-router'
import { generateStyleConfig } from './lib'
import { useSelector } from 'react-redux'
import { permissionsSelectors } from '@Store/permissions'

export const GDPRPage = () => {
	const location = useLocation()
	const currentLinkName = getCurrentLinkName(gdprLinks, location)
	const tabsPermissions = useSelector(permissionsSelectors.tabsPermissions)

	//Note: This config exists for overwriting default css styles
	const styleConfig = generateStyleConfig(false)


	const filteredPageLinks = useMemo(() => gdprLinks.filter((link) => tabsPermissions?.gdpr?.tabs?.[link.label]), [tabsPermissions])
	const PageInMemo = useMemo(() => () => <GDPRTable styleConfig={styleConfig} />, [styleConfig])
	return (
		<DocumentTitle title={`${currentLinkName} - GDPR`}>
			<div className={styles.wrapper}>
				<PageTitle title={currentLinkName} style={styleConfig.pageTitle} />
				<PageNavLinks style={styleConfig.navLinksStyles} root={`/home/gdpr`} links={filteredPageLinks} />
				<Switch>
					{filteredPageLinks.map((link, i) => (
						<Route exact path={`/home/gdpr/${link.path}`} component={PageInMemo} key={i} />
					))}
					<Redirect to='/home/gdpr/gdpr-log' />
				</Switch>
			</div>
		</DocumentTitle>
	)
}

import { createSelector } from 'reselect'

export const isLoading = state => state.contactReducer.person.staff.isLoading

export const isSuccess = state => state.contactReducer.person.staff.isSuccess

export const staff = state => state.contactReducer.person.staff.staff

export const options = state => state.contactReducer.person.staff.options

export const permissions = state => state.contactReducer.person.staff.permissions

export const isPermissionsLoaded = createSelector(permissions, permissions => (permissions.fields ? Object.keys(permissions.fields).length > 0 : false))

import React, { useEffect } from 'react'
import { axios } from '@Root/API'
import { useHistory, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { authActions } from '@Store/auth'

export const TrackServerRequest = ({ children }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  useEffect(() => {
    axios.interceptors.response.use(null, err => {
      const { status, data } = err.response
      if (status === 401) {
        if (pathname === '/login' || pathname === '/page-locked') {
          return
        }
        dispatch(authActions.removeToken({ history }))
      }
      if (status === 404) {
        history.replace('/page-not-found')
      }
      if (status === 403) {
        history.replace('/forbidden-page')
      }
      if (status === 423) {
        dispatch(authActions.removeToken({}))
        dispatch(authActions.setAuthError({ status, message: data }))
        history.push('/page-locked')
      }
      return Promise.reject(err)
    })
  }, [dispatch, history])

  useEffect(() => {
    axios.interceptors.request.use(config => {
      config.headers['url-from'] = window.location.pathname
      return config
    })
  }, [])

  return <>{children}</>
}

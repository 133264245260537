import React from 'react'
import { ContactsOrganisationForm, ContactsPersonForm } from '@Root/components'
import { API } from '@Root/API'
import { Redirect } from 'react-router-dom'

export const ContactInformation = ({
  onSubmit,
  onDelete,
  hideModal,
  showModal,
  onOpenDescriptionModal,
  isNew,
  options,
  contactType,
  initialValuesPerson,
  initialValuesOrganisation,
  personRelationships,
  organisationRelationships,
  error,
  isLoading,
  isSuccess,
  descriptions,
  onError,
  onCancel,
  canCreateOrganisation,
  canCreatePerson,
  permissions,
  is_trashed,
}) => {
  if (isNew) {
    if (contactType === 'person' ? !canCreatePerson : !canCreateOrganisation) return <Redirect to={'/home/contacts'} />
  }

  return contactType === 'person' ? (
    <ContactsPersonForm
      initialIsEditable={isNew}
      isNew={isNew}
      titleOption={{ onEdit: 'Edit the person', onCreate: 'Create the person' }}
      initialData={initialValuesPerson}
      canEdit={permissions.form?.edit}
      options={options}
      personRelationshipsDataList={personRelationships}
      fetchContactHandler={params => API.getOrganisations(params)}
      buttons={['cancel', 'delete', 'save']}
      deleteHandler={onDelete}
      saveHandler={onSubmit}
      errorHandler={error => onError(error)}
      hideModal={hideModal}
      showModal={showModal}
      isSaving={isLoading}
      isSuccess={isSuccess}
      error={error}
      openDescription={onOpenDescriptionModal}
      descriptions={descriptions.person.form}
      onCancel={onCancel}
      permissions={permissions.fields}
      is_trashed={is_trashed}
    />
  ) : (
    <ContactsOrganisationForm
      initialIsEditable={isNew}
      isNew={isNew}
      title={{ onEdit: 'Edit the organisation', onCreate: 'Create the organisation' }}
      initialData={initialValuesOrganisation}
      canEdit={permissions.form?.edit}
      options={options}
      organisationRelationshipsDataList={organisationRelationships}
      buttons={['cancel', 'delete', 'save']}
      fetchContactHandler={params => API.getPersons(params)}
      deleteHandler={onDelete}
      saveHandler={onSubmit}
      hideModal={hideModal}
      showModal={showModal}
      isSaving={isLoading}
      isSuccess={isSuccess}
      error={error}
      openDescription={onOpenDescriptionModal}
      descriptionsOrganisation={descriptions.organisation.form}
      descriptionsTrainingFacilities={descriptions.organisation['training-facilities']}
      onCancel={onCancel}
      permissions={permissions.fields}
      is_trashed={is_trashed}
    />
  )
}

import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { SectionTitle, EditButton, Warning, InputLabel, TextInput, RadioInputGroup } from "@Root/components";
const ContactsComputerUserForm = ({ isNew, initialData, deleteHandler, saveHandler, errorHandler }) => {
  const [isEditable, setIsEditable] = useState(isNew);
  const [data, setData] = useState(initialData);
  const [isSaving, setIsSavind] = useState(false);

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSavind(true);
      },
      () => {
        ContactsComputerUserForm.isMounted && setIsSavind(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    setIsSavind(true);
    try {
      await saveHandler(data);
      ContactsComputerUserForm.isMounted && setIsEditable(false);
    } catch (error) {
      errorHandler(error);
    }
    ContactsComputerUserForm.isMounted && setIsSavind(false);
  };

  useEffect(() => {
    ContactsComputerUserForm.isMounted = true;
    return () => {
      ContactsComputerUserForm.isMounted = false;
    };
  }, []);

  return (
    <FormWrapper
      buttons={isEditable ? [...(!isNew ? ["cancel", "delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete Computer User Details" }]}
      buttonsAreDisabled={isSaving}
      isSpinning={isSaving}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle title={isEditable ? "Edit Computer User Details" : "Computer User Details"} />
        {!isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}
      </div>

      <div className={classes.rowsWrapper}>
        {isNew && (
          <Warning>
            No computer user account exists for this student. To add one, please fill in foorm below. <br />{" "}
            <b>Note that a valid contact email address is required!</b>
          </Warning>
        )}
        <div className={classes.rowWrapper}>
          <InputLabel text="Student's Institution" />
          <RadioInputGroup
            style={{ position: "absolute", left: 150 }}
            values={["TRIN", "BBC"]}
            currentValue={data.institution}
            changeHandler={value => handleChangeInput("institution", value)}
            isInline
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Student's Number" />
          <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 150, width: 260 }} value={data.number} isDisabled />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Student's Full Name" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.fullName}
            changeHandler={value => handleChangeInput("fullName", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Student's Username" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.userName}
            changeHandler={value => handleChangeInput("userName", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Student's Password" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.password}
            changeHandler={value => handleChangeInput("password", value)}
            isDisabled={!isEditable}
            isPassword
          />
        </div>
      </div>
    </FormWrapper>
  );
};

ContactsComputerUserForm.propTypes = {
  isNew: PropTypes.bool,
  initialData: PropTypes.shape({
    institution: PropTypes.string,
    number: PropTypes.string,
    fullName: PropTypes.string,
    userName: PropTypes.string,
    password: PropTypes.string
  }),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func
};

ContactsComputerUserForm.defaultProps = {
  isNew: false,
  initialData: {
    institution: "TRIN",
    number: "",
    fullName: "",
    userName: "",
    password: ""
  },
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {}
};

export default ContactsComputerUserForm;

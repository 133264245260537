import moment from 'moment'
import { DATE } from '@Root/configs'

export const isValidDataFormat = value => {
  let lengthOfData = null
  const DATE_FORMAT = DATE.format
  const date = moment(value, DATE_FORMAT)

  const numberData = getNumberOfData(value)

  if (numberData) {
    lengthOfData = numberData.length
  }

  const isValidDate = date.isValid()
  return isValidDate && lengthOfData === 8
}

export const getNumberOfData = value => {
  if (!value) return value
  let regexp = /\d/g
  return value.match(regexp)
}

export const validateData = arrKey => {
  const answerArray = arrKey.map(value => value && !isValidDataFormat(value))
  return answerArray.includes(true)
}

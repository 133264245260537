import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";

export const InputError = ({ children, style, error }) => {
  return (
    <div className={classes.InputError} style={style}>
      {error ? <div className={classes.wrapper}>{children}</div> : children}
      {error && <div className={classes.error}>{error}*</div>}
    </div>
  );
};

InputError.propTypes = {
  children: PropTypes.element,
  style: PropTypes.object,
  error: PropTypes.string
};

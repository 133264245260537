import * as types from './consents.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	updateConsents: payload => ({
		type: types.UPDATE_CONSENTS,
		payload,
	}),
	setConsents: payload => ({
		type: types.SET_CONSENTS,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { API } from '@Root/API'
import { snackbarActions, modalActions } from '@Root/store'
import { errorMessage } from '@Root/helpers'
import { AdminFormTemplate, Spinner } from '@Root/components'

import { SectionWrapper } from '@Root/HOCs'
import { useHistory, useLocation } from 'react-router'
import { permissionsSelectors } from '@Store/permissions'

export const PageTemplate = () => {
  const [isShown, setIsShown] = useState(false)
  const [data, setData] = useState([])
  const [keyWord, setKeyWord] = useState('')
  const [title, setTitle] = useState('')
  const [deleteByField, setDeleteByField] = useState('')
  const [getValueByField, setGetValueByField] = useState('')
  const [actionsForForm, setActionsForForm] = useState('')
  const [configuration, setConfiguration] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [validationType, setValidationType] = useState('')
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useLocation()
  const param = params.pathname.split('/')[4]
  const getAPIObject = (payload, param) => {
    const object = {
      'awarding-body': {
        get: async () => await API.admin.awardingBody.get(),
        post: async () => await API.admin.awardingBody.create(payload),
        put: async () => await API.admin.awardingBody.edit(payload),
        delete: async () => await API.admin.awardingBody.remove(payload),
      },
      emails: {
        get: async () => await API.admin.emails.get(),
        post: async () => await API.admin.emails.create(payload),
        put: async () => await API.admin.emails.edit(payload),
        delete: async () => await API.admin.emails.remove(payload),
      },
      statuses: {
        get: async () => await API.admin.teiStatuses.get(),
        post: async () => await API.admin.teiStatuses.create(payload),
        put: async () => await API.admin.teiStatuses.edit(payload),
        delete: async () => await API.admin.teiStatuses.remove(payload),
      },
      'programme-types': {
        get: async () => await API.admin.programmTypes.get(),
        post: async () => await API.admin.programmTypes.create(payload),
        put: async () => await API.admin.programmTypes.edit(payload),
        delete: async () => await API.admin.programmTypes.remove(payload),
      },
      'student-types': {
        get: async () => await API.admin.studentTypes.get(),
        post: async () => await API.admin.studentTypes.create(payload),
        put: async () => await API.admin.studentTypes.edit(payload),
        delete: async () => await API.admin.studentTypes.remove(payload),
      },
      'fee-statuses': {
        get: async () => await API.admin.feeStatuses.get(),
        post: async () => await API.admin.feeStatuses.create(payload),
        put: async () => await API.admin.feeStatuses.edit(payload),
        delete: async () => await API.admin.feeStatuses.remove(payload),
      },
      ordinand: {
        get: async () => await API.admin.ordinand.get(),
        post: async () => await API.admin.ordinand.create(payload),
        put: async () => await API.admin.ordinand.edit(payload),
        delete: async () => await API.admin.ordinand.remove(payload),
      },
      tei_code: {
        get: async () => await API.admin.teiCode.get(),
        post: async () => '',
        put: async () => await API.admin.teiCode.edit(payload),
        delete: async () => '',
      },
      hesa_root_url: {
        get: async () => await API.admin.hesaRootUrl.get(),
        post: async () => '',
        put: async () => await API.admin.hesaRootUrl.edit(payload),
        delete: async () => '',
      },
      accommodation: {
        get: async () => await API.admin.accommodations.get(),
        post: async () => await API.admin.accommodations.create(payload),
        put: async () => await API.admin.accommodations.update(payload),
        delete: async () => API.admin.accommodations.remove(payload),
      },
      current_reference_year: {
        get: async () => await API.admin.currentReferenceYear.get(),
        post: async () => '',
        put: async () => await API.admin.currentReferenceYear.edit(payload),
        delete: async () => '',
      },
    }
    return object[param]
  }
  const getKeyWord = param => {
    const object = {
      'awarding-body': () => 'Body',
      emails: () => 'Email',
      statuses: () => 'Status',
      'programme-types': () => 'Type',
      'student-types': () => 'Type',
      'fee-statuses': () => 'Status',
      'session-year': () => 'Session Year',
      ordinand: () => 'Ordinand',
      tei_code: () => ['UKPRN', 'Durham TEI code'],
      hesa_root_url: () => 'Url',
      accommodation: () => 'Field',
      current_reference_year: () => ['Year'],
    }

    setKeyWord(object[param])
  }
  const getTitle = param => {
    const object = {
      'awarding-body': () => 'Awarding Body',
      emails: () => 'Emails',
      statuses: () => 'Statuses',
      'fee-statuses': () => 'Fee statuses',
      'student-types': () => 'Student type',
      'programme-types': () => 'Programme type',
      'session-year': () => 'Session Year',
      ordinand: () => 'Ordinand',
      tei_code: () => 'Constant values',
      hesa_root_url: () => 'Hesa Root Url',
      accommodation: () => 'Add Accommodation',
      current_reference_year: () => 'Current reference year',
    }
    setTitle(object[param])
  }
  const getSubTitle = param => {
    const object = {
      'awarding-body': () => 'Body',
      emails: () => 'Email',
      statuses: () => 'Status',
      'fee-statuses': () => 'Status',
      'student-types': () => 'Type',
      'programme-types': () => 'Type',
      'session-year': () => 'Session Year',
      ordinand: () => 'Ordinand',
      tei_code: () => 'Code',
      hesa_root_url: () => 'Url',
      accommodation: () => 'Accommodation',
      current_reference_year: () => 'Year',
    }
    setSubTitle(object[param])
  }
  const getDeleteByField = param => {
    const object = {
      'awarding-body': () => 'id',
      emails: () => 'id',
      statuses: () => 'id',
      'fee-statuses': () => 'id',
      'student-types': () => 'id',
      'programme-types': () => 'name',
      'session-year': () => 'id',
      ordinand: () => 'id',
      tei_code: () => 'id',
      hesa_root_url: () => 'id',
      accommodation: () => 'id',
      current_reference_year: () => 'id',
    }

    setDeleteByField(object[param])
  }
  const getValueForField = param => {
    const object = {
      'awarding-body': () => 'name',
      emails: () => 'email',
      statuses: () => 'name',
      'fee-statuses': () => 'name',
      'student-types': () => 'name',
      'programme-types': () => 'name',
      'session-year': () => 'name',
      ordinand: () => 'name',
      tei_code: () => 'title',
      hesa_root_url: () => 'url',
      accommodation: () => 'title',
      current_reference_year: () => 'year',
    }

    setGetValueByField(object[param])
  }

  const getActionsForForm = param => {
    const object = {
      'awarding-body': { can_create: dataCreationPermissions.program_awarding_body },
      emails: { can_create: dataCreationPermissions.emails },
      statuses: { can_create: dataCreationPermissions.taids_status },
      'fee-statuses': { can_create: dataCreationPermissions.fee_status },
      'student-types': { can_create: dataCreationPermissions.student_type },
      'programme-types': { can_create: dataCreationPermissions.programme_type },
      'session-year': { can_create: dataCreationPermissions.session_year },
      ordinand: { can_create: dataCreationPermissions.ordinand },
      tei_code: { can_create: false },
      hesa_root_url: { can_create: false },
      accommodation: { can_create: true },
      current_reference_year: { can_create: false },
    }
    setActionsForForm(object[param])
  }

  const getConfiguration = param => {
    const object = {
      tei_code: { maxLength: 8 },
    }
    setConfiguration(object[param])
  }

  const getValidations = param => {
    const object = {
      emails: 'email',
    }
    setValidationType(object[param])
  }

  const fetchData = async param => {
    setIsShown(false)
    try {
      const { data } = await getAPIObject(null, param).get()
      setData(data.data)
      setIsShown(true)
    } catch (error) {
      dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
      history.push('/page-not-found')
    }
  }

  const postData = async (payload, callback) => {
    try {
      const { data } = await getAPIObject(payload, param).post()
      setData(prevState => [...prevState, data.data])
      callback()
    } catch (error) {
      dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    }
  }

  const putData = async payload => {
    try {
      const { data } = await getAPIObject(payload, param).put()
      setData(prevState => {
        return prevState.map(item => (item.id === data.data.id ? data.data : item))
      })
    } catch (error) {
      dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    }
  }

  const deleteData = async (value, callback) => {
    const selectedItem = data.find(item => item[deleteByField] === value)
    const ItemIsDeletable = selectedItem?.can_delete
    const ItemIsRemovable = selectedItem?.can_remove
    const neverWillDelete = ItemIsRemovable === false
    if (ItemIsDeletable || getValueByField === 'email') {
      await new Promise((resolve, reject) => {
        dispatch(
          modalActions.showModal('ConfirmationModal', {
            text: `This ${subTitle.toLowerCase()} will be removed?`,
            clickRejectButtonHandler: reject,
            clickResolveButtonHandler: resolve,
          })
        )
      }).then(
        async () => {
          dispatch(modalActions.hideModal())
          callback(true)
          try {
            await getAPIObject(value, param).delete()
            setData(prevState => prevState.filter(item => item[deleteByField] !== value))
          } catch (error) {
            dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
          }
          callback(false)
        },
        () => {
          dispatch(modalActions.hideModal())
        }
      )
    } else {
      await new Promise((resolve, reject) => {
        dispatch(
          modalActions.showModal('NotificationModal', {
            text: `This ${subTitle.toLowerCase()} can't be deleted. ${!neverWillDelete ? 'It`s in use.' : ''}`,
            clickRejectButtonHandler: reject,
            clickResolveButtonHandler: resolve,
          })
        )
      }).then(
        () => {
          dispatch(modalActions.hideModal())
        },
        () => {
          dispatch(modalActions.hideModal())
        }
      )
    }
  }

  useEffect(() => {
    PageTemplate.isMounted = true
    fetchData(param)
    getKeyWord(param)
    getTitle(param)
    getDeleteByField(param)
    getValueForField(param)
    getSubTitle(param)
    getActionsForForm(param)
    getConfiguration(param)
    getValidations(param)
    return () => {
      PageTemplate.isMounted = false
    }
  }, [param])

  return (
    <div className={classes.wrapper} style={{ marginTop: 235 }}>
      {isShown ? (
        <SectionWrapper>
          <AdminFormTemplate
            deleteByField={deleteByField}
            keyWord={keyWord}
            title={title}
            initialData={data}
            getValueByField={getValueByField}
            saveHandler={(payload, callback) => postData(payload, callback)}
            editHandler={payload => putData(payload)}
            deleteHandler={(payload, callback) => deleteData(payload, callback)}
            actions={actionsForForm}
            configuration={configuration}
            validation={validationType}
          />
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: 'absolute' }} />
      )}
    </div>
  )
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { venueActions, venueSelectors } from '@Store/admin'
import { VenueForm } from '@Root/components'
import { useHistory, useParams } from 'react-router'
import { fieldTooltipManagerSelectors, modalActions } from '@Root/store'

export const EditVenue = ({ onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { venueId } = useParams()
  const venue = useSelector(venueSelectors.venue)
  const isLoading = useSelector(venueSelectors.isLoading)
  const errors = useSelector(venueSelectors.errors)
  const isSuccess = useSelector(venueSelectors.isSuccess)
  const permissions = useSelector(venueSelectors.permissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.adminDescriptions).venues

  useEffect(() => {
    dispatch(venueActions.get({ id: venueId }))
    return () => {
      dispatch(venueActions.clearData({}))
    }
  }, [dispatch, venueId])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This venue will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        dispatch(venueActions.remove({ id: venueId, history }))
      })
      .finally(() => dispatch(modalActions.hideModal()))
  }

  const onSubmit = data => {
    dispatch(venueActions.edit(data))
  }

  const onCancel = memoData => {
    dispatch(venueActions.set(memoData))
    dispatch(venueActions.setErrors({}))
  }

  return (
    <VenueForm
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      initialValue={venue}
      onDelete={onDelete}
      onSave={onSubmit}
      onCancel={onCancel}
      onOpenDescriptionModal={onOpenDescriptionModal}
      descriptions={descriptions}
      isLoading={isLoading}
      isSuccess={isSuccess}
      initialErrors={errors}
    />
  )
}

import React, { useEffect, useState } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { BackButton, NameTitles } from '@Root/components'
import { useParams } from 'react-router'
import { API } from '@Root/API'
import { NavLink } from 'react-router-dom'

export const SectionWrapper = ({
  hasBackButton,
  backButtonText,
  clickBackButtonHandler,
  hasAdditionalAction,
  additionalActionText,
  clickAdditionTextHandler,
  children,
  activeName = false,
}) => {
  const [data, setData] = useState({})
  const { studentId, contactId } = useParams()

  useEffect(() => {
    const isActiveName = !activeName || (!studentId && !contactId)
    if (isActiveName) return
    ;(async function() {
      const { data } = await API.studies.getContactStudentInfo(studentId || contactId, contactId ? 'contact ' : 'student')
      setData(data)
    })()
  }, [])

  return (
    <div className={classes.wrapper}>
      <div className={classes.actionsWrapper}>
        {hasBackButton && (
          <div className={classes.backButtonWrapper}>
            <BackButton text={backButtonText} clickHandler={clickBackButtonHandler} />
          </div>
        )}
        {hasAdditionalAction && (
          <div onClick={clickAdditionTextHandler} className={classes.additionWrapper}>
            {additionalActionText}
          </div>
        )}

        {Object.keys(data).length > 0 && <NameTitles data={data} />}
      </div>
      <div className={classes.main}>
        {React.Children.map(children, (child, i) => {
          return (
            <>
              {i !== 0 && <div className={classes.line} />}
              {child}
            </>
          )
        })}
      </div>
    </div>
  )
}

SectionWrapper.propTypes = {
  hasBackButton: PropTypes.bool,
  backButtonText: PropTypes.string,
  clickBackButtonHandler: PropTypes.func,
  children: PropTypes.node,
}

SectionWrapper.defaultProps = {
  hasBackButton: false,
  backButtonText: '',
  clickBackButtonHandler: () => {},
  children: null,
}

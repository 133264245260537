export const tableOptions = state => state.studiesReducer.programme.reducer.tableOptions

export const programmesListOptions = state => state.studiesReducer.programme.reducer.programmesOptions

export const programme = state => state.studiesReducer.programme.reducer.programme

export const isLoading = state => state.studiesReducer.programme.reducer.isLoading

export const options = state => state.studiesReducer.programme.reducer.options

export const permissions = state => state.studiesReducer.programme.reducer.permissions

import React, { Component } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { FormWrapper } from '@Root/HOCs'
import { EditButton, SectionTitle, InputLabel, TextInput, Select, DatePicker, CheckboxInput, UserAvatar, DataListAsync, Field } from '@Root/components'
import { NavLink } from 'react-router-dom'
import { API } from '@Root/API'
import { booleanOptions } from '@Root/configs'

export class StudiesModuleInstanceForm extends Component {
	constructor(props) {
		super(props)
		const { initialIsEditable, initialData, generateId } = props
		this.state = {
			isEditable: initialIsEditable,
			error: null,
			isSaving: false,
			module_instance_identifier: generateId ? Math.floor(Math.random() * (100000000000000 - 1 + 1)) + 1 : initialData.module_instance_identifier,
			accreditation_of_prior_id: initialData.accreditation_of_prior_id,
			module_count_id: initialData.module_count_id,
			module_start_date: initialData.module_start_date,
			module_end_date: initialData.module_end_date,
			language_percentage: initialData.language_percentage,
			module_outcome_id: initialData.module_outcome_id,
			module_result_id: initialData.module_result_id,
			include_in_HESA: initialData.include_in_HESA,
			student_course_session_id: initialData.student_course_session_id,
			birthday: initialData.birthday,
			student_id: initialData.student_id,
			photo: initialData.photo,
			inactive_module_flag_id: initialData.inactive_module_flag_id,
			fee_amount: initialData.fee_amount,
			continuing_module_id: initialData.continuing_module_id,
		}
	}
	formIsValid = () => {
		const { module_instance_identifier, module_start_date, student_course_session_id } = this.state
		if (!student_course_session_id) {
			this.showError('student_course_session_id', 'Required')
			return false
		}
		if (!module_instance_identifier) {
			this.showError('module_instance_identifier', 'Required')
			return false
		}
		if (!module_start_date) {
			this.showError('module_start_date', 'Required')
			return false
		}
		return true
	}

	showError = (input, message) => {
		this.setState({ error: { input, message } }, () => {
			this.setState({ error: null })
		})
	}

	errorMessage = input => {
		const { error } = this.state
		return error && error.input === input ? error.message : null
	}

	setInitialState = async () => {
		const { initialData } = this.props
		this.setState({
			isEditable: false,
			error: null,
			module_instance_identifier: initialData.module_instance_identifier,
			accreditation_of_prior_id: initialData.accreditation_of_prior_id,
			module_count_id: initialData.module_count_id,
			module_start_date: initialData.module_start_date,

			language_percentage: initialData.language_percentage,
			module_outcome_id: initialData.module_outcome_id,
			module_result_id: initialData.module_result_id,
			include_in_HESA: initialData.include_in_HESA,

			inactive_module_flag_id: initialData.inactive_module_flag_id,
			fee_amount: initialData.fee_amount,
			module_identifier: initialData.module_identifier,
			module_end_date: initialData.module_end_date,
			student_course_session_id: initialData.student_course_session_id,
			birthday: initialData.birthday,
			student_id: initialData.student_id,
			photo: initialData.photo,
			continuing_module_id: initialData.continuing_module_id,
		})
		await this.setUserInformationToState(initialData.student_course_session_id)
	}

	async componentDidMount() {
		const { student_course_session_id } = this.props.initialData
		if (student_course_session_id) {
			await this.setUserInformationToState(student_course_session_id)
		}
	}

	setUserInformationToState = async courseSessionId => {
		const { id: student_id, birthday, photo } = await this.fetchStudent(courseSessionId)
		this.setState({ student_id, birthday, photo })
	}

	fetchStudent = async value => {
		const { data } = await API.getStudentsInStudyRecord(15, 1, 'student_course_session_id', value)
		const student = data.data[0]
		return { id: student.contact_id, name: `${student.name} ${student.academic_year}`, birthday: student.birthday, photo: student.photo }
	}

	getStudentByFieldName = async (id, field) => {
		const value = await this.fetchStudent(id)
		return value[field]
	}

	fetchStudentOptions = async value => {
		if (value) {
			const { data } = await API.getStudentsInStudyRecord(15, 1, 'name', value)
			return data.data.map(option => ({ value: option.student_course_session_id, label: `${option.name} ${option.academic_year}` }))
		}
	}

	onSelectStudentCourseSession = async courseSessionId => {
		if (courseSessionId) {
			const contact = await this.fetchStudent(courseSessionId)
			this.setState({ photo: contact.photo, student_id: contact.id, birthday: contact.birthday })
		} else {
			this.setState({ student_id: null, birthday: null, photo: null })
		}
		this.setState({ student_course_session_id: courseSessionId })
	}

	handleClickCancelButton = () => {
		this.setInitialState()
	}

	handleClickDeleteButton = () => {
		this.props.deleteHandler(
			() => {
				this.setState({ isSaving: true })
			},
			() => {
				this.setState({ isSaving: false })
			}
		)
	}

	handleClickSaveButton = async () => {
		if (!this.formIsValid()) return
		const { state } = this
		const moduleDelId = this.props.location.pathname.split('/')[6]
		this.setState({ isSaving: true })
		try {
			await this.props.saveHandler({
				module_instance_identifier: state.module_instance_identifier,
				accreditation_of_prior_id: state.accreditation_of_prior_id,
				module_count_id: state.module_count_id,
				module_delivery_id: moduleDelId,
				module_start_date: state.module_start_date,
				language_percentage: state.language_percentage,
				module_outcome_id: state.module_outcome_id,
				module_result_id: state.module_result_id,
				include_in_HESA: state.include_in_HESA,
				inactive_module_flag_id: state.inactive_module_flag_id,
				fee_amount: state.fee_amount,
				module_identifier: state.module_identifier,
				module_end_date: state.module_end_date,
				student_course_session_id: state.student_course_session_id,
				birthday: state.birthday,
				student_id: state.student_id,
				photo: state.photo,
				continuing_module_id: state.continuing_module_id,
			})
			!this.isUnmounted && this.setState({ isEditable: false })
		} catch (error) {
			console.log(error)
			alert('Unable to save the data')
		}
		!this.isUnmounted && this.setState({ isSaving: false })
	}

	componentWillUnmount() {
		this.isUnmounted = true
	}

	onToggleEditable = () => {
		this.setState(prevState => ({ isEditable: !prevState.isEditable }))
	}

	onOpenDescriptionModal = (description, title) => {
		const { openDescriptionModal } = this.props
		openDescriptionModal(description, title)
	}

	render() {
		const {
			isEditable,
			isSaving,
			module_instance_identifier,
			accreditation_of_prior_id,
			module_count_id,
			module_start_date,
			language_percentage,
			module_outcome_id,
			module_result_id,
			inactive_module_flag_id,
			fee_amount,
			module_identifier,
			module_end_date,
			student_course_session_id,
			birthday,
			student_id,
			photo,
			continuing_module_id,
		} = this.state

		const { permissions, title, buttons, options, descriptions, isNew } = this.props
		const { handleClickCancelButton, handleClickDeleteButton, handleClickSaveButton, errorMessage, onToggleEditable, onOpenDescriptionModal } = this

		const { onRead: titleOnRead = '', onEdit: titleOnEdit = '' } = title

		const avatarType =
			student_id && student_id !== 'No data available' ? (
				<NavLink to={`/home/contacts/all-contacts/${student_id}`} style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
					<UserAvatar src={photo} style={{ width: '260px', height: '215px', borderRadius: 0 }} />
				</NavLink>
			) : (
				<UserAvatar
					src={photo}
					style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', width: '260px', height: '215px', borderRadius: 0 }}
				/>
			)

		return (
			<div className={classes.StudiesModuleInstanceForm}>
				<FormWrapper
					buttons={isEditable ? buttons : []}
					buttonsNames={[{ button: 'delete', name: 'Delete this module instance' }]}
					buttonsAreDisabled={isSaving}
					isSpinning={isSaving}
					clickCancelButtonHandler={handleClickCancelButton}
					clickDeleteButtonHandler={handleClickDeleteButton}
					clickSaveButtonHandler={handleClickSaveButton}
					canEdit={permissions.includes('edit')}
					isEditable={isEditable}
					editTitle={titleOnEdit}
					readTitle={titleOnRead}
					clickToggleEditModeButtonHandler={onToggleEditable}
				>
					<div className={classes.contentWrapper}>
						<div className={classes.columnWrapper}>
							<div className={classes.inputWrapper}>
								{!isNew && avatarType}
								<Field
									label={'Module instance identifier'}
									classNames={!isEditable ? ['borderless'] : []}
									style={{ width: 260 }}
									value={module_instance_identifier}
									onChange={module_instance_identifier => this.setState({ module_instance_identifier })}
									placeholder='Module ID'
									isDisabled={true}
									maxLength={50}
									isRequired={isEditable}
									error={errorMessage('module_instance_identifier')}
									description={descriptions.module_instance_identifier}
									onOpenDescriptionModal={onOpenDescriptionModal}
								/>
							</div>{' '}
							{module_identifier && (
								<div className={classes.inputWrapper}>
									<Field
										label={'Module identifier'}
										classNames={!isEditable ? ['borderless'] : []}
										style={{ width: 260 }}
										value={module_identifier}
										onChange={module_identifier => this.setState({ module_identifier })}
										placeholder='Module identifier'
										isDisabled={!isEditable}
										maxLength={50}
										isRequired={isEditable}
										error={errorMessage('module_identifier')}
										description={descriptions.module_identifier}
										onOpenDescriptionModal={onOpenDescriptionModal}
									/>
								</div>
							)}
							<Field
								label={'Student course session identifier'}
								classNames={!isEditable ? ['borderless'] : []}
								style={{ width: 260 }}
								listStyle={{ width: 260 }}
								value={student_course_session_id}
								onChange={this.onSelectStudentCourseSession}
								placeholder='Student course session identifier'
								isDisabled={!isEditable}
								maxLength={50}
								isRequired={isEditable}
								error={errorMessage('student_course_session_id')}
								description={descriptions.student_course_session_id}
								onOpenDescriptionModal={onOpenDescriptionModal}
								field={'dataListAsync'}
								fetchLabelHandler={value => this.getStudentByFieldName(value, 'name')}
								fetchOptionsHandler={value => this.fetchStudentOptions(value)}
							/>{' '}
							<Field
								label={'Continuing module'}
								classNames={!isEditable ? ['borderless'] : []}
								style={{ width: 260 }}
								listStyle={{ width: 260 }}
								value={continuing_module_id}
								onChange={continuing_module_id => this.setState({ continuing_module_id })}
								placeholder='Continuing module'
								isDisabled={!isEditable}
								maxLength={2}
								options={options.continuingModules}
								description={descriptions.continuing_module_id}
								onOpenDescriptionModal={onOpenDescriptionModal}
								field={'select'}
							/>
							{!isNew && (
								<Field
									label={'Date of birth'}
									classNames={!isEditable ? ['borderless'] : []}
									style={{ width: 260 }}
									value={birthday || 'No data available'}
									onChange={birthday => this.setState({ birthday })}
									placeholder='Date of birth'
									isDisabled={true}
									error={errorMessage('birthday')}
									description={descriptions.birthday}
									onOpenDescriptionModal={onOpenDescriptionModal}
									field={'datePicker'}
								/>
							)}
							{!isNew && (
								<Field
									label={'Student ID'}
									classNames={!isEditable ? ['borderless'] : []}
									style={{ width: 260 }}
									value={student_id || 'No data available'}
									onChange={student_id => this.setState({ student_id })}
									placeholder='Student ID'
									isDisabled={true}
									error={errorMessage('student_id')}
									description={descriptions.student_id}
									onOpenDescriptionModal={onOpenDescriptionModal}
									field={'textField'}
								/>
							)}
							<div className={classes.inputWrapper}>
								<Field
									label={'Inactive module flag'}
									classNames={!isEditable ? ['borderless'] : []}
									style={{ width: 260 }}
									optionsStyle={{ width: 260 }}
									value={inactive_module_flag_id}
									onChange={inactive_module_flag_id => this.setState({ inactive_module_flag_id })}
									placeholder='Inactive module flag'
									isDisabled={!isEditable}
									maxLength={2}
									options={options.inactiveModuleFlags}
									description={descriptions.inactive_module_flag_id}
									onOpenDescriptionModal={onOpenDescriptionModal}
									field={'select'}
								/>
							</div>
							<div className={classes.inputWrapper}>
								<Field
									label={'Accreditation of prior experiential learning'}
									classNames={!isEditable ? ['borderless'] : []}
									style={{ width: 260 }}
									value={accreditation_of_prior_id}
									onChange={accreditation_of_prior_id => this.setState({ accreditation_of_prior_id })}
									placeholder='Accreditation of prior experiential learning'
									isDisabled={!isEditable}
									maxLength={2}
									options={options.accreditationPriors}
									description={descriptions.accreditation_of_prior_id}
									onOpenDescriptionModal={onOpenDescriptionModal}
									field={'select'}
								/>
							</div>
							<div className={classes.inputWrapper}>
								<Field
									label={'Module count'}
									classNames={!isEditable ? ['borderless'] : []}
									style={{ width: 260 }}
									optionsStyle={{ width: 260 }}
									value={module_count_id}
									onChange={module_count_id => this.setState({ module_count_id })}
									placeholder='Module count'
									isDisabled={!isEditable}
									maxLength={2}
									options={options.counts}
									description={descriptions.module_count_id}
									onOpenDescriptionModal={onOpenDescriptionModal}
									field={'select'}
								/>
							</div>{' '}
						</div>
						<div className={classes.columnWrapper}>
							<div className={classes.inputWrapper}>
								<Field
									label={'Module instance start date'}
									classNames={!isEditable ? ['borderless'] : []}
									style={{ width: 260 }}
									value={module_start_date}
									onChange={module_start_date => this.setState({ module_start_date })}
									placeholder='Module instance start date'
									isDisabled={!isEditable}
									maxLength={2}
									isRequired={isEditable}
									error={errorMessage('module_start_date')}
									description={descriptions.module_start_date}
									onOpenDescriptionModal={onOpenDescriptionModal}
									field={'datePicker'}
								/>
							</div>{' '}
							<div className={classes.inputWrapper}>
								<Field
									label={'Module instance end date'}
									classNames={!isEditable ? ['borderless'] : []}
									style={{ width: 260 }}
									value={module_end_date}
									onChange={module_end_date => this.setState({ module_end_date })}
									placeholder='Module instance end date'
									isDisabled={!isEditable}
									maxLength={10}
									description={descriptions.module_end_date}
									onOpenDescriptionModal={onOpenDescriptionModal}
									field={'datePicker'}
								/>
							</div>
							<div className={classes.inputWrapper}>
								<Field
									label={'Language percentage'}
									classNames={!isEditable ? ['borderless'] : []}
									style={{ width: 260 }}
									value={language_percentage}
									onChange={language_percentage => this.setState({ language_percentage })}
									placeholder='Language percentage'
									isDisabled={!isEditable}
									maxLength={2}
									description={descriptions.language_percentage}
									onOpenDescriptionModal={onOpenDescriptionModal}
									restriction='betweenOneAndHundred'
								/>
							</div>{' '}
							<div className={classes.inputWrapper}>
								<Field
									label={'Module instance fee amount'}
									classNames={!isEditable ? ['borderless'] : []}
									style={{ width: 260 }}
									value={fee_amount}
									onChange={fee_amount => this.setState({ fee_amount })}
									placeholder='Module instance fee amount'
									isDisabled={!isEditable}
									maxLength={6}
									description={descriptions.fee_amount}
									onOpenDescriptionModal={onOpenDescriptionModal}
								/>
							</div>
							<div className={classes.inputWrapper}>
								<Field
									label={'Module outcome'}
									classNames={!isEditable ? ['borderless'] : []}
									style={{ width: 260 }}
									value={module_outcome_id}
									onChange={module_outcome_id => this.setState({ module_outcome_id })}
									placeholder='Module outcome'
									isDisabled={!isEditable}
									maxLength={2}
									options={options.outcomes}
									description={descriptions.module_outcome_id}
									onOpenDescriptionModal={onOpenDescriptionModal}
									field={'select'}
								/>
							</div>
							<div className={classes.inputWrapper}>
								<Field
									label={'Module result'}
									classNames={!isEditable ? ['borderless'] : []}
									style={{ width: 260 }}
									optionsStyle={{ width: 260 }}
									value={module_result_id}
									onChange={module_result_id => this.setState({ module_result_id })}
									placeholder='Module result'
									isDisabled={!isEditable}
									maxLength={2}
									options={options.results}
									description={descriptions.module_result_id}
									onOpenDescriptionModal={onOpenDescriptionModal}
									field={'select'}
								/>
							</div>
						</div>
					</div>
				</FormWrapper>
			</div>
		)
	}
}

StudiesModuleInstanceForm.propTypes = {
	permissions: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.shape({
		onRead: PropTypes.string,
		onEdit: PropTypes.string,
	}),
	initialData: PropTypes.shape({
		module_instance_identifier: PropTypes.number,
		student_course_session_id: PropTypes.number,
		accreditation_of_prior_id: PropTypes.number,
		module_count_id: PropTypes.number,
		module_start_date: PropTypes.string,
		language_percentage: PropTypes.number,
		module_outcome_id: PropTypes.number,
		module_result_id: PropTypes.number,
		inactive_module_flag_id: PropTypes.number,
		fee_amount: PropTypes.number,
		module_identifier: PropTypes.number,
		module_outcome_end_date: PropTypes.number,
	}),
	initialIsEditable: PropTypes.bool,
	buttons: PropTypes.arrayOf(PropTypes.oneOf(['cancel', 'delete', 'save'])),
	cancelHandler: PropTypes.func,
	deleteHandler: PropTypes.func,
	saveHandler: PropTypes.func,
}

StudiesModuleInstanceForm.defaultProps = {
	permissions: [],
	title: {
		onRead: '',
		onEdit: '',
	},
	initialData: {
		module_instance_identifier: null,
		student_course_session_id: null,
		accreditation_of_prior_id: null,
		module_count_id: null,
		module_start_date: null,
		language_percentage: '100',
		module_outcome_id: null,
		module_result_id: null,
		include_in_HESA: true,
		birthday: null,
		student_id: null,
		counting_module: null,
		inactive_module_flag_id: null,
		fee_amount: null,
		module_identifier: null,
		module_end_date: null,
	},
	initialIsEditable: false,
	options: null,
	buttons: ['cancel', 'delete', 'save'],
	cancelHandler: () => { },
	deleteHandler: () => { },
	saveHandler: () => { },
	openDescriptionModal: () => { },
}

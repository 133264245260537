import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'

export const SectionTitle = ({ title }) => <h2 className={`${classes.SectionTitle} section_title`}>{title}</h2>

SectionTitle.propTypes = {
	title: PropTypes.string,
}

SectionTitle.defaultProps = {
	title: null,
}

import React, { useEffect } from 'react'
import { Redirect, useHistory, useLocation, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
	fieldTooltipManagerActions,
	fieldTooltipManagerSelectors,
	modalActions,
	moduleActions,
	moduleSelectors,
	subjectActions,
	subjectSelectors,
} from '@Root/store'
import { StudiesModuleSubjectForm } from '@Root/components'
import { permissionsSelectors } from '@Store/permissions'

export const CreateSubject = ({ defaultURL }) => {
	const history = useHistory()
	const location = useLocation()
	const dispatch = useDispatch()
	const { moduleId } = useParams()
	const isLoading = useSelector(subjectSelectors.isLoading)
	const errors = useSelector(subjectSelectors.errors)
	const maxProportion = useSelector(moduleSelectors.maxProportion)
	const minProportion = useSelector(moduleSelectors.minProportion)
	const staticOptions = useSelector(subjectSelectors.options).static
	const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions)['module-subject']
	const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)

	useEffect(() => {
		dispatch(subjectActions.getStaticOptions())
	}, [dispatch])

	useEffect(() => {
		if (moduleId) dispatch(moduleActions.get({ moduleId }))

		return () => {
			dispatch(subjectActions.setErrors({}))
		}
	}, [moduleId, dispatch])

	const onSubmit = payload => {
		dispatch(subjectActions.create({ data: { ...payload, educational_module_id: moduleId }, history }))
	}

	useEffect(() => {
		dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'module-subject' }))
	}, [dispatch])

	const onOpenDescriptionModal = (description, title) => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('NotificationModal', {
					text: description,
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
					title,
				})
			)
		}).finally(() => dispatch(modalActions.hideModal()))
	}

	const onCancel = () => {
		history.push(defaultURL)
	}

	if (!dataCreationPermissions.modules_sub) return <Redirect to={defaultURL} />
	if (maxProportion === 0) return <Redirect to={defaultURL} />
	return (
		<StudiesModuleSubjectForm
			initialIsEditable={true}
			generateId={true}
			options={staticOptions}
			buttons={['save']}
			onSave={onSubmit}
			location={location}
			maxProportion={maxProportion}
			minProportion={minProportion}
			openDescriptionModal={onOpenDescriptionModal}
			descriptions={descriptions}
			parentURL={defaultURL}
			moduleId={moduleId}
			isLoading={isLoading}
			onCancel={onCancel}
			initialErrors={errors}
			canEdit
			isNew
		/>
	)
}

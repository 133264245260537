import React from 'react'
import styles from './styles.module.scss'

export const ToggleSwitcher = ({ onChange, checked, disabled }) => {
	return (
		<label className={styles.switch}>
			<input className={styles.checkbox} type={'checkbox'} checked={checked} onChange={onChange} disabled={disabled} />
			<span className={`${styles.slider} ${styles.round}`} />
		</label>
	)
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import * as types from './admission.types'
import { actions } from './admission.actions'
import { API } from '@Root/API'
import { errorMessage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'
import { noteRootSaga } from '@Store/admission/note'
import { attachmentRootSaga } from '@Store/admission/attachment'

function* getApplication({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id } = payload
  try {
    const { data } = yield call(API.admissions.application.get, id)
    yield put(actions.setApplication(data))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* getStaticOptions() {
  yield put(actions.toggleLoading(true))
  try {
    const response = yield all([yield call(API.admissions.options.getStatic), yield call(API.admissions.application.options.getStatic)])
    const {
      applications_status = [],
      forms_status = [],
      academic_years = [],
      bap_statuses = [],
      managed_by = [],
      statuses = [],
      ordinand = [],
      study_years = [],
    } = {
      ...response[0].data,
      ...response[1].data,
    }
    const formattedData = {
      applications_status: applications_status.map(obj => ({ value: obj.id, label: obj.label })),
      forms_status: forms_status.map(obj => ({ value: obj.id, label: obj.label })),
      academic_years: academic_years.map(obj => ({ value: obj.id, label: obj.name })),
      bap_statuses: bap_statuses.map(obj => ({ value: obj.id, label: obj.name })),
      managed_by: managed_by.map(obj => ({ value: obj.id, label: obj.name })),
      statuses: statuses.map(obj => ({ value: obj.id, label: obj.label })),
      ordinand: ordinand.map(obj => ({ value: obj.id, label: obj.name })),
      study_years: study_years.map(obj => ({ value: obj.id, label: obj.name })),
    }
    yield put(actions.setOptions({ name: 'static', data: formattedData }))
  } catch (error) { }
  yield put(actions.toggleLoading(false))
}

export function* rootSaga() {
  yield all([takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions), takeEvery(types.GET_APPLICATION, getApplication), noteRootSaga(), attachmentRootSaga()])
}

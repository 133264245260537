import { createSelector } from 'reselect'

export const isLoading = state => state.contactReducer.person.cleric.isLoading

export const isSuccess = state => state.contactReducer.person.cleric.isSuccess

export const cleric = state => state.contactReducer.person.cleric.cleric

export const options = state => state.contactReducer.person.cleric.options

export const permissions = state => state.contactReducer.person.cleric.permissions

export const isPermissionsLoaded = createSelector(permissions, permissions => (permissions.fields ? Object.keys(permissions.fields).length > 0 : false))

export const TOGGLE_LOADING = 'diocese/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'diocese/TOGGLE_SUCCESS'
export const GET_DIOCESE = 'diocese/GET_DIOCESE'
export const SET_DIOCESE = 'diocese/SET_DIOCESE'
export const EDIT_DIOCESE = 'diocese/EDIT_DIOCESE'
export const DELETE_DIOCESE = 'diocese/DELETE_DIOCESE'
export const GET_STATIC_OPTIONS = 'diocese/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'diocese/GET_DYNAMIC_OPTIONS'
export const SET_OPTIONS = 'diocese/SET_OPTIONS'
export const SET_PERMISSIONS = 'diocese/SET_PERMISSIONS'

import React, { Component } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import { Editor as Draft } from 'react-draft-wysiwyg/dist/react-draft-wysiwyg'
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import { cn } from '@Root/helpers'

export class Editor extends Component {
	constructor(props) {
		super(props)
		const contentBlock = htmlToDraft(props.value)
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
			const editorState = EditorState.createWithContent(contentState)
			this.state = {
				editorState,
			}
		}
	}

	onEditorStateChange = editorState => {
		this.setState({
			editorState,
		})

		this.props.changeHandler(draftToHtml(convertToRaw(editorState.getCurrentContent())))
	}

	setEditorState = value => {
		const contentBlock = htmlToDraft(value)
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
			const editorState = EditorState.createWithContent(contentState)
			this.setState({
				editorState,
			})
		}
	}

	render() {
		const { editorState } = this.state
		const { className, isDisabled } = this.props
		const { onEditorStateChange } = this
		return (
			<div className={cn(classes.wrapper, className)} style={isDisabled ? { border: 'none', cursor: 'not-allowed' } : null}>
				<Draft
					readOnly={isDisabled}
					toolbarHidden={isDisabled}
					toolbar={{
						options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
					}}
					editorState={editorState}
					onEditorStateChange={onEditorStateChange}
				/>
			</div>
		)
	}
}

Editor.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	changeHandler: PropTypes.func,
	isDisabled: PropTypes.bool,
}

Editor.defaultProps = {
	className: '',
	value: '<p></p>',
	changeHandler: () => {},
	isDisabled: false,
}

import { combineReducers } from 'redux'
import { programmeReducer } from './programme'
import { studentCourseSessionReducer } from './studentCourseSession'
import { moduleReducer } from './module'
import { engagementReducer } from './engagement'
export { rootSaga as studiesRootSaga } from './studies.saga'

export * from './programme'
export * from './studentCourseSession'
export * from './module'
export * from './engagement'

export const studiesReducer = combineReducers({
	programme: programmeReducer,
	studentCourseSession: studentCourseSessionReducer,
	module: moduleReducer,
	engagement: engagementReducer,
})

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { API, createInstance } from '@Root/API'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage, saveDataOptionsToLocalStorage } from '@Root/helpers'
import { actions } from './hesa.actions'
import * as types from './hesa.types'
import { contactActions } from '@Root/store'

function* get({ payload }) {
  const { contactId } = payload
  try {
    const { data } = yield call(API.contact.person.student.hesa.get, contactId)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

function* edit({ payload }) {
  const { contactId, formName, data, resolve, reject } = payload
  try {
    yield call(API.contact.person.student.hesa.edit, contactId, formName, data)
    const response = yield call(createInstance().get, `contacts/${contactId}`)
    const { contact } = response.data.data
    yield put(contactActions.setPerson(contact))
    resolve()
    yield put(snackbarActions.setSuccessNotification({ text: 'The HESA information has been saved successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true, delay: 15000 }))
    reject()
  }
  yield put(contactActions.toggleSuccess(false))
}

function* editCourseSession({ payload }) {
  const { data, resolve, reject, contactId } = payload
  const { studentCourseSessions } = data
  try {
    yield call(API.contact.person.student.hesa.studentCourseSession.edit, studentCourseSessions.student_course_session_id, studentCourseSessions)
    yield put(actions.get({ contactId }))
    resolve()
    yield put(snackbarActions.setSuccessNotification({ text: 'The HESA information has been saved successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    reject()
  }
  yield put(contactActions.toggleSuccess(false))
}

function* editModuleInstance({ payload }) {
  const { data, resolve, reject, contactId } = payload
  const { moduleInstance } = data
  try {
    yield call(API.contact.person.student.hesa.moduleInstance.edit, moduleInstance.student_course_session_id, moduleInstance)
    yield put(actions.get({ contactId }))
    yield put(actions.getDynamicOptions({ contactId }))
    resolve()
    yield put(snackbarActions.setSuccessNotification({ text: 'The HESA information has been saved successfully!' }))
  } catch (error) {
    const fields = error.response.data.errors
    const entries = Object.entries(fields)
    const field = entries[0][0].split('.')[2]
    if (field === 'module_instance_identifier') {
      yield put(snackbarActions.setSnackbar({ text: 'The module instance identifier has already been taken', isError: true }))
    }
    reject()
  }
  yield put(contactActions.toggleSuccess(false))
}

function* getSnapshot({ payload }) {
  const { id } = payload
  try {
    const { data } = yield call(API.contact.person.student.hesa.snapshot.get, id)
    yield put(actions.set({ id: data.data.id, ...data.data.json_snapshot }))
  } catch (error) {}
}

function* getSnapshots({ payload }) {
  const { contactId } = payload
  try {
    const { data } = yield call(API.contact.person.student.hesa.snapshot.getAll, contactId)
    yield put(actions.setSnapshots(data.data))
  } catch (error) {}
}

function* getStaticOptions() {
  try {
    const { data } = yield call(API.contact.person.student.hesa.options.getStatic)
    const {
      student: {
        carers,
        dependants,
        ethnicities,
        religions,
        service_leavers,
        service_students,
        sex_identifiers,
        transgenders,
        sexual_orientations,
        term_time_accommodation_types,
        genders,
        languages,
        nationalities,
      },
      disability: { disability_types },
      language_proficiency: { language_identifier, level_of_proficiency, proficiency_type },
      national_identity: { national_identity_types },
      student_course_sessions: {
        access_participation_flags,
        activity_duration_types,
        activity_type_identifiers,
        countries,
        distance_learning,
        equivalent_lower_qualifications,
        financial_support_types,
        funding_bodies,
        funding_completions,
        funding_lengths,
        hesa_identifiers,
        host_identifier_types,
        host_identifiers,
        mobility_schemes,
        non_regulated_fee_flags,
        ref2021_unit_assessments,
        reference_periods,
        status_changed_to,
        primarily_outside_uk,
      },
      module_instance: {
        module_counts,
        module_outcomes,
        module_results,
        inactive_module_flags,
        continuing_modules,
        accreditation_prior_experiential_learning,
        include_in_hesa,
      },
    } = data
    const mapToValueAndLabel = arr => {
      return arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    }

    const formattedData = {
      student: {
        carers: mapToValueAndLabel(carers),
        dependants: mapToValueAndLabel(dependants),
        ethnicities: mapToValueAndLabel(ethnicities),
        religions: mapToValueAndLabel(religions),
        serviceLeavers: mapToValueAndLabel(service_leavers),
        serviceStudents: mapToValueAndLabel(service_students),
        sexIdentifiers: mapToValueAndLabel(sex_identifiers),
        transgenders: mapToValueAndLabel(transgenders),
        sexualOrientations: mapToValueAndLabel(sexual_orientations),
        termTimeAccommodationTypes: mapToValueAndLabel(term_time_accommodation_types),
        genders: mapToValueAndLabel(genders),
        languages: mapToValueAndLabel(languages),
        nationalities: mapToValueAndLabel(nationalities),
      },
      disability: { disabilityTypes: mapToValueAndLabel(disability_types) },
      languageProficiencies: {
        languageIdentifiers: mapToValueAndLabel(language_identifier),
        proficiencyTypes: mapToValueAndLabel(proficiency_type),
        levelOfProficiencies: mapToValueAndLabel(level_of_proficiency),
      },
      nationalIdentity: { nationalIdentityTypes: mapToValueAndLabel(national_identity_types) },
      studentCourseSessions: {
        accessParticipationFlags: mapToValueAndLabel(access_participation_flags),
        activityDurationTypes: mapToValueAndLabel(activity_duration_types),
        activityTypeIdentifiers: mapToValueAndLabel(activity_type_identifiers),
        countries: mapToValueAndLabel(countries),
        distanceLearning: mapToValueAndLabel(distance_learning),
        equivalentLowerQualifications: mapToValueAndLabel(equivalent_lower_qualifications),
        financialSupportTypes: mapToValueAndLabel(financial_support_types),
        fundingBodies: mapToValueAndLabel(funding_bodies),
        fundingCompletions: mapToValueAndLabel(funding_completions),
        fundingLengths: mapToValueAndLabel(funding_lengths),
        hesaIdentifiers: mapToValueAndLabel(hesa_identifiers),
        hostIdentifierTypes: mapToValueAndLabel(host_identifier_types),
        hostIdentifiers: mapToValueAndLabel(host_identifiers),
        mobilitySchemes: mapToValueAndLabel(mobility_schemes),
        nonRegulatedFeeFlags: mapToValueAndLabel(non_regulated_fee_flags),
        ref2021UnitAssessments: mapToValueAndLabel(ref2021_unit_assessments),
        referencePeriods: mapToValueAndLabel(reference_periods),
        statusChangedTo: mapToValueAndLabel(status_changed_to),
        primarilyOutsideUk: mapToValueAndLabel(primarily_outside_uk),
      },
      moduleInstance: {
        moduleCounts: mapToValueAndLabel(module_counts),
        moduleOutcomes: mapToValueAndLabel(module_outcomes),
        moduleResults: mapToValueAndLabel(module_results),
        continuingModules: mapToValueAndLabel(continuing_modules),
        inactiveModuleFlags: mapToValueAndLabel(inactive_module_flags),
        accreditationPriorExperientialLearning: mapToValueAndLabel(accreditation_prior_experiential_learning),
        include_in_hesa: [...include_in_hesa].map(obj => ({ value: obj.id, label: `${obj.label}` })),
      },
    }
    yield put(actions.setOptions({ name: 'static', data: formattedData }))
    saveDataOptionsToLocalStorage('hesa_details_options', formattedData)
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

function* getDynamicOptions({ payload }) {
  const { contactId } = payload
  try {
    const {
      data: { modules, student_course_sessions, module_instances, off_venues },
    } = yield call(API.contact.person.student.hesa.options.getDynamic, contactId)
    const mapToValueAndModuleTitle = arr => {
      return arr.map(obj => ({ value: obj.id, label: obj.module_title }))
    }
    const mapToValueAndLabel = arr => {
      return arr.map(obj => ({ value: obj.id, label: obj.label }))
    }
    const formattedData = {
      modules: mapToValueAndModuleTitle(modules),
      studentCourseSessions: mapToValueAndLabel(student_course_sessions),
      moduleInstances: mapToValueAndLabel(module_instances),
      offVenues: mapToValueAndLabel(off_venues),
    }
    yield put(actions.setOptions({ name: 'dynamic', data: formattedData }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_HESA, get),
    takeEvery(types.EDIT_HESA, edit),
    takeEvery(types.GET_SNAPSHOTS, getSnapshots),
    takeEvery(types.GET_SNAPSHOT, getSnapshot),
    takeEvery(types.EDIT_MODULE_INSTANCE, editModuleInstance),
    takeEvery(types.EDIT_COURSE_SESSION, editCourseSession),
    takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
    takeEvery(types.GET_DYNAMIC_OPTIONS, getDynamicOptions),
  ])
}

import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './entryProfile.types'
import { actions } from './entryProfile.actions'
import { entryQualificationAwardedRootSaga } from './entryQualificationAwarded'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { contactId, engagementId } = data
  try {
    const response = yield call(API.studies.engagement.entryProfile.get, engagementId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/entry-profile`)
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history, contactId } = payload
  const { engagementId } = data
  try {
    const response = yield call(API.studies.engagement.entryProfile.create, engagementId, data)
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/entry-profile/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The entry profile has been created successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { engagementId, data } = payload
  try {
    const response = yield call(API.studies.engagement.entryProfile.edit, engagementId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The entry profile has been saved successfully!' }))
  } catch (error) {
    const errors = error.response.data.errors
    yield put(actions.set(payload))
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { contactId, engagementId } = data
  try {
    yield call(API.studies.engagement.entryProfile.remove, engagementId)
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/entry-profile`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The entry profile has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const {
      data: {
        access_programmes = [],
        apel_credits = [],
        care_leavers = [],
        credit_schemes = [],
        dependants = [],
        estranged_students = [],
        highestqualoes = [],
        marital_statuses = [],
        parental_education = [],
        permanent_addresses = [],
        previous_providers = [],
        religious_backgrounds = [],
        socio_economic_classifications = [],
        standard_occupational_classifications = [],
        socio_economic_classifications2020 = [],
      },
    } = yield call(API.studies.engagement.entryProfile.options.getStatic)
    const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    const formattedData = {
      access_programmes: generateObject(access_programmes),
      apel_credits: generateObject(apel_credits),
      care_leavers: generateObject(care_leavers),
      credit_schemes: generateObject(credit_schemes),
      dependants: generateObject(dependants),
      estranged_students: generateObject(estranged_students),
      highestqualoes: generateObject(highestqualoes),
      marital_statuses: generateObject(marital_statuses),
      parental_education: generateObject(parental_education),
      permanent_addresses: generateObject(permanent_addresses),
      previous_providers: generateObject(previous_providers),
      religious_backgrounds: generateObject(religious_backgrounds),
      socio_economic_classifications: generateObject(socio_economic_classifications),
      standard_occupational_classifications: generateObject(standard_occupational_classifications),
      socio_economic_classifications2020: generateObject(socio_economic_classifications2020),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('engagement_entry_profile', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_ENTRY_PROFILE, get),
    takeEvery(types.CREATE_ENTRY_PROFILE, create),
    takeEvery(types.EDIT_ENTRY_PROFILE, edit),
    takeEvery(types.DELETE_ENTRY_PROFILE, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
    entryQualificationAwardedRootSaga(),
  ])
}

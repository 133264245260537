import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import { setToLocalStorage, switchEmptyStringsToNullsInObject } from '@Root/helpers'
import { FormWrapper } from '@Root/HOCs'
import { MultiDataListAsync, InputLabel, TextInput, RoleManager } from '@Root/components'
import { API } from '@Root/API'
import { useError, useToggle } from '@Root/hooks'
import { RoleInstruction } from '@Root/components/RoleInstruction'

const titles = { onRead: 'Role', onEdit: 'Edit Role', onCreate: 'Create Role' }

export const RoleForm = ({
  isNew,
  initialValue,
  onSave,
  isLoading,
  onDelete,
  canEdit,
  isSuccess,
  onCancel,
  isInitialEditMode,
  initialMainCategoryId,
  initialCategoryId,
  initialSubCategoryId,
  initialMainAccordion,
  canDelete,
}) => {
  const [isEditable, toggleEditable] = useToggle(isNew)
  const [data, setData] = useState({})
  const { errors, setError, onClear: onClearError } = useError()

  useEffect(() => {
    if (isInitialEditMode) toggleEditable()
  }, [isInitialEditMode, toggleEditable])

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const formIsValid = () => {
    const { name } = data
    if (!name) {
      setError('name', 'Required')
      return false
    }
    onClearError()
    return true
  }

  const fetchUserLabel = async userId => {
    const { data } = await API.admin.user.getById(userId)
    return data.data.name
  }

  const fetchUserOptions = async userName => {
    const { data } = await API.admin.user.getByName(userName)
    return data.data.map(option => ({ value: option.id, label: option.name }))
  }

  const fetchInstanceLabel = async institutionId => {
    const { data } = await API.admin.institution.getById(institutionId)
    return data.data.name
  }

  const fetchInstanceOptions = async institutionName => {
    const { data } = await API.admin.institution.getByName(institutionName)
    return data.data.map(option => ({ value: option.id, label: option.name }))
  }

  const fetchAllInstitutions = async () => {
    const { data } = await API.options.getInstitutions()
    return data.data.map(option => ({ value: option.id, label: option.name }))
  }

  const onCancelEditForm = () => {
    if (isNew) {
      onCancel()
      return
    }
    toggleEditable()
    onClearError()
    setData(initialValue)
  }

  const onSubmit = () => {
    if (!formIsValid()) return
    const modifiedData = switchEmptyStringsToNullsInObject(data)
    onSave(modifiedData)
  }

  const onDataChange = field => data => {
    setData(prevState => ({ ...prevState, [field]: data }))
  }

  const saveRoleIntoStorage = () => {
    setToLocalStorage('saved_role_data', data)
  }

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess])

  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  const { onRead, onCreate, onEdit } = titles

  const { user_ids, instance_ids, name, permissions, additional_functions, id, form_data_permissions, table_rules_permissions } = data

  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', ...(!isNew ? (canDelete ? ['delete'] : []) : []), 'save'] : []}
      buttonsNames={[{ button: 'delete', name: 'Delete this role' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading}
      clickCancelButtonHandler={onCancelEditForm}
      clickDeleteButtonHandler={onDelete}
      clickSaveButtonHandler={onSubmit}
      canEdit={canEdit}
      isEditable={isEditable}
      readTitle={onRead}
      editTitle={onEdit}
      createTitle={onCreate}
      clickToggleEditModeButtonHandler={toggleEditable}
      isCreate={isNew}
      idForm={id ? `role_manager_${id}` : null}
      isSuccess={isSuccess}
      fixedSpinner
    >
      <div>
        <div className={classes.instruction}>
          <RoleInstruction />{' '}
        </div>
        <div className={classes.grid}>
          <div className={classes.rows}>
            <div className={classes.row}>
              <InputLabel text='Name' hasAsterisk={isEditable} />
              <TextInput
                classNames={!isEditable ? ['borderless'] : []}
                style={{ position: 'absolute', left: 200, width: 340 }}
                value={name}
                changeHandler={value => handleChangeInput('name', value)}
                isDisabled={!isEditable}
                error={errors?.name}
              />
            </div>
            <div className={classes.row}>
              <InputLabel text='Users' />
              <div style={{ position: 'absolute', left: 200 }}>
                <MultiDataListAsync
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 340 }}
                  values={user_ids}
                  fetchLabelHandler={value => fetchUserLabel(value)}
                  fetchOptionsHandler={value => fetchUserOptions(value)}
                  changeHandler={values => handleChangeInput('user_ids', values)}
                  isDisabled={!isEditable}
                  useModal
                />
              </div>
            </div>
            <div className={classes.row}>
              <InputLabel text='Institutions' />
              <div style={{ position: 'absolute', left: 200 }}>
                <MultiDataListAsync
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 340 }}
                  values={instance_ids}
                  fetchLabelHandler={value => fetchInstanceLabel(value)}
                  fetchOptionsHandler={value => fetchInstanceOptions(value)}
                  changeHandler={values => handleChangeInput('instance_ids', values)}
                  fetchInitialHandler={fetchAllInstitutions}
                  isDisabled={!isEditable}
                  maxVisibleOptionsQuantity={5}
                  withInitialList
                />
              </div>
            </div>
          </div>
        </div>
        <RoleManager
          isSuccess={isSuccess}
          permissions={permissions}
          additionalFunctions={additional_functions}
          formDataPermissions={form_data_permissions}
          Save={onSave}
          isEditable={isEditable}
          onDataChange={onDataChange}
          tableRulesPermissions={table_rules_permissions}
          roleId={id}
          initialMainCategoryId={initialMainCategoryId}
          initialCategoryId={initialCategoryId}
          initialSubCategoryId={initialSubCategoryId}
          initialMainAccordion={initialMainAccordion}
          roleName={data.name}
          saveRoleIntoStorage={saveRoleIntoStorage}
        />
      </div>
    </FormWrapper>
  )
}

import { getFromLocalStorage, removeFromLocalStorage } from '@Helpers/localStorage'
import * as types from './note.types'

const initialState = {
  loadingConf: {
    isGetLoading: false,
    isCreateLoading: false,
    isCreateReplyLoadin: { id: 0, status: false },
    isEditLoading: { id: 0, status: false },
    isDeleteLoading: { id: 0, status: false },
    isPaginateloding: { id: 0, status: false },
  },
  isSuccess: false,
  isOpenHistory: false,
  notes: [],
  currentNotesPage: 1,
  notesLength: 0,
  history: [],
  historyLength: 0,
  permissions: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        loadingConf: {
          ...state.loadingConf,
          ...action.payload,
        },
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      }
    case types.SET_NOTES:
      return {
        ...state,
        notes: action.payload.slice(0, 10),
        notesLength: action.payload.length,
      }
    case types.PAGINATE_NOTE:
      const notes = getFromLocalStorage('note')
      return {
        ...state,
        notes: action.payload ? notes.slice(0, 10 * action.payload) : notes.slice(0, 10 * state.currentNotesPage),
        currentNotesPage: action.payload ? action.payload : state.currentNotesPage,
      }
    case types.SET_HISTORY:
      return {
        ...state,
        history: action.payload.slice(0, 5),
        historyLength: action.payload.length,
        isOpenHistory: true,
      }
    case types.PAGINATE_HISTORY:
      const history = getFromLocalStorage('history')
      return {
        ...state,
        history: history.slice(0, 5 * action.payload),
      }
    case types.CLEAR_HISTORY:
      removeFromLocalStorage('history')
      return {
        ...state,
        history: [],
        isOpenHistory: false,
      }
    case types.SET_CREATED_NOTE:
      return {
        ...state,
        notes: action.payload.slice(0, 10 * state.currentNotesPage),
        notesLength: action.payload.length,
      }
    case types.SET_CREATE_REPLY_NOTE:
      return {
        ...state,
        notes: action.payload.slice(0, 10 * state.currentNotesPage),
      }
    case types.SET_EDITED_NOTE:
      return {
        ...state,
        notes: action.payload.slice(0, 10 * state.currentNotesPage),
      }
    case types.SET_PAGINATE_REPLY:
      return {
        ...state,
        notes: action.payload.data.slice(0, 10 * state.currentNotesPage),
      }
    case types.SET_DELETED_NOTE:
      const { data } = action.payload
      return {
        ...state,
        notes: data.slice(0, 10 * state.currentNotesPage),
        notesLength: data.length,
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      }
    default:
      return state
  }
}

import React from 'react'
import styles from './style.module.scss'

export const SaveButton = ({ children, type = 'button', onClick, disabled, style }) => {
  return (
    <button type={type} className={styles.button} onClick={onClick} disabled={disabled} style={style}>
      {children}
    </button>
  )
}

import React, { PureComponent } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";

export class Tooltip extends PureComponent {
  state = {
    isShown: false
  };

  render() {
    const { message = "Tooltip text", position = "bottom", arrowIsShown = false, style } = this.props;
    return (
      <div className={classes.Tooltip} style={style} onMouseLeave={() => this.setState({ isShown: false })}>
        {this.state.isShown && (
          <div className={`${classes.bubble} ${classes[position]} ${arrowIsShown ? classes.withArrow : ""}`}>
            <div className={classes.message}>{message}</div>
          </div>
        )}
        <div onMouseOver={() => this.setState({ isShown: true })}>{this.props.children}</div>
      </div>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.node,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  position: PropTypes.string,
  arrowIsShown: PropTypes.bool,
  style: PropTypes.object
};

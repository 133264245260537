import React from 'react'
import { Switch } from 'react-router-dom'
import { Route } from 'react-router'
import { AdminRolePage } from './Page'
import { ManageExceptionPage } from './ManageException'

export const RolePage = () => {
	return (
		<div>
			<Switch>
				<Route path={`/home/admin/manage-system-team/user-roles/:id/manage-exceptions`} component={ManageExceptionPage} exact />
				<Route component={AdminRolePage} />
			</Switch>
		</div>
	)
}

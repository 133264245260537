import { additionalPermissionsConfig } from '@Configs'

export const usePermissions = ({ additionalPermissions, tab }) => {
  const currentObj = additionalPermissionsConfig.find(el => {
    if (el.path === tab) {
      if (additionalPermissions[el.entity] === undefined) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  })
  if (currentObj === undefined) {
    return true
  } else {
    return false
  }
}

import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useToggle } from '@Root/hooks'

const titles = { onEdit: 'Edit the Collaborative Provision', onRead: 'Collaborative Provision Details', onCreate: 'Create the Collaborative Provision' }
export const CollaborativeProvisionForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  descriptions,
  onOpenDescriptionModal,
}) => {
  const [data, setData] = useState({})
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))

  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (isSuccess) toggleEditable(false)
  }, [isSuccess])

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onCancelEditMode = () => {
    onChangeMode()
    setData(initialValue)
  }

  const onSubmitForm = () => {
    onSave(data)
  }

  const onCancelEdit = () => {
    if (!isNew) {
      onCancelEditMode()
      return
    }
    onCancel()
  }

  const { colprovtypeid_id, partnernumhus, partnersid, partnerukprn, id } = data
  const { colprovtypeid } = options
  const { onEdit, onRead, onCreate } = titles

  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this collaborative provision' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onChangeMode}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={id ? `collaborative_provision_${id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Collaborative provision'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={colprovtypeid_id}
                field={'select'}
                onChange={onChangeValue('colprovtypeid_id')}
                placeholder='Collaborative provision'
                isDisabled={!isEditable}
                options={colprovtypeid}
                maxLength={2}
                optionsStyle={{ width: 260 }}
                description={descriptions?.colprovtypeid_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Partner NUMHUS'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={partnernumhus}
                onChange={onChangeValue('partnernumhus')}
                placeholder='Partner NUMHUS'
                isDisabled={!isEditable}
                maxLength={50}
                field={'textArea'}
                description={descriptions?.partnernumhus}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Partner SID'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={partnersid}
                onChange={onChangeValue('partnersid')}
                placeholder='Engagement Number'
                isDisabled={!isEditable}
                maxLength={17}
                description={descriptions?.partnersid}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Partner UKPRN'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={partnerukprn}
                onChange={onChangeValue('partnerukprn')}
                placeholder='Partner UKPRN'
                isDisabled={!isEditable}
                maxLength={8}
                description={descriptions?.partnerukprn}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

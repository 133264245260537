import React, { useEffect, useState, useRef } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { useToggle, useError } from '@Root/hooks'
import { useSelector } from 'react-redux'

const titles = { onRead: 'Study Location', onEdit: 'Edit Study Location', onCreate: 'Create Study Location' }
export const StudyLocationForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  onOpenDescriptionModal,
  dynamicOptions,
  errorsFields,
}) => {
  const [data, setData] = useState({})
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const { errors, setError, onClear: onClearError } = useError()
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).person.hesa_details.student_course_sessions
  const memoData = useRef({})

  useEffect(() => {
    if (initialValue) setData(initialValue)
    if (!memoData.current?.study_location_identifier) memoData.current = initialValue
  }, [initialValue])

  useEffect(() => {
    if (errorsFields['study_proportion']) {
      const error = errorsFields['study_proportion']
      setError('study_proportion', error[0])
    }
  }, [errorsFields])

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      onClearError()
      memoData.current = initialValue
    }
  }, [isSuccess])

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onChangeMode = () => {
    toggleEditable()
  }
  const validateForm = () => {
    const { study_location_identifier, study_proportion } = data
    if (!study_location_identifier) {
      setError('study_location_identifier', 'Required')
      return false
    }
    if (!study_proportion) {
      setError('study_proportion', 'Required')
      return false
    }

    return true
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    onSave(data)
  }

  const onCancelEdit = () => {
    if (!isNew) {
      toggleEditable()
      onClearError()
      onCancel(memoData.current)
    } else {
      onCancel()
    }
  }

  const { onEdit, onRead, onCreate } = titles
  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this Study Location' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onChangeMode}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={data?.id ? `study_location_${data?.id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Study location identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.study_location_identifier}
                onChange={onChangeValue('study_location_identifier')}
                placeholder='Study location identifier'
                isDisabled={!isEditable}
                description={descriptions?.study_location_identifier}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                maxLength={50}
                isRequired={isEditable}
                error={errors.study_location_identifier}
              />
              <Field
                label={'Distance learning'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.session_distance_learning_id}
                onChange={onChangeValue('session_distance_learning_id')}
                placeholder='Distance learning'
                isDisabled={!isEditable}
                options={options.distance_learning}
                description={descriptions?.session_distance_learning_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
              />
              <Field
                label={'Study proportion'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.study_proportion}
                onChange={onChangeValue('study_proportion')}
                placeholder='Study proportion'
                isDisabled={!isEditable}
                description={descriptions?.study_proportion}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'textField'}
                maxLength={6}
                isRequired={isEditable}
                error={errors.study_proportion}
              />
              <Field
                label={'Venue identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.venue_id}
                onChange={onChangeValue('venue_id')}
                placeholder='Venue identifier'
                isDisabled={!isEditable}
                options={dynamicOptions.offVenues}
                description={descriptions?.venue_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

import * as types from './person.types'

const initialState = {
	relationShips: [],
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_RELATIONSHIPS:
			return {
				...state,
				relationShips: action.payload,
			}
		default:
			return state
	}
}

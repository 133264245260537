import React, { useEffect } from 'react'
import styles from './style.module.scss'
import { Redirect, useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { getDataOptionsFromLocalStorage } from '@Root/helpers'
import { CreateEntryQualificationSubject } from './Create'
import { EditEntryQualificationSubject } from './Edit'
import { useSelector } from 'react-redux'
import { entryQualificationAwardedSelectors } from '@Store/studies'
import { useDispatch } from 'react-redux'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager'

export const EntryQualificationSubjectPage = ({ onOpenDescriptionModal }) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const entryQualificationAwarded = useSelector(entryQualificationAwardedSelectors.entryQualificationAwarded)
  const entryQualificationAwardedPermissions = useSelector(entryQualificationAwardedSelectors.permissions)
  const canBeCreated = entryQualificationAwarded.can_be_created_entry_qualification_subject
  const pathname = location.pathname
  const isNew = pathname.includes('new')
  const { id: engagementId, contactId, entryProfileId, entryQualificationId } = useParams()
  const entryQualificationSubjectId = pathname.split('/')[11]

  const DEFAULT_URL = `/home/studies/engagements/${contactId}/${engagementId}/entry-profile/${entryProfileId}/entry-qualification-award/${entryQualificationId}/entry-qualification-subject`

  useEffect(() => {
    getDataOptionsFromLocalStorage(['entry_qualification_subject'])
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'qualification_subject' }))
  }, [])

  if (!entryQualificationId) history.push(DEFAULT_URL)

  if (!canBeCreated && entryQualificationSubjectId === 'new' && entryQualificationAwarded.id) return <Redirect to={DEFAULT_URL} />

  if (entryQualificationAwardedPermissions.tabs && !entryQualificationAwardedPermissions.tabs.entry_qualification_subject) return <Redirect to={DEFAULT_URL} />

  return (
    <SectionWrapper hasBackButton backButtonText={'Entry Qualification Award'} clickBackButtonHandler={() => history.push(DEFAULT_URL)} activeName>
      <div className={styles.sections}>
        {isNew ? (
          <CreateEntryQualificationSubject parentURL={DEFAULT_URL} onOpenDescriptionModal={onOpenDescriptionModal} />
        ) : (
          <EditEntryQualificationSubject parentURL={DEFAULT_URL} onOpenDescriptionModal={onOpenDescriptionModal} />
        )}
      </div>
    </SectionWrapper>
  )
}

import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { FormWrapper } from '@Root/HOCs'
import { SectionTitle, InputLabel, MultiDataListAsync } from '@Root/components'

export const NewSnapshotForm = ({ fetchContactHandler, saveHandler, onOpenDescription, descriptions }) => {
  const [contactIds, setContactIds] = useState(null)
  const [isSpinning, setIsSpinning] = useState(false)
  const [error, setError] = useState(null)

  const showError = (input, message) => setError({ input, message })

  const errorMessage = input => (error && error.input === input ? error.message : null)

  const formIsValid = () => {
    if (!contactIds) {
      showError('contactIds', 'Required')
      return false
    }
    return true
  }

  const fetchContactOptions = async value => {
    const { data } = await fetchContactHandler({ search: `name:${value}`, limit: '100' })
    return data.data.map(option => ({ value: option.id, label: option.name }))
  }

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return
    setIsSpinning(true)
    await saveHandler({ contact_ids: contactIds })
  }

  useEffect(() => {
    NewSnapshotForm.isMounted = true
    return () => {
      NewSnapshotForm.isMounted = false
    }
  }, [])

  useEffect(() => {
    error !== null && setError(null)
  }, [error])

  return (
    <div className={classes.wrapper}>
      <FormWrapper buttons={['save']} buttonsAreDisabled={isSpinning} isSpinning={isSpinning} clickSaveButtonHandler={handleClickSaveButton}>
        <div className={classes.titleWrapper}>
          <SectionTitle title='Create a Snapshot' />
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.columnWrapper}>
            <div className={classes.inputWrapper}>
              <InputLabel text='Contacts' hasAsterisk description={descriptions.contact_id} onOpenDescription={onOpenDescription} />
              <MultiDataListAsync
                inputStyle={{ width: 260 }}
                values={contactIds}
                fetchOptionsHandler={value => fetchContactOptions(value)}
                changeHandler={values => setContactIds(values)}
                error={errorMessage('contactIds')}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

NewSnapshotForm.propTypes = {
  fetchContactHandler: PropTypes.func,
  saveHandler: PropTypes.func,
}

NewSnapshotForm.defaultProps = {
  fetchContactHandler: () => {},
  saveHandler: () => {},
}

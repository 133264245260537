import React, { useCallback, useEffect, useMemo } from 'react'
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { errorMessage, getDataOptionsFromLocalStorage } from '@Root/helpers'
import { modalActions, snackbarActions, optionsSelectors, optionsActions } from '@Root/store'
import { API } from '@Root/API'
import { Table } from '@Root/components'
import { useLocation } from 'react-router'
import { useToggle } from '@Root/hooks'
import { booleanOptions, dataPruningMandatoryRules } from '@Root/configs'
import { personSelectors } from '@Store/contact/person'
import { organisationSelectors } from '@Store/contact/organisation'
import { permissionsSelectors } from '@Store/permissions'

export const GDPRTable = ({ styleConfig }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const personRelationships = useSelector(personSelectors.relationships)
  const organisationsRelationships = useSelector(organisationSelectors.relationships)
  const institutions = useSelector(optionsSelectors.institutions)
  const rulePermissions = useSelector(permissionsSelectors.rulePermissions)
  const [shouldBeUpdated, toggleShouldBeUpdated] = useToggle(false)
  const tab = location.pathname.split('/')[3]

  const GDPRLogTable = useCallback(() => {
    return {
      fetchInitialDataHandler: () => {
        const relationShips = [...organisationsRelationships, ...personRelationships]
        if (relationShips.length <= 0) {
          getDataOptionsFromLocalStorage(['person_relationships', 'organisation_relationships'])
        }
      },
      table: () => {
        const options = {
          relation: [...personRelationships, ...organisationsRelationships].map(({ label }) => label),
          action: ['create', 'view', 'delete', 'edit'],
        }
        return {
          name: 'gdpr-log',
          fetchDataHandler: params => API.gdpr.logs.get(params),
          fetchSaveColumnOptions: options => API.saveColumnOptions('gdpr-log', options),
          fetchColumnOptions: () => API.getColumnOptions('gdpr-log'),
          hasRules: true,
          hasSorting: true,
          hasFilters: true,
          options,
          permissions: {
            rule: rulePermissions['gdpr-log'],
          },
        }
      },
    }
  }, [organisationsRelationships, personRelationships])

  const puringDataTable = useCallback(() => {
    return {
      fetchInitialDataHandler: () => {
        if (institutions.length <= 0) dispatch(optionsActions.getInstitutions())
      },
      table: () => {
        const options = {
          tier_records: booleanOptions.map(x => x.label),
          institution: institutions.map(x => x.label),
          accessibility_records: booleanOptions.map(x => x.label),
          finance_records: booleanOptions.map(x => x.label),
          other_records_attachments: booleanOptions.map(x => x.label),
        }
        return {
          name: 'data-pruning',
          columns: [],
          mandatoryRules: dataPruningMandatoryRules,
          exceptionsFetchDataHandlers: {
            archived: params => API.gdpr.puringData.getArchived(params),
          },
          fetchDataHandler: params => API.gdpr.puringData.get(params),
          fetchSaveColumnOptions: options => API.saveColumnOptions('data-pruning', options),
          fetchColumnOptions: () => API.getColumnOptions('data-pruning'),
          hasRules: true,
          hasSorting: true,
          hasFilters: true,
          options: options,
          checkboxes: {
            'Student Visa': {
              values: {
                No: false,
                Yes: true,
              },
              isDisabledWhenTrue: false,
              changeHandler: ({ id }) => {
                new Promise((resolve, reject) => {
                  dispatch(
                    modalActions.showModal('ConfirmationModal', {
                      text: `This field will be changed`,
                      clickRejectButtonHandler: reject,
                      clickResolveButtonHandler: resolve,
                    })
                  )
                })
                  .then(async () => {
                    try {
                      await API.gdpr.puringData.actions.tierFourRecords(id)
                      toggleShouldBeUpdated()
                    } catch (error) {
                      dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
                    }
                  })
                  .finally(() => {
                    dispatch(modalActions.hideModal())
                  })
              },
            },
            'Accessibility Records': {
              values: {
                No: false,
                Yes: true,
              },
              isDisabledWhenTrue: false,
              changeHandler: ({ id }) => {
                new Promise((resolve, reject) => {
                  dispatch(
                    modalActions.showModal('ConfirmationModal', {
                      text: `This field will be changed`,
                      clickRejectButtonHandler: reject,
                      clickResolveButtonHandler: resolve,
                    })
                  )
                })
                  .then(async () => {
                    try {
                      await API.gdpr.puringData.actions.accessibilityRecords(id)
                      toggleShouldBeUpdated()
                    } catch (error) {
                      dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
                    }
                  })

                  .finally(() => {
                    dispatch(modalActions.hideModal())
                  })
              },
            },
            'Finance records': {
              values: {
                No: false,
                Yes: true,
              },
              isDisabledWhenTrue: false,
              changeHandler: ({ id }) => {
                new Promise((resolve, reject) => {
                  dispatch(
                    modalActions.showModal('ConfirmationModal', {
                      text: `This field will be changed`,
                      clickRejectButtonHandler: reject,
                      clickResolveButtonHandler: resolve,
                    })
                  )
                })
                  .then(async () => {
                    try {
                      await API.gdpr.puringData.actions.financeRecords(id)
                      toggleShouldBeUpdated()
                    } catch (error) {
                      dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
                    }
                  })
                  .finally(() => {
                    dispatch(modalActions.hideModal())
                  })
              },
            },
            'Other Records & Attachments': {
              values: {
                No: false,
                Yes: true,
              },
              isDisabledWhenTrue: false,
              changeHandler: ({ id }) => {
                new Promise((resolve, reject) => {
                  dispatch(
                    modalActions.showModal('ConfirmationModal', {
                      text: `This field will be changed`,
                      clickRejectButtonHandler: reject,
                      clickResolveButtonHandler: resolve,
                    })
                  )
                })
                  .then(async () => {
                    try {
                      await API.gdpr.puringData.actions.otherRecordsAttachments(id)
                      toggleShouldBeUpdated()
                    } catch (error) {
                      dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
                    }
                  })
                  .finally(() => {
                    dispatch(modalActions.hideModal())
                  })
              },
            },
          },
          permissions: {
            rule: rulePermissions['data-pruning'],
          },
        }
      },
    }
  }, [dispatch, institutions, toggleShouldBeUpdated])

  useEffect(() => {
    shouldBeUpdated && toggleShouldBeUpdated()
  }, [shouldBeUpdated, toggleShouldBeUpdated])

  const tableData = useCallback(
    () => ({
      'gdpr-log': GDPRLogTable(),
      'data-pruning': puringDataTable(),
    }),
    [GDPRLogTable, puringDataTable]
  )

  const onError = error => {
    dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }

  const onSuccess = message => {
    dispatch(snackbarActions.setSuccessNotification({ text: message }))
  }

  const { table, fetchInitialDataHandler } = useMemo(() => {
    const data = tableData()[tab]
    return {
      table: data.table(),
      fetchInitialDataHandler: data.fetchInitialDataHandler,
    }
  }, [tab, tableData])

  useEffect(() => {
    fetchInitialDataHandler()
  }, [fetchInitialDataHandler])

  return (
    <div className={classes.wrapper}>
      <Table
        key={table.name}
        name={table.name}
        columns={table.columns}
        fetchDataHandler={table.fetchDataHandler}
        fetchColumnOptions={table.fetchColumnOptions}
        fetchSaveColumnOptions={table.fetchSaveColumnOptions}
        hasRules={table.hasRules}
        hasSorting={table.hasSorting}
        hasFilters={table.hasFilters}
        checkboxes={table.checkboxes}
        mandatoryRules={table.mandatoryRules}
        exceptionsFetchDataHandlers={table.exceptionsFetchDataHandlers}
        errorHandler={onError}
        successHandler={onSuccess}
        styleConfig={{ ...styleConfig.table, tableBar: styleConfig.tableBar }}
        shouldBeUpdated={shouldBeUpdated}
        options={table.options}
        permissions={table.permissions}
      />
    </div>
  )
}

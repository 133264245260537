export const TOGGLE_LOADING = 'studentCourseSession/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'studentCourseSession/TOGGLE_SUCCESS'
export const GET_STUDENT_COURSE_SESSION = 'studentCourseSession/GET_STUDENT_COURSE_SESSION'
export const DELETE_STUDENT_COURSE_SESSION = 'studentCourseSession/DELETE_STUDENT_COURSE_SESSION'
export const EDIT_STUDENT_COURSE_SESSION = 'studentCourseSession/EDIT_STUDENT_COURSE_SESSION'
export const SET_STUDENT_COURSE_SESSION = 'studentCourseSession/SET_STUDENT_COURSE_SESSION'
export const SET_ERRORS_FIELDS = 'studentCourseSession/SET_ERRORS_FIELDS'
export const CREATE_STUDENT_COURSE_SESSION = 'studentCourseSession/CREATE_STUDENT_COURSE_SESSION'
export const COPY_STUDENT_COURSE_SESSION = 'studentCourseSession/COPY_STUDENT_COURSE_SESSION'
export const CLEAR_DATA = 'studentCourseSession/CLEAR_DATA'
export const GET_STATIC_OPTIONS = 'studentCourseSession/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'studentCourseSession/GET_DYNAMIC_OPTIONS'
export const SET_OPTIONS = 'studentCourseSession/SET_OPTIONS'
export const SET_PERMISSIONS = 'studentCourseSession/SET_PERMISSIONS'
export const GET_DYNAMIC_OPTIONS_BY_STUDENT = 'studentCourseSession/GET_DYNAMIC_OPTIONS_BY_STUDENT'

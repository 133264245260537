import React from 'react'
import { CourseReferenceForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { courseReferenceSelectors, courseReferenceActions } from '@Store/studies'

export const CreateCourseReference = ({ onOpenDescriptionModal, parentURL }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { studyProgrammeId } = useParams()
  const courseReference = useSelector(courseReferenceSelectors.courseReference)
  const options = useSelector(courseReferenceSelectors.options)
  const isLoading = useSelector(courseReferenceSelectors.isLoading)

  const onCreate = data => {
    dispatch(courseReferenceActions.create({ data, history, studyProgrammeId }))
  }

  const onCancel = () => {
    history.length > 1 ? history.goBack() : history.push(parentURL)
  }

  return (
    <CourseReferenceForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onCreate}
      onCancel={onCancel}
      canEdit={true}
      isNew
      initialValue={courseReference}
      options={options}
      isLoading={isLoading}
    />
  )
}

import * as types from './sessionYear.types'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions } from './sessionYear.actions'
import { API } from '@Root/API'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id } = payload
  try {
    const { data } = yield call(API.admin.sessionYear.getById, id)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  try {
    const response = yield call(API.admin.sessionYear.create, data)
    history.push(`/home/admin/data/session-year/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The session year has been created successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  try {
    const { data } = yield call(API.admin.sessionYear.edit, payload)
    yield put(actions.set(data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The session year has been saved successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { id, history } = payload
  try {
    yield call(API.admin.sessionYear.remove, id)
    history.push('/home/admin/data/session-year')
    yield put(snackbarActions.setSuccessNotification({ text: 'The session year has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}


export function* rootSaga() {
  yield all([
    takeEvery(types.GET_SESSIONYEAR, get),
    takeEvery(types.CREAT_SESSIONYEAR, create),
    takeEvery(types.EDIT_SESSIONYEAR, edit),
    takeEvery(types.DELETE_SESSIONYEAR, remove),
  ])
}

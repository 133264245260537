import React, { useEffect, useState } from 'react'
import { RoleForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { roleActions, roleSelectors } from '@Store/admin'
import { useHistory } from 'react-router'
import { getFromLocalStorage, removeFromLocalStorage } from '@Root/helpers'

export const CreateRole = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const role = useSelector(roleSelectors.role)
	const isLoading = useSelector(roleSelectors.isLoading)

	useEffect(() => {
		const savedRoleData = getFromLocalStorage('saved_role_data')
		if (savedRoleData) {
			dispatch(roleActions.set(savedRoleData))
		} else {
			dispatch(roleActions.getInitial())
		}
		removeFromLocalStorage('role_form_params')
		removeFromLocalStorage('saved_role_data')
		removeFromLocalStorage('isNew')
	}, [])

	const onSave = payload => {
		dispatch(roleActions.create({ data: payload, history }))
	}

	const onCancel = () => {
		history.length > 1 ? history.goBack() : history.push('/home/admin/manage-system-team/user-roles')
	}

	return <RoleForm canEdit isNew initialValue={role} onSave={onSave} isLoading={isLoading} onCancel={onCancel} />
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { API } from '@Root/API'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage } from '@Root/helpers'
import { actions } from './student.actions'
import * as types from './student.types'
import { contactActions } from '@Root/store'
import { hesaRootSaga } from './hesa'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { contactId } = payload
  try {
    const { data } = yield call(API.contact.person.student.get, contactId)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions(data.permissions))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, contactId } = payload
  try {
    yield call(API.contact.person.student.edit, contactId, data)
    yield put(contactActions.get({ contactId }))
    yield put(actions.set(data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The student information has been saved successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  const { contactId, relationshipId, history } = payload
  yield put(actions.toggleLoading(true))
  try {
    yield call(API.contact.person.student.remove, contactId, relationshipId)
    yield put(contactActions.get({ contactId }))
    yield put(actions.set(null))
    history.push(`/home/contacts/all-contacts/${contactId}/notes`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The student information has been deleted successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(contactActions.toggleLoading(false))
}

function* getStaticOptions() {
  try {
    const {
      data: { disabilities, ethnicities, marital_statuses, nationalities },
    } = yield call(API.contact.person.student.options.getStatic)
    const formattedData = {
      disabilities: disabilities.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
      ethnicities: ethnicities.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
      marital_statuses: marital_statuses.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
      nationalities: nationalities.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
    }
    yield put(actions.setOptions({ name: 'static', data: formattedData }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

function* getDynamicOptions() {
  try {
    const response = yield call(API.contact.person.student.options.getDynamic)
    const formattedOptions = {
      student_types: response[0].data.data.map(obj => ({ value: obj.id, label: obj.name })),
      institutions: response[1].data.data.map(({ id, name }) => ({ value: id, label: name })),
    }
    yield put(actions.setOptions({ name: 'dynamic', data: formattedOptions }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_STUDENT, get),
    takeEvery(types.EDIT_STUDENT, edit),
    takeEvery(types.REMOVE_STUDENT, remove),
    takeEvery(types.GET_STATIC_OPTIONS, getStaticOptions),
    takeEvery(types.GET_DYNAMIC_OPTIONS, getDynamicOptions),
    hesaRootSaga(),
  ])
}

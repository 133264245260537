import { combineReducers } from 'redux'
import { collaborationProvisionReducer } from './collaborativeProvision'
import { entryProfileReducer } from './entryProfile'
import { leaverReducer } from './leaver'
import { qualificationAwardReducer } from './qualificationAward'
import { accreditationAimReducer } from './accreditationAim'
import { studentInitiativesReducer } from './studentInitiatives'
import { reducer } from './engagement.reducer'
import * as engagementSelectors from './engagement.selectors'

export { actions as engagementActions } from './engagement.actions'
export { rootSaga as engagementRootSaga } from './engagement.saga'
export { engagementSelectors }
export * from './collaborativeProvision'
export * from './entryProfile'
export * from './leaver'
export * from './qualificationAward'
export * from './accreditationAim'
export * from './studentInitiatives'
export * from './accreditationAim'

export const engagementReducer = combineReducers({
	reducer,
	collaborationProvision: collaborationProvisionReducer,
	entryProfile: entryProfileReducer,
	leaver: leaverReducer,
	qualificationAward: qualificationAwardReducer,
	accreditationAim: accreditationAimReducer,
	studentInitiatives: studentInitiativesReducer,
})

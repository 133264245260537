import { all, call, put, takeEvery } from 'redux-saga/effects'
import { actions } from './person.actions'
import { createInstance } from '@Root/API'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage, saveDataOptionsToLocalStorage } from '@Root/helpers'
import * as types from './person.types'
import { academicRootSaga } from './academic'
import { clericRootSaga } from './cleric'
import { staffRootSaga } from './staff'
import { studentRootSaga } from './student'
import { associationRootSaga } from './association'

function* getRelationships({ payload }) {
  const { isNew } = payload
  try {
    const { data } = yield call(createInstance().get, 'contacts/person/relationships')
    const formattedData = data.data.map(({ id, display_name }) => {
      const item = { value: id, label: display_name }
      if (display_name === 'Student') return { ...item, isBlocked: true, isDisabled: isNew ? false : true }
      return item
    })
    yield put(actions.setRelationShips(formattedData))
    saveDataOptionsToLocalStorage('person_relationships', formattedData)
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_RELATIONSHIPS, getRelationships),
    academicRootSaga(),
    clericRootSaga(),
    staffRootSaga(),
    studentRootSaga(),
    associationRootSaga(),
  ])
}

import React, { useEffect, useMemo, useState } from 'react'
import styles from './style.module.scss'
import closeIcon from '../../../assets/icons/close.png'
import { CancelButton, CheckboxInput, InputLabel, SaveButton } from '@Root/components'
import { useWindowSize } from '@Root/hooks'
import { modalActions } from '@Root/store'
import { useDispatch } from 'react-redux'

const ignoredFieldsNames = ['Photo']
export const ConfigureExportModal = ({ onSave, onClose, columns }) => {
	const dispatch = useDispatch()
	const [result, setResult] = useState([])
	const { height } = useWindowSize()
	const wrapperHeight = useMemo(() => height - 170, [height])

	useEffect(() => {
		console.log('columns', columns)
		const withoutIgnoredFields = columns.filter(item => !ignoredFieldsNames.includes(item.label))
		setResult(withoutIgnoredFields)
	}, [columns])

	const formattedResult = useMemo(() => result.filter(item => !item.value).map(item => item.label), [result])

	const onToggleModal = () => {
		dispatch(modalActions.hideModal())
	}

	const onChangeValue = (label, value) => {
		setResult(prevState => prevState.map(column => (column.label === label ? { ...column, value: !value } : column)))
	}

	const onSaveForm = () => {
		if (formattedResult.length === columns.length - ignoredFieldsNames.length) {
			dispatch(
				modalActions.showModal('NotificationModal', {
					text: 'At least one data should be active',
					clickRejectButtonHandler: onToggleModal,
					clickResolveButtonHandler: onToggleModal,
				})
			)
			return
		}
		onSave(formattedResult)
	}

	const onSelectAll = () => {
		setResult(result.map(column => ({ ...column, value: true })))
	}

	const onSelectNone = () => {
		setResult(result.map(column => ({ ...column, value: false })))
	}

	return result.length > 0 ? (
		<div className={styles.wrapper}>
			<div className={styles.header} style={{ justifyContent: 'space-between' }}>
				<h3 className={styles.title}>Choose exported columns</h3>
				<div className={styles.icon} onClick={onClose}>
					<img src={closeIcon} alt='' />
				</div>
			</div>
			<div className={styles.line} />
			<div className={`${styles.grid} custom-scroll`} style={{ maxHeight: `${wrapperHeight}px` }}>
				{result.map(({ label, value }) => {
					return (
						<div className={styles.row} key={label}>
							<CheckboxInput isChecked={value} changeHandler={() => onChangeValue(label, value)} />
							<InputLabel text={label} />
						</div>
					)
				})}
			</div>
			<div className={styles.buttons}>
				<div className={styles.left_buttons}>
					<SaveButton onClick={onSelectAll}>Select all</SaveButton>
					<CancelButton onClick={onSelectNone}>Select none</CancelButton>
				</div>
				<div className={styles.right_button}>
					<SaveButton onClick={onSaveForm}>Submit</SaveButton>
				</div>
			</div>
		</div>
	) : (
		<></>
	)
}

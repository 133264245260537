import React from 'react'
import './style.module.scss'
import { SectionWrapper } from '@Root/HOCs'
import { useHistory, useLocation } from 'react-router'
import { Edit } from './Edit'
import { Create } from './Create'

export const MoodleLinkPage = () => {
	const history = useHistory()
	const location = useLocation()
	const isNew = location.pathname.includes('new')

	const DEFAULT_URL = '/home/admin/moodle-links'
	return (
		<div style={{ marginTop: 200 }}>
			<SectionWrapper hasBackButton backButtonText='Moodle Links' clickBackButtonHandler={() => history.push(DEFAULT_URL)}>
				{isNew ? <Create parentURL={DEFAULT_URL} /> : <Edit parentURL={DEFAULT_URL} />}
			</SectionWrapper>
		</div>
	)
}

import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import optionsIcon from '../../../assets/icons/options.png'
import { matchTypes, equalities } from '../../../configs/rules'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router'

export const TableRuleTile = ({
  rule,
  dateRange,
  clickEditHandler = () => {},
  clickBarHandler = () => {},
  isActive,
  errorHandler = () => {},
  shouldBeUpdated = false,
  getFetchDataHandlerByRuleId,
  rulePermissions,
  additionalRules,
}) => {
  const [quantity, setQuantity] = useState(null)
  const { pathname } = useLocation()

  const fetchData = async () => {
    try {
      const { matchType, rows } = rule
      const withAdditionalRules = additionalRules.length > 0 ? [...rows, ...additionalRules[0]?.rows] : rows
      const ruleParams = withAdditionalRules.reduce((acc, row, i) => acc + `${i === 0 ? '' : ';'}${row.field}:${row.filter}`, '')
      const rulesForFields = withAdditionalRules.reduce((acc, row, i) => acc + `${i === 0 ? '' : ';'}${row.field}:${equalities[row.equality]}`, '')
      const params = { start_date: dateRange[0], end_date: dateRange[1], rule: ruleParams, rulesForFields, ruleJoin: matchTypes[matchType] }
      const fetchDataHandler = await getFetchDataHandlerByRuleId(rule.id)
      const res = await fetchDataHandler(params)
      const data = res.data

      setQuantity(data.meta.total)
    } catch (error) {
      errorHandler(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [rule.matchType, JSON.stringify(rule.rows), JSON.stringify(dateRange), JSON.stringify(additionalRules.length)])

  useEffect(() => {
    shouldBeUpdated && fetchData()
  }, [shouldBeUpdated])

  // to prevent page reload
  const handleChange = e => {
    e.preventDefault()
    clickBarHandler()
  }

  return (
    <div className={classes.TableRuleTile}>
      <div className={`${classes.bar} ${isActive ? classes.active : ''}`}>
        <NavLink to={{ pathname: pathname, search: `?rule=${rule.id}` }} onClick={handleChange}>
          <div className={classes.text}>
            <span className={classes.name} style={rule.isMandatory ? { fontWeight: 'bold' } : null}>
              {rule.name}
            </span>
            &nbsp;
            <span>{quantity}</span>
            {/* <span>{quantity !== null ? `(${suspendedRulesId[rule.id] !== undefined ? suspendedRulesId[rule.id] : quantity})` : ''}</span> */}
          </div>
        </NavLink>
        {!rule.isMandatory && rulePermissions?.edit && (
          <div className={classes.opener} onClick={() => clickEditHandler()}>
            <img src={optionsIcon} alt='' />
          </div>
        )}
      </div>
    </div>
  )
}

TableRuleTile.propTypes = {
  rule: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    matchType: PropTypes.string,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        columnName: PropTypes.string,
        equality: PropTypes.string,
        filter: PropTypes.string,
      })
    ),
    isMandatory: PropTypes.bool,
  }),
  dateRange: PropTypes.arrayOf(PropTypes.string),
  fetchDataHandler: PropTypes.func,
  clickBarHandler: PropTypes.func,
  clickEditHandler: PropTypes.func,
  isActive: PropTypes.bool,
  errorHandler: PropTypes.func,
  shouldBeUpdated: PropTypes.bool,
  additionalRules: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.string,
      matchType: PropTypes.string,
      name: PropTypes.string,
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          field: PropTypes.string,
          equality: PropTypes.string,
          filter: PropTypes.string,
        })
      ),
    })
  ),
}

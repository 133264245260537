import { createSelector } from 'reselect'

export const options = state => state.adminReducer.user.reducer.options

export const isLoading = state => state.adminReducer.user.reducer.isLoading

export const isSuccess = state => state.adminReducer.user.reducer.isSuccess

export const user = state => state.adminReducer.user.reducer.user

export const permissions = state => state.adminReducer.user.reducer.permissions

export const errors = state => state.adminReducer.user.reducer.errors

export const formattedOptions = createSelector(options, options => {
	return { ...options.static, ...options.dynamic }
})

import React from 'react'
import { VerticalTabs, ApplicationForm, ApplicationsNotesForm, ApplicationsAttachmentForm } from '@Root/components'
import { Redirect, Route, Switch, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { admissionSelectors } from '@Store/admission'
import { programmeSelectors } from '@Root/store'
import styles from './style.module.scss'

export const DetailInformation = () => {
  const { applicationId } = useParams()
  const defaultPathURL = '/home/admissions/applications'
  const application = useSelector(admissionSelectors.application)
  const programmes = useSelector(programmeSelectors.programmesListOptions)

  const forms = () => {
    return [
      ...(application && programmes
        ? [
            {
              name: 'Application details',
              label: `${applicationId}/application_details`,
              path: '',
              Component: <ApplicationForm data={application} programmes={programmes} />,
            },
          ]
        : []),
      ...(application && programmes
        ? [
            {
              name: 'Notes',
              label: `${applicationId}/notes`,
              Component: <ApplicationsNotesForm applicant_id={applicationId} />,
            },
          ]
        : []),
      ...(application && programmes
        ? [
            {
              name: 'Attachments',
              label: `${applicationId}/attachments`,
              Component: <ApplicationsAttachmentForm applicant_id={applicationId} />,
            },
          ]
        : []),
    ]
  }

  return forms()?.length > 0 ? (
    <div className={styles.wrapper_container}>
      <VerticalTabs tabs={forms()} isLink={true} defaultURL={defaultPathURL} />

      <Switch>
        {forms().map(({ label, Component }) => {
          return <Route exact path={`${defaultPathURL}/${label}`} component={() => Component} key={label} />
        })}
        <Redirect to={`${defaultPathURL}/${applicationId}`} />
      </Switch>
    </div>
  ) : (
    <></>
  )
}

export const isLoading = state => state.studiesReducer.studentCourseSession.supervisorAllocation.isLoading

export const isSuccess = state => state.studiesReducer.studentCourseSession.supervisorAllocation.isSuccess

export const supervisorAllocation = state => state.studiesReducer.studentCourseSession.supervisorAllocation.supervisorAllocation

export const errors = state => state.studiesReducer.studentCourseSession.supervisorAllocation.errors

export const options = state => state.studiesReducer.studentCourseSession.supervisorAllocation.options

export const permissions = state => state.studiesReducer.studentCourseSession.supervisorAllocation.permissions

export const canBeCreatedSupervisorAllocation = state => state.studiesReducer.studentCourseSession.supervisorAllocation.canBeCreatedSupervisorAllocation

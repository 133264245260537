import { combineReducers } from 'redux'
import { reducer } from './contact.reducer'

import { personReducer } from './person'
import { organisationReducer } from './organisation'
import { additionalInformationReducer } from './additionalInformation'
import * as contactSelectors from './contact.selectors'
import { noteReducer } from './note'
import { attachmentReducer } from './attachment'
import { admissionReducer } from './admission'

export { actions as contactActions } from './contact.actions'
export { rootSaga as contactRootSaga } from './contact.saga'
export { contactSelectors }
export * from './note'
export * from './attachment'
export * from './admission'

export const contactReducer = combineReducers({
  reducer,
  person: personReducer,
  organisation: organisationReducer,
  additionalInformation: additionalInformationReducer,
  note: noteReducer,
  attachment: attachmentReducer,
  admission: admissionReducer,
})

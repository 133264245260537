export const toggleArrayPrimitiveValue = (arr, val) => {
  const newArr = [...arr]
  arr.includes(val) ? newArr.splice(arr.indexOf(val), 1) : newArr.push(val)
  return newArr
}

export const toggleArrayPrimitiveValues = (initialArray, valuesArray) => {
  const newArray = [...initialArray]
  if (valuesArray.every(value => newArray.includes(value))) {
    valuesArray.forEach(value => {
      newArray.splice(newArray.indexOf(value), 1)
    })
  } else {
    valuesArray.forEach(value => {
      !newArray.includes(value) && newArray.push(value)
    })
  }
  return newArray
}

export const toggleObjectKeys = (initialObject, { key, value }) => {
  const object = { ...initialObject }
  if (key in object) {
    value ? (object[key] = value) : delete object[key]
  } else {
    value && (object[key] = value)
  }
  return object
}

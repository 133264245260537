import React, { useState, useEffect } from 'react'
import styles from './style.module.scss'
import { HoverVisibleNotification, SectionTabsLinks, TableColumnManager } from '@Root/components'
import { tableManagerMenuTabLinks } from '@Root/configs'
import { Redirect, Route, useLocation, useParams } from 'react-router'

export const TableManager = () => {
  const pathname = useLocation().pathname
  const { slug: currentSlugTab, sub: currentSubSlug } = useParams()
  const [activeTabName, setActiveTabName] = useState(tableManagerMenuTabLinks.studies[0].name)
  const [tabs, setTabs] = useState([])
  const [linksMemo, setLinksMemo] = useState([])
  const [currentSlugTabObj, setCurrentSlugTabObj] = useState([])
  const currentTabLabel = pathname.split('/')[4]

  useEffect(() => {
    setLinksMemo(Object.values(tableManagerMenuTabLinks).flat())
  }, [])

  useEffect(() => {
    setActiveTabName(currentSlugTab)
    setTabs(tableManagerMenuTabLinks[currentTabLabel])
  }, [currentSlugTab, currentTabLabel])

  useEffect(() => {
    setCurrentSlugTabObj(tabs.find(link => link.path.toLowerCase() === currentSlugTab))
  }, [currentSlugTab, tabs])

  return (
    <div className={styles.wrapper} style={{ marginTop: 251 }}>
      <SectionTabsLinks
        tabs={tableManagerMenuTabLinks[currentTabLabel]}
        activeTabName={activeTabName}
        defaultUrl={`/home/admin/table-manager/${currentTabLabel}`}
      />
      <div className={styles.options}>
        <HoverVisibleNotification title='Check columns you need to be visible' initialTop={'-380%'} width={'650px'}>
          <span className={styles.title}>Pay attention: </span>
          <p className={styles.content}>
            You can turn off the display of any column on any page. To save the data, click the "save" button. The data will be visible or not for all TEI
            users.
          </p>
          <ol className={styles.list}>
            <li>
              In this section, you can see the fields that are in the general tables of 5 sections: 'Contacts', Studies', 'Admissions', 'Finance', 'GDPR',
              'Admin'.
            </li>
            <li>By default, all fields are selected in each category, which means that all fields are all available (visible) in the specified tables.</li>
            <li>You can enable all fields in any table (if any fields are disabled) for visibility by clicking the "select all" and "save changes" button.</li>
            <li>
              Since tables cannot be completely empty, there is no "unselect all" button. If you manually disable all fields and try to save these changes, the
              system will warn you that at least one field should be selected. If you still want to hide the entire table, you can do it in the "permissions"
              section by turning off the entire section for visibility for the specific role or for everyone.
            </li>
            <li>
              Keep in mind, since data from the table is going to the export files, if a field is disabled from the table, you will not be able to add it to the
              export file either.
            </li>
            <li>The settings in this section affect what all users of the system will see, regardless of role.</li>
          </ol>
        </HoverVisibleNotification>
        {linksMemo
          .filter(link => !link.hideInTableManager)
          .map(link => {
            return link.subLinks?.length > 0 ? (
              link.subLinks.map(subLink => {
                return (
                  <Route
                    path={`/home/admin/table-manager/${currentTabLabel}/${link.path}/${subLink.path}`}
                    exact
                    render={() => <TableColumnManager />}
                    key={subLink.name}
                  />
                )
              })
            ) : (
              <Route path={`/home/admin/table-manager/${currentTabLabel}/${link.path}`} exact render={() => <TableColumnManager />} key={link.name} />
            )
          })}
        {!currentSlugTab && (
          <Redirect
            from={`/home/admin/table-manager/${currentTabLabel}`}
            to={`/home/admin/table-manager/${currentTabLabel}/${tableManagerMenuTabLinks[currentTabLabel][0].path}`}
          />
        )}
        {currentSlugTab && currentSlugTabObj?.subLinks?.length > 0 && !currentSubSlug && (
          <Redirect
            from={`/home/admin/table-manager/${currentTabLabel}/${currentSlugTabObj.path}`}
            to={`/home/admin/table-manager/${currentTabLabel}/${currentSlugTabObj.path}/${currentSlugTabObj.subLinks[0].path}`}
          />
        )}
      </div>
    </div>
  )
}

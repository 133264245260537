import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { SettingsIcon } from "@Root/assets/icons";

export const SettingsButton = ({ clickHandler }) => {
  return (
    <div className={classes.SettingsButton} onClick={clickHandler}>
      <img src={SettingsIcon} alt="" />
    </div>
  );
};

SettingsButton.propTypes = {
  clickHandler: PropTypes.func
};

export const isLoading = state => state.studiesReducer.studentCourseSession.moduleInstances.isLoading

export const isSuccess = state => state.studiesReducer.studentCourseSession.moduleInstances.isSuccess

export const moduleInstances = state => state.studiesReducer.studentCourseSession.moduleInstances.moduleInstances

export const errors = state => state.studiesReducer.studentCourseSession.moduleInstances.errors

export const options = state => state.studiesReducer.studentCourseSession.moduleInstances.options

export const permissions = state => state.studiesReducer.studentCourseSession.moduleInstances.permissions

export const canBeCreatedModuleInstances = state => state.studiesReducer.studentCourseSession.moduleInstances.canBeCreatedModuleInstances

import React from 'react'
import classes from '../style.module.scss'
import moment from 'moment'
import { SkeletonNote } from '@Root/components/Skeleton/SkeletonNote/index'

export const NotesItem = ({
  note,
  loadingConf,
  onOpenHistoryOfChanges,
  onEditNote,
  onReplyNote,
  editedNoteId,
  canManipulate,
  onDeleteNote,
  renderEditNote,
  editValue,
  isReply = false,
  parentId,
  pageReply,
  callBack,
}) => {
  return (
    <>
      {(loadingConf.isDeleteLoading.status && loadingConf.isDeleteLoading.id === parentId) ||
      (loadingConf.isEditLoading.status && loadingConf.isEditLoading.id === parentId && !isReply) ? (
        <SkeletonNote isReply={isReply} />
      ) : (
        <div className={classes.noteWrapper} style={isReply ? { width: `700px` } : { width: `900px` }}>
          <div className={classes.left}>
            <div className={classes.photo} style={{ backgroundImage: `url(${note.user_avatar})` }} />
          </div>
          <div className={classes.right}>
            <div className={classes.author}>
              <div className={classes.noteInfo}>
                <p>{note?.author_name},</p>
                <p>{note.created_at}, </p>
                {note.edited_author_name && (
                  <p>
                    {' '}
                    (Edited by {note.edited_author_name}, {note.updated_at})
                  </p>
                )}
                {note.reply_to_name && <p> Reply to {note.reply_to_name},</p>}
                <p className={classes.historyOfChanges}>
                  <span onClick={() => onOpenHistoryOfChanges(note.id)} className={classes.historyOfChangesBtn}>
                    history of changes
                  </span>
                </p>
              </div>

              <div className={classes.actions}>
                {note.reply && editedNoteId !== note.id && canManipulate && (
                  <div
                    onClick={() => {
                      const id = parentId === note.id ? '' : note.id
                      onReplyNote(parentId, id, note?.author_name)
                    }}
                    className={classes.reply}
                  >
                    Reply
                  </div>
                )}
                {note.edit && editedNoteId !== note.id && canManipulate && (
                  <div onClick={() => onEditNote(note.id, note.note_text)} className={classes.edit}>
                    Edit
                  </div>
                )}

                {note.delete && editedNoteId !== note.id && canManipulate && (
                  <div onClick={() => onDeleteNote(note.id, isReply ? 'reply' : 'note', isReply && pageReply, callBack)} className={classes.delete}>
                    Delete
                  </div>
                )}
              </div>
            </div>
            {note.id !== editedNoteId ? <div className={classes.text}>{note.note_text}</div> : renderEditNote(editValue)}
          </div>
        </div>
      )}
    </>
  )
}

import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './fundingBody.types'
import { actions } from './fundingBody.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history } = payload
	const { studyProgrammeId, studentCourseSessionId, contactId, fundingBodyId } = data
	try {
		const response = yield call(API.studies.studentCourseSession.fundingBody.get, studentCourseSessionId, fundingBodyId)
		yield put(actions.set(response.data.data))
		yield put(actions.setPermissions(response.data.permissions))
	} catch (error) {
		history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/funding_body`)
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history, studyProgrammeId, studentCourseSessionId, contactId } = payload
	try {
		const response = yield call(API.studies.studentCourseSession.fundingBody.create, studentCourseSessionId, data)
		history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/funding_body/${response.data.data.id}`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The Funding Body has been created successfully!' }))
	} catch (error) {
		const errors = error.response.data.errors
		if (errors['studyProgrammeId']) {
			history.push('/home/studies/programmes/study')
		} else {
			yield put(actions.set(data))
			yield put(actions.setErrors(errors))
		}
	}
	yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, fundingBodyId, studentCourseSessionId } = payload
	try {
		const response = yield call(API.studies.studentCourseSession.fundingBody.edit, studentCourseSessionId, fundingBodyId, data)
		yield put(actions.set(response.data.data))
		yield put(actions.toggleSuccess(true))
		yield put(snackbarActions.setSuccessNotification({ text: 'The Funding Body has been saved successfully!' }))
	} catch (error) {
		const errors = error.response.data.errors
		yield put(actions.set(payload))
		yield put(actions.setErrors(errors))
	}
	yield put(actions.toggleSuccess(false))
	yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
	yield put(actions.toggleLoading(true))
	const { data, history } = payload
	const { contactId, studyProgrammeId, studentCourseSessionId, fundingBodyId } = data
	try {
		yield call(API.studies.studentCourseSession.fundingBody.remove, studentCourseSessionId, fundingBodyId)
		history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}/funding_body`)
		yield put(snackbarActions.setSuccessNotification({ text: 'The Funding Body has been deleted successfully!' }))
	} catch (error) {
		console.log(error)
	}
	yield put(actions.toggleLoading(false))
}

function* getOptions() {
	try {
		const {data} = yield call(API.studies.studentCourseSession.fundingBody.options.getStatic)
		const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
		const formattedData = {
			funding_bodies: generateObject(data.funding_bodies) ,
		}
		yield put(actions.setOptions(formattedData))
		saveDataOptionsToLocalStorage('fundingBody', formattedData)
	} catch (error) {
		console.log(error)
	}
}

export function* rootSaga() {
	yield all([
		takeEvery(types.GET_FUNDINGBODY, get),
		takeEvery(types.CREATE_FUNDINGBODY, create),
		takeEvery(types.EDIT_FUNDINGBODY, edit),
		takeEvery(types.DELETE_FUNDINGBODY, remove),
		takeEvery(types.GET_OPTIONS, getOptions),
	])
}

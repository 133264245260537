import React from 'react'
import styles from './style.module.scss'
import { CrossIcon, PlusIcon } from '@Root/assets'
import { generateId } from '@Root/helpers'
import { Field } from '@Root/components'

export const CreateOptions = ({ fields, onAddOption, onChangeOptionValue, isEditable = true, onRemoveAdditionalOption, errors }) => {
	const addOption = () => {
		const option = { id: generateId(), value: '', label: '' }
		onAddOption(option)
	}

	return (
		<div className={styles.fields}>
			{fields.map(({ id, value, label }, index) => {
				return (
					<div key={id} className={styles.field}>
						<Field
							label={`Option ${index + 1}`}
							classNames={!isEditable ? ['borderless'] : []}
							style={{ width: 260 }}
							listStyle={{ width: 260 }}
							value={value}
							onChange={onChangeOptionValue(id)}
							isDisabled={!isEditable}
							error={errors[id]}
						/>
						<img src={CrossIcon} alt='' onClick={() => onRemoveAdditionalOption(id)} />
					</div>
				)
			})}
			<div className={styles.button} onClick={addOption}>
				<img src={PlusIcon} alt='' /> Add Option
			</div>
		</div>
	)
}

export const TOGGLE_LOADING = 'programme/TOGGLE_LOADING'
export const GET_PROGRAMME = 'programme/GET_PROGRAMME'
export const DELETE_PROGRAMME = 'programme/DELETE_PROGRAMME'
export const EDIT_PROGRAMME = 'programme/EDIT_PROGRAMME'
export const SET_PROGRAMME = 'programme/SET_PROGRAMME'
export const CREATE_PROGRAMME = 'programme/CREATE_PROGRAMME'
export const GET_STATIC_OPTIONS = 'programme/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'programme/GET_DYNAMIC_OPTIONS'
export const SET_OPTIONS = 'programme/SET_OPTIONS'
export const GET_PROGRAMMES_POSTGARD_OPTIONS = 'programme/GET_PROGRAMMES_POSTGARD_OPTIONS'
export const SET_PROGRAMMES_POSTGARD_OPTIONS = 'programme/SET_PROGRAMMES_POSTGARD_OPTIONS'
export const GET_PROGRAMMES_OPTIONS = 'programme/GET_PROGRAMMES_OPTIONS'
export const SET_PROGRAMMES_OPTIONS = 'programme/SET_PROGRAMMES_OPTIONS'
export const SET_PERMISSIONS = 'programme/SET_PERMISSIONS'
export const CLEAR_PERMISSIONS = 'programme/CLEAR_PERMISSIONS'

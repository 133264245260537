import React, { useEffect } from 'react'
import { CourseInitiativeForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { courseInitiativeSelectors, courseInitiativeActions } from '@Store/studies'

export const EditCourseInitiative = ({ onOpenDescriptionModal }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { studyProgrammeId, courseInitiativeId } = useParams()
	const courseInitiative = useSelector(courseInitiativeSelectors.courseInitiative)
	const isSuccess = useSelector(courseInitiativeSelectors.isSuccess)
	const isLoading = useSelector(courseInitiativeSelectors.isLoading)
	const options = useSelector(courseInitiativeSelectors.options)
	const permissions = useSelector(courseInitiativeSelectors.permissions)

	useEffect(() => {
		dispatch(
			courseInitiativeActions.get({
				data: { studyProgrammeId, courseInitiativeId },
				history,
			})
		)
		return () => {
			dispatch(courseInitiativeActions.clearData())
		}
	}, [])

	const onDelete = () => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('ConfirmationModal', {
					text: 'This Course Initiative will be removed?',
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
				})
			)
		})
			.then(async () => {
				dispatch(
					courseInitiativeActions.delete({
						data: { studyProgrammeId, courseInitiativeId },
						history,
					})
				)
			})
			.finally(() => {
				dispatch(modalActions.hideModal())
			})
	}

	const onSave = data => {
		dispatch(courseInitiativeActions.edit({ data, courseInitiativeId, studyProgrammeId }))
	}

	return (
		<CourseInitiativeForm
			onOpenDescriptionModal={onOpenDescriptionModal}
			onSave={onSave}
			onDelete={onDelete}
			canEdit={permissions.form?.edit}
			canDelete={permissions.form?.delete}
			initialValue={courseInitiative}
			isLoading={isLoading}
			isSuccess={isSuccess}
			options={options}
		/>
	)
}

import * as types from './instance.types'

const initialState = {
	options: {
		static: {},
		dynamic: {},
	},
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_OPTIONS:
			return {
				...state,
				options: {
					...state.options,
					[action.payload.name]: action.payload.data,
				},
			}
		default:
			return state
	}
}

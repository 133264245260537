import { combineReducers } from 'redux'
import { feeReducer } from './fee'
import { fundingAndMonitoringReducer } from './fundingAndMonitoring'
import { venueActivityReducer } from './venueActivity'
import { referencePeriodReducer } from './referencePeriod'
import { sessionStatusReducer } from './sessionStatus'
import { studentFinancialSupportReducer } from './studentFinancialSupport'
import { studyLocationReducer } from './studyLocation'
import { fundingBodyReducer } from './fundingBody'
import { moduleInstancesReducer } from './moduleInstances'
import { supervisorAllocationReducer } from './supervisorAllocation'

import { reducer } from './studentCourseSession.reducer'
import * as studentCourseSessionSelectors from './studentCourseSession.selectors'
import { noteReducer } from './note'
import { attachmentReducer } from './attachment'
import { postgradReducer } from './postgrad'

export { actions as studentCourseSessionActions } from './studentCourseSession.actions'
export { rootSaga as studentCourseSessionRootSaga } from './studentCourseSession.saga'
export { studentCourseSessionSelectors }

export * from './fee'
export * from './fundingAndMonitoring'
export * from './note'
export * from './venueActivity'
export * from './attachment'
export * from './postgrad'
export * from './referencePeriod'
export * from './sessionStatus'
export * from './studentFinancialSupport'
export * from './studyLocation'
export * from './fundingBody'
export * from './moduleInstances'
export * from './supervisorAllocation'

export const studentCourseSessionReducer = combineReducers({
  reducer,
  fee: feeReducer,
  fundingAndMonitoring: fundingAndMonitoringReducer,
  note: noteReducer,
  venueActivity: venueActivityReducer,
  referencePeriod: referencePeriodReducer,
  attachment: attachmentReducer,
  postgrad: postgradReducer,
  sessionStatus: sessionStatusReducer,
  studentFinancialSupport: studentFinancialSupportReducer,
  studyLocation: studyLocationReducer,
  fundingBody: fundingBodyReducer,
  moduleInstances: moduleInstancesReducer,
  supervisorAllocation: supervisorAllocationReducer,
})

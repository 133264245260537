export const TOGGLE_LOADING = 'association/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'association/TOGGLE_SUCCESS'
export const GET_ASSOCIATION = 'association/GET_ASSOCIATION'
export const SET_ASSOCIATION = 'association/SET_ASSOCIATION'
export const EDIT_ASSOCIATION = 'association/EDIT_ASSOCIATION'
export const DELETE_ASSOCIATION = 'association/DELETE_ASSOCIATION'
export const GET_STATIC_OPTIONS = 'association/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'association/GET_DYNAMIC_OPTIONS'
export const SET_OPTIONS = 'association/SET_OPTIONS'
export const SET_PERMISSIONS = 'association/SET_PERMISSIONS'

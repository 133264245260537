export const TOGGLE_LOADING = 'studentCourseSession/postgrad/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'studentCourseSession/postgrad/TOGGLE_SUCCESS'
export const GET_POSTGRAD = 'studentCourseSession/postgrad/GET_POSTGRAD'
export const SET_POSTGRAD = 'studentCourseSession/postgrad/SET_POSTGRAD'
export const CREATE_POSTGRAD = 'studentCourseSession/postgrad/CREATE_POSTGRAD'
export const DELETE_POSTGRAD = 'studentCourseSession/postgrad/DELETE_POSTGRAD'
export const EDIT_POSTGRAD = 'studentCourseSession/postgrad/EDIT_POSTGRAD'
export const TOGGLE_CAN_BE_ATTACHED = 'studentCourseSession/postgrad/TOGGLE_CAN_BE_ATTACHED'
export const GET_STATIC_OPTIONS = 'studentCourseSession/postgrad/GET_STATIC_OPTIONS'
export const SET_OPTIONS = 'studentCourseSession/postgrad/SET_OPTIONS'
export const SET_PERMISSIONS = 'studentCourseSession/postgrad/SET_PERMISSIONS'
export const CLEAR_PERMISSIONS = 'studentCourseSession/postgrad/CLEAR_PERMISSIONS'

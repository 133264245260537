export const TOGGLE_LOADING = 'contact/note/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'contact/note/TOGGLE_SUCCESS'
export const GET_NOTE = 'contact/note/GET_NOTE'
export const GET_HISTORY = 'contact/note/GET_HISTORY'
export const SET_NOTES = 'contact/note/SET_NOTES'
export const SET_HISTORY = 'contact/note/SET_HISTORY'
export const CREATE_NOTE = 'contact/note/CREATE_NOTE'
export const CREATE_REPLY_NOTE = 'contact/note/CREATE_REPLY_NOTE'
export const SET_CREATED_NOTE = 'contact/note/SET_CREATED_NOTE'
export const SET_CREATE_REPLY_NOTE = 'contact/note/SET_CREATE_REPLY_NOTE'
export const EDIT_NOTE = 'contact/note/EDIT_NOTE'
export const EDIT_REPLY = 'contact/note/EDIT_REPLY'
export const SET_EDITED_NOTE = 'contact/note/SET_EDITED_NOTE'
export const DELETE_NOTE = 'contact/note/DELETE_NOTE'
export const SET_DELETED_NOTE = 'contact/note/SET_DELETED_NOTE'
export const SET_PERMISSIONS = 'contact/note/SET_PERMISSIONS'
export const CLEAR_HISTORY = 'contact/note/CLEAR_HISTORY'
export const PAGINATE_NOTE = 'contact/note/PAGINATE_NOTE'
export const PAGINATE_REPLY = 'contact/note/PAGINATE_REPLY'
export const SET_PAGINATE_REPLY = 'contact/note/SET_PAGINATE_REPLY'
export const PAGINATE_HISTORY = 'contact/note/PAGINATE_HISTORY'
export const SORT_NOTE = 'contact/note/SORT_NOTE'

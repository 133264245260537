import * as types from './sessionYear.types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	sessionYear: { end_date: null, own_identifier: null, start_date: null, id: null, name: null, identifier: null },
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: action.payload,
			}
		case types.SET_SESSIONYEAR:
			return {
				...state,
				sessionYear: action.payload,
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			}
		default:
			return state
	}
}

import * as types from './fundingAndMonitoring.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_FUNDING_AND_MONITORING,
		payload,
	}),
	set: payload => ({
		type: types.SET_FUNDING_AND_MONITORING,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_FUNDING_AND_MONITORING,
		payload,
	}),
	delete: payload => ({
		type: types.DELETE_FUNDING_AND_MONITORING,
		payload,
	}),
	getOptions: () => ({
		type: types.GET_OPTIONS,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
	clearPermissions: () => ({
		type: types.CLEAR_PERMISSIONS,
	}),
}

import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { useError, useToggle } from '@Root/hooks'
import { useSelector } from 'react-redux'

const titles = { onRead: 'Funding Body', onEdit: 'Edit Funding Body', onCreate: 'Create Funding Body' }
export const FundingBodyForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  onOpenDescriptionModal,
}) => {
  const [data, setData] = useState({})
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).person.hesa_details.student_course_sessions
  const { errors, setError, onClear: onClearError } = useError()

  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      onClearError()
    }
  }, [isSuccess])

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const validateForm = () => {
    const { body_id } = data
    if (!body_id) {
      setError('body_id', 'Required')
      return false
    }

    return true
  }

  const onCancelEditMode = () => {
    onChangeMode()
    setData(initialValue)
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    onSave(data)
  }

  const onCancelEdit = () => {
    if (!isNew) {
      onCancelEditMode()
      onClearError()
      return
    }
    onCancel()
  }

  const { onEdit, onRead, onCreate } = titles
  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this Funding Body' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onChangeMode}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={data?.id ? `funding_body_${data?.id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Funding body'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.body_id}
                onChange={onChangeValue('body_id')}
                placeholder='Funding body'
                isDisabled={!isEditable}
                options={options.funding_bodies}
                description={descriptions?.body_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                isRequired={isEditable}
                error={errors.body_id}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

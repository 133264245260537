import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { FormWrapper } from '@Root/HOCs'
import { Select, InputLabel } from '@Root/components'
import { useSelector } from 'react-redux'
import { fieldTooltipManagerSelectors } from '@Root/store'

const titles = { onRead: 'Edit Funding and Monitoring Details', onEdit: 'Edit Funding and Monitoring Details', onCreate: 'Create Funding and Monitoring' }
export const FundingAndMonitoringForm = ({
  isNew,
  isLoading,
  initialValue,
  options,
  onOpenDescriptionModal,
  onDelete,
  isSuccess,
  onSave,
  onCancel,
  canEdit,
  canDelete,
}) => {
  const [isEditable, setIsEditable] = useState(isNew)
  const [data, setData] = useState({})
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions)['funding-and-monitoring']
  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const handleClickCancelButton = () => {
    if (!isNew) {
      setData(initialValue)
      setIsEditable(false)
      return
    }
    onCancel()
  }

  const handleClickSaveButton = async () => {
    onSave(data)
  }

  const toggleEditMode = () => {
    setIsEditable(prevState => !prevState)
  }

  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (isSuccess) setIsEditable(false)
  }, [isSuccess])

  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', ...(!isNew ? (canDelete ? ['delete'] : []) : []), 'save'] : []}
      buttonsNames={[{ button: 'delete', name: 'Delete this funding and monitoring' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={onDelete}
      clickSaveButtonHandler={handleClickSaveButton}
      canEdit={canEdit}
      clickToggleEditModeButtonHandler={toggleEditMode}
      isEditable={isEditable}
      readTitle={titles.onRead}
      editTitle={titles.onEdit}
      createTitle={titles.onCreate}
      isCreate={isNew}
      idForm={data?.funding_and_monitoring_id ? `funding_monitoring_${data?.funding_and_monitoring_id}` : null}
      isSuccess={isSuccess}
    >
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text='Funding length' description={descriptions.funding_length_id} onOpenDescription={onOpenDescriptionModal} />
          <Select
            inputClassNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 190, width: 260 }}
            options={options?.fundingLengths}
            value={data.funding_length_id}
            changeHandler={value => handleChangeInput('funding_length_id', value)}
            isDisabled={!isEditable}
          />
          <InputLabel
            style={{ position: 'absolute', left: 490 }}
            text='Equivalent or Lower Qualification'
            description={descriptions.equivalent_qualification_id}
            onOpenDescription={onOpenDescriptionModal}
          />
          <Select
            inputClassNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 715, width: 260 }}
            options={options?.equivalentQualification}
            value={data.equivalent_qualification_id}
            changeHandler={value => handleChangeInput('equivalent_qualification_id', value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Funding Completion' description={descriptions.funding_completion_id} onOpenDescription={onOpenDescriptionModal} />
          <Select
            inputClassNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 190, width: 260 }}
            options={options?.fundingCompletion}
            value={data.funding_completion_id}
            changeHandler={value => handleChangeInput('funding_completion_id', value)}
            isDisabled={!isEditable}
          />
          <InputLabel
            style={{ position: 'absolute', left: 490 }}
            text='Non regulated fee flag'
            description={descriptions.non_regulated_fee_flag_id}
            onOpenDescription={onOpenDescriptionModal}
          />
          <Select
            inputClassNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 715, width: 260 }}
            options={options?.nonRegulatedFeeFlags}
            value={data.non_regulated_fee_flag_id}
            changeHandler={value => handleChangeInput('non_regulated_fee_flag_id', value)}
            isDisabled={!isEditable}
          />
        </div>
      </div>
    </FormWrapper>
  )
}

FundingAndMonitoringForm.propTypes = {
  isNew: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  onOpenDescriptionModal: PropTypes.func,
  options: PropTypes.object,
  initialValue: PropTypes.object,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
}

FundingAndMonitoringForm.defaultProps = {
  isNew: false,
  isLoading: false,
  isSuccess: false,
  onOpenDescriptionModal: () => {},
  options: {},
  initialValue: {},
  onCancel: () => {},
  onDelete: () => {},
  onSave: () => {},
  canEdit: false,
  canDelete: false,
}

import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './accreditationAim.types'
import { actions } from './accreditationAim.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { engagementId, accreditationAimId, contactId } = data
  try {
    const response = yield call(API.studies.engagement.accreditationAim.get, engagementId, accreditationAimId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/student-accreditation-aim`)
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history, contactId, engagementId } = payload
  try {
    const response = yield call(API.studies.engagement.accreditationAim.create, engagementId, data)
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/student-accreditation-aim/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The accreditation aim has been created successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, engagementId } = payload
  try {
    const response = yield call(API.studies.engagement.accreditationAim.edit, engagementId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The accreditation aim has been saved successfully!' }))
  } catch (error) {
    const errors = error.response.data.errors
    yield put(actions.set(payload))
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { engagementId, contactId, accreditationAimId } = data
  try {
    yield call(API.studies.engagement.accreditationAim.remove, engagementId, accreditationAimId)
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/student-accreditation-aim`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The accreditation aim has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const {
      data: { accreditations },
    } = yield call(API.studies.engagement.accreditationAim.options.getStatic)
    const generateObject = arr => arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }))
    const formattedData = {
      accreditations: generateObject(accreditations),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('accreditation_aim', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_ACCREDITATION_AIM, get),
    takeEvery(types.CREATE_ACCREDITATION_AIM, create),
    takeEvery(types.EDIT_ACCREDITATION_AIM, edit),
    takeEvery(types.DELETE_ACCREDITATION_AIM, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
  ])
}

import React, { useMemo } from 'react'
import styles from './style.module.scss'
import { Switch, Route, Redirect } from 'react-router-dom'
import { getCurrentLinkName } from '@Root/helpers'
import DocumentTitle from 'react-document-title'
import { PageTitle, PageNavLinks } from '@Root/components'

import { FinanceTable } from './Table'
import { financeLinks } from '@Root/configs'
import { useLocation } from 'react-router'
import { generateStyleConfig } from './lib'
import { useSelector } from 'react-redux'
import { permissionsSelectors } from '@Store/permissions'

export const FinancePage = () => {
	const location = useLocation()
	const currentLinkName = getCurrentLinkName(financeLinks, location)
	const tabsPermissions = useSelector(permissionsSelectors.tabsPermissions)
	//Note: This config exists for overwriting default css styles
	const styleConfig = generateStyleConfig(false)

	const filteredPageLinks = useMemo(() => financeLinks.filter(link => tabsPermissions?.finance?.tabs?.[link.label]), [tabsPermissions])
	const PageInMemo = useMemo(() => () => <FinanceTable styleConfig={styleConfig} />, [styleConfig])

	return (
		<DocumentTitle title={`${currentLinkName} - Finance`}>
			<div className={styles.wrapper}>
				<PageTitle title={currentLinkName} style={styleConfig.pageTitle} />
				<PageNavLinks style={styleConfig.navLinksStyles} root={`/home/finance`} links={filteredPageLinks} />
				<Switch>
					{filteredPageLinks.map((link, i) => (
						<Route exact path={`/home/finance/${link.path}`} component={PageInMemo} key={i} />
					))}
					<Redirect to='/home/finance/tuition-fees' />
				</Switch>
			</div>
		</DocumentTitle>
	)
}

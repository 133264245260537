import React, { useEffect } from 'react'
import { FundingBodyForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { fundingBodySelectors, fundingBodyActions } from '@Store/studies'

export const EditFundingBody = ({ onOpenDescriptionModal, programmeRecord }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { studyProgrammeId, contactId, fundingBodyId } = useParams()
	const fundingBody = useSelector(fundingBodySelectors.fundingBody)
	const isSuccess = useSelector(fundingBodySelectors.isSuccess)
	const isLoading = useSelector(fundingBodySelectors.isLoading)
	const options = useSelector(fundingBodySelectors.options)
	const permissions = useSelector(fundingBodySelectors.permissions)

	useEffect(() => {
		if (!programmeRecord.student_course_session_id) return
		dispatch(
			fundingBodyActions.get({
				data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, fundingBodyId },
				history,
			})
		)
		return () => {
			dispatch(fundingBodyActions.clearData())
		}
	}, [programmeRecord])

	const onDelete = () => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('ConfirmationModal', {
					text: 'This Funding Body will be removed?',
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
				})
			)
		})
			.then(async () => {
				dispatch(fundingBodyActions.delete({ data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, fundingBodyId }, history }))
			})
			.finally(() => {
				dispatch(modalActions.hideModal())
			})
	}

	const onSave = data => {
		dispatch(fundingBodyActions.edit({ data, studentCourseSessionId: programmeRecord.student_course_session_id, fundingBodyId }))
	}

	return (
		<FundingBodyForm
			onOpenDescriptionModal={onOpenDescriptionModal}
			onSave={onSave}
			onDelete={onDelete}
			canEdit={permissions.form?.edit}
			canDelete={permissions.form?.delete}
			initialValue={fundingBody}
			isLoading={isLoading}
			isSuccess={isSuccess}
			options={options}
		/>
	)
}

export const options = state => state.adminReducer.role.options

export const role = state => state.adminReducer.role.role

export const roles = state => state.adminReducer.role.roles

export const isLoading = state => state.adminReducer.role.isLoading

export const isSuccess = state => state.adminReducer.role.isSuccess

export const permissions = state => state.adminReducer.role.permissions

export const TOGGLE_LOADING = 'admin/user/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'admin/user/TOGGLE_SUCCESS'
export const GET_USER = 'admin/user/GET_USER'
export const CREATE_USER = 'admin/user/CREATE_USER'
export const SET_USER = 'admin/user/SET_USER'
export const EDIT_USER = 'admin/user/EDIT_USER'
export const DELETE_USER = 'admin/user/DELETE_USER'
export const GET_STATIC_OPTIONS = 'admin/user/GET_STATIC_OPTIONS'
export const SET_OPTIONS = 'admin/user/SET_OPTIONS'
export const SET_PERMISSIONS = 'admin/user/SET_PERMISSIONS'
export const SET_ERRORS = 'admin/user/SET_ERRORS'

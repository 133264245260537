import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'
import { validateData } from '@Root/helpers'

const titles = { onEdit: 'Edit the Leaver', onRead: 'Leaver Details', onCreate: 'Create the Leaver' }
export const LeaverForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  descriptions,
  onOpenDescriptionModal,
}) => {
  const [data, setData] = useState({})
  const { errors, setError, onClear: onClearError } = useError()
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))

  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess])

  const validateForm = () => {
    const { engagement_end_date, ending_reason_id } = data
    if (!engagement_end_date) {
      setError('engagement_end_date', 'Required')
      return false
    }
    if (!ending_reason_id) {
      setError('ending_reason_id', 'Required')
      return false
    }
    if (validateData([engagement_end_date])) {
      return false
    }
    return true
  }

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onCancelEditMode = () => {
    onChangeMode()
    setData(initialValue)
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onSubmitForm = () => {
    if (!validateForm()) return
    onSave(data)
  }

  const onCancelEdit = () => {
    if (!isNew) {
      onCancelEditMode()
      onClearError()
      return
    }
    onCancel()
  }

  const { engagement_end_date, intended_destination, ending_reason_id, id } = data
  const { reasons } = options
  const { onEdit, onRead, onCreate } = titles
  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this leaver' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onChangeMode}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={id ? `leaver_details_${id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Engagement end date'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={engagement_end_date}
                onChange={onChangeValue('engagement_end_date')}
                placeholder='Engagement end date'
                isDisabled={!isEditable}
                maxLength={10}
                field={'datePicker'}
                error={errors.engagement_end_date}
                isRequired={isEditable}
                description={descriptions?.engagement_end_date}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
            <div className={styles.row}>
              <Field
                label={'Intended destination'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={intended_destination}
                onChange={onChangeValue('intended_destination')}
                placeholder='Intended destination'
                isDisabled={!isEditable}
                maxLength={8}
                description={descriptions?.intended_destination}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Reason for Engagement ending'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={ending_reason_id}
                onChange={onChangeValue('ending_reason_id')}
                placeholder='Reason for Engagement ending'
                isDisabled={!isEditable}
                field={'select'}
                options={reasons}
                isRequired={isEditable}
                optionsStyle={{ width: 260 }}
                maxLength={2}
                error={errors.ending_reason_id}
                description={descriptions?.ending_reason_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

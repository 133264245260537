import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";

export const ApplicationFormTable = ({ style, columnsNamesAndFields, data }) => {
  const columnsNames = Object.keys(columnsNamesAndFields);

  return (
    <table className={classes.ApplicationFormTable} style={style}>
      <thead>
        <tr>
          {columnsNames.map((columnsName, i) => (
            <th style={{ width: `${100 / columnsNames.length}%` }} key={i}>
              {columnsName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.length ? (
          data.map((row, i) => (
            <tr key={i}>
              {columnsNames.map((columnsName, k) => (
                <td key={k}>{row[columnsNamesAndFields[columnsName]]}</td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={columnsNames.length}>Not specified</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

ApplicationFormTable.propTypes = {
  style: PropTypes.object,
  columnsNamesAndFields: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object)
};

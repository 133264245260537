import * as types from './qualificationAwardAccreditation.types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	qualificationAwardAccreditation: {
		accreditation_id: null,
	},
	options: {},
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.SET_QUALIFICATION_AWARD_ACCREDITATION:
			return {
				...state,
				qualificationAwardAccreditation: payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: payload,
			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: payload,
			}
		case types.CLEAR_DATA:
			return {
				...state,
				qualificationAwardAccreditation: initialState.qualificationAwardAccreditation,
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: payload,
			}
		default:
			return state
	}
}

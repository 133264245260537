import React, { useCallback, useEffect, useMemo } from 'react'
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { errorMessage, getFromLocalStorage } from '@Root/helpers'
import { snackbarActions } from '@Root/store'
import { API } from '@Root/API'
import { Table, ActionsDropdown } from '@Root/components'
import { useHistory, useLocation } from 'react-router'
import { qualificationActions, qualificationSelectors, roleActions } from '@Store/admin'
import { permissionsSelectors } from '@Store/permissions'

export const AdminTable = ({ styleConfig }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const qualificationOptions = useSelector(qualificationSelectors.options).static
  const rulePermissions = useSelector(permissionsSelectors.rulePermissions)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const location = useLocation()
  const tab = location.pathname.split('/')[4] || location.pathname.split('/')[3]

  const onSuccess = message => {
    dispatch(snackbarActions.setSuccessNotification({ text: message }))
  }

  const onError = message => {
    dispatch(snackbarActions.setSnackbar({ text: message, isError: true }))
  }

  useEffect(() => {
    dispatch(qualificationActions.getStaticOptions())
  }, [dispatch])

  useEffect(() => {
    const prevLocation = getFromLocalStorage('prevLocation')
    if (prevLocation) {
      if (prevLocation.includes('user-roles/')) dispatch(roleActions.set(null))
    }
  }, [dispatch])

  const systemUsersTableData = useCallback(
    () => ({
      actions: [
        ...(dataCreationPermissions['system-users']
          ? [
              {
                name: 'Create User',
                path: '/home/admin/manage-system-team/system-users/new',
              },
            ]
          : []),
      ],
      table: () => ({
        name: 'system-users',
        fetchDataHandler: params => API.admin.user.getAll(params),
        fetchSaveColumnOptions: options => API.saveColumnOptions('system-users', options),
        fetchColumnOptions: () => API.getColumnOptions('system-users'),
        hasRules: true,
        hasSorting: true,
        clickLinkHandlers: {},
        permissions: {
          rule: rulePermissions['system-users'],
        },
      }),
    }),
    [dataCreationPermissions, history, rulePermissions]
  )

  const userRolesTableData = useCallback(
    () => ({
      actions: [
        ...(dataCreationPermissions['user-roles']
          ? [
              {
                name: 'Create Role',
                path: '/home/admin/manage-system-team/user-roles/new',
              },
            ]
          : []),
      ],
      table: () => ({
        name: 'user-roles',
        fetchDataHandler: params => API.admin.role.getAll(params),
        fetchSaveColumnOptions: options => API.saveColumnOptions('user-roles', options),
        fetchColumnOptions: () => API.getColumnOptions('user-roles'),
        hasRules: true,
        clickLinkHandlers: {},
        permissions: {
          rule: rulePermissions['user-roles'],
        },
      }),
    }),
    [dataCreationPermissions, history, rulePermissions]
  )

  const institutionsTableData = useCallback(
    () => ({
      actions: [
        ...(dataCreationPermissions.institutions
          ? [
              {
                name: 'Add an Institution',
                path: '/home/admin/data/institutions/new',
              },
            ]
          : []),
      ],
      table: () => ({
        name: 'institutions',
        fetchDataHandler: params => API.admin.institution.getAll(params),
        fetchSaveColumnOptions: options => API.saveColumnOptions('institutions', options),
        fetchColumnOptions: () => API.getColumnOptions('institutions'),
        hasRules: true,
        hasSorting: true,
        clickLinkHandlers: {},
        permissions: {
          rule: rulePermissions.institutions,
        },
      }),
    }),
    [dataCreationPermissions.institution, history, rulePermissions.institutions]
  )

  const snapshotsTableData = useCallback(
    () => ({
      actions: [
        ...(dataCreationPermissions.snapshots
          ? [
              {
                name: 'Add a Snapshot',
                path: '/home/admin/snapshots/new',
              },
            ]
          : []),
      ],
      table: () => ({
        name: 'snapshots',
        fetchDataHandler: params => API.admin.snapshot.getAll(params),
        fetchSaveColumnOptions: options => API.saveColumnOptions('snapshots', options),
        fetchColumnOptions: () => API.getColumnOptions('snapshots'),
        hasRules: true,
        hasSorting: true,
        clickLinkHandlers: {},
        permissions: {
          rule: rulePermissions.snapshots,
        },
      }),
    }),
    [dataCreationPermissions.snapshot, history, rulePermissions.snapshots]
  )

  const qualificationsTableData = useCallback(
    () => ({
      fetchInitialDataHandler: () => {
        if (!qualificationOptions) dispatch(qualificationActions.getStaticOptions())
      },
      actions: [
        ...(dataCreationPermissions.qualifications
          ? [
              {
                name: 'Add a Qualification',
                path: '/home/admin/data/qualifications/new',
              },
            ]
          : []),
      ],
      table: () => ({
        name: 'qualifications',
        fetchDataHandler: params => API.admin.qualification.getAll(params),
        fetchSaveColumnOptions: options => API.saveColumnOptions('qualifications', options),
        fetchColumnOptions: () => API.getColumnOptions('qualifications'),
        hasRules: true,
        hasSorting: true,
        clickLinkHandlers: {},
        permissions: {
          rule: rulePermissions.qualifications,
        },
      }),
    }),
    [dataCreationPermissions.qualifications, dispatch, history, qualificationOptions, rulePermissions.qualifications]
  )

  const venuesTableData = useCallback(
    () => ({
      fetchInitialDataHandler: () => {},
      actions: [
        ...(dataCreationPermissions.venues
          ? [
              {
                name: 'Add a Venue',
                path: '/home/admin/data/venues/new',
              },
            ]
          : []),
      ],
      table: () => ({
        name: 'venues',
        fetchDataHandler: params => API.admin.venue.getAll(params),
        fetchSaveColumnOptions: options => API.saveColumnOptions('venues', options),
        fetchColumnOptions: () => API.getColumnOptions('venues'),
        hasRules: true,
        hasSorting: true,
        clickLinkHandlers: {},
        permissions: {
          rule: rulePermissions.venues,
        },
      }),
    }),
    [dataCreationPermissions.venue, dispatch, history, rulePermissions.venues]
  )

  const moodleLinksTableData = useCallback(
    () => ({
      fetchInitialDataHandler: () => {},
      actions: [
        ...(dataCreationPermissions.moodle_links
          ? [
              {
                name: 'Add Moodle Link',
                path: '/home/admin/moodle-links/new',
              },
            ]
          : []),
      ],
      table: () => ({
        name: 'moodle-links',
        fetchDataHandler: params => API.admin.moodle.getAll(params),
        fetchSaveColumnOptions: options => API.saveColumnOptions('moodle-links', options),
        fetchColumnOptions: () => API.getColumnOptions('moodle-links'),
        hasRules: false,
        clickLinkHandlers: {},
        permissions: {
          rule: rulePermissions['moodle-links'],
        },
      }),
    }),
    [history, rulePermissions]
  )

  const sessionYearTableData = useCallback(
    () => ({
      fetchInitialDataHandler: () => {},
      actions: [
        ...(dataCreationPermissions.session_year
          ? [
              {
                name: 'Add Session Year',
                path: '/home/admin/data/session-year/new',
              },
            ]
          : []),
      ],
      table: () => ({
        name: 'session_year',
        fetchDataHandler: params => API.admin.sessionYear.get(params),
        fetchSaveColumnOptions: options => API.saveColumnOptions('session_year', options),
        fetchColumnOptions: () => API.getColumnOptions('session_year'),
        hasRules: true,
        hasSorting: true,
        clickLinkHandlers: {},
        permissions: {
          rule: rulePermissions.session_year,
        },
      }),
    }),
    [history, rulePermissions]
  )
  const tableData = useCallback(
    tab => {
      switch (tab) {
        case 'system-users':
          return systemUsersTableData()
        case 'user-roles':
          return userRolesTableData()
        case 'institutions':
          return institutionsTableData()
        case 'snapshots':
          return snapshotsTableData()
        case 'qualifications':
          return qualificationsTableData()
        case 'venues':
          return venuesTableData()
        case 'moodle-links':
          return moodleLinksTableData()
        case 'session-year':
          return sessionYearTableData()
        default:
          return
      }
    },
    [
      institutionsTableData,
      moodleLinksTableData,
      qualificationsTableData,
      snapshotsTableData,
      systemUsersTableData,
      userRolesTableData,
      venuesTableData,
      sessionYearTableData,
    ]
  )

  const { actions = [], table, fetchInitialDataHandler = () => {} } = useMemo(() => {
    const data = tableData(tab)
    return {
      ...data,
      table: data.table(),
    }
  }, [tab, tableData])

  useEffect(() => {
    fetchInitialDataHandler()
  }, [fetchInitialDataHandler])

  return (
    <div className={classes.wrapper}>
      {!!actions.length && <ActionsDropdown actions={actions} style={styleConfig?.actionsDropdown} />}
      <Table
        name={table.name}
        fetchDataHandler={table.fetchDataHandler}
        fetchSaveColumnOptions={table.fetchSaveColumnOptions}
        fetchColumnOptions={table.fetchColumnOptions}
        convertDataHandler={table.convertDataHandler}
        clickLinkHandlers={table.clickLinkHandlers}
        hasSorting={table.hasSorting}
        hasRules={table.hasRules}
        errorHandler={error => onError(errorMessage(error))}
        successHandler={onSuccess}
        styleConfig={{ ...styleConfig.table, tableBar: styleConfig.tableBar }}
        permissions={table.permissions}
      />
    </div>
  )
}

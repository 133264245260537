import * as types from './engagement.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  engagement: {
    number: null,
    school_director: null,
    start_date: null,
    itt_entry_route: null,
    end_date: null,
    fee_eligibility: null,
    fee_status: null,
    incoming_exchange: null,
    lead_school: null,
    nhs_employer: null,
    engagement_identifier: null,
    qualified_teacher_status: null,
    research_council_student: null,
    research_council_student_identifier: null,
    scottish_candidate_number: null,
    study_intention: null,
    teacher_reference_number: null,
    include_in_hesa: null,
  },
  studyRecords: null,
  entryProfiles: null,
  errors: {},
  options: {
    engagementIds: [],
  },
  permissions: {
    fields: {
      employing_school_id: { view: true, edit: true },
      entryrte_id: { view: true, edit: true },
      expected_end_date: { view: true, edit: true },
      fee_eligibility_id: { view: true, edit: true },
      fee_status_id: { view: true, edit: true },
      incoming_exchange_id: { view: true, edit: true },
      lead_school: { view: true, edit: true },
      nhs_employer_id: { view: true, edit: true },
      number: { view: true, edit: true },
      own_identifier: { view: true, edit: true },
      qualified_teacher_status_id: { view: true, edit: true },
      research_council_student_id: { view: true, edit: true },
      research_council_student_identifier: { view: true, edit: true },
      start_date: { view: true, edit: true },
      study_intention_id: { view: true, edit: true },
      teacher_reference_number: { view: true, edit: true },
      outside_id: { view: true, edit: true },
    },
  },
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case types.SET_ENGAGEMENT:
      return {
        ...state,
        engagement: action.payload,
      }
    case types.SET_ENTRY_PROFILE:
      return {
        ...state,
        entryProfiles: action.payload,
      }
    case types.SET_PROFILE_RECORDS:
      return {
        ...state,
        studyRecords: action.payload,
      }
    case types.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          ...action.payload,
        },
      }

    case types.CLEAR_DATA:
      return {
        ...state,
        engagement: initialState.engagement,
        errors: initialState.errors,
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      }
    default:
      return state
  }
}

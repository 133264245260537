import React, { useEffect } from 'react'
import { SupervisorAllocationForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { supervisorAllocationSelectors, supervisorAllocationActions } from '@Store/studies'
import { hesaSelectors } from '@Store/contact/person'

export const EditVenueActivity = ({ onOpenDescriptionModal, programmeRecord }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { studyProgrammeId, contactId, supervisorAllocationId } = useParams()
  const supervisorAllocation = useSelector(supervisorAllocationSelectors.supervisorAllocation)
  const isSuccess = useSelector(supervisorAllocationSelectors.isSuccess)
  const isLoading = useSelector(supervisorAllocationSelectors.isLoading)
  const options = useSelector(supervisorAllocationSelectors.options)
  const errors = useSelector(supervisorAllocationSelectors.errors)
  const permissions = useSelector(supervisorAllocationSelectors.permissions)
  const dynamicOptions = useSelector(hesaSelectors.options).dynamic

  useEffect(() => {
    if (!programmeRecord.student_course_session_id) return
    dispatch(
      supervisorAllocationActions.get({
        data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, supervisorAllocationId },
        history,
      })
    )
  }, [programmeRecord])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This Supervisor Allocation will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(
          supervisorAllocationActions.delete({
            data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, supervisorAllocationId },
            history,
          })
        )
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = data => {
    dispatch(supervisorAllocationActions.edit({ data, studentCourseSessionId: programmeRecord.student_course_session_id, supervisorAllocationId }))
  }

  const onCancel = memoData => {
    dispatch(supervisorAllocationActions.set(memoData))
    dispatch(supervisorAllocationActions.setErrors({}))
  }

  return (
    <SupervisorAllocationForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onSave}
      onDelete={onDelete}
      onCancel={onCancel}
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      initialValue={supervisorAllocation}
      isLoading={isLoading}
      isSuccess={isSuccess}
      options={options}
      dynamicOptions={dynamicOptions}
      errorsFields={errors}
    />
  )
}

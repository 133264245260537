export const TOGGLE_LOADING = 'studentCourseSession/fundingBody/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'studentCourseSession/fundingBody/TOGGLE_SUCCESS'
export const GET_FUNDINGBODY = 'studentCourseSession/fundingBody/GET_FUNDINGBODY'
export const SET_FUNDINGBODY = 'studentCourseSession/fundingBody/SET_FUNDINGBODY'
export const CREATE_FUNDINGBODY = 'studentCourseSession/fundingBody/CREATE_FUNDINGBODY'
export const DELETE_FUNDINGBODY = 'studentCourseSession/fundingBody/DELETE_FUNDINGBODY'
export const EDIT_FUNDINGBODY = 'studentCourseSession/fundingBody/EDIT_FUNDINGBODY'
export const GET_OPTIONS = 'studentCourseSession/fundingBody/GET_OPTIONS'
export const SET_OPTIONS = 'studentCourseSession/fundingBody/SET_OPTIONS'
export const CLEAR_DATA = 'studentCourseSession/fundingBody/CLEAR_DATA'
export const SET_PERMISSIONS = 'studentCourseSession/fundingBody/SET_PERMISSIONS'
export const SET_CAN_BE_CREATED = 'studentCourseSession/fundingBody/SET_CAN_BE_CREATED'
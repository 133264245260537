import { createSelector } from 'reselect'

const formattedArrayToObject = array => {
  if (array.length === 0 || !array) return {}
  return Object.entries(array).reduce((acc, item) => {
    const entityType = item[0]
    const obj = item[1]
    return {
      ...acc,
      [entityType]: Object.entries(obj).reduce((acc, item) => {
        const obj = item[1]
        return {
          ...acc,
          [obj.name]: obj.pars_description,
        }
      }, {}),
    }
  }, {})
}

const contactTypeDescriptionArrayToObject = array => {
  const formattedObject = formattedArrayToObject(array)

  const {
    address_line_1,
    address_line_2,
    address_line_3,
    city,
    country_id,
    address_type,
    county,
    postcode,
    issued_by,
    date_expires,
    valid_dbs,
    title,
    ...rest
  } = formattedObject.form
  const addressObject = {
    address_line_1,
    address_line_2,
    address_line_3,
    city,
    country_id,
    address_type,
    county,
    postcode,
  }
  const dbsObject = {
    issued_by,
    date_expires,
    valid_dbs,
    title,
  }
  return { ...formattedObject, form: { ...rest, address: addressObject, dbs: dbsObject } }
}

export const isLoading = state => state.fieldTooltipManagerReducer.isLoading

export const isSuccess = state => state.fieldTooltipManagerReducer.isSuccess

export const messages = state => state.fieldTooltipManagerReducer.messages

export const person = state => state.fieldTooltipManagerReducer.descriptions.contact.person

export const organisation = state => state.fieldTooltipManagerReducer.descriptions.contact.organisation

const studies = state => state.fieldTooltipManagerReducer.descriptions.studies

const admin = state => state.fieldTooltipManagerReducer.descriptions.admin

export const permissions = state => state.fieldTooltipManagerReducer.permissions

export const studiesDescriptions = createSelector([studies], studies => {
  return formattedArrayToObject(studies)
})

export const adminDescriptions = createSelector([admin], admin => formattedArrayToObject(admin))

export const contactDescriptions = createSelector([person, organisation], (person, organisation) => {
  return {
    person: {
      ...contactTypeDescriptionArrayToObject(person),
      hesa_details: formattedArrayToObject(person.hesa_details),
    },
    organisation: contactTypeDescriptionArrayToObject(organisation),
  }
})

export const rollover = state => state.fieldTooltipManagerReducer.descriptions.contact.rollover

export const rolloverDescriptions = createSelector([rollover], rollover => {
  return formattedArrayToObject(rollover)
})

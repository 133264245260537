import { useEffect } from 'react'
import { Redirect, useHistory, useLocation, useParams } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import styles from './style.module.scss'
import { CreateQualificationAward } from './Create'
import { EditQualificationAward } from './Edit'
import { useSelector } from 'react-redux'
import { engagementSelectors } from '@Store/studies/engagement/index'
import { useDispatch } from 'react-redux'
import { fieldTooltipManagerActions } from '@Store/fieldTooltipManager'

export const QualificationAwardPage = ({ onOpenDescriptionModal }) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const engagementPermissions = useSelector(engagementSelectors.permissions)
  const pathname = location.pathname
  const isNew = location.pathname.includes('new')
  const { id: engagementId, contactId } = useParams()
  const qualificationAwardedId = pathname.split('/')[7]

  useEffect(() => {
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'studies', entity_type: 'engagement_qualification_award' }))
  }, [])

  const PARENT_URL = `/home/studies/engagements/${contactId}/${engagementId}/qualification-award`
  if (!qualificationAwardedId) history.push(PARENT_URL)

  if (engagementPermissions.tabs && !engagementPermissions.tabs.qualification_awarded) return <Redirect to={PARENT_URL} />

  return (
    <SectionWrapper hasBackButton backButtonText={'Engagement'} clickBackButtonHandler={() => history.push(PARENT_URL)} activeName>
      <div className={styles.sections}>
        {isNew ? (
          <CreateQualificationAward parentURL={PARENT_URL} onOpenDescriptionModal={onOpenDescriptionModal} />
        ) : (
          <EditQualificationAward parentURL={PARENT_URL} onOpenDescriptionModal={onOpenDescriptionModal} />
        )}
      </div>
    </SectionWrapper>
  )
}

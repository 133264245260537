import * as types from './modules.types'
import { CLEAR_FORM } from './modules.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_MODULE,
		payload,
	}),
	set: payload => ({
		type: types.SET_MODULE,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_MODULE,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_MODULE,
		payload,
	}),
	remove: payload => ({
		type: types.DELETE_MODULE,
		payload,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	getStaticOptions: () => ({
		type: types.GET_STATIC_OPTIONS,
	}),
	getDynamicOptions: () => ({
		type: types.GET_DYNAMIC_OPTIONS,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
	clearPermissions: () => ({
		type: types.CLEAR_PERMISSIONS,
	}),
}

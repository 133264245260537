import React, { useEffect } from 'react'
import classes from './style.module.scss'
import closeIcon from '../../../assets/icons/close.png'
import { processStatuses } from '@Root/configs'

export const ProcessModal = ({ text, clickRejectButtonHandler, clickResolveButtonHandler, title, fetchDataHandler }) => {
	useEffect(() => {
		const DURATION = 2000
		let interval
		interval = setInterval(async () => {
			try {
				const { data } = await fetchDataHandler()
				const status = data.data.status
				if (status === processStatuses.success) {
					clearInterval(interval)
					clickResolveButtonHandler(data.data)
				}
			} catch (error) {
				clickRejectButtonHandler(error)
				console.log('Error:', error)
			}
		}, DURATION)

		return () => {
			clearInterval(interval)
		}
	}, [clickRejectButtonHandler, clickResolveButtonHandler, fetchDataHandler])

	return (
		<div className={classes.wrapper}>
			<div className={classes.header} style={{ justifyContent: title ? 'space-between' : 'flex-end' }}>
				{title && <h3 className={classes.title}>{title}</h3>}
				<div className={classes.icon} onClick={clickRejectButtonHandler}>
					<img src={closeIcon} alt='' />
				</div>
			</div>
			<div className={classes.line} />
			<div className={classes.contentText}>{text}</div>
		</div>
	)
}

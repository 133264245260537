export const TOGGLE_LOADING = 'admin/venue/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'admin/venue/TOGGLE_SUCCESS'
export const GET_VENUE = 'admin/venue/GET_VENUE'
export const SET_VENUE = 'admin/venue/SET_VENUE'
export const CREAT_VENUE = 'admin/venue/CREAT_VENUE'
export const EDIT_VENUE = 'admin/venue/EDIT_VENUE'
export const DELETE_VENUE = 'admin/venue/DELETE_VENUE'
export const SET_PERMISSIONS = 'admin/venue/SET_PERMISSIONS'
export const CLEAR_DATA = 'admin/venue/CLEAR_DATA'
export const SET_ERRORS = 'admin/venue/SET_ERRORS'

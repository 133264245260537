import React, { useState, useEffect, useRef } from 'react'
import styles from './style.module.scss'
import isEmail from 'validator/lib/isEmail'
import { switchEmptyStringsToNullsInObject } from '@Root/helpers'
import { FormWrapper } from '@Root/HOCs'
import { FileInput, Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'
import { ToggleSwitcher } from '@Root/components/ToggleSwitcher'
import { InputLabel } from '@Root/components'

const titles = { onRead: 'User', onCreate: 'Create User', onEdit: 'Edit User' }
export const UserForm = ({
  isNew,
  options,
  initialValue,
  onDelete,
  onSave,
  descriptions,
  isLoading,
  onCancel,
  canEdit,
  onOpenDescriptionModal,
  isSuccess,
  canDelete,
  initialErrors,
}) => {
  const [isEditable, toggleEditable] = useToggle(isNew)
  const [file, setFile] = useState(null)
  const [data, setData] = useState({})
  const { errors, setError, onClear: onClearError } = useError()
  const memoData = useRef({})

  useEffect(() => {
    if (initialErrors?.email) setError('email', initialErrors.email)
    if (initialErrors?.login) setError('login', initialErrors.login)
  }, [initialErrors])

  const validateForm = () => {
    const { login, password, name, email, user_roles, status } = data
    if (!login) {
      setError('login', 'Required')
      return false
    }
    if (isNew) {
      if (!password) {
        setError('password', 'Required')
        return false
      }
      if (password && password.length < 5) {
        setError('password', 'Password length should be at least 5 characters')
        return false
      }
    }
    if (!name) {
      setError('name', 'Required')
      return false
    }
    if (!email) {
      setError('email', 'Required')
      return false
    }
    if (email && !isEmail(email)) {
      setError('email', 'Invalid email')
      return false
    }
    if (!user_roles) {
      setError('user_roles', 'Required')
      return false
    }
    if (!status) {
      setError('status', 'Required')
      return false
    }
    return true
  }

  const modifiedData = () => {
    const modifiedData = switchEmptyStringsToNullsInObject(data)
    file && (modifiedData.photo = file)
    return modifiedData
  }

  const onSubmit = async () => {
    if (!validateForm()) return
    onSave(modifiedData())
  }

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onChangePhoto = (photo, file) => {
    onChangeValue('photo')(photo)
    setFile(file)
  }

  const onCancelEditForm = () => {
    if (!isNew) {
      toggleEditable()
      onClearError()
      setData(memoData.current)
      onCancel(memoData.current)
    } else {
      onCancel()
    }
  }

  const onChangeSwitcher = field => {
    setData(prevState => ({ ...prevState, [field]: !data[field] }))
  }

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      onClearError()
      memoData.current = initialValue
    }
  }, [isSuccess])

  useEffect(() => {
    delete initialValue.tokens
    if (initialValue) setData(initialValue)
    if (!memoData.current?.login) memoData.current = initialValue
  }, [initialValue, isNew])

  const { onRead, onCreate, onEdit } = titles

  const {
    login,
    password,
    name,
    email,
    user_roles,
    status,
    phone_number,
    address,
    note,
    photo,
    twofa_status,
    force_activated,
    prevent_turning_off,
    reset_id,
    id,
  } = data

  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', ...(!isNew ? (canDelete ? ['delete'] : []) : []), 'save'] : []}
      buttonsNames={[{ button: 'delete', name: 'Delete this user' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading}
      clickCancelButtonHandler={onCancelEditForm}
      clickDeleteButtonHandler={onDelete}
      clickSaveButtonHandler={onSubmit}
      canEdit={canEdit}
      isEditable={isEditable}
      readTitle={onRead}
      editTitle={onEdit}
      createTitle={onCreate}
      clickToggleEditModeButtonHandler={toggleEditable}
      isCreate={isNew}
      idForm={id ? `student_financial_support_${id}` : null}
      isSuccess={isSuccess}
    >
      <div className={styles.grid}>
        <div className={styles.column}>
          <FileInput
            inputClassNames={!isEditable ? ['borderless'] : []}
            value={photo}
            file={file}
            changeHandler={onChangePhoto}
            removeHandler={() => onChangePhoto(null, null)}
            isDisabled={!isEditable}
            restrictions={['image']}
          />
        </div>
        <div className={styles.column}>
          <Field
            label={'Login'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={login}
            onChange={onChangeValue('login')}
            placeholder='Login'
            isDisabled={!isEditable}
            description={descriptions.login}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'textField'}
            error={errors.login}
            isRequired={isEditable}
          />{' '}
          <Field
            label={isNew ? 'Password' : 'New Password'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={password}
            onChange={onChangeValue('password')}
            placeholder={isNew ? 'Password' : 'New Password'}
            isDisabled={!isEditable}
            description={descriptions.password}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'textField'}
            error={errors.password}
            isRequired={isEditable && isNew}
          />
          <Field
            label={'Contact name'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={name}
            onChange={onChangeValue('name')}
            placeholder={'Contact name'}
            isDisabled={!isEditable}
            description={descriptions.name}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'textField'}
            error={errors.name}
            isRequired={isEditable}
          />
          <Field
            label={'Email address'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={email}
            onChange={onChangeValue('email')}
            placeholder={'Email address'}
            isDisabled={!isEditable}
            description={descriptions.email}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'textField'}
            error={errors.email}
            isRequired={isEditable}
          />{' '}
          <Field
            label={'User role'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            optionsStyle={{ width: 260 }}
            value={user_roles}
            options={options?.roles}
            onChange={onChangeValue('user_roles')}
            placeholder={'User role'}
            isDisabled={!isEditable}
            description={descriptions.user_roles_names}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'multiSelect'}
            error={errors.user_roles}
            isRequired={isEditable}
          />
          <Field
            label={'Status'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            optionsStyle={{ width: 260 }}
            value={status}
            options={options?.statuses}
            onChange={onChangeValue('status')}
            placeholder={'Status'}
            isDisabled={!isEditable}
            description={descriptions.status}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'select'}
            error={errors.status}
            isRequired={isEditable}
          />
          <Field
            label={'Phone number'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260 }}
            value={phone_number}
            onChange={onChangeValue('phone_number')}
            placeholder={'Phone number'}
            isDisabled={!isEditable}
            description={descriptions.phone_number}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'textField'}
          />
          <Field
            label={'Address'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260, height: 90 }}
            value={address}
            onChange={onChangeValue('address')}
            placeholder={'Address'}
            isDisabled={!isEditable}
            description={descriptions.address}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'textArea'}
          />
        </div>
        <div className={styles.column}>
          <Field
            label={'Note'}
            classNames={!isEditable ? ['borderless'] : []}
            style={{ width: 260, height: 90 }}
            value={note}
            onChange={onChangeValue('note')}
            placeholder={'Note'}
            isDisabled={!isEditable}
            description={descriptions.note}
            onOpenDescriptionModal={onOpenDescriptionModal}
            field={'textArea'}
          />
          <div className={styles.AuthWrapper}>
            <p className={styles.AuthTitle}>Authorization</p>

            <div className={styles.row}>
              <InputLabel text={'Status'} description={descriptions.twofa_status} onOpenDescription={onOpenDescriptionModal} />
              <div>{twofa_status ? <span className={styles.statusActive}>Activated</span> : <span className={styles.statusNotActive}>Not Activated</span>}</div>
            </div>

            <div className={styles.row}>
              <InputLabel text={'Force to be activated'} description={descriptions.force_activated} onOpenDescription={onOpenDescriptionModal} />
              <div className={styles.checkbox_item}>
                <span>no</span>
                <ToggleSwitcher checked={force_activated} onChange={e => onChangeSwitcher('force_activated')} disabled={!isEditable} />
                <span>yes</span>
              </div>
            </div>

            <div className={styles.row}>
              <InputLabel text={'Prevent from turning off'} description={descriptions.prevent_turning_off} onOpenDescription={onOpenDescriptionModal} />
              <div className={styles.checkbox_item}>
                <span>no</span>
                <ToggleSwitcher checked={prevent_turning_off} onChange={e => onChangeSwitcher('prevent_turning_off')} disabled={!isEditable} />
                <span>yes</span>
              </div>
            </div>
            <Field
              label={'Reset'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              optionsStyle={{ width: 260 }}
              value={reset_id}
              options={options?.reset_2fa}
              onChange={onChangeValue('reset_id')}
              placeholder={'Reset'}
              isDisabled={!isEditable}
              description={descriptions.reset_id}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'select'}
            />
          </div>
        </div>
      </div>
    </FormWrapper>
  )
}

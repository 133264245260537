import React, { useEffect, useState } from 'react'
import { modalActions } from '@Store/modal'
import { moodleActions, moodleSelectors } from '@Store/admin'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { MoodleLinkForm } from '@Root/components'

export const Edit = () => {
  const [isEditable, setEditable] = useState(false)
  const { moodleId } = useParams()
  const moodle = useSelector(moodleSelectors.moodle)
  const isLoading = useSelector(moodleSelectors.isLoading)
  const isSuccess = useSelector(moodleSelectors.isSuccess)
  const error = useSelector(moodleSelectors.error)
  const permissions = useSelector(moodleSelectors.permissions)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isSuccess) setEditable(false)
  }, [isSuccess])

  useEffect(() => {
    dispatch(moodleActions.get({ moodleId }))
  }, [dispatch, moodleId])

  useEffect(() => {
    let timeout
    if (error) {
      timeout = setTimeout(() => {
        dispatch(moodleActions.setError(null))
      }, 1000)
    }
    return () => {
      dispatch(moodleActions.setError(null))
      dispatch(moodleActions.set(null))
      clearTimeout(timeout)
    }
  }, [dispatch, error])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This moodle link will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        dispatch(moodleActions.delete({ moodleId, history }))
      })
      .finally(() => dispatch(modalActions.hideModal()))
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  const onSubmit = data => {
    dispatch(moodleActions.edit({ moodleId, data }))
  }

  const onToggleEdit = () => {
    setEditable(prevState => !prevState)
  }

  const onCancel = memoData => {
    onToggleEdit()
    dispatch(moodleActions.set(memoData))
    dispatch(moodleActions.setError(null))
  }

  return (
    <MoodleLinkForm
      isLoading={isLoading}
      onSubmit={onSubmit}
      onDelete={onDelete}
      initialData={moodle}
      isSuccess={isSuccess}
      onCancel={onCancel}
      isEditable={isEditable}
      onToggleEdit={onToggleEdit}
      error={error}
      onOpenDescriptionModal={onOpenDescriptionModal}
      permissions={permissions}
    />
  )
}

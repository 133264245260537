import React, { useEffect } from 'react'
import { fieldTooltipManagerActions, modalActions } from '@Root/store'
import { useDispatch } from 'react-redux'
import styles from './style.module.scss'
import { useHistory, useLocation } from 'react-router'
import { SectionWrapper } from '@Root/HOCs'
import { EditUser } from './Edit'
import { CreateUser } from './Create'
import { userActions } from '@Store/admin'

const PARENT_URL = '/home/admin/manage-system-team/system-users'
export const UserPage = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()
	const pathname = location.pathname
	const isNew = pathname.includes('new')

	useEffect(() => {
		dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'admin', entity_type: 'system-users' }))
		dispatch(userActions.getStaticOptions())
	}, [dispatch])

	const onOpenDescriptionModal = (description, title) => {
		new Promise((resolve, reject) => {
			dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
		}).finally(() => dispatch(modalActions.hideModal()))
	}

	return (
		<div className={styles.wrapper}>
			<SectionWrapper hasBackButton backButtonText='System Users' clickBackButtonHandler={() => history.push(PARENT_URL)}>
				{isNew ? <CreateUser onOpenDescriptionModal={onOpenDescriptionModal} /> : <EditUser onOpenDescriptionModal={onOpenDescriptionModal} />}
			</SectionWrapper>
		</div>
	)
}

import * as types from './qualification.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_QUALIFICATION,
		payload,
	}),
	set: payload => ({
		type: types.SET_QUALIFICATION,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_QUALIFICATION,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_QUALIFICATION,
		payload,
	}),
	remove: payload => ({
		type: types.DELETE_QUALIFICATION,
		payload,
	}),
	getStaticOptions: () => ({
		type: types.GET_STATIC_OPTIONS,
	}),
	setOptions: payload => ({
		type: types.SET_OPTIONS,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
	clearData: () => ({
		type: types.CLEAR_DATA,
	}),
}

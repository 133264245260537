import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, Select, DatePicker } from "@Root/components";

export class StudiesDeliveryModuleForm extends Component {
  constructor(props) {
    super(props);
    const { initialIsEditable, initialData, generateId } = props;
    this.state = {
      isEditable: initialIsEditable,
      error: null,
      isSaving: false,
      module_delivery_identifier: generateId ? Math.floor(Math.random() * (100000000000000 - 1 + 1)) + 1 : initialData.module_delivery_identifier,
      language_identifier_id: initialData.language_identifier_id,
      module_end_date: initialData.module_end_date,
      educational_module_id: initialData.educational_module_id,
      module_start_date: initialData.module_start_date
    };
  }

  formIsValid = () => {
    const { module_start_date, module_end_date, module_delivery_identifier } = this.state;
    if (!module_delivery_identifier) {
      this.showError("module_delivery_identifier", "Required");
      return false;
    }
    if (!module_start_date) {
      this.showError("module_start_date", "Required");
      return false;
    }
    if (!module_end_date) {
      this.showError("module_end_date", "Required");
      return false;
    }
    return true;
  };

  showError = (input, message) => {
    this.setState({ error: { input, message } }, () => {
      this.setState({ error: null });
    });
  };

  errorMessage = input => {
    const { error } = this.state;
    return error && error.input === input ? error.message : null;
  };

  setInitialState = () => {
    const { initialData } = this.props;
    this.setState({
      isEditable: false,
      error: null,
      module_delivery_identifier: initialData.module_delivery_identifier,
      language_identifier_id: initialData.language_identifier_id,
      module_end_date: initialData.module_end_date,
      educational_module_id: initialData.educational_module_id,
      module_start_date: initialData.module_start_date
    });
  };

  handleClickCancelButton = () => {
    this.setInitialState();
  };

  handleClickDeleteButton = () => {
    this.props.deleteHandler(
      () => {
        this.setState({ isSaving: true });
      },
      () => {
        this.setState({ isSaving: false });
      }
    );
  };

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return;
    const moduleId = this.props.location.pathname.split("/")[4];
    const { state } = this;
    this.setState({ isSaving: true });
    try {
      await this.props.saveHandler({
        module_delivery_identifier: state.module_delivery_identifier,
        language_identifier_id: state.language_identifier_id,
        module_end_date: state.module_end_date,
        educational_module_id: moduleId,
        module_start_date: state.module_start_date
      });
      !this.isUnmounted && this.setState({ isEditable: false });
    } catch (error) {
      console.log(error);
      alert("Unable to save the data");
    }
    !this.isUnmounted && this.setState({ isSaving: false });
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const { isEditable, isSaving, module_delivery_identifier, language_identifier_id, module_end_date, module_start_date } = this.state;

    const { permissions, title, options, buttons } = this.props;
    const { handleClickCancelButton, handleClickDeleteButton, handleClickSaveButton, errorMessage } = this;

    const { onRead: titleOnRead = "", onEdit: titleOnEdit = "" } = title;

    return (
      <div className={classes.StudiesDeliveryModuleForm}>
        <FormWrapper
          buttons={isEditable ? buttons : []}
          buttonsNames={[{ button: "delete", name: "Delete this Delivery module" }]}
          buttonsAreDisabled={isSaving}
          isSpinning={isSaving}
          clickCancelButtonHandler={handleClickCancelButton}
          clickDeleteButtonHandler={handleClickDeleteButton}
          clickSaveButtonHandler={handleClickSaveButton}
        >
          <div className={classes.titleWrapper}>
            <SectionTitle title={isEditable ? titleOnEdit : titleOnRead} />
            {permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => this.setState({ isEditable: true })} />}
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module delivery identifier" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={module_delivery_identifier.toString()}
                  placeholder=""
                  isDisabled={true}
                  error={errorMessage("module_delivery_identifier")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Language Identifier" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.language_identifiers}
                  value={language_identifier_id}
                  changeHandler={value => this.setState({ language_identifier_id: value })}
                  isDisabled={!isEditable}
                />
              </div>
            </div>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module start date" hasAsterisk={isEditable} />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={module_start_date}
                  changeHandler={module_start_date => this.setState({ module_start_date })}
                  error={errorMessage("module_start_date")}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module end date" hasAsterisk={isEditable} />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={module_end_date}
                  changeHandler={module_end_date => this.setState({ module_end_date })}
                  isDisabled={!isEditable}
                  error={errorMessage("module_end_date")}
                />
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

StudiesDeliveryModuleForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  initialData: PropTypes.shape({
    module_identifier: PropTypes.number,
    credit_value_of_module: PropTypes.number,
    credit_transfer_scheme_id: PropTypes.number,
    full_time_equivalence: PropTypes.string,
    level_of_credit_points_id: PropTypes.number,
    mark_scheme_id: PropTypes.number,
    module_title: PropTypes.string
  }),
  generateId: PropTypes.bool,
  initialIsEditable: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

StudiesDeliveryModuleForm.defaultProps = {
  permissions: [],
  title: {
    onRead: "",
    onEdit: ""
  },
  initialData: {
    module_delivery_identifier: null,
    language_identifier_id: null,
    module_end_date: null,
    educational_module_id: null,
    module_start_date: null
  },
  generateId: false,
  initialIsEditable: false,
  options: null,
  buttons: ["cancel", "delete", "save"],
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {}
};

export const isLoading = state => state.permissionsReducer.isLoading

export const additionalPermissions = state => state.permissionsReducer.additionalPermissions

export const dataCreationPermissions = state => state.permissionsReducer.dataCreationPermissions

export const dataDeletingPermissions = state => state.permissionsReducer.dataDeletingPermissions

export const rulePermissions = state => state.permissionsReducer.rulePermissions

export const mainPermissions = state => state.permissionsReducer.mainPermissions

export const tabsPermissions = state => state.permissionsReducer.tabsPermissions

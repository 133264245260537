import React from 'react'
import classes from './style.module.scss'
import { Accordion, PermissionsCheckboxes } from '@Root/components'

export const RoleManager = ({
	onSave,
	title,
	showSaveButton,
	isEditable,
	onDataChange,
	additionalFunctions,
	tableRulesPermissions,
	permissions,
	formDataPermissions,
	children,
	roleId,
	initialMainCategoryId,
	initialCategoryId,
	initialSubCategoryId,
	initialMainAccordion,
	roleName,
	saveRoleIntoStorage,
	isSuccess,
}) => {
	const onSaveForm = () => {
		onSave({
			permissions,
			additional_functions: additionalFunctions,
			table_rules_permissions: tableRulesPermissions,
			form_data_permissions: formDataPermissions,
		})
	}

	return (
		<div className={classes.wrapper}>
			<div className={classes.manager_Wrapper}>
				<div className={classes.first_block}>
					{title && <h2 className={classes.title}>{title}</h2>}
					{children}
				</div>
				{permissions?.length > 0 || tableRulesPermissions?.length || formDataPermissions?.length > 0 ? (
					<Accordion
						mainData={permissions}
						additionalData={{
							form_data_permissions: formDataPermissions,
							table_rules_permissions: tableRulesPermissions,
						}}
						onDataChange={onDataChange}
						isEditable={isEditable}
						roleId={roleId}
						initialMainCategoryId={initialMainCategoryId}
						initialCategoryId={initialCategoryId}
						initialSubCategoryId={initialSubCategoryId}
						initialMainAccordion={initialMainAccordion}
						roleName={roleName}
						saveRoleIntoStorage={saveRoleIntoStorage}
						isSuccess={isSuccess}
					/>
				) : null}
				<PermissionsCheckboxes initialData={additionalFunctions} onChangeHandler={onDataChange('additional_functions')} isEditable={isEditable} />
			</div>
			{showSaveButton && (
				<div className={classes.buttons}>
					<button className={classes.button_save} onClick={onSaveForm}>
						Save Changes
					</button>
				</div>
			)}
		</div>
	)
}

import { axios } from '@Root/API'
import objectToFormdata from 'object-to-formdata'

export const edit = payload => {
	return axios.post('update/user', objectToFormdata(payload), {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

import React, { useEffect, useState } from 'react'
import { NotesForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { contactNoteActions, contactNoteSelectors } from '@Store/contact'
import { Redirect } from 'react-router'
import { contactSelectors } from '@Root/store'
import moment from 'moment'
import { modalActions } from '@Store/modal'

const PARENT_URL = '/home/contacts/all-contacts'

export const ContactsNotesForm = ({ contactId }) => {
  const dispatch = useDispatch()
  const loadingConf = useSelector(contactNoteSelectors.loadingConf)
  const isSuccess = useSelector(contactNoteSelectors.isSuccess)
  const notes = useSelector(contactNoteSelectors.notes)
  const notesLength = useSelector(contactNoteSelectors.notesLength)
  const permissions = useSelector(contactNoteSelectors.permissions)
  const contactPermissions = useSelector(contactSelectors.permissions)
  const [openIdHistoryOfChanges, setOpenIdHistoryOfChanges] = useState(null)
  const page = useSelector(contactNoteSelectors.currentNotesPage)
  const isDragDisabled = useSelector(contactNoteSelectors.isOpenHistory)
  const [pageHistory, setPageHistory] = useState(1)
  const history = useSelector(contactNoteSelectors.history)
  const historyLength = useSelector(contactNoteSelectors.historyLength)

  useEffect(() => {
    dispatch(contactNoteActions.get({ contactId }))

    return () => {
      dispatch(contactNoteActions.set([]))
    }
  }, [dispatch, contactId])

  useEffect(() => {
    if (history.length > 0) {
      dispatch(
        modalActions.showModal('HistoryModal', {
          history,
          historyLength,
          moment,
          historyPaginate,
          clearHistory,
        })
      )
    }
  }, [history])

  const onCreate = payload => {
    dispatch(contactNoteActions.create({ ...payload, contact_id: contactId }))
  }

  const OnSave = (id, payload) => {
    dispatch(contactNoteActions.edit({ id, data: payload }))
  }

  const onDelete = (id, pageReply, callBack) => {
    dispatch(contactNoteActions.delete({ id, pageReply, callBack }))
  }

  const onReply = payload => {
    dispatch(contactNoteActions.createReply({ ...payload, contact_id: contactId }))
  }

  const onSort = newData => {
    dispatch(contactNoteActions.sortNode({ contact_id: contactId, data: newData }))
  }

  const onOpenHistoryOfChanges = id => {
    dispatch(contactNoteActions.getHistory({ id }))
    setOpenIdHistoryOfChanges(id)
  }

  const handelPaginationNoteAction = () => {
    dispatch(contactNoteActions.paginateNote(page + 1))
  }

  const historyPaginate = () => {
    setPageHistory(pageHistory + 1)
    dispatch(contactNoteActions.paginateHistory(pageHistory + 1))
  }

  const clearHistory = () => {
    dispatch(contactNoteActions.clearHistory())
  }

  const paginateReply = (pageReply, parentId, resolve, reject) => {
    dispatch(contactNoteActions.paginateReply({ page: pageReply + 1, parentId, resolve, reject }))
  }

  if (!contactPermissions.tabs?.note) return <Redirect to={PARENT_URL} />
  return (
    <NotesForm
      onEdit={OnSave}
      onCreate={onCreate}
      loadingConf={loadingConf}
      isSuccess={isSuccess}
      initialValue={notes}
      onDelete={onDelete}
      canManipulate
      canCreate={permissions?.create}
      onReply={onReply}
      notesLength={notesLength}
      onSort={onSort}
      handelPaginationNoteAction={handelPaginationNoteAction}
      onOpenHistoryOfChanges={onOpenHistoryOfChanges}
      openIdHistoryOfChanges={openIdHistoryOfChanges}
      page={page}
      isDragDisabled={isDragDisabled}
      paginateReply={paginateReply}
    />
  )
}

import React, { useEffect } from 'react'
import { modalActions, programmeActions, programmeSelectors } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { eventService } from '@Helpers/events'

export const AddToStudentCourseSession = ({ children, contactId, shouldBeAskedProgrammes = true }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const programmeOptions = useSelector(programmeSelectors.programmesListOptions)

  useEffect(() => {
    if ((!programmeOptions || programmeOptions?.length <= 0) && shouldBeAskedProgrammes) dispatch(programmeActions.getProgrammesOptions())
  }, [])

  const handleAddStudyProgramme = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('InputModal', {
          title: 'Add a Student Course Session',
          inputType: 'select',
          options: programmeOptions,
          placeholder: 'Select a Programme',
          resolveButtonText: 'Continue',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(programmeId => {
        console.log(programmeId)
        history.push(`/home/studies/programmes/study/${contactId}/${programmeId}/new`)
      })
      .finally(() => dispatch(modalActions.hideModal()))
  }

  useEffect(() => {
    eventService.on('addStudentToCourseSession', handleAddStudyProgramme)
    return () => {
      eventService.off('addStudentToCourseSession', handleAddStudyProgramme)
    }
  }, [handleAddStudyProgramme])

  return <>{children}</>
}

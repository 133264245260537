import React, { useEffect } from 'react'
import classes from './style.module.scss'
import { useDispatch } from 'react-redux'
import { modalActions, programmeActions, snackbarActions } from '@Root/store'
import { contactActions } from '@Root/store'
import { useHistory, useLocation, useParams } from 'react-router'
import { AddToStudentCourseSession } from '@Root/processes'
import { SectionWrapper } from '@Root/HOCs'
import { DetailInformation } from './DetailInformation'
import { CreateContact } from './Create'
import { EditContact } from './Edit'

export const ContactsDetailsPage = ({ currentLink, styleConfig }) => {
	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()
	const { contactId } = useParams()
	const pathname = location.pathname
	const isNew = pathname.includes('new')

	useEffect(() => {
		dispatch(programmeActions.getProgrammesOptions())
		dispatch(contactActions.getStaticOptions())
		dispatch(contactActions.getDynamicOptions())
	}, [dispatch])

	useEffect(() => {
		return () => {
			dispatch(contactActions.setError({ errorName: '', errorMessage: '' }))
		}
	}, [dispatch])

	const onError = error => {
		dispatch(snackbarActions.setSnackbar(error))
	}

	const onOpenDescriptionModal = (description, title) => {
		new Promise((resolve, reject) => {
			dispatch(
				modalActions.showModal('NotificationModal', {
					text: description,
					clickRejectButtonHandler: reject,
					clickResolveButtonHandler: resolve,
					title,
				})
			)
		}).finally(() => dispatch(modalActions.hideModal()))
	}


	return (
		<AddToStudentCourseSession contactId={Number(contactId)} shouldBeAskedProgrammes={false}>
			<SectionWrapper hasBackButton backButtonText={currentLink.name} clickBackButtonHandler={() => history.push(`/home/contacts/${currentLink.path}`)}>
				<div className={classes.wrapper}>
					<div className={classes.sectionsWrapper}>
						{isNew ? (
							<CreateContact onError={onError} onOpenDescriptionModal={onOpenDescriptionModal} styleConfig={styleConfig}  />
						) : (
							<EditContact onError={onError} onOpenDescriptionModal={onOpenDescriptionModal} styleConfig={styleConfig} />
						)}
						{!isNew && <DetailInformation contactId={Number(contactId)} />}
					</div>
				</div>
			</SectionWrapper>
		</AddToStudentCourseSession>
	)
}

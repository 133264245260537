import React from 'react'
import DocumentTitle from 'react-document-title'
import { getCurrentLink } from '@Root/helpers'
import { pagesLinksTextHelp } from '@Root/configs'
import { Route, Switch, useLocation } from 'react-router'
import { PageNavLinks, PageTitle } from '@Root/components'
import { Redirect } from 'react-router-dom'
import { Manager } from './Manager'

export const HelpTextPage = () => {
	const location = useLocation()
	const currentLink = getCurrentLink(pagesLinksTextHelp, location)

	const styleConfig = {
		navLinksStyles: {
			position: 'fixed',
			width: '100%',
			top: '115px',
			right: 0,
			zIndex: 10,
			padding: '0 10px',
		},
		pageTitle: {
			position: 'fixed',
			top: 0,
			zIndex: 10,
			marginTop: 50,
		},
	}

	return (
		<DocumentTitle title={`Help Text`}>
			<div>
				<PageTitle title={currentLink.name} style={styleConfig.pageTitle} />
				<PageNavLinks style={styleConfig.navLinksStyles} root={`/home/help-text`} links={pagesLinksTextHelp} />
				<Switch>
					<Route path='/home/help-text/:tabId' component={Manager} />
					<Redirect to='/home/help-text/contacts' />
				</Switch>
			</div>
		</DocumentTitle>
	)
}

import * as types from './role.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  role: {
    module_delivery_role_identifier: null,
    franchise_indicator_id: null,
    role_type_id: null,
    hesa_identifier: null,
    module_delivery_role_proportion: null,
    venue_id: null,
  },
  options: {
    static: {},
    dynamic: {},
  },
  permissions: [],
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: payload,
      }
    case types.SET_ROLE:
      return {
        ...state,
        role: payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          [payload.name]: payload.data,
        },
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    default:
      return state
  }
}

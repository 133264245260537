import * as types from './studyLocation.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  studyLocation: {
    study_location_identifier: null,
    session_distance_learning_id: null,
    primarily_outside_id: null,
    study_proportion: null,
    venue: null,
  },
  options: {},
  errors: {},
  canBeCreatedStudyLocation: false,
  permissions: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.SET_STUDY_LOCATION:
      return {
        ...state,
        studyLocation: payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: payload,
      }
    case types.CLEAR_DATA:
      return {
        ...state,
        studyLocation: initialState.studyLocation,
        errors: initialState.errors,
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    case types.SET_CAN_BE_CREATED:
      return {
        ...state,
        canBeCreatedStudyLocation: payload,
      }
    case types.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    default:
      return state
  }
}

import * as types from './fundingAndMonitoring.types'

const initialPermissions = {
	fields: {
		funding_length_id: { view: true, edit: true },
		equivalent_qualification_id: { view: true, edit: true },
		funding_completion_id: { view: true, edit: true },
		non_regulated_fee_flag_id: { view: true, edit: true },
	},
}
const initialState = {
	fundingAndMonitoring: null,
	options: [],
	isLoading: false,
	isSuccess: false,
	permissions: initialPermissions,
}

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: action.payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: action.payload,
			}
		case types.SET_FUNDING_AND_MONITORING:
			return {
				...state,
				fundingAndMonitoring: action.payload,
			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: action.payload,
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: action.payload,
			}
		case types.CLEAR_PERMISSIONS:
			return {
				...state,
				permissions: initialPermissions,
			}
		default:
			return state
	}
}

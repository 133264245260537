export const TOGGLE_LOADING = 'admin/qualification/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'admin/qualification/TOGGLE_SUCCESS'
export const GET_INSTITUTION = 'admin/institution/GET_INSTITUTION'
export const SET_INSTITUTION = 'admin/institution/SET_INSTITUTION'
export const EDIT_INSTITUTION = 'admin/institution/EDIT_INSTITUTION'
export const CREATE_INSTITUTION = 'admin/institution/CREATE_INSTITUTION'
export const DELETE_INSTITUTION = 'admin/institution/DELETE_INSTITUTION'
export const SET_PERMISSIONS = 'admin/qualification/SET_PERMISSIONS'
export const GET_OPTIONS = 'admin/institution/GET_OPTIONS'
export const SET_OPTIONS = 'admin/institution/SET_OPTIONS'

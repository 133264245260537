import React, { useCallback } from 'react'
import styles from './style.module.scss'
import { createDataConfig } from '@Root/configs'
import { VerticalTabs } from '@Root/components'
import { Redirect, Route, Switch } from 'react-router'
import { API } from '@Root/API'
import { TableBlock } from './TableBlock'

export const DetailInformation = ({ moduleId, moduleDeliveryId, permissions }) => {
	const defaultURL = `/home/studies/modules/${moduleId}`
	const moduleSubjectTableData = useCallback(() => {
		const columns = () => [
			{
				name: 'Module Subject',
				field: 'subject',
				searchInputType: 'textInput',
				cellType: 'link',
				cellLinkField: 'subject_link',
			},
			{
				name: 'Module proportion',
				field: 'module_proportion',
				searchInputType: 'textInput',
				cellType: 'text',
			},
		]
		return {
			...createDataConfig('studiesModulesSubject', [], columns(), 'getModules', 'studiesModulesSubject', false, false, false, false, false, false),
			fetchDataHandler: params => API.studies.module.subject.getAll(moduleId, params),
			clickLinkHandlers: {},
		}
	}, [moduleId])

	const moduleCostCenterTableData = useCallback(() => {
		const columns = () => [
			{
				name: 'Module cost centre',
				field: 'costcn_label',
				searchInputType: 'select',
				cellType: 'link',
				cellLinkField: 'costcn_label_link',
			},
			{
				name: 'Module cost centre proportion',
				field: 'hesa_cost_centre_proportion',
				searchInputType: 'textInput',
				cellType: 'text',
			},
		]
		return {
			...createDataConfig('Module Cost Centers', [], columns(), 'getModules', 'moduleCostCentre', false, false, false, false, false, false),
			fetchDataHandler: params => API.studies.module.costCenter.getAll(moduleDeliveryId, params),
			clickLinkHandlers: {},
		}
	}, [moduleDeliveryId])

	const moduleDeliveryRoleTableData = useCallback(() => {
		const columns = () => [
			{
				name: 'HESA identifier',
				field: 'hessa',
				searchInputType: 'select',
				cellType: 'link',
				cellLinkField: 'hessa_link',
			},
			{
				name: 'Franchise indicator',
				field: 'franchise_indicator',
				searchInputType: 'select',
				cellType: 'text',
			},
			{
				name: 'Module delivery role proportion',
				field: 'module_delivery_role_proportion',
				searchInputType: 'textInput',
				cellType: 'text',
			},
		]
		return {
			name: 'Module Delivery Roles',
			...createDataConfig('Modules Delivery Locations', [], columns(), 'getModules', 'modulesDeliveryLocations', false, false, false, false, false, false),
			fetchDataHandler: async params => API.studies.module.role.getAll(moduleDeliveryId, params),
			clickLinkHandlers: {},
		}
	}, [moduleDeliveryId])

	const tables = [
		...(permissions?.module_subject ? [{ name: 'Module Subjects', label: 'module-subject', tableInformation: moduleSubjectTableData() }] : []),
		...(permissions?.module_cost_centre ? [{ name: 'Module Cost Centers', label: 'module-cost-center', tableInformation: moduleCostCenterTableData() }] : []),
		...(permissions?.module_delivery_role ? [{ name: 'Module delivery Roles', label: 'module-delivery-role-identifier', tableInformation: moduleDeliveryRoleTableData() }] : []),
	]
	return (
		<div className={styles.wrapper}>
			<VerticalTabs tabs={tables} isLink defaultURL={defaultURL} />
			<Switch>
				{tables.map(({ tableInformation, label, name }) => {
					return (
						<Route
							exact
							key={name}
							path={`${defaultURL}/${label}`}
							render={() => (
								<TableBlock
									tabName={name}
									name={tableInformation.name}
									mandatoryRules={tableInformation.mandatoryRules}
									columns={tableInformation.columns}
									fetchDataHandler={tableInformation.fetchDataHandler}
									fetchColumnOptions={tableInformation.fetchColumnOptions}
									fetchSaveColumnOptions={tableInformation.fetchSaveColumnOptions}
									clickLinkHandlers={tableInformation.clickLinkHandlers}
									hasImport={tableInformation.hasImport}
									hasSorting={tableInformation.hasSorting}
									hasFilters={tableInformation.hasFilters}
									hasRules={tableInformation.hasRules}
								/>
							)}
						/>
					)
				})}
				<Redirect to={`${defaultURL}/${tables[0]?.label || ''}`} />
			</Switch>
		</div>
	)
}

import * as types from './note.types'

export const actions = {
  toggleLoading: payload => ({
    type: types.TOGGLE_LOADING,
    payload,
  }),
  toggleSuccess: payload => ({
    type: types.TOGGLE_SUCCESS,
    payload,
  }),
  get: payload => ({
    type: types.GET_NOTE,
    payload,
  }),
  paginateNote: payload => ({
    type: types.PAGINATE_NOTE,
    payload,
  }),
  paginateReply: payload => ({
    type: types.PAGINATE_REPLY,
    payload,
  }),
  setPaginateReply: payload => ({
    type: types.SET_PAGINATE_REPLY,
    payload,
  }),
  getHistory: payload => ({
    type: types.GET_HISTORY,
    payload,
  }),
  set: payload => ({
    type: types.SET_NOTES,
    payload,
  }),
  setHistory: payload => ({
    type: types.SET_HISTORY,
    payload,
  }),
  paginateHistory: payload => ({
    type: types.PAGINATE_HISTORY,
    payload,
  }),
  edit: payload => ({
    type: types.EDIT_NOTE,
    payload,
  }),
  sortNode: payload => ({
    type: types.SORT_NOTE,
    payload,
  }),
  editReply: payload => ({
    type: types.EDIT_REPLY,
    payload,
  }),
  setEdited: payload => ({
    type: types.SET_EDITED_NOTE,
    payload,
  }),
  create: payload => ({
    type: types.CREATE_NOTE,
    payload,
  }),
  createReply: payload => ({
    type: types.CREATE_REPLY_NOTE,
    payload,
  }),
  setCreated: payload => ({
    type: types.SET_CREATED_NOTE,
    payload,
  }),
  setCreateReply: payload => ({
    type: types.SET_CREATE_REPLY_NOTE,
    payload,
  }),
  delete: payload => ({
    type: types.DELETE_NOTE,
    payload,
  }),
  clearHistory: payload => ({
    type: types.CLEAR_HISTORY,
    payload,
  }),
  setDeleted: payload => ({
    type: types.SET_DELETED_NOTE,
    payload,
  }),
  setPermissions: payload => ({
    type: types.SET_PERMISSIONS,
    payload,
  }),
}

import { axios } from '@Root/API'
import objectToFormdata from 'object-to-formdata'

export const csv = (file, params) => {
	return axios.post('import-student', objectToFormdata({ file }), {
		params,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

import React from 'react'
import {
  AcademicForm,
  AdditionalFieldsForm,
  AssociationForm,
  ChurchForm,
  ClericForm,
  ContactConsentsForm,
  ContactsApplicationsForm,
  ContactsHesaDetailForm,
  ContactsProgrammesForm,
  ContactsStudentForm,
  DioceseForm,
  EngagementsForm,
  ModulesForm,
  PostgradForm,
  SectionTabsLinks,
  StaffForm,
  TrainingFacilityForm,
  ContactsAttachmentsForm,
  ContactsNotesForm,
} from '@Root/components'
import { SectionWrapper } from '@Root/HOCs'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { contactSelectors, programmeSelectors, studentCourseSessionPostgradSelectors } from '@Root/store'
import { eventService } from '@Helpers/events'
import { AdmissionForm } from '@Root/components/forms/contacts/student'

export const DetailInformation = ({ contactId }) => {
  const history = useHistory()
  const person = useSelector(contactSelectors.person)
  const organisation = useSelector(contactSelectors.organisation)
  const contact = person.contact.id ? person.contact : organisation.contact
  const programmesList = useSelector(programmeSelectors.programmesListOptions)
  const postgrad = useSelector(studentCourseSessionPostgradSelectors.postgrad)
  const relationshipsInformation = useSelector(contactSelectors.relationshipsInformation)
  const permissions = useSelector(contactSelectors.permissions)

  const handleAddStudyProgramme = () => {
    eventService.trigger('addStudentToCourseSession')
  }
  const forms = () => {
    const hesaDetailForm = () => {
      return permissions.tabs?.hesa_detail
        ? [
            {
              name: 'HESA Detail',
              path: 'hesa_detail',
              isShifted: true,
              Component: <ContactsHesaDetailForm contactId={contactId} />,
            },
          ]
        : []
    }
    const studentForms =
      relationshipsInformation.is_student && permissions.tabs?.student
        ? [
            {
              name: 'Student',
              path: 'student',
              isShifted: false,
              Component: <ContactsStudentForm contactId={contactId} />,
            },

            ...hesaDetailForm(),
            ...(programmesList.length > 0 && permissions.tabs?.student_course_session_detail
              ? [
                  {
                    name: 'Student Course Sessions',
                    path: 'student_records',
                    isShifted: true,
                    Component: <ContactsProgrammesForm contactId={contactId} handleAddStudyProgramme={handleAddStudyProgramme} />,
                  },
                ]
              : []),
            ...(permissions.tabs?.student_module
              ? [
                  {
                    name: 'Modules',
                    path: 'modules',
                    isShifted: true,
                    Component: <ModulesForm contactId={contactId} />,
                  },
                ]
              : []),
            ...(permissions.tabs?.student_engagement
              ? [
                  {
                    name: 'Engagements',
                    path: 'engagements',
                    isShifted: true,
                    Component: <EngagementsForm contactId={contactId} />,
                  },
                ]
              : []),
            ...(permissions.tabs?.student_application
              ? [
                  {
                    name: 'Applications',
                    path: 'applications',
                    isShifted: true,
                    Component: <ContactsApplicationsForm history={history} contactId={contactId} />,
                  },
                ]
              : []),
            ...(permissions.tabs?.admission
              ? [
                  {
                    name: 'Admissions',
                    path: 'admissions',
                    isShifted: true,
                    Component: <AdmissionForm contactId={contactId} />,
                  },
                ]
              : []),
          ]
        : []

    const academicForms =
      relationshipsInformation.is_academic && permissions.tabs?.academic
        ? [
            {
              name: 'Academic',
              path: 'academic',
              isShifted: false,
              Component: <AcademicForm contactId={contactId} />,
            },
          ]
        : []

    const staffForms =
      relationshipsInformation.is_staff && permissions.tabs?.staff
        ? [
            {
              name: 'Staff',
              path: 'staff',
              isShifted: false,
              Component: <StaffForm contactId={contactId} />,
            },
          ]
        : []

    const clericForms =
      relationshipsInformation.is_cleric && permissions.tabs?.cleric
        ? [
            {
              name: 'Cleric',
              path: 'cleric',
              isShifted: false,
              Component: <ClericForm contactId={contactId} />,
            },
          ]
        : []

    const associationForms =
      relationshipsInformation.is_association && permissions.tabs?.association
        ? [
            {
              name: 'Association',
              path: 'association',
              isShifted: false,
              Component: <AssociationForm contactId={contactId} />,
            },
          ]
        : []

    const churchForms =
      relationshipsInformation.is_church && permissions.tabs?.church
        ? [
            {
              name: 'Church',
              path: 'church',
              isShifted: false,
              Component: <ChurchForm contactId={contactId} />,
            },
          ]
        : []
    const trainingFacilityForms =
      relationshipsInformation.is_training_facility && permissions.tabs?.training_facility
        ? [
            {
              name: 'Training Facility',
              path: 'training_facility',
              isShifted: false,
              Component: <TrainingFacilityForm contactId={contactId} />,
            },
          ]
        : []

    const postgradForm = postgrad
      ? [
          {
            name: 'Postgrad research',
            path: 'postgrad_research',
            isShifted: false,
            Component: <PostgradForm canManipulate={true} isStudent contactId={contactId} />,
          },
        ]
      : []
    const dioceseForms =
      relationshipsInformation.is_diocese && permissions.tabs?.diocese
        ? [
            {
              name: 'Diocese',
              path: 'diocese',
              isShifted: false,
              Component: <DioceseForm contactId={contactId} />,
            },
          ]
        : []
    const consents = permissions.tabs?.consent
      ? [
          {
            name: 'Consents',
            path: 'consents',
            isShifted: false,
            Component: <ContactConsentsForm contactId={contactId} />,
          },
        ]
      : []

    const notes = permissions.tabs?.note
      ? [
          {
            name: 'Notes',
            path: 'notes',
            isShifted: false,
            Component: <ContactsNotesForm contactId={contactId} />,
          },
        ]
      : []
    const attachments = permissions.tabs?.attachment
      ? [
          {
            name: 'Attachments',
            path: 'attachments',
            isShifted: false,
            Component: <ContactsAttachmentsForm contactId={contactId} />,
          },
        ]
      : []
    const additionalFields = permissions.tabs?.additional_field
      ? [
          {
            name: 'Additional Fields',
            path: 'additional_fields',
            isShifted: false,
            Component: <AdditionalFieldsForm contactId={contactId} canCreate={true} />,
          },
        ]
      : []
    return [
      ...studentForms,
      ...academicForms,
      ...staffForms,
      ...clericForms,
      ...associationForms,
      ...churchForms,
      ...dioceseForms,
      ...trainingFacilityForms,
      ...notes,
      ...attachments,
      ...postgradForm,
      ...consents,
      ...additionalFields,
    ]
  }
  return Object.keys(relationshipsInformation).length > 0 && contact?.id && person.moodleLinks && programmesList && permissions.tabs && forms()?.length > 0 ? (
    <SectionWrapper>
      <SectionTabsLinks tabs={forms()} defaultUrl={`/home/contacts/all-contacts/${contactId}`} tabsToOtherSites={person.moodleLinks} />
      <Switch>
        {forms().map(({ path, Component }) => {
          return <Route exact path={`/home/contacts/all-contacts/${contactId}/${path}`} component={() => Component} key={path} />
        })}
        <Redirect to={`/home/contacts/all-contacts/${contactId}/${forms()[0]?.path || ''}`} />
      </Switch>
    </SectionWrapper>
  ) : (
    <></>
  )
}

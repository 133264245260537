import classes from './style.module.scss'
import { NavLink } from 'react-router-dom'

export const NameTitles = ({ data }) => {
  return (
    <>
      {data?.link ? (
        <NavLink to={data?.link}>
          <div className={classes.studentInfo}>
            {data?.id && (
              <div>
                ID: <span>{data?.id}</span>
              </div>
            )}
            {data?.sid && (
              <div>
                SID: <span>{data?.sid}</span>
              </div>
            )}
            {data?.name && (
              <div>
                Name: <span>: {data?.name}</span>
              </div>
            )}
          </div>
        </NavLink>
      ) : (
        <></>
      )}
    </>
  )
}

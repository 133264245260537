import React, { useEffect } from 'react'
import './App.scss'
import { Switch, Route, Redirect } from 'react-router-dom'
import { HomePage, LoginPage, ErrorPage, PasswordChangingPage, PasswordRecoveryPage, ForbiddenPage } from '@Root/pages'

import { SuccessNotification, Snackbar } from '@Root/components'
import '@Root/assets'
import { CheckUserAuthentication, ListenBrowserLocation, TrackServerRequest, trackTokenInLocalStorage } from '@Root/processes'

export const App = () => {
  useEffect(() => {
    const cacheShouldBeCleared = localStorage.getItem('isSaved')
    if (!cacheShouldBeCleared) {
      localStorage.removeItem('dataOptions')
    }
    trackTokenInLocalStorage(localStorage.isKeptLoggedIn)
  }, [])

  return (
    <ListenBrowserLocation>
      <TrackServerRequest>
        <CheckUserAuthentication>
          <div className='App'>
            <Switch>
              <Redirect exact from='/' to='/home' />
              <Route path='/home' component={HomePage} />
              <Route exact path='/login' component={LoginPage} />
              <Route path='/password-recovery' exact component={PasswordRecoveryPage} />
              <Route path='/password-changing' exact component={PasswordChangingPage} />
              <Route exact path='/page-not-found' component={ErrorPage} />
              <Route exact path='/page-locked' component={ErrorPage} />
              <Route exact path='/forbidden-page' component={() => <ForbiddenPage />} />
              <Route component={ErrorPage} />
            </Switch>
            <Snackbar />
            <SuccessNotification />
          </div>
        </CheckUserAuthentication>
      </TrackServerRequest>
    </ListenBrowserLocation>
  )
}

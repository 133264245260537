import { axios } from '@Root/API'
import objectToFormdata from 'object-to-formdata'

export const sort = (id, data) => {
  return axios.post(`notes/applications/${id}/sort`, objectToFormdata({ data: { ...data } }), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

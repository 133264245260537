import React, { useEffect } from 'react'
import { ModuleInstanceForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { moduleInstancesSelectors, moduleInstancesActions } from '@Store/studies'
import { hesaSelectors } from '@Store/contact/person'

export const EditModuleInstance = ({ onOpenDescriptionModal, programmeRecord }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { studyProgrammeId, contactId, moduleInstancesId } = useParams()
  const moduleInstances = useSelector(moduleInstancesSelectors.moduleInstances)
  const isSuccess = useSelector(moduleInstancesSelectors.isSuccess)
  const isLoading = useSelector(moduleInstancesSelectors.isLoading)
  const options = useSelector(moduleInstancesSelectors.options)
  const errors = useSelector(moduleInstancesSelectors.errors)
  const permissions = useSelector(moduleInstancesSelectors.permissions)
  const dynamicOptions = useSelector(hesaSelectors.options).dynamic

  useEffect(() => {
    if (!programmeRecord.student_course_session_id) return
    dispatch(
      moduleInstancesActions.get({
        data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, moduleInstancesId },
        history,
      })
    )
    return () => {
      dispatch(moduleInstancesActions.clearData())
    }
  }, [programmeRecord])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This Module Instances will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(
          moduleInstancesActions.delete({
            data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, moduleInstancesId },
            history,
          })
        )
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = data => {
    dispatch(moduleInstancesActions.edit({ data, studentCourseSessionId: programmeRecord.student_course_session_id, moduleInstancesId }))
  }

  const onCancel = memoData => {
    dispatch(moduleInstancesActions.set(memoData))
    dispatch(moduleInstancesActions.setErrors({}))
  }

  return (
    <ModuleInstanceForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onSave}
      onDelete={onDelete}
      onCancel={onCancel}
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      initialValue={moduleInstances}
      isLoading={isLoading}
      isSuccess={isSuccess}
      options={options}
      dynamicOptions={dynamicOptions}
      errorsFields={errors}
    />
  )
}

import React from 'react'
import styles from './style.module.scss'
import { Error } from '@Root/HOCs'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

export const DragAndDropFields = ({ hasFields, fields, onClickEdit, onClickDelete, onDragEnd, isDraggable }) => {
  return hasFields ? (
    <div className={styles.fields}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map(({ name, id, edit, delete: can_delete }, index) => {
                return (
                  <Draggable key={id} draggableId={String(id)} index={index} isDragDisabled={!isDraggable}>
                    {(provided, { isDragging }) => (
                      <div
                        className={`${styles.field} ${isDragging ? styles.dragging : ''}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <span className={styles.name}>{name}</span>
                        <div className={styles.actions}>
                          {edit && (
                            <button className={`${styles.button} ${styles.edit_button}`} onClick={() => onClickEdit(id)}>
                              Edit
                            </button>
                          )}
                          {can_delete && (
                            <button className={`${styles.button} ${styles.delete_button}`} onClick={() => onClickDelete(id)}>
                              Delete
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  ) : (
    <Error>Data is empty</Error>
  )
}

import React, { useState, useEffect } from 'react'
import styles from './style.module.scss'
import isEmail from 'validator/lib/isEmail'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { useError, useToggle } from '@Root/hooks'
import { validateData } from '@Root/helpers'

const titles = { onRead: 'SessionYear', onEdit: 'Edit SessionYear', onCreate: 'Create SessionYear' }
export const SessionYearForm = ({
  canEdit,
  isNew = false,
  initialValue,
  onDelete,
  onSave,
  onOpenDescriptionModal,
  descriptions,
  isLoading,
  onCancel,
  isSuccess,
  canDelete,
}) => {
  const [isEditable, toggleEditable] = useToggle(isNew)
  const { errors, setError, onClear: onClearError } = useError()
  const [data, setData] = useState({})

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess, toggleEditable])

  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  const onChangeValue = field => value => {
    setData({ ...data, [field]: value })
  }

  const formIsValid = () => {
    const { name, identifier, start_date, end_date } = data
    if (!name) {
      setError('name', 'Required')
      return false
    }

    if (!identifier) {
      setError('identifier', 'Required')
      return false
    }
    if (!start_date) {
      setError('start_date', 'Required')
      return false
    }
    if (!end_date) {
      setError('end_date', 'Required')
      return false
    }
    if (validateData([start_date, end_date])) {
      return false
    }
    return true
  }

  const onCancelEditForm = () => {
    if (!isNew) {
      setData(initialValue)
      onClearError()
      toggleEditable()
      return
    }
    onCancel()
  }

  const onSubmit = async () => {
    if (!formIsValid()) return
    onSave(data)
  }

  const { onRead, onCreate, onEdit } = titles
  return (
    <div className={styles.wrapper}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(!isNew ? (canDelete ? ['delete'] : []) : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this Session Year' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEditForm}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmit}
        canEdit={canEdit}
        isEditable={isEditable}
        readTitle={onRead}
        editTitle={onEdit}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={toggleEditable}
        isCreate={isNew}
        isSuccess={isSuccess}
        idForm={data?.id ? `session_year_${data?.id}` : null}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <Field
              label={'Name'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.name}
              onChange={onChangeValue('name')}
              placeholder='Name'
              isDisabled={!isEditable}
              description={descriptions.name}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
              error={errors.name}
              isRequired={isEditable}
            />
            <Field
              label={'Session Year Identifier'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.identifier}
              onChange={onChangeValue('identifier')}
              placeholder='Session Year Identifier'
              isDisabled={!isEditable}
              description={descriptions.identifier}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
              error={errors.identifier}
              isRequired={isEditable}
              maxLength={50}
            />
            <Field
              label={'Own Session Year Identifier'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.own_identifier}
              onChange={onChangeValue('own_identifier')}
              placeholder='Own Session Year Identifier'
              isDisabled={!isEditable}
              description={descriptions.own_identifier}
              onOpenDescriptionModal={onOpenDescriptionModal}
              field={'textField'}
              maxLength={50}
            />
            <Field
              label={'Session Year Start Date'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.start_date}
              onChange={onChangeValue('start_date')}
              placeholder='Session Year Start Date'
              isDisabled={!isEditable}
              description={descriptions.start_date}
              onOpenDescriptionModal={onOpenDescriptionModal}
              isRequired={isEditable}
              error={errors.start_date}
              field={'datePicker'}
            />
            <Field
              label={'Session Year End Date'}
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 260 }}
              value={data.end_date}
              onChange={onChangeValue('end_date')}
              placeholder='Session Year End Date'
              isDisabled={!isEditable}
              description={descriptions.end_date}
              onOpenDescriptionModal={onOpenDescriptionModal}
              isRequired={isEditable}
              field={'datePicker'}
              error={errors.end_date}
            />
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

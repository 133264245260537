import * as types from './fieldConstructor.types'

const initialState = {
	isLoading: false,
	isSuccess: false,
	fields: [],
	field: null,
	contactFields: [],
	paginationInformation: {},
	errors: {},
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.TOGGLE_SUCCESSES:
			return {
				...state,
				isSuccess: payload,
			}
		case types.SET_FIELDS:
			return {
				...state,
				fields: payload,
			}
		case types.SET_PAGINATION_INFORMATION:
			return {
				...state,
				paginationInformation: payload,
			}
		case types.SET_FIELD:
			return {
				...state,
				field: payload,
			}
		case types.SET_FIELDS_FOR_CONTACT:
			return {
				...state,
				contactFields: payload,
			}
		case types.SET_ERRORS:
			return {
				...state,
				errors: payload,
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: payload,
			}
		default:
			return state
	}
}

import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { switchEmptyStringsToNullsInObject } from '@Root/helpers'
import { booleanOptions } from '@Root/configs'
import { FormWrapper } from '@Root/HOCs'
import { MultiSelect, TextInput, Select, InputLabel } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useToggle } from '@Root/hooks'
import { fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions } from '@Root/store'
import { academicActions, academicSelectors, personSelectors } from '@Store/contact/person'
import { Redirect, useHistory } from 'react-router'
import { permissionsActions } from '@Store/permissions'
import { contactSelectors } from '@Store/index'

const PARENT_URL = '/home/contacts/all-contacts'
const relationShip = 'Academic'
const titles = { onRead: 'Academic Details', onEdit: 'Edit Academic Details' }
export const AcademicForm = ({ contactId }) => {
  const history = useHistory()
  const [isEditable, toggleEditable] = useToggle(false)
  const [data, setData] = useState({})
  const relationships = useSelector(personSelectors.relationships)
  const academic = useSelector(academicSelectors.academic)
  const isLoading = useSelector(academicSelectors.isLoading)
  const isSuccess = useSelector(academicSelectors.isSuccess)
  const staticOptions = useSelector(academicSelectors.options).static
  const permissions = useSelector(academicSelectors.permissions)
  const isPermissionsLoaded = useSelector(academicSelectors.isPermissionsLoaded)
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).person.academics
  const contactPermissions = useSelector(contactSelectors.permissions)
  const dispatch = useDispatch()

  useEffect(() => {
    const hasOptions = Object.keys(staticOptions).length > 0
    if (!hasOptions) dispatch(academicActions.getStaticOptions())
  }, [dispatch, staticOptions])

  useEffect(() => {
    dispatch(fieldTooltipManagerActions.getContact({ entity: 'contacts', entity_type: 'person', entity_sub_type: 'academics' }))
  }, [dispatch])

  useEffect(() => {
    if (academic) setData(academic)
  }, [academic])

  useEffect(() => {
    if (contactId) {
      dispatch(academicActions.get({ contactId }))
      dispatch(permissionsActions.getMain({ entity: 'academic', contactId: Number(contactId) }))
    }
  }, [contactId, dispatch])

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess, toggleEditable])

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const onCancel = () => {
    setData(academic)
    toggleEditable()
  }

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: `The "${relationShip}" relationship will be removed?`,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        const relationshipId = relationships.find(item => item.label === relationShip).value
        dispatch(academicActions.delete({ contactId, relationshipId, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = async () => {
    const modifiedData = switchEmptyStringsToNullsInObject(data)
    dispatch(academicActions.edit({ ...modifiedData, id: contactId }))
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  if (!contactPermissions?.tabs?.academic) return <Redirect to={PARENT_URL} />
  const { form, fields } = permissions
  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', ...(form?.delete ? ['delete'] : []), 'save'] : []}
      buttonsNames={[{ button: 'delete', name: 'Delete this academic' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading || !isPermissionsLoaded}
      clickCancelButtonHandler={onCancel}
      clickDeleteButtonHandler={onDelete}
      clickSaveButtonHandler={onSave}
      canEdit={form?.edit}
      clickToggleEditModeButtonHandler={toggleEditable}
      isEditable={isEditable}
      readTitle={titles.onRead}
      editTitle={titles.onEdit}
      idForm={data?.id ? `academic_details_${data?.id}` : null}
      isSuccess={isSuccess}
    >
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text='Examiner' description={descriptions.examiner} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 150 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.examiner}
            changeHandler={value => handleChangeInput('examiner', value)}
            isDisabled={!isEditable || !fields.examiner?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Internal' description={descriptions.internal} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 150 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.internal}
            changeHandler={value => handleChangeInput('internal', value)}
            isDisabled={!isEditable || !fields.internal?.edit}
          />
          <InputLabel
            style={{ position: 'absolute', left: 490 }}
            text='External'
            description={descriptions.external}
            onOpenDescription={onOpenDescriptionModal}
          />
          <Select
            style={{ position: 'absolute', left: 640 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.external}
            changeHandler={value => handleChangeInput('external', value)}
            isDisabled={!isEditable || !fields.external?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Unavailable' description={descriptions.unavailable} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 150, width: 260 }}
            value={data.unavailable}
            changeHandler={value => handleChangeInput('unavailable', value)}
            isDisabled={!isEditable || !fields.unavailable?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Department' description={descriptions.department} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 150, width: 260 }}
            value={data.department}
            changeHandler={value => handleChangeInput('department', value)}
            isDisabled={!isEditable || !fields.department?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Position' description={descriptions.position} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 150, width: 260 }}
            value={data.position}
            changeHandler={value => handleChangeInput('position', value)}
            isDisabled={!isEditable || !fields.position?.edit}
          />
        </div>
      </div>
    </FormWrapper>
  )
}

AcademicForm.propTypes = {
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func,
}

AcademicForm.defaultProps = {
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {},
}

import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import { App } from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from '@Root/store'
import { changeDefaultMomentOptions } from '@Root/configs'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
	<BrowserRouter>
		<Provider store={store}>
			<App />
		</Provider>
	</BrowserRouter>,
	document.getElementById('root')
)

changeDefaultMomentOptions()

serviceWorker.unregister()

import React from 'react'
import PropTypes from 'prop-types'
import Scrollbar from 'react-scrollbars-custom'

export const CustomScrollbar = ({ children, horizontalOnly, verticalOnly, scrollHandler }) => {
	const myTrackStyles = {
		borderRadius: 0,
		background: '#FAFAFA',
	}

	const myThumbStyles = {
		borderRadius: 0,
		background: '#4A4A4A',
	}

	let additionalProps = {}
	let myTrackXStyles = {}
	let myTrackYStyles = {}

	if (horizontalOnly) {
		additionalProps = {
			noScrollY: true,
		}
		myTrackXStyles = {
			left: 0,
			width: '100%',
		}
	}

	if (verticalOnly) {
		additionalProps = {
			noScrollX: true,
		}
		myTrackYStyles = {
			top: 0,
			height: '100%',
		}
	}

	return (
		<Scrollbar
			{...additionalProps}
			style={{ width: '100%', height: '100%' }}
			trackXRenderer={props => {
				const { elementRef, ...restProps } = props
				restProps.style = { ...restProps.style, ...myTrackStyles, ...myTrackXStyles }
				return <div {...restProps} ref={elementRef} />
			}}
			thumbXRenderer={props => {
				const { elementRef, ...restProps } = props
				restProps.style = { ...restProps.style, ...myThumbStyles }
				return <div {...restProps} ref={elementRef} />
			}}
			trackYRenderer={props => {
				const { elementRef, ...restProps } = props
				restProps.style = { ...restProps.style, ...myTrackStyles, ...myTrackYStyles }
				return <div {...restProps} ref={elementRef} />
			}}
			thumbYRenderer={props => {
				const { elementRef, ...restProps } = props
				restProps.style = { ...restProps.style, ...myThumbStyles }
				return <div {...restProps} ref={elementRef} />
			}}
			onScroll={scrollOptions => {
				scrollHandler(scrollOptions)
			}}
		>
			{children}
		</Scrollbar>
	)
}

CustomScrollbar.propTypes = {
	children: PropTypes.node.isRequired,
	horizontalOnly: PropTypes.bool,
	verticalOnly: PropTypes.bool,
	scrollHandler: PropTypes.func,
}

CustomScrollbar.defaultProps = {
	scrollHandler: () => {},
}

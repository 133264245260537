import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Field } from '@Root/components'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { useToggle } from '@Root/hooks'
import { useSelector } from 'react-redux'
import { useError } from '@Root/hooks'
import { validateData } from '@Root/helpers'

const titles = { onRead: 'Curriculum Accreditation', onEdit: 'Edit Curriculum Accreditation', onCreate: 'Create Curriculum Accreditation' }
export const CurriculumAccreditationForm = ({
  canEdit,
  canDelete,
  onDelete,
  onSave,
  onCancel,
  isNew,
  initialValue,
  isLoading,
  isSuccess,
  options,
  onOpenDescriptionModal,
  dynamicOptions,
}) => {
  const [data, setData] = useState({})
  const { errors, setError, onClear: onClearError } = useError()
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).programmes_curriculum_accreditation
  useEffect(() => {
    if (initialValue) setData(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess])

  const formIsValid = () => {
    const { identifier_id, valid_from, valid_to } = data
    if (!identifier_id) {
      setError('identifier_id', 'Required')
      return false
    }
    if (!valid_from) {
      setError('valid_from', 'Required')
      return false
    }
    if (validateData([valid_from, valid_to])) {
      return false
    }
    onClearError()
    return true
  }

  const onChangeValue = field => value => {
    setData(prevState => ({ ...prevState, [field]: value }))
  }

  const onCancelEditMode = () => {
    onChangeMode()
    setData(initialValue)
  }

  const onChangeMode = () => {
    toggleEditable()
  }

  const onSubmitForm = () => {
    if (!formIsValid()) return
    onSave(data)
  }

  const onCancelEdit = () => {
    if (!isNew) {
      onCancelEditMode()
      onClearError()
      return
    }
    onCancel()
  }

  const { onEdit, onRead, onCreate } = titles
  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canDelete ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this Curriculum Accreditation' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onCancelEdit}
        clickDeleteButtonHandler={onDelete}
        clickSaveButtonHandler={onSubmitForm}
        editTitle={onEdit}
        readTitle={onRead}
        createTitle={onCreate}
        clickToggleEditModeButtonHandler={onChangeMode}
        isEditable={isEditable}
        canEdit={canEdit}
        isCreate={isNew}
        idForm={data?.id ? `curriculum_accreditation_${data?.id}` : null}
        isSuccess={isSuccess}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <Field
                label={'Accreditation identifier'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.identifier_id}
                onChange={onChangeValue('identifier_id')}
                placeholder='Accreditation identifier'
                isDisabled={!isEditable}
                options={options.accreditation_identifier}
                description={descriptions?.identifier_id}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'select'}
                error={errors.identifier_id}
                isRequired={isEditable}
              />
              <Field
                label={'Accreditation valid from date'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.valid_from}
                onChange={onChangeValue('valid_from')}
                placeholder='Accreditation valid from date'
                isDisabled={!isEditable}
                description={descriptions?.valid_from}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'datePicker'}
                error={errors.valid_from}
                isRequired={isEditable}
              />
              <Field
                label={'Accreditation valid to date'}
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={data.valid_to}
                onChange={onChangeValue('valid_to')}
                placeholder='Accreditation valid to date'
                isDisabled={!isEditable}
                description={descriptions?.valid_to}
                onOpenDescriptionModal={onOpenDescriptionModal}
                field={'datePicker'}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  )
}

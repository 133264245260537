import React, { useState, useEffect } from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import isInt from 'validator/lib/isInt'
import isDecimal from 'validator/lib/isDecimal'
import { switchEmptyStringsToNullsInObject, validateData } from '@Root/helpers'
import { booleanOptions } from '@Root/configs'
import { FormWrapper } from '@Root/HOCs'
import { InputLabel, TextInput, Select, DatePicker, MultiSelect, TextArea } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions } from '@Root/store'
import { useError, useToggle } from '@Root/hooks'
import { associationActions, associationSelectors, personSelectors } from '@Store/contact/person'
import { Redirect, useHistory } from 'react-router'
import { permissionsActions } from '@Store/permissions'
import { contactSelectors } from '@Store/index'

const relationShip = 'Association'
const associationStatusOptions = [
  { value: 'member', label: 'Member' },
  { value: 'friend', label: 'Friend' },
]
const doNotPostOptions = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
]
const PARENT_URL = '/home/contacts/all-contacts'
const titles = { onRead: 'Association Details', onEdit: 'Edit Association Details' }
export const AssociationForm = ({ contactId }) => {
  const history = useHistory()
  const [isEditable, toggleEditable] = useToggle(false)
  const [data, setData] = useState({})
  const { errors, setError, onClear: onClearError } = useError()
  const relationships = useSelector(personSelectors.relationships)
  const association = useSelector(associationSelectors.association)
  const isLoading = useSelector(associationSelectors.isLoading)
  const isSuccess = useSelector(associationSelectors.isSuccess)
  const permissions = useSelector(associationSelectors.permissions)
  const staticOptions = useSelector(associationSelectors.options).static
  const isPermissionsLoaded = useSelector(associationSelectors.isPermissionsLoaded)
  const descriptions = useSelector(fieldTooltipManagerSelectors.contactDescriptions).person.associations
  const contactPermissions = useSelector(contactSelectors.permissions)
  const dispatch = useDispatch()

  useEffect(() => {
    const hasOptions = Object.keys(staticOptions).length > 0
    if (!hasOptions) dispatch(associationActions.getStaticOptions())
  }, [dispatch, staticOptions])

  useEffect(() => {
    dispatch(associationActions.getStaticOptions())
    dispatch(fieldTooltipManagerActions.getContact({ entity: 'contacts', entity_type: 'person', entity_sub_type: 'associations' }))
  }, [dispatch])

  useEffect(() => {
    if (association) setData({ ...association, old_associate_id: association.old_associate_id !== null ? association.old_associate_id : null })
  }, [association])

  useEffect(() => {
    if (contactId) {
      dispatch(associationActions.get({ contactId }))
      dispatch(permissionsActions.getMain({ entity: 'association', contactId: Number(contactId) }))
    }
  }, [dispatch, contactId])

  useEffect(() => {
    if (isSuccess) toggleEditable()
  }, [isSuccess, toggleEditable])

  const validateForm = () => {
    const { old_associate_id, so_amount, date_joined_associate, date_left_associate, left_college, so_date, last_so_payment, subs_paid_till } = data

    if (!!old_associate_id && !isInt(String(old_associate_id))) {
      setError('old_associate_id', 'Invalid value. Should be an integer')
      return false
    }
    if (!!so_amount && !isDecimal(String(so_amount))) {
      setError('so_amount', 'Invalid value. Should be a decimal')
      return false
    }
    if (validateData([date_joined_associate, date_left_associate, left_college, so_date, last_so_payment, subs_paid_till])) {
      return false
    }
    onClearError()
    return true
  }

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const onCancel = () => {
    setData({ ...association, old_associate_id: association.old_associate_id !== null ? String(association.old_associate_id) : null })
    onClearError()
    toggleEditable()
  }

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: `The "${relationShip}" relationship will be removed?`,
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(() => {
        const relationshipId = relationships.find(item => item.label === relationShip).value
        dispatch(associationActions.remove({ contactId, relationshipId, history }))
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = () => {
    if (!validateForm()) return
    const modifiedData = switchEmptyStringsToNullsInObject(data)
    dispatch(associationActions.edit({ ...modifiedData, id: contactId }))
  }

  const onOpenDescriptionModal = (description, title) => {
    new Promise((resolve, reject) => {
      dispatch(modalActions.showModal('NotificationModal', { text: description, clickRejectButtonHandler: reject, clickResolveButtonHandler: resolve, title }))
    }).finally(() => dispatch(modalActions.hideModal()))
  }

  if (!contactPermissions.tabs?.association) return <Redirect to={PARENT_URL} />
  const { form, fields } = permissions
  return (
    <FormWrapper
      buttons={isEditable ? ['cancel', ...(form?.delete ? ['delete'] : []), 'save'] : []}
      buttonsNames={[{ button: 'delete', name: 'Delete this association' }]}
      buttonsAreDisabled={isLoading}
      isSpinning={isLoading || !isPermissionsLoaded}
      clickCancelButtonHandler={onCancel}
      clickDeleteButtonHandler={onDelete}
      clickSaveButtonHandler={onSave}
      canEdit={form?.edit}
      clickToggleEditModeButtonHandler={toggleEditable}
      isEditable={isEditable}
      readTitle={titles.onRead}
      editTitle={titles.onEdit}
      idForm={data?.id ? `association_details_${data?.id}` : null}
      isSuccess={isSuccess}
    >
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text='Assoc Status' description={descriptions.associate_status} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 120 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 180 }}
            options={associationStatusOptions}
            value={data.associate_status}
            changeHandler={value => handleChangeInput('associate_status', value)}
            isDisabled={!isEditable || !fields.associate_status?.edit}
          />
          <InputLabel
            style={{ position: 'absolute', left: 315 }}
            text='Date Joined Assoc'
            description={descriptions.date_joined_associate}
            onOpenDescription={onOpenDescriptionModal}
          />
          <DatePicker
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 455, width: 180 }}
            value={data.date_joined_associate}
            changeHandler={value => handleChangeInput('date_joined_associate', value)}
            isDisabled={!isEditable || !fields.date_joined_associate?.edit}
          />
          <InputLabel
            style={{ position: 'absolute', left: 645 }}
            text='Date Left Assoc'
            description={descriptions.date_left_associate}
            onOpenDescription={onOpenDescriptionModal}
          />
          <div style={{ position: 'absolute', left: 800 }}>
            <DatePicker
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 180 }}
              value={data.date_left_associate}
              changeHandler={value => handleChangeInput('date_left_associate', value)}
              isDisabled={!isEditable || !fields.date_left_associate?.edit}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Old Assoc ID' description={descriptions.old_associate_id} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 120, width: 180 }}
            value={data.old_associate_id}
            changeHandler={value => handleChangeInput('old_associate_id', value)}
            isDisabled={!isEditable || !fields.old_associate_id?.edit}
            error={errors.old_associate_id}
          />
          <InputLabel
            style={{ position: 'absolute', left: 315 }}
            text='Left College'
            description={descriptions.left_college}
            onOpenDescription={onOpenDescriptionModal}
          />
          <div style={{ position: 'absolute', left: 455 }}>
            <DatePicker
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 180 }}
              value={data.left_college}
              changeHandler={value => handleChangeInput('left_college', value)}
              isDisabled={!isEditable || !fields.left_college?.edit}
            />
          </div>
          <InputLabel
            style={{ position: 'absolute', left: 645 }}
            text='Contact Preference'
            description={descriptions.contact_preference_id}
            onOpenDescription={onOpenDescriptionModal}
          />
          <Select
            style={{ position: 'absolute', left: 800 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 180 }}
            options={staticOptions.contact_preferences}
            value={data.contact_preference_id}
            changeHandler={value => handleChangeInput('contact_preference_id', value)}
            isDisabled={!isEditable || !fields.contact_preference_id?.edit}
          />
        </div>
        <div className={classes.line} />
        <div className={classes.rowWrapper}>
          <div className={classes.subtitle}>Standing Order</div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='S/O Date' description={descriptions.so_date} onOpenDescription={onOpenDescriptionModal} />
          <div style={{ position: 'absolute', left: 120 }}>
            <DatePicker
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 180 }}
              value={data.so_date}
              changeHandler={value => handleChangeInput('so_date', value)}
              isDisabled={!isEditable || !fields.so_date?.edit}
            />
          </div>
          <InputLabel
            style={{ position: 'absolute', left: 315 }}
            text='Last S/O Payment'
            description={descriptions.last_so_payment}
            onOpenDescription={onOpenDescriptionModal}
          />
          <div style={{ position: 'absolute', left: 455 }}>
            <DatePicker
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 180 }}
              value={data.last_so_payment}
              changeHandler={value => handleChangeInput('last_so_payment', value)}
              isDisabled={!isEditable || !fields.last_so_payment?.edit}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='S/O Amount' description={descriptions.so_amount} onOpenDescription={onOpenDescriptionModal} />
          <TextInput
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 120, width: 180 }}
            value={data.so_amount}
            changeHandler={value => handleChangeInput('so_amount', value)}
            isDisabled={!isEditable || !fields.so_amount?.edit}
            error={errors.so_amount}
          />
          <InputLabel
            style={{ position: 'absolute', left: 315 }}
            text='S/O Frequency'
            description={descriptions.frequency_id}
            onOpenDescription={onOpenDescriptionModal}
          />
          <Select
            style={{ position: 'absolute', left: 455 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 180 }}
            options={staticOptions.frequencies}
            value={data.frequency_id}
            changeHandler={value => handleChangeInput('frequency_id', value)}
            isDisabled={!isEditable || !fields.frequency_id?.edit}
          />
        </div>
        <div className={classes.line} />
        <div className={classes.rowWrapper}>
          <div className={classes.subtitle}>Cash Receipts</div>
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel
            text='Please record Date/Amount/Purpose (one item per line)'
            description={descriptions.cash_receipts}
            onOpenDescription={onOpenDescriptionModal}
          />
          <TextArea
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 455, width: 485, height: 90 }}
            value={data.cash_receipts}
            changeHandler={value => handleChangeInput('cash_receipts', value)}
            isDisabled={!isEditable || !fields.cash_receipts?.edit}
          />
        </div>
        <div className={classes.line} />
        <div className={classes.rowWrapper}>
          <div className={classes.subtitle}>Other Detail</div>
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text='Gift Aid' description={descriptions.gift_aid} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 120 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 180 }}
            options={booleanOptions}
            value={data.gift_aid}
            changeHandler={value => handleChangeInput('gift_aid', value)}
            isDisabled={!isEditable || !fields.gift_aid?.edit}
          />
          <InputLabel
            style={{ position: 'absolute', left: 315 }}
            text='Subs Paid Till'
            description={descriptions.subs_paid_till}
            onOpenDescription={onOpenDescriptionModal}
          />
          <div style={{ position: 'absolute', left: 455 }}>
            <DatePicker
              classNames={!isEditable ? ['borderless'] : []}
              style={{ width: 180 }}
              value={data.subs_paid_till}
              changeHandler={value => handleChangeInput('subs_paid_till', value)}
              isDisabled={!isEditable || !fields.subs_paid_till?.edit}
            />
          </div>
          <InputLabel
            style={{ position: 'absolute', left: 645 }}
            text='Brief Notes'
            description={descriptions.brief_notes}
            onOpenDescription={onOpenDescriptionModal}
          />
          <TextArea
            classNames={!isEditable ? ['borderless'] : []}
            style={{ position: 'absolute', left: 775, width: 180, height: 90 }}
            value={data.brief_notes}
            changeHandler={value => handleChangeInput('brief_notes', value)}
            isDisabled={!isEditable || !fields.brief_notes?.edit}
          />
        </div>
        <div className={classes.line} />
        <div className={classes.rowWrapper}>
          <div className={classes.subtitle}>Key Flags</div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Do not Post' description={descriptions.dont_post} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 120 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 180 }}
            options={doNotPostOptions}
            value={data.dont_post}
            changeHandler={value => handleChangeInput('dont_post', value)}
            isDisabled={!isEditable || !fields.dont_post?.edit}
          />
          <InputLabel
            style={{ position: 'absolute', left: 315 }}
            text="Principal's Contact"
            description={descriptions.principals_contact}
            onOpenDescription={onOpenDescriptionModal}
          />
          <Select
            style={{ position: 'absolute', left: 455 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 180 }}
            options={booleanOptions}
            value={data.principals_contact}
            changeHandler={value => handleChangeInput('principals_contact', value)}
            isDisabled={!isEditable || !fields.principals_contact?.edit}
          />
          <InputLabel
            style={{ position: 'absolute', left: 645 }}
            text='Deceased'
            description={descriptions.deceased}
            onOpenDescription={onOpenDescriptionModal}
          />
          <Select
            style={{ position: 'absolute', left: 775 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 180 }}
            options={booleanOptions}
            value={data.deceased}
            changeHandler={value => handleChangeInput('deceased', value)}
            isDisabled={!isEditable || !fields.deceased?.edit}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text='Bishop' description={descriptions.bishop} onOpenDescription={onOpenDescriptionModal} />
          <Select
            style={{ position: 'absolute', left: 120 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 180 }}
            options={booleanOptions}
            value={data.bishop}
            changeHandler={value => handleChangeInput('bishop', value)}
            isDisabled={!isEditable || !fields.bishop?.edit}
          />
          <InputLabel
            style={{ position: 'absolute', left: 315 }}
            text='Overseas'
            description={descriptions.overseas}
            onOpenDescription={onOpenDescriptionModal}
          />
          <Select
            style={{ position: 'absolute', left: 455 }}
            inputClassNames={!isEditable ? ['borderless'] : []}
            inputStyle={{ width: 180 }}
            options={booleanOptions}
            value={data.overseas}
            changeHandler={value => handleChangeInput('overseas', value)}
            isDisabled={!isEditable || !fields.overseas?.edit}
          />
        </div>
      </div>
    </FormWrapper>
  )
}

const arrayOfValueLabelShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })
)

AssociationForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.shape({
    contactPreferences: arrayOfValueLabelShape,
    frequencies: arrayOfValueLabelShape,
  }),
  initialData: PropTypes.shape({
    associate_status: PropTypes.string,
    date_joined_associate: PropTypes.string,
    date_left_associate: PropTypes.string,
    old_associate_id: PropTypes.number,
    left_college: PropTypes.string,
    contact_preference_id: PropTypes.number,
    so_date: PropTypes.string,
    last_so_payment: PropTypes.string,
    so_amount: PropTypes.string,
    frequency_id: PropTypes.number,
    cash_receipts: PropTypes.string,
    gift_aid: PropTypes.bool,
    subs_paid_till: PropTypes.string,
    brief_notes: PropTypes.string,
    dont_post: PropTypes.bool,
    principals_contact: PropTypes.bool,
    deceased: PropTypes.bool,
    bishop: PropTypes.bool,
    overseas: PropTypes.bool,
    institution_ids: PropTypes.arrayOf(PropTypes.number),
  }),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func,
}

AssociationForm.defaultProps = {
  permissions: [],
  options: {
    contactPreferences: [],
    frequencies: [],
  },
  initialData: {
    associate_status: null,
    date_joined_associate: null,
    date_left_associate: null,
    old_associate_id: null,
    left_college: null,
    contact_preference_id: null,
    so_date: null,
    last_so_payment: null,
    so_amount: null,
    frequency_id: null,
    cash_receipts: null,
    gift_aid: null,
    subs_paid_till: null,
    brief_notes: null,
    dont_post: null,
    principals_contact: null,
    deceased: null,
    bishop: null,
    overseas: null,
    institution_ids: [],
  },
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {},
}

import React from 'react'
import PropTypes from 'prop-types'
import { FormWrapper } from '@Root/HOCs'
import { Table, TitleWithButton } from '@Root/components'
import { API } from '@Root/API'
import { useSelector } from 'react-redux'
import { studentSelectors } from '@Store/contact/person'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router'
import { contactSelectors } from '@Root/store'

const PARENT_URL = '/home/contacts/all-contacts'
export const EngagementsForm = ({ contactId }) => {
  const information = useSelector(studentSelectors.information)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const contactPermissions = useSelector(contactSelectors.permissions)
  const table = {
    name: 'studentEngagements',
    columns: [
      {
        name: 'Engagement number',
        field: 'number',
        searchInputType: 'textInput',
        cellType: 'link',
        cellLinkField: 'number_link',
      },
      {
        name: 'School direct employing school',
        field: 'employing_school',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Engagement start date',
        field: 'start_date',
        searchInputType: 'datePicker',
        cellType: 'text',
      },
      {
        name: 'ITT entry route',
        field: 'entryrte',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Expected end date',
        field: 'expected_end_date',
        searchInputType: 'datePicker',
        cellType: 'text',
      },
      {
        name: 'Fee eligibility',
        field: 'fee_eligibility',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Fee status',
        field: 'fee_status',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Incoming exchange',
        field: 'incoming_exchange',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Lead school',
        field: 'lead_school',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'NHS employer',
        field: 'nhs_employer',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Own Engagement identifier',
        field: 'own_identifier',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Qualified teacher status',
        field: 'qualified_teacher_status',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Research council student',
        field: 'research_council_student',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Research council student identifier',
        field: 'research_council_student_identifier',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Study intention',
        field: 'study_intention',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      
    ],
    fetchDataHandler: params => API.studies.engagement.getAll(contactId, params),
    fetchColumnOptions: () => API.getColumnOptions('contactStudentEngagements'),
    fetchSaveColumnOptions: options => API.saveColumnOptions('contactStudentEngagements', options),
    hasSorting: true,
    clickLinkHandlers: {},
  }

  if (!contactPermissions.tabs?.student_engagement) return <Redirect to={PARENT_URL} />
  return (
    <FormWrapper>
      {dataCreationPermissions.engagements && (
        <TitleWithButton title={'Engagements'} isLink url={`/home/studies/engagements/${contactId}/new`} canDoAction={dataCreationPermissions.engagements}>
          Create an Engagement
        </TitleWithButton>
      )}
      <Table
        style={{ marginTop: 20 }}
        datePeriodDropdownStyle={{ top: -70 }}
        name={table.name}
        columns={table.columns}
        fetchDataHandler={table.fetchDataHandler}
        fetchColumnOptions={table.fetchColumnOptions}
        fetchSaveColumnOptions={table.fetchSaveColumnOptions}
        clickLinkHandlers={table.clickLinkHandlers}
      />
    </FormWrapper>
  )
}

EngagementsForm.propTypes = {
  fetchTableDataHandler: PropTypes.func,
  contactId: PropTypes.number,
}

EngagementsForm.defaultProps = {
  fetchTableDataHandler: () => { },
}

import React from 'react'
import classes from './style.module.scss'
import { ArrowIcon, CrossIcon, PlusIcon } from '@Root/assets'
import { ToggleSwitcher } from '@Root/components/ToggleSwitcher'

export class AccordionStudentsRoleManager extends React.Component {
	render() {
		const { data = {}, isEditable, onOpenExceptionsModal, onChangeFieldStatus, onDataChange, permissionType, initialCategoryId, onChangeData } = this.props
		return (
			<div className={classes.wrapper}>
				<ul className={classes.accordion_list}>
					{data.map(item => {
						return (
							<li className={classes.accordion_list__item} key={item.label}>
								<AccordionItem
									data={item}
									onDataChange={onDataChange}
									onOpenExceptionsModal={onOpenExceptionsModal}
									onChangeFieldStatus={onChangeFieldStatus}
									isEditable={isEditable}
									permissionType={permissionType}
									initialCategoryId={initialCategoryId}
									onChangeData={onChangeData}
								/>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}
}

class AccordionItem extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isOpen: false,
		}
	}

	componentDidMount() {
		const { data, initialCategoryId } = this.props
		if (data.label === initialCategoryId) {
			this.onToggleOpen()
		}
	}

	onToggleOpen = () => {
		this.setState(prevState => ({ isOpen: !prevState.isOpen }))
	}

	render() {
		const {
			state: { isOpen },
			props: { onChangeFieldStatus, isEditable, permissionType, data = {}, onOpenExceptionsModal, onChangeData },
		} = this
		const { name, permissions = [], label } = data
		const fields = data.fields
		const exceptedFields = fields?.filter(field => !field.permissions.includes(permissionType.label))
		const hasFields = fields?.length > 0
		return (
			<>
				<div className={`${classes.accordion_item}, ${isOpen && `${classes.accordion_item__opened}`}`}>
					<div className={classes.accordion_item__line} onClick={this.onToggleOpen}>
						<div className={classes.accordion_item__main}>
							<div className={classes.accordion_item__main_name}>
								<h3 className={classes.accordion_item__title}>{name}</h3>
								<img className={classes.accordion_item__icon} src={ArrowIcon} alt={'arrow'} />
							</div>
							{permissions && (
								<div className={classes.checkbox_item}>
									<span>Turn off</span>
									<ToggleSwitcher checked={permissions.includes(permissionType.label)} onChange={() => onChangeData(data)} disabled={!isEditable} />
									<span>Turn on</span>
								</div>
							)}
						</div>
						<div className={classes.accordion_item__status}>{name} access</div>
					</div>
					<div className={classes.accordion_item__inner}>
						<div className={classes.accordion_item__content}>
							<div className={classes.accordion_item__excepts}>
								<div className={classes.accordion_item__excepts_except}>
									<div className={classes.accordion_item__excepts_except__actions}>
										{hasFields > 0 && (
											<div className={classes.accordion_item__excepts_except__maintainer}>
												<span>Excepts:</span>
												{isEditable && (
													<div>
														<div className={classes.accordion_item__excepts_except_add_button} onClick={hasFields ? () => onOpenExceptionsModal(data) : null}>
															<img src={PlusIcon} alt='' />
															<span>Add</span>
														</div>
													</div>
												)}
												<div className={classes.accordion_item__excepts_block}>
													{exceptedFields?.length > 0
														? exceptedFields.map(field => {
																return (
																	<div
																		className={`${classes.accordion_item__excepts_block__item} ${classes.accordion_item__excepts_block__item__selected}`}
																		key={field.prefix ? `${field.prefix}-${field.label}` : field.label}
																	>
																		<span>{field.name}</span>
																		{isEditable && <img src={CrossIcon} alt='cross' onClick={() => onChangeFieldStatus(field, label)} />}
																	</div>
																)
														  })
														: !isEditable && hasFields && <span className={classes.accordion_item__excepts_without_label}>No excepts yet</span>}
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

import { all, call, put, takeEvery } from 'redux-saga/effects'
import { API } from '@Root/API'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage } from '@Root/helpers'
import { actions } from './note.actions'
import * as types from './note.types'
import { updateColection, deleteItemInColection, addToColection } from '@Root/helpers'
import { getFromLocalStorage, setToLocalStorage } from '@Helpers/localStorage'

function* get({ payload }) {
  yield put(actions.toggleLoading({ isGetLoading: true }))
  const { contactId } = payload
  try {
    const { data } = yield call(API.contact.note.get, contactId)
    setToLocalStorage('note', data.data)
    yield put(actions.set(data.data))
    yield put(actions.setPermissions({ ...data.permissions, delete: true, edit: true }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading({ isGetLoading: false }))
}

function* getHistory({ payload }) {
  const { id } = payload
  try {
    const { data } = yield call(API.contact.note.historyList.get, id)
    const dataReverse = [...data.data].reverse()
    setToLocalStorage('history', dataReverse)
    yield put(actions.setHistory(dataReverse))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
}

function* create({ payload }) {
  yield put(actions.toggleLoading({ isCreateLoading: true }))
  try {
    const { data } = yield call(API.contact.note.create, payload)
    const notes = getFromLocalStorage('note')
    const newColection = addToColection(notes, data.data)
    setToLocalStorage('note', newColection)
    yield put(actions.setCreated(newColection))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The note has been created successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    yield put(actions.toggleSuccess(false))
  }
  yield put(actions.toggleLoading({ isCreateLoading: false }))
  yield put(actions.toggleSuccess(false))
}

function* createReply({ payload }) {
  yield put(actions.toggleLoading({ isCreateReplyLoadin: { id: payload.parent_id, status: true } }))
  try {
    const { data } = yield call(API.contact.note.create, payload)
    const notes = getFromLocalStorage('note')
    const newColection = addToColection(notes, data.data)
    setToLocalStorage('note', newColection)
    yield put(actions.setCreateReply(newColection))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The note reply has been created successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    yield put(actions.toggleSuccess(false))
  }
  yield put(actions.toggleLoading({ isCreateReplyLoadin: { id: 0, status: false } }))
  yield put(actions.toggleSuccess(false))
}

function* paginateReply({ payload }) {
  const { parentId, page, resolve, reject } = payload
  yield put(actions.toggleLoading({ isPaginateloding: { id: parentId, status: true } }))
  try {
    const { data } = yield call(API.contact.note.paginateReply, parentId, { page })
    const prevNote = getFromLocalStorage('note')
    const newData = prevNote.map(note => {
      if (note.id === parentId) note.replies = [...note.replies, ...data.data]
      return note
    })
    setToLocalStorage('note', newData)
    yield put(actions.setPaginateReply({ data: newData }))
    resolve({ lastPage: data.meta.last_page })
    yield put(actions.toggleSuccess(true))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
    yield put(actions.toggleSuccess(false))
    reject()
  }
  yield put(actions.toggleLoading({ isPaginateloding: { id: 0, status: false } }))
  yield put(actions.toggleSuccess(false))
}

function* edit({ payload }) {
  const { id, data } = payload
  yield put(actions.toggleLoading({ isEditLoading: { id: id, status: true } }))
  try {
    const response = yield call(API.contact.note.edit, id, data)
    const notes = getFromLocalStorage('note')
    const editNotes = updateColection(notes, response.data.data)
    setToLocalStorage('note', editNotes)
    yield put(actions.setEdited(editNotes))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: `The ${response.data.data.parent_id ? 'reply' : 'note'} has been edit successfully!` }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleSuccess(true))
  yield put(actions.toggleLoading({ isEditLoading: { id: 0, status: false } }))
}

function* sortNote({ payload }) {
  const { contact_id, data } = payload
  try {
    const response = yield call(API.contact.note.sort, contact_id, data)
    setToLocalStorage('note', response.data.data)
    yield put(actions.paginateNote())
    yield put(actions.toggleSuccess(true))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleSuccess(true))
}

function* remove({ payload }) {
  const { id, pageReply, callBack } = payload
  yield put(actions.toggleLoading({ isDeleteLoading: { id: id, status: true } }))
  try {
    const { data } = yield call(API.contact.note.remove, id)
    const notes = getFromLocalStorage('note')
    let editNotes = null
    if (pageReply) {
      const response = yield call(API.contact.note.paginateReply, data.data.parent_id, { limit: pageReply * 100 })
      editNotes = deleteItemInColection(notes, response.data.data, data.data.parent_id)
      callBack(response.data.meta.last_page)
    } else {
      editNotes = deleteItemInColection(notes, data.data)
    }
    setToLocalStorage('note', editNotes)
    yield put(actions.setDeleted({ data: editNotes }))
    yield put(snackbarActions.setSuccessNotification({ text: 'The note has been deleted successfully!' }))
  } catch (error) {
    yield put(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }
  yield put(actions.toggleLoading({ isDeleteLoading: { id: id, status: false } }))
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_NOTE, get),
    takeEvery(types.GET_HISTORY, getHistory),
    takeEvery(types.EDIT_NOTE, edit),
    takeEvery(types.DELETE_NOTE, remove),
    takeEvery(types.CREATE_NOTE, create),
    takeEvery(types.CREATE_REPLY_NOTE, createReply),
    takeEvery(types.PAGINATE_REPLY, paginateReply),
    takeEvery(types.SORT_NOTE, sortNote),
  ])
}

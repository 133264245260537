import React, { Component } from "react";
import "./style.scss";
import PropTypes from "prop-types";
import { delay } from "redux-saga";

import TEIDLogo from "../../../icons/TEIDLogo/TEIDLogo";

export class PasswordChangingForm extends Component {
  state = {
    passwords: ["", ""],
    errorIsShown: false
  };

  handleInput = (e, i) => {
    const passwords = [...this.state.passwords];
    passwords[i] = e.target.value;
    this.setState({
      passwords
    });
  };

  handleSubmit = async e => {
    if (!e.target.checkValidity()) return;
    e.preventDefault();
    if (this.state.passwords[0].trim() === this.state.passwords[1].trim()) {
      this.props.newPasswordHandler(this.state.passwords[0].trim(), this.props.history);
    } else {
      this.setState({ errorIsShown: true });
      await delay(3000);
      this.setState({ errorIsShown: false });
    }
  };

  render() {
    const { passwords, errorIsShown } = this.state;
    const { error } = this.props;
    const { handleSubmit } = this;

    return (
      <form className="PasswordChangingForm" onSubmit={handleSubmit}>
        <input type="text" style={{ display: "none" }} />
        {/* // the input above is needed to prevent form autocompletion in Chrome */}
        <TEIDLogo style={{ marginBottom: 20 }} />
        <div className="title">Password changing</div>
        <div className="description">Please create your new password</div>
        <div className="wrapper">
          <div className="row">
            <span className="label">New password</span>
            <input type="text" minLength="5" value={passwords[0]} onChange={e => this.handleInput(e, 0)} required />
          </div>
        </div>
        <div className="wrapper">
          <div className="row">
            <span className="label">Confirm new password</span>
            <input
              className={errorIsShown ? "invalid" : ""}
              type="password"
              minLength="5"
              value={passwords[1]}
              onChange={e => this.handleInput(e, 1)}
              required
            />
          </div>
          {errorIsShown && !error && <div className="error">Passwords must be equal*</div>}
          {error === "recoveryToken" && <div className="error">Your password recovery link has expired*</div>}
        </div>
        <div className="divider" />
        <div>
          <button type="submit">Confirm</button>
        </div>
      </form>
    );
  }
}

PasswordChangingForm.propTypes = {
  error: PropTypes.string,
  history: PropTypes.object.isRequired,
  newPasswordHandler: PropTypes.func.isRequired
};

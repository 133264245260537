export const isLoading = state => state.studiesReducer.engagement.entryProfile.reducer.isLoading

export const isSuccess = state => state.studiesReducer.engagement.entryProfile.reducer.isSuccess

export const entryProfile = state => state.studiesReducer.engagement.entryProfile.reducer.entryProfile

export const errors = state => state.studiesReducer.engagement.entryProfile.reducer.errors

export const permissions = state => state.studiesReducer.engagement.entryProfile.reducer.permissions

export const options = state => state.studiesReducer.engagement.entryProfile.reducer.options

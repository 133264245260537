import React, { useEffect } from 'react'
import { ReferencePeriodForm } from '@Root/components'
import { modalActions } from '@Root/store'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { referencePeriodSelectors, referencePeriodActions } from '@Store/studies'

export const EditReferencePeriod = ({ onOpenDescriptionModal, programmeRecord }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { studyProgrammeId, contactId, referencePeriodId } = useParams()
  const referencePeriod = useSelector(referencePeriodSelectors.referencePeriod)
  const isSuccess = useSelector(referencePeriodSelectors.isSuccess)
  const isLoading = useSelector(referencePeriodSelectors.isLoading)
  const options = useSelector(referencePeriodSelectors.options)
  const permissions = useSelector(referencePeriodSelectors.permissions)
  const errors = useSelector(referencePeriodSelectors.errors)

  useEffect(() => {
    if (!programmeRecord.student_course_session_id) return
    dispatch(
      referencePeriodActions.get({
        data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, referencePeriodId },
        history,
      })
    )
  }, [programmeRecord])

  const onDelete = () => {
    new Promise((resolve, reject) => {
      dispatch(
        modalActions.showModal('ConfirmationModal', {
          text: 'This Reference Period will be removed?',
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve,
        })
      )
    })
      .then(async () => {
        dispatch(
          referencePeriodActions.delete({
            data: { studyProgrammeId, studentCourseSessionId: programmeRecord.student_course_session_id, contactId, referencePeriodId },
            history,
          })
        )
      })
      .finally(() => {
        dispatch(modalActions.hideModal())
      })
  }

  const onSave = data => {
    dispatch(referencePeriodActions.edit({ data, studentCourseSessionId: programmeRecord.student_course_session_id, referencePeriodId }))
  }

  const onCancel = memoData => {
    dispatch(referencePeriodActions.set(memoData))
    dispatch(referencePeriodActions.setErrors([]))
  }

  return (
    <ReferencePeriodForm
      onOpenDescriptionModal={onOpenDescriptionModal}
      onSave={onSave}
      onDelete={onDelete}
      onCancel={onCancel}
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      initialValue={referencePeriod}
      isLoading={isLoading}
      isSuccess={isSuccess}
      options={options}
      errorsFields={errors}
    />
  )
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { entryQualificationAwardedActions, entryQualificationAwardedSelectors } from '@Store/studies'
import { EntryQualificationAwardForm } from '@Root/components'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router-dom'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const CreateEntryQualificationAward = ({ goBack, parentURL, onOpenDescriptionModal }) => {
  const entryQualificationAward = useSelector(entryQualificationAwardedSelectors.entryQualificationAwarded)
  const options = useSelector(entryQualificationAwardedSelectors.options)
  const isLoading = useSelector(entryQualificationAwardedSelectors.isLoading)
  const errors = useSelector(entryQualificationAwardedSelectors.errors)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).entry_qualification_award
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: engagementId, contactId, entryProfileId } = useParams()

  const onCreate = data => {
    dispatch(entryQualificationAwardedActions.create({ data, history, contactId, engagementId, entryProfileId }))
  }

  if (!dataCreationPermissions.engagements_sub) return <Redirect to={parentURL} />

  return (
    <div>
      <EntryQualificationAwardForm
        onSave={onCreate}
        onCancel={goBack}
        canEdit={true}
        isNew
        initialValue={entryQualificationAward}
        options={options}
        isLoading={isLoading}
        initialErrors={errors}
        descriptions={descriptions}
        onOpenDescriptionModal={onOpenDescriptionModal}
      />
    </div>
  )
}

import React from 'react'
import { PostgradForm } from '@Root/components'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { permissionsSelectors } from '@Store/permissions'

export const CreatePostgrad = ({ parentURL, isNew }) => {
  const { contactId, studyProgrammeId } = useParams()
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)

  return (
    <PostgradForm
      isNew={isNew}
      studyProgrammeId={studyProgrammeId}
      contactId={contactId}
      canCreate={dataCreationPermissions.student_course_session_sub}
      parentURL={parentURL}
    />
  )
}

import axios from 'axios'

const config = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		timezone: new Date().getTimezoneOffset(),
	},
})

export const createInstance = (options = {}) => {
	return {
		get: url => config.get(url),
		post: (url, payload) => config.post(url, payload, options),
		put: (url, payload) => config.put(url, payload),
		config,
		delete: url => config.delete(url, options),
	}
}
export { config as axios }

import * as types from './qualificationAward.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  qualificationAward: {
    qualification_awarded_identifier: null,
    qualification_id: null,
    qualification_result_id: null,
    thesis_title: null,
  },
  options: {},
  errors: [],
  permissions: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.SET_QUALIFICATION_AWARD:
      return {
        ...state,
        qualificationAward: payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: payload,
      }
    case types.SET_ERRORS:
      return {
        ...state,
        errors: payload,
      }
    case types.CLEAR_DATA:
      return {
        ...state,
        qualificationAward: initialState.qualificationAward,
        errors: [],
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    default:
      return state
  }
}

import React from 'react'
import styles from './style.module.scss'

export const RoleInstruction = () => {
  return (
    <div className={styles.instruction}>
      <h3>Instructions:</h3>
      <ol>
        <li>
          Please give each role a name and assign an institution. You can assign one or more institutions, so this role will have acces to the data that applies
          only to those institutions. If you choose "head office", this role will have access to all the institutions that are created in TEID.
        </li>
        <li>You can add one or more users for each role.</li>
        <li>
          Pay attention that by default when creating a role, all permissions are allowed in each category. Manually disable those one you want to disable.
        </li>
        <li>
          For "Create data", "Create rules", "Edit rules", "Can delete own data" and "Can delete other user data" - you have a list of data that is allowed for
          these options with cheboxes near them. You need to enable the checkbox to allow the action, or leave it blank to not allow it.
        </li>
        <li>
          Some categories have missing data or an incomplete list of fields - this means that no data is available or less available for this "action" (all
          fields or categories are fully available only for the "view" option).
        </li>
        <li>
          For functions with checkboxes, you just need make one action 'check' it or 'uncheck it'. If an option is unchecked it means that the role will not
          have access to this function, user with such role will not be able to make such action on TEID.
        </li>
        <li>One user can have multiple roles. Note that if one role can perform a certain action and another does not, the first will work.</li>
        <li>
          If you allow to the role manage 'own data', user with such role will be able to see and edit only those data which was created by him. If you allow to
          the role manage 'other user data', user with such role will be able to see and edit data which other users have created.
        </li>
        <li>
          In categories such as "view" and "edit" there is an option to add data to "Excepts". "Excepts" work as an exception to viewing or editing certain
          data. Example: if for a certain role you added the Student phone field to the "Can view other user data" exceptions, then the user with this role will
          not see the contents of the "phone" field, which was previously filled by some another user, among all students. In the case of a similar situation,
          but in "Can view own data", a user with this role will not see the student's phone if he himself filled this field earlier.
        </li>
        <li>Additional functions: here you can allow to manage additional features. Just make checkbox 'active' to allow do this.</li>
        <li>All changes will be saved for the Role ONLY by clicking on 'save' button.</li>
      </ol>
    </div>
  )
}

import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'

export const LoadMoreButton = ({ handelPaginationAction, article = '' }) => {
  return (
    <button type='button' className={classes.loadMoreNoteBtn} onClick={handelPaginationAction}>
      {`Load more ${article}`}
    </button>
  )
}

LoadMoreButton.propTypes = {
  handelPaginationAction: PropTypes.func.isRequired,
  article: PropTypes.string,
}

import { axios } from '@Root/API'
import objectToFormdata from 'object-to-formdata'

export const csv = file => {
  return axios.post('import-modules-instances', objectToFormdata({ file }), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

import * as types from './attachment.types'

export const actions = {
	toggleLoading: payload => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: payload => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	get: payload => ({
		type: types.GET_ATTACHMENT,
		payload,
	}),
	set: payload => ({
		type: types.SET_ATTACHMENTS,
		payload,
	}),
	edit: payload => ({
		type: types.EDIT_ATTACHMENT,
		payload,
	}),
	setEdited: payload => ({
		type: types.SET_EDITED_ATTACHMENT,
		payload,
	}),
	create: payload => ({
		type: types.CREATE_ATTACHMENT,
		payload,
	}),
	setCreated: payload => ({
		type: types.SET_CREATED_ATTACHMENT,
		payload,
	}),
	delete: payload => ({
		type: types.DELETE_ATTACHMENT,
		payload,
	}),
	setDeleted: payload => ({
		type: types.SET_DELETED_ATTACHMENT,
		payload,
	}),
	setPermissions: payload => ({
		type: types.SET_PERMISSIONS,
		payload,
	}),
}

import * as types from './role.types'
import { permissionsConfig } from '@Root/configs'

const initialRole = {
	additional_functions: {},
	create_data_permissions: [],
	table_rules_permissions: [],
}

const initialState = {
	isLoading: false,
	isSuccess: false,
	role: initialRole,
	roles: [
		{ options: permissionsConfig, role: { value: 'admin', label: 'admin' } },
		{ options: permissionsConfig, role: { value: 'super-admin', label: 'super-admin' } },
		{ options: permissionsConfig, role: { value: 'manager', label: 'manager' } },
	],
	options: {
		static: {},
		dynamic: {},
	},
	permissions: {},
}

export const reducer = (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case types.TOGGLE_LOADING:
			return {
				...state,
				isLoading: payload,
			}
		case types.TOGGLE_SUCCESS:
			return {
				...state,
				isSuccess: payload,
			}
		case types.SET_ROLE:
			return {
				...state,
				role: payload,
			}
		case types.SET_ROLES:
			return {
				...state,
				roles: payload,
			}
		case types.SET_SINGLE_TAB:
			return {
				...state,
				role: {
					...state.role,
					permissions: state.role.permissions.map(permission => (permission.label === payload.label ? payload : permission)),
				},
			}
		case types.SET_OPTIONS:
			return {
				...state,
				options: {
					...state.options,
					[payload.name]: payload.data,
				},
			}
		case types.CHANGE_EXCEPTIONS:
			return {
				...state,
				role: {
					...state.role,
					permissions: state.role?.permissions.map(permission =>
						permission.label === payload.mainCategoryId
							? {
									...permission,
									categories: permission.categories.map(category =>
										category.label === payload.categoryId
											? {
													...category,
													fields: payload.isMainForm ? payload.fields : category.fields,
													categories: !payload.isMainForm
														? category.categories.map(category =>
																category.label === payload.subCategoryId ? { ...category, fields: payload.fields } : category
														  )
														: category.categories,
											  }
											: category
									),
							  }
							: permission
					),
				},
			}
		case types.SET_PERMISSIONS:
			return {
				...state,
				permissions: payload,
			}
		case types.SET_INITIAL_ROLE:
			return {
				...state,
				role: initialRole,
			}
		default:
			return state
	}
}

import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

export const HeaderNavLinks = ({ style, links }) => {
  return (
    <div className={classes.HeaderNavLinks} style={style}>
      {links.map((link, i) => (
        <NavLink to={`/home/${link.path}`} activeClassName={classes.active} key={i}>
          {link.name}
        </NavLink>
      ))}
    </div>
  );
};

HeaderNavLinks.propTypes = {
  style: PropTypes.object,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  )
};

import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { FormWrapper } from '@Root/HOCs'
import { FileInput, InputLabel, TextInput, Select, DatePicker, TextArea, MultiSelect, MultiDataListAsync, CheckboxInput } from '@Root/components'
import { withRouter } from 'react-router-dom'
import { booleanOptions } from '@Root/configs'
import { SubAddressesFormForFields } from './SubAddressesFormForFields'
import { DBSFormForFields } from './DBSFormForFields'
import { checkAddressValidFields, generateId, emailIsValid, validateCustomIdToEmpty, validateData } from '@Root/helpers'
import { NO_DATA_AVAILABLE } from '@Configs/constants'
import { SubformWrapper } from '@Root/HOCs'

const addressTemplateField = {
  address_line_1: '',
  address_line_2: '',
  address_line_3: '',
  city: '',
  postcode: '',
  country_id: '',
  county: '',
  address_type: null,
  current_address: false,
  other_address_type: '',
}

const firstAddress = [
  {
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    city: '',
    postcode: '',
    country_id: '',
    county: '',
    address_type: null,
    other_address_type: '',
    current_address: false,
    id: generateId(),
  },
]

const dbsTemplateTab = {
  title: false,
  issued_by: '',
  valid_dbs: false,
  date_expires: '',
}

const firstDBS = [
  {
    title: false,
    issued_by: '',
    valid_dbs: false,
    date_expires: '',
    id: generateId(),
  },
]

export class Component extends React.Component {
  appealOptions = ['Academic programme', 'Faculty', 'Location', 'Other', 'The community', 'Theological position', 'Vision', 'Worship style']
  mocsOptions = ['Email', 'Phone', 'Post', 'Social media']

  constructor(props) {
    super(props)
    const { initialIsEditable, initialData, personRelationshipsDataList } = props
    const currentAddressId = initialData?.addresses?.find(address => address.current_address)?.id
    const reservedAddressId = initialData?.addresses ? initialData.addresses[0]?.id : firstAddress[0]?.id
    const currentDBSId = initialData?.dbsses ? initialData.dbsses[initialData.dbsses.filter(dbs => dbs.title).length - 1]?.id : null
    const reservedDBSId = initialData?.dbsses ? initialData.dbsses[initialData.dbsses.filter(dbs => dbs.title).length - 1]?.id : firstDBS[0]?.id
    this.state = {
      isOpenedAccordion: true,
      isEditable: initialIsEditable,
      error: null,
      isSaving: false,
      photoFile: null,
      photo: initialData.photo,
      surname: initialData.surname,
      name: initialData.name,
      middle_names: initialData.middle_names,
      email: initialData.email,
      second_email: initialData.second_email,
      relationshipIds: initialData.relationship_ids, // array of integers
      student_id: initialData.student_id,
      dateOfBirthday: initialData.date_of_birthday,
      genderId: initialData.gender_id, // integer
      title: initialData.title,
      isNotEmailed: initialData.is_not_emailed, // bool
      addresses: initialData.addresses?.length > 0 ? initialData.addresses : firstAddress,
      telPrimary: initialData.tel_primary,
      telSecondary: initialData.tel_secondary,
      telMobile: initialData.tel_mobile,
      organisation_ids: initialData.organisation_ids,
      trashed: initialData.trashed,
      spouse_name: initialData.spouse_name,
      spouse_surname: initialData.spouse_surname,
      dietary_requirements: initialData.dietary_requirements,
      year_joined_federation: initialData.year_joined_federation,
      year_left_federation: initialData.year_left_federation,
      updated_at: initialData.updated_at,
      pruning_review_date: initialData.pruning_review_date,
      financial_arrangements: initialData.financial_arrangements,
      university_card_barcode: initialData.university_card_barcode,
      publicly_fundable: Number(initialData.publicly_fundable),
      age: initialData.age,
      emergency_contact_details: initialData.emergency_contact_details,
      university_card_date: initialData.university_card_date,
      previous_name: initialData.previous_name,
      previous_surname: initialData.previous_surname,
      known_first_name: initialData.known_first_name,
      known_surname: initialData.known_surname,
      moodle_id: initialData.moodle_id,
      include_in_hesa: initialData.include_in_hesa,
      institution_ids: initialData.institution_ids,
      dbsses: initialData.dbsses?.length > 0 ? initialData.dbsses : firstDBS,

      openedItemId: currentAddressId || reservedAddressId,
      activeDBSTabId: currentDBSId || reservedDBSId,
      poc: '',
      appeal: [],
      mocs: [],
      relationshipsDataList: personRelationshipsDataList,
      studentIdValidationError: null,
      studentIdValidationIsFetching: false,
    }
  }

  formIsValid = () => {
    const {
      name,
      surname,
      email,
      relationshipIds,
      student_id,
      studentIdValidationError,
      studentIdValidationIsFetching,
      addresses,
      institution_ids,
      dateOfBirthday,
      pruning_review_date,
    } = this.state
    const { isNew } = this.props

    if (!name) {
      this.showError('name', 'Required')
      return false
    } else if (!surname) {
      this.showError('surname', 'Required')
      return false
    } else if (!email) {
      this.showError('email', 'Required')
      return false
    } else if (!emailIsValid(email)) {
      this.showError('email', 'Invalid email')
      return false
    } else if (!relationshipIds) {
      this.showError('relationship_ids', 'Required')
      return false
    } else if (studentIdValidationError || studentIdValidationIsFetching) {
      return false
    } else if (!isNew && this.relationshipsIncludeStudent() && !student_id) {
      this.showError('student_id', 'Required')
      return false
    } else if (Boolean(checkAddressValidFields(addresses).length)) {
      this.showError('addresses', 'You can`t save empty addresses')
      this.setState({ arrayOfErrorIdFields: checkAddressValidFields(addresses) })
      return false
    } else if (validateData([dateOfBirthday, pruning_review_date])) {
      return false
    } else if (!institution_ids) {
      this.showError('institution_ids', 'Required')
      return false
    } else {
      this.setState({ error: {} })
      return true
    }
  }

  showError = (input, message) => {
    this.setState({ error: { input, message } })
  }

  errorMessage = input => {
    const { error } = this.state
    return error && error.input === input ? error.message : null
  }

  setInitialState = () => {
    const { initialData } = this.props
    const currentAddressId = initialData.addresses?.find(address => address.current_address)?.id
    const reservedAddressId = initialData.addresses ? initialData.addresses[0]?.id : firstAddress[0]?.id
    const currentDBSId = initialData.dbsses ? initialData.dbsses[initialData.dbsses.filter(dbs => dbs.title).length - 1]?.id : null
    const reservedDBSId = initialData.dbsses ? initialData.dbsses[initialData.dbsses.filter(dbs => dbs.title).length - 1]?.id : firstDBS[0]?.id
    this.setState({
      isEditable: false,
      error: null,
      photoFile: null,
      arrayOfErrorIdFields: [],
      photo: initialData.photo,
      surname: initialData.surname,
      name: initialData.name,
      middle_names: initialData.middle_names,
      email: initialData.email,
      second_email: initialData.second_email,
      relationshipIds: initialData.relationship_ids,
      dateOfBirthday: initialData.date_of_birthday,
      genderId: initialData.gender_id, // integer
      title: initialData.title,
      isNotEmailed: initialData.is_not_emailed, // bool
      addresses: initialData.addresses?.length > 0 ? initialData.addresses : firstAddress,
      telPrimary: initialData.tel_primary,
      telSecondary: initialData.tel_secondary,
      telMobile: initialData.tel_mobile,
      organisation_ids: initialData.organisation_ids,
      trashed: initialData.trashed,
      spouse_name: initialData.spouse_name,
      spouse_surname: initialData.spouse_surname,
      dietary_requirements: initialData.dietary_requirements,
      year_joined_federation: initialData.year_joined_federation,
      year_left_federation: initialData.year_left_federation,
      updated_at: initialData.updated_at,
      pruning_review_date: initialData.pruning_review_date,
      financial_arrangements: initialData.financial_arrangements,
      university_card_barcode: initialData.university_card_barcode,
      publicly_fundable: Number(initialData.publicly_fundable),
      age: initialData.age,
      emergency_contact_details: initialData.emergency_contact_details,
      university_card_date: initialData.university_card_date,
      previous_name: initialData.previous_name,
      previous_surname: initialData.previous_surname,
      know_first_name: initialData.know_first_name,
      know_surname: initialData.know_surname,
      moodle_id: initialData.moodle_id,
      institution_ids: initialData.institution_ids,
      dbsses: initialData.dbsses.length > 0 ? initialData.dbsses : firstDBS,
      openedItemId: currentAddressId || reservedAddressId,
      activeDBSTabId: currentDBSId || reservedDBSId,
      student_id: initialData.student_id,
      poc: '',
      appeal: [],
      mocs: [],
    })
  }

  fetchContactLabel = async value => {
    const { data } = await this.props.fetchContactHandler({ search: `id:${value}`, limit: '1' })
    return data.data[0].name
  }

  fetchContactOptions = async value => {
    const { data } = await this.props.fetchContactHandler({ search: `name:${value}`, limit: '100' })
    return data.data.map(option => ({ value: option.id, label: option.name }))
  }

  relationshipsIncludeStudent = () => {
    const { relationshipIds } = this.state
    const { personRelationshipsDataList: relationships } = this.props
    if (!relationshipIds) return
    for (let id of relationshipIds) {
      if (relationships.find(relationship => relationship.value === id)?.label === 'Student') {
        return true
      }
    }
  }

  handleClickCancelButton = () => {
    const { isNew, onCancel } = this.props
    onCancel()
    if (!isNew) this.setInitialState()
  }

  handleClickDeleteButton = () => {
    this.props.deleteHandler(
      () => {
        this.setState({ isSaving: true })
      },
      () => {
        this.setState({ isSaving: false })
      }
    )
  }

  handlerChangeAddress = (id, field, value) => {
    this.setState(prevState => {
      let addressCopy
      const currentAddress = prevState.addresses.find(address => address.id === id)
      if (field === 'address_type' && value !== null && value !== 'Other') {
        const checkDuplicatedValue = this.state.addresses.filter(address => address.id !== id).some(address => address.address_type === value)
        if (checkDuplicatedValue) {
          addressCopy = { ...currentAddress }
          this.showError(
            `address_type/${id}`,
            'The Address Type has already been selected.  It can be selected only once.  If required for this address, remove it from the other address where it is used.'
          )
        } else addressCopy = { ...currentAddress, [field]: value }
      } else addressCopy = { ...currentAddress, [field]: value }

      return {
        ...prevState,
        addresses:
          field !== 'current_address'
            ? prevState.addresses.map(address => (address.id === addressCopy.id ? addressCopy : address))
            : prevState.addresses.map(address => {
              if (address.id === id) return { ...address, current_address: true }
              else if (address.current_address) return { ...address, current_address: false }
              return address
            }),
      }
    })
  }

  handleChangeDBS = (id, field, value) => {
    this.setState(prevState => {
      const currentDBS = prevState.dbsses.find(dbs => dbs.id === id)
      const newDBS = { ...currentDBS, [field]: value }
      return {
        ...prevState,
        dbsses: prevState.dbsses.map(dbs => (dbs.id === id ? newDBS : dbs)),
        activeDBSTabId: field === 'title' && value ? id : prevState.activeDBSTabId,
      }
    })
  }

  onCreateNewAddressField = () => {
    this.setState(prevState => ({ ...prevState, addresses: [...prevState.addresses, { ...addressTemplateField, id: generateId() }] }))
  }

  onRemoveAddressField = id => {
    this.setState(prevState => ({ ...prevState, addresses: prevState.addresses.filter(address => address.id !== id) }))
  }

  onCreateNewDBSTab = () => {
    this.setState(prevState => ({ ...prevState, dbsses: [...prevState.dbsses, { ...dbsTemplateTab, id: generateId() }] }))
  }

  onRemoveDBSTab = id => {
    this.setState(prevState => {
      const currentDbS = prevState.dbsses.find(dbs => dbs.id === id)
      const DBSsesWithoutCurrent = prevState.dbsses.filter(dbs => dbs.id !== id)
      const latestSelected = DBSsesWithoutCurrent.filter(dbs => dbs.title)
      return {
        ...prevState,
        dbsses: DBSsesWithoutCurrent,
        activeDBSTabId: currentDbS.title ? latestSelected[latestSelected.length - 1].id : prevState.activeDBSTabId,
      }
    })
  }

  validateDBSses = () => {
    return validateCustomIdToEmpty(this.state.dbsses)
  }

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return
    const { state } = this
    const { initialData } = this.props
    const photoWasDeleted = initialData.photo !== null && this.state.photo === null

    this.props.saveHandler({
      photo: photoWasDeleted ? null : state.photoFile ? state.photoFile : state.photo,
      surname: state.surname,
      name: state.name,
      middle_names: state.middle_names,
      email: state.email,
      second_email: state.second_email,
      relationship_ids: state.relationshipIds,
      date_of_birthday: state.dateOfBirthday,
      gender_id: state.genderId,
      title: state.title,
      is_not_emailed: state.isNotEmailed,
      addresses: state.addresses,
      tel_primary: state.telPrimary,
      tel_secondary: state.telSecondary,
      tel_mobile: state.telMobile,
      spouse_name: state.spouse_name,
      spouse_surname: state.spouse_surname,
      organisation_ids: state.organisation_ids,
      trashed: state.trashed,
      dietary_requirements: state.dietary_requirements,
      year_joined_federation: state.year_joined_federation,
      year_left_federation: state.year_left_federation,
      pruning_review_date: state.pruning_review_date,
      financial_arrangements: state.financial_arrangements,
      university_card_barcode: state.university_card_barcode,
      publicly_fundable: state.publicly_fundable || 0,
      age: state.age,
      emergency_contact_details: state.emergency_contact_details,
      university_card_date: state.university_card_date,
      previous_name: state.previous_name,
      previous_surname: state.previous_surname,
      known_first_name: state.known_first_name,
      known_surname: state.known_surname,
      moodle_id: state.moodle_id,
      include_in_hesa: state.include_in_hesa,
      institution_ids: state.institution_ids,
      student_id: state.student_id,
      dbsses: this.validateDBSses(),
    })
  }

  changeOpenTab = id => {
    this.setState(prevState => ({ ...prevState, openedItemId: prevState.openedItemId === id ? null : id }))
  }

  onChangeActiveDBSTab = id => {
    this.setState(prevState => {
      const currentDBS = prevState.dbsses.find(dbs => dbs.id === id)
      return { activeDBSTabId: currentDBS.title && prevState.activeDBSTabId !== id ? id : prevState.activeDBSTabId }
    })
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.initialData !== this.props.initialData) {
      this.setInitialState()
    }
    if (prevState.relationshipIds !== this.state.relationshipIds) {
      if (!this.relationshipsIncludeStudent()) {
        this.setState({ student_id: '' })
      }
    }
    if (prevState.student_id !== this.state.student_id) {
      if (this.state.student_id) {
        this.setState({ studentIdValidationIsFetching: true })
        try {
          await this.props.studentIdValidationHandler(this.state.student_id)
          this.setState({ studentIdValidationError: null })
        } catch (error) {
          this.setState({ studentIdValidationError: 'This student ID is already in use' })
        }
        this.setState({ studentIdValidationIsFetching: false })
      } else {
        this.setState({ studentIdValidationError: null })
      }
    }
    if (this.state.relationshipsDataList !== this.props.personRelationshipsDataList) {
      this.setState({ relationshipsDataList: this.props.personRelationshipsDataList })
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {
      error: { errorName, errorMessage },
      isSuccess,
      initialData,
    } = nextProps

    if (errorName && errorMessage && errorName !== this.state.error?.input) {
      this.showError(errorName, errorMessage)
    }
    //if (initialData.student_id && initialData.student_id !== this.state.student_id) this.setState({ student_id: initialData.student_id })
    if (isSuccess) this.setState({ isEditable: false })
  }

  componentDidMount() {
    const { initialData } = this.props
    this.setState(prevState => ({
      relationshipsDataList: prevState.relationshipsDataList.map(relationShip => {
        if (initialData.relationship_ids?.includes(1) && relationShip.value === 1) {
          return { ...relationShip, isDisabled: true, isBlocked: true }
        }
        return relationShip
      }),
    }))
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }

  showNotificationModal = async (text, fieldName, value = null) => {
    const { hideModal, showModal } = this.props
    await new Promise((resolve, reject) => {
      showModal('ConfirmationModal', {
        text: text,
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve,
      })
    }).then(
      () => {
        hideModal()
        if (value !== null) {
          this.setState({ [fieldName]: value })
        }
      },
      () => {
        hideModal()
        if (value !== null) {
          this.setState({ [fieldName]: !value })
        }
      }
    )
  }

  toggleEditMode = () => {
    this.setState(prevState => ({ isEditable: !prevState.isEditable }))
  }

  onOpenDescriptionModal = (description, title) => {
    const { openDescription } = this.props
    openDescription(description, title)
  }

  render() {
    const {
      isEditable,
      photoFile,
      photo,
      surname,
      name,
      second_email,
      middle_names,
      email,
      relationshipIds,
      student_id,
      dateOfBirthday,
      genderId,
      title,
      isNotEmailed,
      addresses,
      telPrimary,
      telSecondary,
      telMobile,
      organisation_ids,
      trashed,
      studentIdValidationIsFetching,
      studentIdValidationError,
      spouse_name,
      spouse_surname,
      dietary_requirements,
      year_joined_federation,
      year_left_federation,
      updated_at,
      pruning_review_date,
      financial_arrangements,
      university_card_barcode,
      publicly_fundable,
      age,
      emergency_contact_details,
      university_card_date,
      openedItemId,
      previous_name,
      previous_surname,
      known_first_name,
      known_surname,
      relationshipsDataList,
      dbsses,
      activeDBSTabId,
      arrayOfErrorIdFields,
      moodle_id,
      include_in_hesa,
      institution_ids,
      isOpenedAccordion
    } = this.state
    const { isNew, titleOption, buttons, isSaving, descriptions, options, canEdit, permissions, isSuccess, is_trashed } = this.props
    const {
      relationshipsIncludeStudent,
      handleClickCancelButton,
      handleClickDeleteButton,
      handleClickSaveButton,
      errorMessage,
      fetchContactLabel,
      fetchContactOptions,
      toggleEditMode,
      onOpenDescriptionModal,
    } = this
    const { contactId } = this.props.match.params
    const { onRead: titleOnRead = '', onEdit: titleOnEdit = '', onCreate: titleOnCreate = '' } = titleOption
    return (
      <div>
        <FormWrapper
          buttons={isEditable ? buttons : []}
          buttonsNames={[{ button: 'delete', name: 'Delete this contact' }]}
          buttonsAreDisabled={isSaving}
          isSpinning={isSaving}
          clickCancelButtonHandler={handleClickCancelButton}
          clickDeleteButtonHandler={handleClickDeleteButton}
          clickSaveButtonHandler={handleClickSaveButton}
          isEditable={isEditable}
          canEdit={canEdit}
          editTitle={titleOnEdit}
          readTitle={titleOnRead}
          createTitle={titleOnCreate}
          isCreate={isNew}
          clickToggleEditModeButtonHandler={toggleEditMode}
          idForm={contactId ? `student_form_${contactId}` : null}
          isSuccess={isSuccess}
        >
          <div className={classes.contentWrapper}>
            <div className={classes.columnWrapper}>
              <div className={classes.latestUpdateWrapper} style={{ justifyContent: isNew ? 'flex-end' : 'space-between' }}>
                {!isNew && (
                  <div>
                    <div>Last updated:</div>{' '}
                    <div>{updated_at?.split(' ')[0] !== NO_DATA_AVAILABLE.label ? updated_at?.split(' ')[0] : NO_DATA_AVAILABLE.value}</div>
                    {updated_at?.split(' ')[1] && <div>{updated_at?.split(' ')[1]}</div>}
                  </div>
                )}
                <FileInput
                  style={{ width: '260px' }}
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  value={photo}
                  file={photoFile}
                  changeHandler={(photo, photoFile) => this.setState({ photo, photoFile })}
                  removeHandler={() => this.setState({ photo: null, photoFile: null })}
                  isDisabled={!isEditable || !permissions.photo?.edit}
                  restrictions={['image']}
                  error={errorMessage('photo')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Name' hasAsterisk={isEditable} description={descriptions.first_name} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 125 }}
                  value={name}
                  changeHandler={name => this.setState({ name })}
                  placeholder='First name'
                  isDisabled={!isEditable || !permissions.name?.edit}
                  error={errorMessage('name')}
                />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 125 }}
                  value={surname}
                  changeHandler={surname => this.setState({ surname })}
                  placeholder='Surname'
                  isDisabled={!isEditable || !permissions.surname?.edit}
                  error={errorMessage('surname')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Middle Names' description={descriptions.middle_names} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={middle_names}
                  changeHandler={middle_names => this.setState({ middle_names })}
                  isDisabled={!isEditable || !permissions.middle_names?.edit}
                  error={errorMessage('middle_names')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Previous name' description={descriptions.previous_name} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 125 }}
                  value={previous_name}
                  changeHandler={previous_name => this.setState({ previous_name })}
                  placeholder='Previous first name'
                  isDisabled={!isEditable || !permissions.previous_name?.edit}
                  error={errorMessage('previous_name')}
                />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 125 }}
                  value={previous_surname}
                  changeHandler={previous_surname => this.setState({ previous_surname })}
                  placeholder='Previous surname'
                  isDisabled={!isEditable || !permissions.previous_surname?.edit}
                  error={errorMessage('previous_surname')}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel
                  text='Known as name'
                  description={descriptions.known_first_name || descriptions.known_surname}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 125 }}
                  value={known_first_name}
                  changeHandler={known_first_name => this.setState({ known_first_name })}
                  placeholder='Know as first name'
                  isDisabled={!isEditable || !permissions.known_first_name?.edit}
                  error={errorMessage('known_first_name')}
                />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 125 }}
                  value={known_surname}
                  changeHandler={known_surname => this.setState({ known_surname })}
                  placeholder='Know as surname'
                  isDisabled={!isEditable || !permissions.known_surname?.edit}
                  error={errorMessage('known_surname')}
                />
              </div>

              <div className={classes.inputWrapper}>
                <InputLabel text='Gender' description={descriptions.gender_id} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 125 }}
                  options={options.genders}
                  value={genderId}
                  changeHandler={genderId => this.setState({ genderId })}
                  maxVisibleOptionsQuantity={5}
                  error={errorMessage('gender_id')}
                  isDisabled={!isEditable || !permissions.gender_id?.edit}
                />
                <InputLabel text='Title' description={descriptions.title} onOpenDescription={onOpenDescriptionModal} />

                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 80 }}
                  value={title}
                  changeHandler={title => this.setState({ title })}
                  placeholder='Title'
                  isDisabled={!isEditable || !permissions.title?.edit}
                  error={errorMessage('title')}
                  maxLength={50}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Spouse Name' description={descriptions.spouse_name} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 125 }}
                  value={spouse_name ? spouse_name : ''}
                  changeHandler={spouse_name => this.setState({ spouse_name })}
                  placeholder='Spouse name'
                  isDisabled={!isEditable || !permissions.spouse_name?.edit}
                  error={errorMessage('spouse_name')}
                />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 125 }}
                  value={spouse_surname ? spouse_surname : ''}
                  changeHandler={spouse_surname => this.setState({ spouse_surname })}
                  placeholder='Spouse surname'
                  error={errorMessage('spouse_surname')}
                  isDisabled={!isEditable || !permissions.spouse_surname?.edit}
                  maxLength={255}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel
                  text='Relationships'
                  hasAsterisk={isEditable || !permissions.photo?.edit}
                  description={descriptions.relationship_ids}
                  onOpenDescription={onOpenDescriptionModal}
                />
                <MultiSelect
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={relationshipsDataList}
                  values={relationshipIds ? relationshipIds : []}
                  changeHandler={relationshipIds => this.setState({ relationshipIds })}
                  error={errorMessage('relationship_ids')}
                  isDisabled={!isEditable || !permissions.relationship_ids?.edit}
                />
              </div>
              {!isNew && relationshipsIncludeStudent() && (
                <div className={classes.inputWrapper}>
                  <InputLabel text='Student ID' hasAsterisk={isEditable} description={descriptions.student_id} onOpenDescription={onOpenDescriptionModal} />
                  <TextInput
                    classNames={!isEditable ? ['borderless'] : []}
                    isDisabled={!isEditable || !permissions.student_id?.edit}
                    style={{ width: 260 }}
                    value={student_id}
                    changeHandler={student_id => this.setState({ student_id })}
                    error={studentIdValidationError ? studentIdValidationError : errorMessage('student_id')}
                    errorIsInfinite={!!studentIdValidationError}
                    isSpinning={studentIdValidationIsFetching}
                  />
                </div>
              )}
              {!isNew && is_trashed && (
                <div className={classes.inputWrapper}>
                  <InputLabel text='Trashed' description={descriptions.trashed} onOpenDescription={onOpenDescriptionModal} />
                  <CheckboxInput
                    style={{ height: 38 }}
                    isChecked={trashed}
                    changeHandler={trashed => this.setState({ trashed })}
                    isDisabled={!isEditable || !permissions.trashed?.edit}
                  />
                </div>
              )}
            </div>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text='Date of Birth' description={descriptions.date_of_birthday} onOpenDescription={onOpenDescriptionModal} />
                <DatePicker
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={dateOfBirthday}
                  changeHandler={dateOfBirthday => {
                    console.log(dateOfBirthday)
                    this.setState({ dateOfBirthday: dateOfBirthday })
                  }}
                  error={errorMessage('date_of_birthday')}
                  isDisabled={!isEditable || !permissions.date_of_birthday?.edit}
                />
              </div>
              {!isNew && (
                <div className={classes.inputWrapper}>
                  <InputLabel text='Age' description={descriptions.age} onOpenDescription={onOpenDescriptionModal} />
                  <TextInput
                    isDisabled={true}
                    style={{ width: 260 }}
                    value={age !== NO_DATA_AVAILABLE.label ? `${age} years old` : 'No data available'}
                    changeHandler={age => this.setState({ age })}
                    error={errorMessage('age')}
                    classNames={!isEditable ? ['borderless'] : []}
                  />
                </div>
              )}
              <div className={classes.inputWrapper}>
                <InputLabel text='Tel - Primary' description={descriptions.tel_primary} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={telPrimary}
                  changeHandler={telPrimary => this.setState({ telPrimary })}
                  error={errorMessage('tel_primary')}
                  isDisabled={!isEditable || !permissions.tel_primary?.edit}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Tel - Secondary' description={descriptions.tel_secondary} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={telSecondary}
                  changeHandler={telSecondary => this.setState({ telSecondary })}
                  error={errorMessage('tel_secondary')}
                  isDisabled={!isEditable || !permissions.tel_secondary?.edit}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Tel - Mobile' description={descriptions.tel_mobile} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={telMobile}
                  changeHandler={telMobile => this.setState({ telMobile })}
                  error={errorMessage('tel_mobile')}
                  isDisabled={!isEditable || !permissions.tel_mobile?.edit}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Email' hasAsterisk={isEditable} description={descriptions.email} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={email}
                  changeHandler={email => this.setState({ email })}
                  error={errorMessage('email')}
                  isDisabled={!isEditable || !permissions.email?.edit}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Second Email' description={descriptions.second_email} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={second_email}
                  changeHandler={second_email => this.setState({ second_email })}
                  error={errorMessage('second_email')}
                  isDisabled={!isEditable || !permissions.second_email?.edit}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Contactable by email' description={descriptions.is_not_emailed} onOpenDescription={onOpenDescriptionModal} />
                <Select
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 125 }}
                  options={booleanOptions}
                  value={isNotEmailed}
                  changeHandler={isNotEmailed => this.setState({ isNotEmailed })}
                  error={errorMessage('is_not_emailed')}
                  isDisabled={!isEditable || !permissions.is_not_emailed?.edit}
                />
              </div>
              <div className={classes.noteWrapper}>
                <span>Note:&nbsp;</span>
                <span>The "Contactable by email" flag should NOT be changed from No to Yes without Contact's confirmation</span>
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Emergency contact details' description={descriptions.emergency_contact_details} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={emergency_contact_details}
                  changeHandler={emergency_contact_details => this.setState({ emergency_contact_details })}
                  error={errorMessage('emergency_contact_details')}
                  isDisabled={!isEditable || !permissions.emergency_contact_details?.edit}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Organisations' description={descriptions.organisation_ids} onOpenDescription={onOpenDescriptionModal} />
                <MultiDataListAsync
                  isAllContact
                  inputClassNames={['borderless']}
                  inputStyle={{ width: 260, minHeight: '38px' }}
                  values={organisation_ids}
                  fetchLabelHandler={value => fetchContactLabel(value)}
                  fetchOptionsHandler={value => fetchContactOptions(value)}
                  changeHandler={organisation_ids => this.setState({ organisation_ids })}
                  isDisabled
                />
              </div>
              <div className={classes.textareaWrapper}>
                <InputLabel text='Dietary Requirements' description={descriptions.dietary_requirements} onOpenDescription={onOpenDescriptionModal} />
                <TextArea
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={dietary_requirements ? dietary_requirements : ''}
                  changeHandler={dietary_requirements => this.setState({ dietary_requirements })}
                  error={errorMessage('dietary_requirements')}
                  isDisabled={!isEditable || !permissions.dietary_requirements?.edit}
                  maxLength={255}
                />
              </div>
              <div className={classes.textareaWrapper}>
                <InputLabel text='Moodle ID' description={descriptions.moodle_id} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={moodle_id}
                  changeHandler={moodle_id => this.setState({ moodle_id })}
                  error={errorMessage('moodle_id')}
                  isDisabled={!isEditable || !permissions.moodle_id?.edit}
                  maxLength={10}
                  restriction={'digits'}
                />
              </div>
            </div>
            <div className={classes.columnWrapper}>
              <SubAddressesFormForFields
                changeHandler={this.handlerChangeAddress}
                openedId={openedItemId}
                changeOpenTab={this.changeOpenTab}
                errorMessage={errorMessage}
                isEditable={isEditable}
                permissions={permissions.address}
                addresses={addresses}
                onCreateNewAddressField={this.onCreateNewAddressField}
                onRemoveAddressField={this.onRemoveAddressField}
                contactAddressesType={options.contact_address_type}
                countryOptions={options.countries}
                isError={errorMessage('addresses')}
                errorArray={arrayOfErrorIdFields}
                isCreate
                onOpenDescription={onOpenDescriptionModal}
                descriptions={descriptions.address}
              />
              <div className={classes.inputWrapper}>
                <InputLabel text='Institution' hasAsterisk={isEditable} description={descriptions.institution_ids} onOpenDescription={onOpenDescriptionModal} />
                <MultiSelect
                  inputClassNames={!isEditable ? ['borderless'] : []}
                  inputStyle={{ width: 260 }}
                  options={options.institutions}
                  values={institution_ids}
                  changeHandler={institution_ids => this.setState({ institution_ids })}
                  isDisabled={!isEditable || !permissions.institution_ids?.edit}
                  error={errorMessage('institution_ids')}
                />
              </div>
            </div>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text='Year joined' description={descriptions.year_joined_federation} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  isDisabled={!isEditable || !permissions.year_joined_federation?.edit}
                  style={{ width: 260 }}
                  value={year_joined_federation}
                  changeHandler={year_joined_federation => this.setState({ year_joined_federation })}
                  error={errorMessage('year_joined_federation')}
                  classNames={!isEditable ? ['borderless'] : []}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Year left' description={descriptions.year_left_federation} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  isDisabled={!isEditable || !permissions.photo?.edit}
                  style={{ width: 260 }}
                  value={year_left_federation}
                  changeHandler={year_left_federation => this.setState({ year_left_federation })}
                  error={errorMessage('year_left_federation')}
                  classNames={!isEditable ? ['borderless'] : []}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Data review date' description={descriptions.pruning_review_date} onOpenDescription={onOpenDescriptionModal} />
                <DatePicker
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={pruning_review_date}
                  changeHandler={pruning_review_date => this.setState({ pruning_review_date })}
                  error={errorMessage('pruning_review_date')}
                  isDisabled={!isEditable || !permissions.pruning_review_date?.edit}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Financial arrangements' description={descriptions.financial_arrangements} onOpenDescription={onOpenDescriptionModal} />
                <TextInput
                  classNames={!isEditable ? ['borderless'] : []}
                  style={{ width: 260 }}
                  value={financial_arrangements}
                  changeHandler={financial_arrangements => this.setState({ financial_arrangements })}
                  error={errorMessage('financial_arrangements')}
                  isDisabled={!isEditable || !permissions.financial_arrangements?.edit}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text='Publicly Fundable' description={descriptions.publicly_fundable} onOpenDescription={onOpenDescriptionModal} />
                <CheckboxInput
                  style={{ height: 38 }}
                  isChecked={Boolean(publicly_fundable)}
                  changeHandler={publicly_fundable => this.setState({ publicly_fundable: Number(publicly_fundable) })}
                  isDisabled={!isEditable || !permissions.publicly_fundable?.edit}
                />
              </div>

              <div className={classes.inputWrapper}>
                <InputLabel text={`Do not include in HESA`} description={descriptions.include_in_hesa} onOpenDescription={onOpenDescriptionModal} />
                <CheckboxInput
                  style={{ height: 38 }}
                  isChecked={include_in_hesa}
                  changeHandler={include_in_hesa => this.setState({ include_in_hesa })}
                  isDisabled={!isEditable || !permissions.include_in_hesa?.edit}
                />
              </div>
              <DBSFormForFields
                data={dbsses}
                permissions={permissions.dbs}
                isEditable={isEditable}
                changeHandler={this.handleChangeDBS}
                activeDBSTabId={activeDBSTabId}
                onChangeActiveTab={this.onChangeActiveDBSTab}
                onCreateNewTab={this.onCreateNewDBSTab}
                onRemoveTab={this.onRemoveDBSTab}
                onOpenDescription={onOpenDescriptionModal}
                descriptions={descriptions.dbs}
              />
              <div className={classes.wrapperAccordion}>
                <SubformWrapper
                  title={"Unused fields"}
                  isRoot
                  isOnlyForView
                  style={{ content: { padding: "10px" } }}
                  isOpened={isOpenedAccordion}
                  isOpenedHandler={() => { this.setState({ isOpenedAccordion: !isOpenedAccordion }) }}
                >

                  <div className={classes.inputWrapper}>
                    <InputLabel text='University card barcode' description={descriptions.university_card_barcode} onOpenDescription={onOpenDescriptionModal} />
                    <TextInput
                      classNames={!isEditable ? ['borderless'] : []}
                      style={{ width: 240 }}
                      value={university_card_barcode}
                      changeHandler={university_card_barcode => this.setState({ university_card_barcode })}
                      error={errorMessage('university_card_barcode')}
                      isDisabled={!isEditable || !permissions.university_card_barcode?.edit}
                    />
                  </div>
                  {relationshipsIncludeStudent() && (
                    <div className={classes.inputWrapper}>
                      <InputLabel
                        text={`University card date applied for`}
                        description={descriptions.university_card_date}
                        onOpenDescription={onOpenDescriptionModal}
                      />
                      <TextInput
                        classNames={!isEditable ? ['borderless'] : []}
                        style={{ width: 240 }}
                        value={university_card_date}
                        changeHandler={university_card_date => this.setState({ university_card_date })}
                        error={errorMessage('university_card_date')}
                        isDisabled={!isEditable || !permissions.university_card_date?.edit}
                        maxLength={4}
                      />
                    </div>
                  )}

                </SubformWrapper></div>
            </div>
          </div>
        </FormWrapper>
      </div>
    )
  }
}

export const ContactsPersonForm = withRouter(Component)

ContactsPersonForm.propTypes = {
  is_trashed: PropTypes.bool,
  isNew: PropTypes.bool,
  titleOption: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string,
  }),
  contactIsSaving: PropTypes.bool,
  initialData: PropTypes.shape({
    photo: PropTypes.string,
    surname: PropTypes.string,
    name: PropTypes.string,
    given_name: PropTypes.string,
    email: PropTypes.string,
    relationship_ids: PropTypes.arrayOf(PropTypes.number),
    date_of_birthday: PropTypes.string,
    gender_id: PropTypes.number,
    title: PropTypes.string,
    is_not_emailed: PropTypes.bool,
    tel_primary: PropTypes.string,
    tel_secondary: PropTypes.string,
    tel_mobile: PropTypes.string,
    year_joined_federation: PropTypes.string,
    year_left_federation: PropTypes.string,
    organisation_ids: PropTypes.arrayOf(PropTypes.number),
    trashed: PropTypes.bool,
    pruning_review_date: PropTypes.string,
    financial_arrangements: PropTypes.string,
    university_card_barcode: PropTypes.string,
    publicly_fundable: PropTypes.bool,
  }),
  permissions: PropTypes.object,
  initialIsEditable: PropTypes.bool,
  titlesDataList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  gendersDataList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  countriesDataList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  academicYears: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  personRelationshipsDataList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  buttons: PropTypes.arrayOf(PropTypes.oneOf(['cancel', 'delete', 'save'])),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func,
  studentIdValidationHandler: PropTypes.func,
}

ContactsPersonForm.defaultProps = {
  isNew: false,
  titleOption: {
    onRead: '',
    onEdit: '',
  },
  contactIsSaving: false,
  initialData: {
    photo: '',
    surname: '',
    name: '',
    middle_names: '',
    email: '',
    relationship_ids: null,
    date_of_birthday: '',
    gender_id: null,
    title: null,
    is_not_emailed: true,
    tel_primary: '',
    tel_secondary: '',
    tel_mobile: '',
    spouse_name: '',
    spouse_surname: '',
    year_joined_federation: '',
    year_left_federation: '',
    updated_at: '',
    organisation_ids: null,
    moodle_id: null,
    trashed: false,
    pruning_review_date: '',
    financial_arrangements: '',
    university_card_barcode: '',
    publicly_fundable: 0,
  },
  permissions: {},
  initialIsEditable: false,
  titlesDataList: [],
  gendersDataList: [],
  countriesDataList: [],
  personRelationshipsDataList: [],
  academicYears: [],
  buttons: ['cancel', 'delete', 'save'],
  deleteHandler: () => { },
  saveHandler: () => { },
  openDescription: () => { },
  studentIdValidationHandler: () => { },
}

import React from 'react'
import { StudentInitiativesForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { studentInitiativesActions, studentInitiativesSelectors } from '@Store/studies'
import { Redirect } from 'react-router-dom'
import { permissionsSelectors } from '@Store/permissions'
import { fieldTooltipManagerSelectors } from '@Store/fieldTooltipManager'

export const CreateStudentInitiativesPage = ({ parentURL, onOpenDescriptionModal }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: engagementId, contactId } = useParams()
  const studentInitiatives = useSelector(studentInitiativesSelectors.studentInitiatives)
  const options = useSelector(studentInitiativesSelectors.options)
  const isLoading = useSelector(studentInitiativesSelectors.isLoading)
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.studiesDescriptions).engagement_student_initiatives

  const onCreate = data => {
    dispatch(studentInitiativesActions.create({ data: data, history, engagementId, contactId }))
  }

  const onCancel = () => {
    history.goBack()
  }

  if (!dataCreationPermissions.engagements_sub) return <Redirect to={parentURL} />
  return (
    <StudentInitiativesForm
      onSave={onCreate}
      onCancel={onCancel}
      canEdit={true}
      isNew
      initialValue={studentInitiatives}
      options={options}
      isLoading={isLoading}
      descriptions={descriptions}
      onOpenDescriptionModal={onOpenDescriptionModal}
    />
  )
}

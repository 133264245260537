export const TOGGLE_SUCCESS = 'hesa/TOGGLE_SUCCESS'
export const GET_HESA = 'hesa/GET_HESA'
export const SET_HESA = 'hesa/SET_HESA'
export const EDIT_HESA = 'hesa/EDIT_HESA'
export const EDIT_COURSE_SESSION = 'hesa/EDIT_COURSE_SESSION'
export const EDIT_MODULE_INSTANCE = 'hesa/EDIT_MODULE_INSTANCE'
export const GET_SNAPSHOT = 'hesa/GET_SNAPSHOT'
export const GET_SNAPSHOTS = 'hesa/GET_SNAPSHOTS'
export const SET_SNAPSHOTS = 'hesa/SET_SNAPSHOTS'
export const GET_STATIC_OPTIONS = 'hesa/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'hesa/GET_DYNAMIC_OPTIONS'
export const SET_OPTIONS = 'hesa/SET_OPTIONS'
export const SET_PERMISSIONS = 'hesa/SET_PERMISSIONS'

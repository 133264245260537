import { createSelector } from 'reselect'

export const isLoading = state => state.studiesReducer.module.reducer.isLoading

export const isSuccess = state => state.studiesReducer.module.reducer.isSuccess

const studyModule = state => state.studiesReducer.module.reducer.module

export const options = state => state.studiesReducer.module.reducer.options

export const minProportion = state => state.studiesReducer.module.reducer.minProportion

export const permissions = state => state.studiesReducer.module.reducer.permissions

export const maxProportion = createSelector(
	studyModule,
	module => 100 - (module?.module_subject?.reduce((acc, module_subject) => acc + Number(module_subject.module_proportion), 0) || 0)
)

export const canBeCreatedSubject = createSelector([studyModule], module => {
	return module?.module_subject?.length < 3 && module?.module_subject?.reduce((acc, module_subject) => acc + +module_subject.module_proportion, 0) < 100
})

export { studyModule as module }

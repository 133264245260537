import React from 'react'
import styles from './style.module.scss'
import { FormTitle, Table } from '@Root/components'
import { useDispatch } from 'react-redux'
import { snackbarActions } from '@Store/snackbar'
import { errorMessage } from '@Root/helpers'

export const TableBlock = ({ title, config }) => {
	const dispatch = useDispatch()

	const setError = message => {
		dispatch(snackbarActions.setSnackbar({ text: errorMessage(message), isError: true }))
	}
	const setSuccessNotification = message => {
		dispatch(snackbarActions.setSuccessNotification({ text: message }))
	}

	return (
		<div className={styles.wrapper}>
			<FormTitle title={title} hideEditButton />
			<Table
				datePeriodDropdownStyle={{ top: -50 }}
				cleanFiltersStyle={{ top: 170 }}
				datePeriodTop={62}
				key={config.name}
				name={config.name}
				mandatoryRules={config.mandatoryRules}
				columns={config.columns}
				fetchDataHandler={config.fetchDataHandler}
				fetchColumnOptions={config.fetchColumnOptions}
				fetchSaveColumnOptions={config.fetchSaveColumnOptions}
				hasImport={config.hasImport}
				hasSorting={config.hasSorting}
				hasFilters={config.hasFilters}
				clickLinkHandlers={config.clickLinkHandlers}
				hasRules={config.hasRules}
				errorHandler={error => setError(error)}
				successHandler={success => setSuccessNotification(success)}
				shouldBeUpdated={false}
			/>
		</div>
	)
}

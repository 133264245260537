import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { errorMessage } from '@Root/helpers'
import { authSelectors, fieldTooltipManagerActions, fieldTooltipManagerSelectors, modalActions, snackbarActions } from '@Root/store'
import { API } from '@Root/API'
import { UserForm } from '@Root/components'
import { useHistory, useLocation } from 'react-router'
import { userActions, userSelectors } from '@Store/admin'

export const EditUser = ({ onOpenDescriptionModal }) => {
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname
  const userId = pathname.split('/').pop()
  const user = useSelector(userSelectors.user)
  const isLoading = useSelector(userSelectors.isLoading)
  const isSuccess = useSelector(userSelectors.isSuccess)
  const permissions = useSelector(userSelectors.permissions)
  const loggedUser = useSelector(authSelectors.user)
  const errors = useSelector(userSelectors.errors)
  const options = useSelector(userSelectors.formattedOptions)
  const descriptions = useSelector(fieldTooltipManagerSelectors.adminDescriptions)['system-users']
  const dispatch = useDispatch()

  const onError = error => {
    dispatch(snackbarActions.setSnackbar({ text: errorMessage(error), isError: true }))
  }

  const onSubmit = payload => {
    dispatch(userActions.edit({ data: payload, loggedUserid: loggedUser.id }))
  }

  const onDelete = () => {
    if (!user.is_main_admin) {
      new Promise((resolve, reject) => {
        dispatch(
          modalActions.showModal('ConfirmationModal', {
            text: 'Are you sure you want to delete this system user?',
            clickRejectButtonHandler: reject,
            clickResolveButtonHandler: resolve,
          })
        )
      })
        .then(async () => {
          try {
            await API.admin.user.remove(userId)
            history.push('/home/admin/manage-system-team/system-user')
          } catch (error) {
            onError(error)
          }
        })
        .finally(() => dispatch(modalActions.hideModal()))
    } else {
      new Promise((resolve, reject) => {
        dispatch(
          modalActions.showModal('NotificationModal', {
            text: 'This user can`t be removed! He is in use of programme.',
            clickRejectButtonHandler: reject,
            clickResolveButtonHandler: resolve,
          })
        )
      }).finally(() => dispatch(modalActions.hideModal()))
    }
  }

  useEffect(() => {
    dispatch(fieldTooltipManagerActions.getDescriptions({ entity: 'admin', entity_type: 'system-users' }))
  }, [dispatch])

  useEffect(() => {
    dispatch(userActions.get({ id: userId }))
    return () => {
      dispatch(userActions.set({}))
      dispatch(userActions.setErrors({}))
    }
  }, [dispatch, userId])

  const onCancel = memoData => {
    dispatch(userActions.set(memoData))
    dispatch(userActions.setErrors({}))
  }

  return (
    <UserForm
      options={options}
      initialValue={user}
      onDelete={onDelete}
      onSave={onSubmit}
      onCancel={onCancel}
      descriptions={descriptions}
      isLoading={isLoading}
      canEdit={permissions.form?.edit}
      canDelete={permissions.form?.delete}
      onOpenDescriptionModal={onOpenDescriptionModal}
      isSuccess={isSuccess}
      initialErrors={errors}
    />
  )
}

import React, { useEffect } from 'react'
import { PostgradForm } from '@Root/components'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { permissionsSelectors } from '@Store/permissions'

export const EditPostgrad = () => {
  const { studyProgrammeId, contactId } = useParams()
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)

  return (
    <PostgradForm
      canManipulate={true}
      studyProgrammeId={studyProgrammeId}
      contactId={contactId}
      canCreate={dataCreationPermissions.student_course_session_sub}
    />
  )
}

export const TOGGLE_LOADING = 'permissions/TOGGLE_LOADING'
export const GET_ADDITIONAL_PERMISSIONS = 'permissions/GET_ADDITIONAL_PERMISSIONS'
export const SET_ADDITIONAL_PERMISSIONS = 'permissions/SET_ADDITIONAL_PERMISSIONS'
export const GET_DATA_CREATION_PERMISSIONS = 'permissions/GET_DATA_CREATION_PERMISSIONS'
export const SET_DATA_CREATION_PERMISSIONS = 'permissions/SET_DATA_CREATION_PERMISSIONS'
export const GET_DATA_DELETING_PERMISSIONS = 'permissions/GET_DATA_DELETING_PERMISSIONS'
export const SET_DATA_DELETING_PERMISSIONS = 'permissions/SET_DATA_DELETING_PERMISSIONS'
export const GET_RULE_PERMISSIONS = 'permissions/GET_RULE_PERMISSIONS'
export const SET_RULE_PERMISSIONS = 'permissions/SET_RULE_PERMISSIONS'
export const GET_MAIN_PERMISSIONS = 'permissions/GET_MAIN_PERMISSIONS'
export const SET_MAIN_PERMISSIONS = 'permissions/SET_MAIN_PERMISSIONS'
export const GET_TABS_PERMISSIONS = 'permissions/GET_TABS_PERMISSIONS'
export const SET_TABS_PERMISSIONS = 'permissions/SET_TABS_PERMISSIONS'

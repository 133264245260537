import React from 'react'
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router'
import { HoverVisibleNotification, SectionTabsLinks } from '@Root/components'
import { helperTextMenuTabLinks } from '@Root/configs'
import styles from './styles.module.scss'
import { HelpMessageManager } from './helpMessageManager'

export const Manager = () => {
  const location = useLocation()
  const pathname = location.pathname
  const { tabId: currentTabLabel } = useParams()
  const currentSlugTab = pathname.split('/')[4]
  const currentSubSlug = pathname.split('/')[5]
  const currentLinks = helperTextMenuTabLinks[currentTabLabel]
  const currentSlugTabObj = currentLinks.find(tab => tab.path === currentSlugTab)

  return (
    <div style={{ marginTop: 190 }} className={styles.wrapper}>
      <SectionTabsLinks tabs={currentLinks} defaultUrl={`/home/help-text/${currentTabLabel}`} />
      <div className={styles.options}>
        <HoverVisibleNotification title={'Instruction'} initialTop={'-230%'}>
          <div>
            <ui>
              <li>
                Here you see a list of fields in different categories. To each field to can add it's description. Such description will work as explanation for
                users.
              </li>
              <li>
                If you add description here (Max allowed number of characters is 1000), near such field users will see '!' icon by clicking on which description
                will appear.
              </li>
              <li>To save data on current page you need to click on 'save' button. You can change description any time.</li>
            </ui>
          </div>
        </HoverVisibleNotification>

        <div className={styles.helpBlock}>
          To add a URL, use {'{{HESA_root_URL}}'}, e.g: {'{{HESA_root_URL}}'}Course/field/COURSEID
        </div>
        <Switch>
          {currentLinks
            .filter(link => !link.hideInTableManager)
            .map(link =>
              link.subLinks ? (
                link.subLinks.map(subLink => {
                  return (
                    <Route
                      path={`/home/help-text/${currentTabLabel}/${link.path}/${subLink.path}`}
                      exact
                      render={() => <HelpMessageManager />}
                      key={subLink.name}
                    />
                  )
                })
              ) : (
                <Route path={`/home/help-text/${currentTabLabel}/${link.path}`} exact render={() => <HelpMessageManager />} key={link.name} />
              )
            )}
          {!currentSlugTab && <Redirect from={`/home/help-text/${currentTabLabel}`} to={`/home/help-text/${currentTabLabel}/${currentLinks[0].path}`} />}
          {currentSlugTab && currentSlugTabObj?.subLinks?.length > 0 && !currentSubSlug && (
            <Redirect
              from={`/home/help-text/${currentTabLabel}/${currentSlugTabObj.path}`}
              to={`/home/help-text/${currentTabLabel}/${currentSlugTabObj.path}/${currentSlugTabObj.subLinks[0].path}`}
            />
          )}
        </Switch>
      </div>
    </div>
  )
}

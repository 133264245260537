import * as types from './hesa.types'

const initialState = {
  isSuccess: false,
  hesa: {
    student: {
      unusedStudentFields: [],
      disabilities: [],
      language_proficiencies: [],
      national_identities: [],
    },
    module_instances: [],
    student_course_session: [],
    engagements: [],
  },
  snapshots: null,
  options: {
    static: {
      student: {},
      disability: {},
      languageProficiencies: {},
      nationalIdentity: {},
      studentCourseSessions: {},
      moduleInstance: {},
    },
    dynamic: {},
  },
  permissions: {},
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      }
    case types.SET_HESA:
      return {
        ...state,
        hesa: {
          ...action.payload,
          student: {
            ...action.payload.student,
            unusedStudentFields: [
              {
                carer_type_id: action.payload?.student.carer_type_id,
                dependant_type_id: action.payload?.student.dependant_type_id,
                language_preference_id: action.payload?.student.language_preference_id,
                scottish_candidate_number: action.payload?.student.scottish_candidate_number,
                service_leaver_type_id: action.payload?.student.service_leaver_type_id,
                service_student_id: action.payload?.student.service_student_id,
                transgender_id: action.payload?.student.transgender_id,
                ucas_personal_identifier: action.payload?.student.ucas_personal_identifier,
                unique_learner_number: action.payload?.student.unique_learner_number,
              },
            ],
          },
        },
      }
    case types.SET_SNAPSHOTS:
      return {
        ...state,
        snapshots: action.payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.name]: action.payload.data,
        },
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      }
    default:
      return state
  }
}

import { useState, useEffect } from 'react'
import * as classes from './style.module.scss'
import { useDispatch } from 'react-redux'
import { modalActions } from '@Root/store'

export const ModalIdle = ({ updateTime, exitEditMode }) => {
  let timer = null
  const dispatch = useDispatch()

  useEffect(() => {
    timer = setTimeout(stopTimerIdle, 300000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const stopTimerIdle = () => {
    exitEditMode('cancel')
    dispatch(modalActions.hideModal())
  }

  const updateTimeIdle = () => {
    updateTime()
    dispatch(modalActions.hideModal())
  }

  return (
    <>
      <div className={classes.wrapper} onClick={updateTimeIdle}></div>
      <div className={classes.modal}>
        <h3 className={classes.tittle}>Are you still editing data? If so, please click "continue" or edit mode will be disabled after 5 minutes</h3>
        <button type='button' className={classes.continueBtn} onClick={updateTimeIdle}>
          continue
        </button>
      </div>
    </>
  )
}

export const TOGGLE_LOADING = 'trainingFacility/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'trainingFacility/TOGGLE_SUCCESS'
export const GET_TRAINING_FACILITY = 'trainingFacility/GET_TRAINING_FACILITY'
export const SET_TRAINING_FACILITY = 'trainingFacility/SET_TRAINING_FACILITY'
export const EDIT_TRAINING_FACILITY = 'trainingFacility/EDIT_TRAINING_FACILITY'
export const DELETE_TRAINING_FACILITY = 'trainingFacility/DELETE_TRAINING_FACILITY'
export const GET_STATIC_OPTIONS = 'trainingFacility/GET_STATIC_OPTIONS'
export const GET_DYNAMIC_OPTIONS = 'trainingFacility/GET_DYNAMIC_OPTIONS'
export const SET_OPTIONS = 'trainingFacility/SET_OPTIONS'
export const SET_PERMISSIONS = 'trainingFacility/SET_PERMISSIONS'

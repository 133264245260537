import * as types from './entryProfile.types'
import { SET_PERMISSIONS } from './entryProfile.types'

const initialState = {
  isLoading: false,
  isSuccess: false,
  entryProfile: {
    access_programme_id: null,
    apel_credits_id: null,
    care_leaver_id: null,
    crdptstu: null,
    credit_scheme_id: null,
    dependant_id: null,
    estranged_student_id: null,
    highestqualoe_id: null,
    marital_status_id: null,
    parental_education_id: null,
    permanent_address_id: null,
    postcode_permanent_address: null,
    previous_provider: null,
    religious_background_id: null,
    socio_economic_classification_id: null,
    standard_occupational2020_id: null,
    standard_occupational_id: null,
    ucas_scheme_code: null,
    year_left_last_provider: null,
  },
  options: {},
  permissions: {},
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.TOGGLE_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.SET_ENTRY_PROFILE:
      return {
        ...state,
        entryProfile: payload,
      }
    case types.TOGGLE_SUCCESS:
      return {
        ...state,
        isSuccess: payload,
      }
    case types.SET_OPTIONS:
      return {
        ...state,
        options: payload,
      }
    case types.CLEAR_DATA:
      return {
        ...state,
        entryProfile: initialState.entryProfile,
      }
    case types.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      }
    default:
      return state
  }
}

import { put, takeEvery, all, call } from 'redux-saga/effects'
import { API } from '@Root/API'
import * as types from './qualificationAward.types'
import { actions } from './qualificationAward.actions'
import { saveDataOptionsToLocalStorage } from '@Root/helpers'
import { qualificationAwardAccreditationRootSaga, qualificationAwardActions } from '@Store/studies'
import { parseErrorToArray } from '@Helpers/parseErrorToArray'
import { snackbarActions } from '@Root/store'

function* get({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { contactId, engagementId, qualificationAwardId } = data
  try {
    const response = yield call(API.engagement.qualificationAward.get, engagementId, qualificationAwardId)
    yield put(actions.set(response.data.data))
    yield put(actions.setPermissions(response.data.permissions))
  } catch (error) {
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/qualification-award`)
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* create({ payload }) {
  yield put(actions.toggleLoading(true))
  yield put(qualificationAwardActions.setErrors([]))
  const { data, history, contactId, engagementId } = payload
  try {
    const response = yield call(API.engagement.qualificationAward.create, engagementId, data)
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/qualification-award/${response.data.data.id}`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The qualification award has been created successfully!' }))
  } catch (error) {
    const errors = parseErrorToArray(error.response.data.errors)
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleLoading(false))
}

function* edit({ payload }) {
  yield put(actions.toggleLoading(true))
  yield put(qualificationAwardActions.setErrors([]))
  const { data, engagementId } = payload
  try {
    const response = yield call(API.engagement.qualificationAward.edit, engagementId, data)
    yield put(actions.set(response.data.data))
    yield put(actions.toggleSuccess(true))
    yield put(snackbarActions.setSuccessNotification({ text: 'The qualification award has been saved successfully!' }))
  } catch (error) {
    yield put(actions.set(data))
    const errors = parseErrorToArray(error.response.data.errors)
    yield put(actions.setErrors(errors))
  }
  yield put(actions.toggleSuccess(false))
  yield put(actions.toggleLoading(false))
}

function* remove({ payload }) {
  yield put(actions.toggleLoading(true))
  const { data, history } = payload
  const { engagementId, contactId, qualificationAwardId } = data
  try {
    yield call(API.engagement.qualificationAward.remove, engagementId, qualificationAwardId)
    history.push(`/home/studies/engagements/${contactId}/${engagementId}/qualification-award`)
    yield put(snackbarActions.setSuccessNotification({ text: 'The qualification award has been deleted successfully!' }))
  } catch (error) {
    console.log(error)
  }
  yield put(actions.toggleLoading(false))
}

function* getOptions() {
  try {
    const {
      data: { qualification_results, qualifications },
    } = yield call(API.engagement.qualificationAward.options.getStatic)
    const formattedData = {
      qualification_results: qualification_results.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
      qualifications: qualifications.map(obj => ({ value: obj.id, label: obj.qualification_title })),
    }
    yield put(actions.setOptions(formattedData))
    saveDataOptionsToLocalStorage('qualification_award', formattedData)
  } catch (error) {
    console.log(error)
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_QUALIFICATION_AWARD, get),
    takeEvery(types.CREATE_QUALIFICATION_AWARD, create),
    takeEvery(types.EDIT_QUALIFICATION_AWARD, edit),
    takeEvery(types.DELETE_QUALIFICATION_AWARD, remove),
    takeEvery(types.GET_OPTIONS, getOptions),
    qualificationAwardAccreditationRootSaga(),
  ])
}

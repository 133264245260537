import React from 'react'
import { VenueForm } from '@Root/components'
import { useDispatch, useSelector } from 'react-redux'
import { fieldTooltipManagerSelectors } from '@Root/store'
import { venueActions, venueSelectors } from '@Store/admin'
import { useHistory } from 'react-router'
import { permissionsSelectors } from '@Store/permissions'
import { Redirect } from 'react-router-dom'

export const CreateVenue = ({ onOpenDescriptionModal, parentURL }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const venue = useSelector(venueSelectors.venue)
  const isLoading = useSelector(venueSelectors.isLoading)
  const errors = useSelector(venueSelectors.errors)
  const descriptions = useSelector(fieldTooltipManagerSelectors.adminDescriptions).venues
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)

  const onSubmit = data => {
    dispatch(venueActions.create({ data, history }))
  }

  const onCancel = () => {
    history.length > 1 ? history.goBack() : history.push(parentURL)
  }

  if (!dataCreationPermissions.venues) return <Redirect to={parentURL} />
  return (
    <VenueForm
      canEdit
      isNew
      initialValue={venue}
      onSave={onSubmit}
      onOpenDescriptionModal={onOpenDescriptionModal}
      descriptions={descriptions}
      isLoading={isLoading}
      onCancel={onCancel}
      initialErrors={errors}
    />
  )
}

import React from 'react'
import classes from './style.module.scss'
import PropTypes from 'prop-types'
import { FormWrapper } from '@Root/HOCs'
import { Table, SectionTitle } from '@Root/components'
import { API } from '@Root/API'
import { Redirect } from 'react-router'
import { useSelector } from 'react-redux'
import { contactSelectors } from '@Root/store'

const PARENT_URL = '/home/contacts/all-contacts'
export const ModulesForm = ({ contactId }) => {
  const contactPermissions = useSelector(contactSelectors.permissions)
  const table = {
    name: 'studentModules',
    columns: [
      {
        name: 'Module code',
        field: 'module_identifier',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Module name',
        field: 'module_title',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Year module was taken',
        field: 'module_start_date',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Number of credits module is worth',
        field: 'credit_value_of_module',
        searchInputType: 'textInput',
        cellType: 'text',
      },
      {
        name: 'Level of credit points',
        field: 'level_of_credit_points',
        searchInputType: 'textInput',
        cellType: 'text',
      },
    ],
    fetchDataHandler: params => API.contact.person.student.module.get(contactId, params),
    fetchColumnOptions: () => API.getColumnOptions('studentModuleInstances'),
    fetchSaveColumnOptions: options => API.saveColumnOptions('studentModuleInstances', options),
    clickLinkHandlers: {},
  }

  if (!contactPermissions.tabs?.student_module) return <Redirect to={PARENT_URL} />
  return (
    <FormWrapper>
      <div className={classes.titleWrapper}>
        <SectionTitle title='Modules' />
      </div>
      <Table
        style={{ marginTop: 20 }}
        datePeriodDropdownStyle={{ top: -70 }}
        name={table.name}
        columns={table.columns}
        fetchDataHandler={table.fetchDataHandler}
        fetchColumnOptions={table.fetchColumnOptions}
        fetchSaveColumnOptions={table.fetchSaveColumnOptions}
        clickLinkHandlers={table.clickLinkHandlers}
      />
    </FormWrapper>
  )
}

ModulesForm.propTypes = {
  fetchTableDataHandler: PropTypes.func,
  contactId: PropTypes.number,
}

ModulesForm.defaultProps = {
  fetchTableDataHandler: () => {},
}

import React, { useState, useEffect, useRef } from 'react'
import styles from './style.module.scss'
import { DatePicker, InputLabel, Select, TextInput, Field, CheckboxInput } from '@Root/components'
import { FormWrapper } from '@Root/HOCs'
import { useError, useToggle } from '@Root/hooks'
import { nanoid } from '@Root/../node_modules/nanoid/index'
import { validateData } from '@Root/helpers'
import { SubformWrapper } from '@Root/HOCs'

const titleOnEdit = 'Edit the Engagement'
const titleOnCreate = 'Create the Engagement'
const titleOnRead = 'Engagement Details'

export const EngagementDetailsForm = ({
  permissions,
  onClickDeleteButton,
  onCancel,
  onSave,
  isNew,
  isSuccess,
  initialValue,
  errorsFields,
  options,
  isLoading,
  descriptions,
  onOpenDescriptionModal,
}) => {
  const [isEditable, toggleEditable] = useToggle(Boolean(isNew))
  const [isOpenedAccordion, setIsOpenedAccordion] = useState(false)
  const { errors, setError, onClear: onClearError } = useError()
  const [data, setData] = useState({})
  const [customCode, setCustomCode] = useState({ label: '', value: '' })
  const [leadSchoolOptions, setLeadSchoolOptions] = useState([])
  const memoData = useRef({})

  const validateForm = () => {
    const { number, start_date, expected_end_date } = data
    if (!number) {
      setError('number', 'Required')
      return false
    }
    if (!start_date) {
      setError('start_date', 'Required')
      return false
    }
    if (validateData([start_date, expected_end_date])) {
      return false
    }

    return true
  }

  useEffect(() => {
    if (errorsFields['number']) {
      const error = errorsFields['number']
      setError('number', error[0])
    }
  }, [errorsFields])

  useEffect(() => {
    if (isSuccess) {
      toggleEditable()
      memoData.current = initialValue
    }
  }, [isSuccess, toggleEditable])

  //init data operation
  useEffect(() => {
    if (options?.lead_schools) {
      if (options.lead_schools.find(el => el.value === initialValue.lead_school) || initialValue.lead_school === null) {
        setLeadSchoolOptions([...options.lead_schools])
        setData(initialValue)
      } else {
        const id = nanoid()
        setLeadSchoolOptions([...options.lead_schools, { label: initialValue.lead_school, value: id }])
        setData({ ...initialValue, lead_school: id })
      }
    }
    if (!memoData.current?.number) memoData.current = initialValue
  }, [initialValue, options.lead_schools])

  const onChangeMode = () => {
    toggleEditable()
  }

  const onCancelEditMode = () => {
    onChangeMode()
    setData(initialValue)
  }

  const onClickSaveButton = () => {
    if (!validateForm()) return
    onClearError()
    if (options.lead_schools.find(el => el.value === data.lead_school) || data.lead_school === null) {
      onSave(data)
    } else {
      onSave({ ...data, lead_school: leadSchoolOptions.find(el => el.value === data.lead_school).label })
      setCustomCode({ label: '', value: '' })
    }
  }

  const onClickCancelButton = () => {
    if (!isNew) {
      onCancelEditMode()
      onClearError()
      onCancel(memoData.current)
    } else {
      onCancel()
    }
  }

  const onChangeValue = fieldName => value => {
    setData(prevState => ({ ...prevState, [fieldName]: value }))
  }

  const onChangeCustomCode = field => value => {
    setCustomCode({ [field]: value, value: nanoid() })
  }

  const onAddCustomCode = () => {
    setData(prevState => ({ ...prevState, lead_school: customCode.value }))
    setLeadSchoolOptions([...leadSchoolOptions, customCode])
    setCustomCode({ label: '', value: '' })
  }

  const { form, fields } = permissions
  const canBeDeletable = form?.delete && !isNew
  const {
    number,
    employing_school_id,
    start_date,
    entryrte_id,
    expected_end_date,
    fee_eligibility_id,
    fee_status_id,
    incoming_exchange_id,
    lead_school,
    nhs_employer_id,
    own_identifier,
    qualified_teacher_status_id,
    research_council_student_id,
    research_council_student_identifier,
    study_intention_id,
    id,
    include_in_hesa,
    outside_id
  } = data

  return (
    <div className={styles.form}>
      <FormWrapper
        buttons={isEditable ? ['cancel', ...(canBeDeletable ? ['delete'] : []), 'save'] : []}
        buttonsNames={[{ button: 'delete', name: 'Delete this engagement' }]}
        buttonsAreDisabled={isLoading}
        isSpinning={isLoading}
        clickCancelButtonHandler={onClickCancelButton}
        clickDeleteButtonHandler={onClickDeleteButton}
        clickSaveButtonHandler={onClickSaveButton}
        clickToggleEditModeButtonHandler={toggleEditable}
        isCreate={isNew}
        isEditable={isEditable}
        readTitle={titleOnRead}
        editTitle={titleOnEdit}
        createTitle={titleOnCreate}
        canEdit={form?.edit}
        isSuccess={isSuccess}
        idForm={id ? `engagement_details_${id}` : null}
      >
        <div className={styles.grid}>
          <div className={styles.column}>
            <div className={styles.row}>
              <InputLabel
                text={'Engagement Number'}
                hasAsterisk={isEditable}
                description={descriptions?.engagement_number}
                onOpenDescription={onOpenDescriptionModal}
              />
              <TextInput
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={number}
                changeHandler={onChangeValue('number')}
                placeholder='Engagement Number'
                isDisabled={!isEditable || !fields?.number?.edit}
                isView={fields?.number?.view}
                error={errors.number}
                maxLength={50}
              />
            </div>{' '}
            <div className={styles.row}>
              <InputLabel
                text={'Engagement start date'}
                hasAsterisk={isEditable}
                description={descriptions.start_date}
                onOpenDescription={onOpenDescriptionModal}
              />
              <DatePicker
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={start_date}
                changeHandler={onChangeValue('start_date')}
                placeholder='Engagement start date'
                isDisabled={!isEditable || !fields?.start_date?.edit}
                isView={fields?.start_date?.view}
                error={errors.start_date}
                maxLength={10}
              />
            </div>{' '}
            <div className={styles.row}>
              <InputLabel text={'Expected end date'} description={descriptions.expected_end_date} onOpenDescription={onOpenDescriptionModal} />
              <DatePicker
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={expected_end_date}
                changeHandler={onChangeValue('expected_end_date')}
                placeholder='Expected end date'
                isDisabled={!isEditable || !fields?.expected_end_date?.edit}
                isView={fields?.expected_end_date?.view}
                maxLength={10}
              />
            </div>{' '}
            <div className={styles.row}>
              <InputLabel text={'Fee eligibility'} description={descriptions.fee_eligibility_id} onOpenDescription={onOpenDescriptionModal} />
              <Select
                inputClassNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={fee_eligibility_id}
                options={options.fee_eligility_types}
                changeHandler={onChangeValue('fee_eligibility_id')}
                placeholder='Fee eligibility'
                isDisabled={!isEditable || !fields?.fee_eligibility_id?.edit}
                isView={fields?.fee_eligibility_id?.view}
                maxLength={2}
              />
            </div>
            <div className={styles.row}>
              <InputLabel text={'Own Engagement identifier'} description={descriptions.own_identifier} onOpenDescription={onOpenDescriptionModal} />
              <TextInput
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={own_identifier}
                changeHandler={onChangeValue('own_identifier')}
                placeholder='Own Engagement identifier'
                isDisabled={!isEditable || !fields?.own_identifier?.edit}
                isView={fields?.own_identifier?.view}
                maxLength={50}
              />
            </div>{' '}
            <div className={styles.row}>
              <InputLabel text={'Research council student'} description={descriptions.research_council_student_id} onOpenDescription={onOpenDescriptionModal} />
              <Select
                inputClassNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                options={options.research_council_students}
                value={research_council_student_id}
                changeHandler={onChangeValue('research_council_student_id')}
                placeholder='Research council student'
                isDisabled={!isEditable || !fields?.research_council_student_id?.edit}
                isView={fields?.research_council_student_id?.view}
                maxLength={2}
              />
            </div>{' '}
            <div className={styles.row}>
              <InputLabel
                text={'Research council student identifier'}
                description={descriptions.research_council_student_identifier}
                onOpenDescription={onOpenDescriptionModal}
              />
              <TextInput
                classNames={!isEditable ? ['borderless'] : []}
                style={{ width: 260 }}
                value={research_council_student_identifier}
                changeHandler={onChangeValue('research_council_student_identifier')}
                placeholder='Research council student identifier'
                isDisabled={!isEditable || !fields?.research_council_student_identifier?.edit}
                isView={fields?.research_council_student_identifier?.view}
                maxLength={8}
              />
            </div>{' '}
            <div className={styles.row}>
              <InputLabel text={'Primarily outside the UK'} description={descriptions.outside_id} onOpenDescription={onOpenDescriptionModal} />
              <Select
                inputClassNames={!isEditable ? ['borderless'] : []}
                inputStyle={{ width: 260 }}
                options={options.outside_uk}
                value={outside_id}
                changeHandler={onChangeValue('outside_id')}
                placeholder='Primarily outside the UK'
                isDisabled={!isEditable || !fields?.outside_id?.edit}
                isView={fields?.outside_id?.view}
              />
            </div>
            <div className={styles.row}>
              <InputLabel text={'Do not include in HESA'} description={descriptions.include_in_hesa} onOpenDescription={onOpenDescriptionModal} />
              <CheckboxInput
                style={{ height: 38 }}
                isChecked={include_in_hesa}
                changeHandler={onChangeValue('include_in_hesa')}
                isDisabled={!isEditable || !fields?.include_in_hesa?.edit}
              />
            </div>{' '}
          </div>
          <div className={styles.column}>
            <div className={styles.wrapperAccordion}>
              <SubformWrapper
                title={"Unused fields"}
                isRoot
                isOnlyForView
                style={{ content: { padding: "10px" } }}
                isOpened={isOpenedAccordion}
                isOpenedHandler={() => { setIsOpenedAccordion(!isOpenedAccordion) }}
                withOutLine
              >
                <div className={styles.row}>
                  <InputLabel text={'Qualified teacher status'} description={descriptions.qualified_teacher_status_id} onOpenDescription={onOpenDescriptionModal} />
                  <Select
                    inputClassNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    options={options.qualified_teacher_status}
                    value={qualified_teacher_status_id}
                    changeHandler={onChangeValue('qualified_teacher_status_id')}
                    placeholder='Qualified teacher status'
                    isDisabled={!isEditable || !fields?.qualified_teacher_status_id?.edit}
                    isView={fields?.qualified_teacher_status_id?.view}
                    maxLength={2}
                  />
                </div>
                <div className={styles.row}>
                  <InputLabel text={'School direct employing school'} description={descriptions.employing_school_id} onOpenDescription={onOpenDescriptionModal} />
                  <Select
                    inputClassNames={!isEditable ? ['borderless'] : []}
                    inputStyle={{ width: 260 }}
                    options={options.employing_schools}
                    value={employing_school_id}
                    changeHandler={onChangeValue('employing_school_id')}
                    placeholder='School direct employing school'
                    isDisabled={!isEditable || !fields?.employing_school_id?.edit}
                    isView={fields?.employing_school_id?.view}
                  />
                </div>
                <div className={styles.row}>
                  <InputLabel text={'ITT entry route'} description={descriptions.entryrte_id} onOpenDescription={onOpenDescriptionModal} />
                  <Select
                    inputClassNames={!isEditable ? ['borderless'] : []}
                    inputStyle={{ width: 260 }}
                    options={options.entryrte}
                    value={entryrte_id}
                    changeHandler={onChangeValue('entryrte_id')}
                    placeholder='ITT entry route'
                    isDisabled={!isEditable || !fields?.entryrte_id?.edit}
                    isView={fields?.entryrte_id?.view}
                    maxLength={2}
                  />
                </div>
                <div className={styles.row}>
                  <InputLabel text={'Fee status'} description={descriptions.fee_status_id} onOpenDescription={onOpenDescriptionModal} />
                  <Select
                    inputClassNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    value={fee_status_id}
                    options={options.fee_statuses}
                    changeHandler={onChangeValue('fee_status_id')}
                    placeholder='Fee status'
                    isDisabled={!isEditable || !fields?.fee_status_id?.edit}
                    isView={fields?.fee_status_id?.view}
                    maxLength={2}
                  />
                </div>

                <div className={styles.row}>
                  <InputLabel text={'Incoming exchange'} description={descriptions.incoming_exchange_id} onOpenDescription={onOpenDescriptionModal} />
                  <Select
                    inputClassNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    value={incoming_exchange_id}
                    options={options.incoming_exchanges}
                    changeHandler={onChangeValue('incoming_exchange_id')}
                    placeholder='Incoming exchange'
                    isDisabled={!isEditable || !fields?.incoming_exchange_id?.edit}
                    isView={fields?.incoming_exchange_id?.view}
                    maxLength={2}
                  />
                </div>
                <div className={styles.row}>
                  <InputLabel text={'Lead school'} description={descriptions.lead_school_id} onOpenDescription={onOpenDescriptionModal} />
                  <Select
                    inputClassNames={!isEditable ? ['borderless'] : []}
                    inputStyle={{ width: 260 }}
                    options={leadSchoolOptions}
                    value={lead_school}
                    changeHandler={onChangeValue('lead_school')}
                    placeholder='Lead school'
                    isDisabled={!isEditable || !fields?.lead_school?.edit}
                    isView={fields?.lead_school_id?.view}
                    maxLength={6}
                    optionsStyle={{ overflow: 'inherit' }}
                    customComponents={
                      <Field
                        style={{ width: 230, border: 'none', height: 36 }}
                        value={customCode.label}
                        onChange={onChangeCustomCode('label')}
                        placeholder='Set custom code'
                        isDisabled={!isEditable}
                        field={'textField'}
                        maxLength={6}
                        autoFocus
                      />
                    }
                    callBack={onAddCustomCode}
                  />
                </div>
                <div className={styles.row}>
                  <InputLabel text={'NHS employer'} description={descriptions.nhs_employer_id} onOpenDescription={onOpenDescriptionModal} />
                  <Select
                    inputClassNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    value={nhs_employer_id}
                    options={options.nhs_employers}
                    changeHandler={onChangeValue('nhs_employer_id')}
                    placeholder='NHS employer'
                    isDisabled={!isEditable || !fields?.nhs_employer_id?.edit}
                    isView={fields?.nhs_employer_id?.view}
                    maxLength={5}
                  />
                </div>
                <div className={styles.row}>
                  <InputLabel text={'Study intention'} description={descriptions.study_intention_id} onOpenDescription={onOpenDescriptionModal} />
                  <Select
                    inputClassNames={!isEditable ? ['borderless'] : []}
                    style={{ width: 260 }}
                    options={options.study_intentions}
                    value={study_intention_id}
                    changeHandler={onChangeValue('study_intention_id')}
                    placeholder='Study intention'
                    isDisabled={!isEditable || !fields?.study_intention_id?.edit}
                    isView={fields?.study_intention_id?.view}
                    maxLength={2}
                  />
                </div>
              </SubformWrapper>
            </div>
          </div>

        </div>
      </FormWrapper>
    </div>
  )
}

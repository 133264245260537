import React from 'react'
import classes from './style.module.scss'
import { FormWrapper } from '@Root/HOCs'
import { Table, SectionTitle, ActionButton } from '@Root/components'
import { API } from '@Root/API'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { permissionsSelectors } from '@Store/permissions'

export const ContactsProgrammesForm = ({ contactId, handleAddStudyProgramme }) => {
  const dataCreationPermissions = useSelector(permissionsSelectors.dataCreationPermissions)
  const history = useHistory()

  const fetchTableDataHandler = params => API.contact.person.student.programme.get(contactId, params)
  const clickProgrammeNameHandler = studyProgrammeId => {
    history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}`)
  }

  const table = {
    name: 'contactsStudentApplications',
    mandatoryRules: [
      {
        id: 'Current Year',
        name: 'Current Year',
        description: 'Current Year',
        matchType: 'All of the rules',
        rows: [
          {
            field: 'academic_year',
            equality: 'is',
            filter: 'thisAcademicYear',
          },
        ],
      },
    ],
    columns: [
      {
        name: 'Programme',
        field: 'program_name',
        cellType: 'link',
        searchInputType: 'select',
        options: [],
        cellLinkField: 'program_name_link',
      },
      {
        name: 'Academic Year',
        field: 'academic_year',
        cellType: 'text',
        searchInputType: 'select',
        options: [],
      },
      {
        name: 'Ordinand',
        field: 'ordinand',
        cellType: 'text',
        searchInputType: 'select',
        options: [],
      },
      {
        name: 'Institution',
        field: 'institution',
        cellType: 'text',
        searchInputType: 'select',
        options: [],
      },
      {
        name: 'Learning Center',
        field: 'training_mode',
        cellType: 'text',
        searchInputType: 'select',
        options: [],
      },
      {
        name: 'Credits',
        field: 'credits',
        cellType: 'text',
        searchInputType: 'textInput',
      },
      {
        name: 'Hesa reason for ending',
        field: 'reason_course_session',
        cellType: 'text',
        searchInputType: 'textInput',
      },
      {
        name: 'Note for session ending',
        field: 'reason_note',
        cellType: 'text',
        searchInputType: 'textInput',
      },
    ],
    fetchDataHandler: params => fetchTableDataHandler(params),
    fetchColumnOptions: () => API.getColumnOptions('contactsStudentProgrammes'),
    fetchSaveColumnOptions: options => API.saveColumnOptions('contactsStudentProgrammes', options),
    clickLinkHandlers: {
      Programme: row => clickProgrammeNameHandler(row.id),
    },
    defaultSortingField: 'academic_year',
  }

  return (
    <FormWrapper>
      <div className={classes.titleWrapper}>
        <SectionTitle title='Student Course Sessions' />
        {dataCreationPermissions.student_course_session && <ActionButton onClick={handleAddStudyProgramme}>Add a Student Course Sessions</ActionButton>}
      </div>
      <Table
        style={{ marginTop: 92 }}
        datePeriodDropdownStyle={{ top: -70 }}
        name={table.name}
        mandatoryRules={table.mandatoryRules}
        columns={table.columns}
        fetchDataHandler={table.fetchDataHandler}
        fetchColumnOptions={table.fetchColumnOptions}
        fetchSaveColumnOptions={table.fetchSaveColumnOptions}
        clickLinkHandlers={table.clickLinkHandlers}
        defaultSortingField={table.defaultSortingField}
      />
    </FormWrapper>
  )
}
